import React, { Component } from "react"; // import * as d3 from "d3";
import { Link } from "react-router-dom";
// import './Login.css';
import "./Login.sass";
// import { Link } from "react-router-dom";
// import { Auth } from "aws-amplify";
import { Form, Button, Checkbox, Modal } from "semantic-ui-react"; // Loader, Input, Modal
// import plant from '../../img/plant.svg';
// import loginData from "..//../data/login.csv"
import dompurify from "dompurify";
import googleIcon from "../../img/google-icon.svg";
// import slackIcon from '..//../img/slack-icon.svg';
// import SlackLogin from "../../components/SlackLogin";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // clientData: {},
      // loginData: {},
      isLoading: false,
      isError: false,
      isNameValid: true,
      isCompanyNameValid: true,
      email: "",
      company: "",
      password: "",
      isPasswordVisible: false,
      isAuthenticated: false,
      slackKeyReceived: false,
      // darkTheme: this.props.darkTheme || "false",
    };
    this.sanitizer = dompurify.sanitize;
    // this.loginData = {};
    this.keepMeInLocalStore = undefined;
    // this.slackAppId = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT_ID || undefined;
    // this.slackAppPermits = window.__RUNTIME_CONFIG__.REACT_APP_APP_PERMITS || undefined;
    this.slackAppPermits = `im:history,im:read,mpim:history,mpim:read,channels:read,channels:history,groups:read,groups:history,users:read,users:read.email`;
    // this.apiUrl = window.__RUNTIME_CONFIG__.REACT_APP_API_URL || undefined;
    this.respSlackError = "";
    this.slackRedirectUrl = "";
    // // console.log( window.__RUNTIME_CONFIG__.REACT_APP_API_URL);
    // this.getClientData = this.props.getClientData.bind(this);
  }

  componentDidMount() {
    document.title = "Pavooq Log In";
    // let allLoginData = {};
    // d3.csv(loginData, (data) => {
    //   if (data) {
    //     data.forEach(data => {
    //       allLoginData[data.email] = data;
    //     });
    //     this.setState({ loginData: allLoginData });
    //   };
    // });
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0; //this.state.company.length > 0 &&
  }

  toggleLocalStoreSaving = (event) => {
    event.preventDefault();
    this.keepMeInLocalStore = !this.keepMeInLocalStore;
    // console.log("this.keepMeInLocalStore", this.keepMeInLocalStore);
  };

  togglePass = (event) => {
    event.preventDefault();
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: this.sanitizer(event.target.value),
    });
  };

  pavooqAPILogin = (email, password, persistent) => {
    var details = {
      username: email,
      password: password,
    };

    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(`${this.props.apiUrl}/authorization?persistent=${persistent}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      credentials: "include", //'same-origin',
      body: formBody,
    })
      // .then((res) => res.json())
      .then((res) => {
        // console.log("res ", res, "res ok", res.ok);
        if (res.ok) {
          return res.json();
        } else {
          if (res.status === 400) {
            this.onFail("Invalid credentials");
            // this.setState({ firstStepPassed: false });
          } else {
            this.onFail(res.statusText || "Error");
          }
          // this.onFail(res.statusText || "Error");
          // this.respSlackError =  res.statusText || 'Error';
          // };
        }
      })
      .then((response) => {
        // console.log("response ", response);
        if (!this.state.isError) {
          // console.log("response ", response);
          // this.props.toggleLoading()
          // this.setState({ isAuthenticated: true, isLoading: false });
          // let user = {
          //   id: "",
          //   email: this.state.email,
          //   password: this.state.password,
          // };
          // Cookies.set('access_token', response.access_token); //, { expires: 90 }
          this.props.userHasAuthenticated(true);
          // this.props.getSlackAutorisationLink(); //response.access_token
          this.props.getSlackUserListToInvite(true); //response.access_token,this.props.apiUrl,
          this.props.profileAPILoader();
          
          this.props.history.push(`${this.props.initLocation}` || "forcepage");
          this.props.handleItemClick(`${this.props.initLocation}` || "forcepage");

          this.setState({ isAuthenticated: true, isLoading: false });
        }
      })
      .catch((e) => {
        console.log("ERROR", e);

        this.onFail(e);
        // this.respSlackError = e;
      });
  };

  onFail = (e) => {
    if (e) {
      console.log("ERROR", e);
      this.respSlackError = e;
      this.setState((prevState) => ({
        isError: true,
        isLoading: !prevState.isLoading,
        errorText: e,
      })); // isLoading: false
    }
  };

  handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ isLoading: true });
    this.pavooqAPILogin(
      this.state.email,
      this.state.password,
      !!this.keepMeInLocalStore
    );
  };

  render() {
    // this.slackRedirectUrl = window.location.href || undefined;
    // let protocolName = window.location.protocol || undefined;
    // if (this.slackRedirectUrl) {
    //   this.slackRedirectUrl = this.slackRedirectUrl.split("/")[2];
    // }
    // this.slackRedirectUrl = `${protocolName}//${this.slackRedirectUrl}/auth/install`; //location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');

    // console.log("url", this.slackRedirectUrl);
    return (
      <div
        // className="Login"
        className={this.props.darkTheme ? "Login dark" : "Login"}
      >
        <div className="main-content">
          <div className="signup-link-container">
            <span>Not a member? </span>
            <Link className="login-link" to={`/signup`}>
              Sign up now
            </Link>
          </div>

          <div className="sign-in-container">
            <div className="logo-big-container"></div>
            {/* <img src={logoBigWhite} alt="" srcSet="" /> */}
          </div>

          <div>
            <div className="main-registration-form">
              <div className="registration-form">
                <Form autoComplete="new-password" onSubmit={this.handleSubmit}>
                  {/* <Button className="field big-button slack-btn" fluid>
                    <img src={slackIcon} alt="" srcSet="" />
                    <span>Sign in with Slack</span>
                  </Button> */}

                  <Modal
                    size="mini"
                    closeIcon={<span className="close-container"></span>}
                    className={
                      this.props.darkTheme ? "customModal dark" : "customModal"
                    }
                    centered={true} //false
                    open={this.state.isError}
                    onClose={() => this.setState({ isError: false })}
                    onOpen={() => this.setState({ isError: true })}
                    // trigger={
                    //   <SlackLogin
                    //     buttonText="Sign in with Slack"
                    //     isDisabled={true}
                    //     buttonLoading={this.state.slackKeyReceived}
                    //     slackRedirectUrl={this.slackRedirectUrl}
                    //     // onFailure={console.log("")}//{this.onFail}
                    //     // onSuccess={console.log("")}//{this.onSuccess}
                    //     // slackClientId={this.slackAppId}
                    //     slackUserScope={this.slackAppPermits}
                    //   />
                    // }
                  >
                    <Modal.Header>Opps..</Modal.Header>
                    <Modal.Content>
                      <Modal.Description>
                        An error has been occurred.
                        <br />
                        {this.respSlackError ? this.respSlackError + "" : ""}
                      </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions className="center">
                      <Button
                        className="wide"
                        onClick={() => this.setState({ isError: false })}
                      >
                        Ok
                      </Button>
                    </Modal.Actions>
                  </Modal>

                  <Button
                    className="field big-button google-btn"
                    fluid
                    as="a"
                    href={`${this.props.apiUrl}/oauth/google`}
                    // disabled
                    type="submit"
                  >
                    <img src={googleIcon} alt="" srcSet="" />
                    <span>Sign in with Google</span>
                  </Button>

                  {/* <a target="_blank" rel="noopener noreferrer" className="field big-button slack-btn" href="https://slack.com/oauth/v2/authorize?scope=incoming-webhook&client_id=33336676.569200954261">
                    <img src={slackIcon} alt="" srcSet="" />
                    <span>Sign in with Slack</span>
                  </a> */}

                  <div className="login-opts-divider">
                    <hr /> <span> Or </span> <hr />
                  </div>
                  <Form.Input //Field
                    // disabled
                    className="item-content"
                    // control='input'
                    type="email"
                    autoComplete="new-password"
                    name="email"
                    id="email"
                    // label='Email'
                    placeholder="Login"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />

                  {/* <Form.Field
                    className="item-content"
                    autoComplete="new-password"
                    name="email"
                    id="email"
                    value={this.state.email}
                    onChange={this.handleChange} >
                    <input placeholder='Login' />
                     </Form.Field> */}

                  <Form.Input //Field
                    // disabled
                    className="item-content password"
                    // control={Input}
                    icon={
                      <div
                        className={
                          this.state.isPasswordVisible
                            ? "pwrd-icon"
                            : "pwrd-icon-hidden"
                        }
                        onClick={this.togglePass}
                        alt=""
                        srcSet=""
                      ></div>
                    }
                    // label='Password'
                    type={this.state.isPasswordVisible ? "text" : "password"}
                    autoComplete="new-password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.handleChange}
                  />
                  <div className="additional-login-options">
                    <Checkbox
                      // className="item-content"
                      // disabled
                      // id="keepMeLoginedCheckbox"
                      className=" checkbox-custom field "
                      label={
                        <label id="keepMeLoginedCheckboxLabel">
                          Keep me logged in
                        </label>
                      } //"Keep me logged in"//Remember me
                      // label="Keep me logged in"//Remember me
                      onClick={this.toggleLocalStoreSaving}
                    />

                    <Link
                      className="login-link field pass-recover" // disable-links"
                      to={`/recoverpassword`}
                    >
                      Forgot password?
                    </Link>
                  </div>
                  <Button
                    className="big-button"
                    fluid
                    type="submit"
                    id="submitLogin"
                    disabled={!this.validateForm()}
                  >
                    {/* {this.state.isLoading && <Icon name='spinner' /> } */}
                    <h4>
                      {!this.state.isLoading ? (
                        <span>Sign in</span>
                      ) : (
                        <span className="Loader-wrapper">Loading...</span>
                      )}
                    </h4>
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div className="additional-container">
          <div className="mail-to-container">
            <div className="avatar-monochrome-support"></div>
            <p>
              Any problem? Please, write me at
              {/* <Link
                // className='borderless-button'
                to='#'
                onClick={(e) => {
                    window.location = 'yevhen@pavooq.com';
                    e.preventDefault();
                }}
              >
                {' yevhen@pavooq.com'}
              </Link> */}
              <a id="loginSupport" href="mailto:yevhen.bereza@pavooq.com">
                yevhen.bereza@pavooq.com
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
