// import React from 'react'; //., { Component }
// import * as d3 from "d3";

// const helpers = {
//   isMobileDevice: function () {
//     var check = false;
//     (function (a) {
//       if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
//     })(navigator.userAgent || navigator.vendor || window.opera);
//     // console.clear();
//     return check;
//   },  
//   validateFullName: function (fullName) {
//     const pattern = /^[a-zA-Z]{2,40}( [a-zA-Z]{2,40})+$/;
//     return pattern.test(String(fullName));
//   },
//   anyToNumber: function (any) {
//     return +(any + "").replace(/[^-.0-9]+/g, "") || 0;
//   },
//   positions: {},
//   transform: { k: 1, x: undefined, y: undefined },
//   resetTransform: () => {
//     this.positions = { k: 1, x: undefined, y: undefined }
//   }
// };

let isMobileDevice = function () {
  var check = false;
  (function (a) {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  // console.clear();
  return check;
};
let validateFullName = function (fullName) {
  const pattern = /^[a-zA-Z]{2,40}( [a-zA-Z]{2,40})+$/;
  return pattern.test(String(fullName));
};
let anyToNumber = function (any) {
  return +(any + "").replace(/[^-.0-9]+/g, "") || 0;
};
let positions = { isEmpty: true };
let getPositions = () => {
  return positions;
}
let resetPositions = () => {
  positions = { isEmpty: true };
  // console.log("positions was cleared", positions);
}
let transform = { k: 1, x: undefined, y: undefined, calculatedK: false };
let resetTransform = () => {
  // transform = { k: 1, x: undefined, y: undefined, calculatedK: false };
  transform.k = 1;
  transform.x = undefined;
  transform.y = undefined;
  transform.calculatedK = false;
  // console.log("transform was cleared", transform);
}

// Valid formats:

// (123) 456-7890
// (123)456-7890
// 123-456-7890
// 123.456.7890
// 1234567890
// +31636363634
// 075-63546725

//   /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im

let phonenumber = (inputtxt) => {
  let firstChar = inputtxt ? inputtxt.charAt(0) : "";
  let otherChars = inputtxt.slice(1, inputtxt.length - 1);//  inputtxt &&  inputtxt.length > 3 ? inputtxt.splice(1, inputtxt.length - 1) : "";
  // console.log(inputtxt, otherChars);
  var phoneno = /^[0-9]{6,14}$/; //  /^[+\d](?:.*\d)?$/; //^[+\d](?:.*\d)?$ // /^\+?([0-9]{6,20})$/;
  let isNumberSuitst = otherChars ? otherChars.match(phoneno) : false
  if (firstChar && firstChar === "+" && otherChars && isNumberSuitst) {
    // console.log("phone number valid");
    return true;
  }
  else {
    // console.log("phone number invalid");
    return false;
  }
};

let trimPhone = (phoneString) => {

  return phoneString.replace(/\D/g, '');
}
let validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const defaultDataArray = {
  FFMD2ECVNWR: {
    name: "Wilhelm Röntgen",
    email: "uemasdfynrvelwjfv@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png",
    granted: true,
    invitable: true,
    relations: {
      VMD2ECVNWR: {
        private: 0,
        mention: 14,
        general: 14,
      },
      VMD2ECVNEW: {
        private: 0,
        mention: 14,
        general: 14,
      },
      VMD2ECVNEW1: {
        private: 0,
        mention: 14,
        general: 14,
      },
      VMD2ECVNEW2: {
        private: 0,
        mention: 14,
        general: 14,
      },
    },
  },
  VMD2ECVNWR: {
    name: "Hendrik Lorentz",
    email: "uemafynrvelwjfv@example.com",
    avatar: "", //"https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/sloth_lazybones_sluggard_avatar-512.png",
    granted: true,
    invitable: true,
    relations: {
      FFMD2ECVNWR: {
        private: 0,
        mention: 14,
        general: 14,
      },
      RMTWI9S0SZ: {
        private: 7,
        mention: 58,
        general: 78,
      },
      GA64M5QDM5: {
        private: 30,
        mention: 2,
        general: 22,
      },
      WBDPZ1FG2W: {
        private: 68,
        mention: 64,
        general: 55,
      },
      "1DYZ7OS00O": {
        private: 1,
        mention: 86,
        general: 60,
      },
      CK4RGUJ11B: {
        private: 74,
        mention: 95,
        general: 34,
      },
      IXUUCPL1PC: {
        private: 32,
        mention: 67,
        general: 70,
      },
      AQJX5JSJQO: {
        private: 5,
        mention: 74,
        general: 53,
      },
      W5KYHODPWZ: {
        private: 82,
        mention: 94,
        general: 81,
      },
    },
  },
  RMTWI9S0SZ: {
    name: "Pieter Zeeman",
    email: "jktwczsuhdyptmg@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/trump_president_avatar_male-512.png",
    granted: true,
    invitable: true,
    relations: {
      GA64M5QDM5: {
        private: 57,
        mention: 74,
        general: 4,
      },
      IXUUCPL1PC: {
        private: 42,
        mention: 32,
        general: 20,
      },
      W5KYHODPWZ: {
        private: 52,
        mention: 31,
        general: 57,
      },
      CK4RGUJ11B: {
        private: 19,
        mention: 48,
        general: 69,
      },
      VMD2ECVNWR: {
        private: 7,
        mention: 58,
        general: 92,
      },
      "1DYZ7OS00O": {
        private: 62,
        mention: 88,
        general: 7,
      },
      AQJX5JSJQO: {
        private: 51,
        mention: 24,
        general: 49,
      },
      MB8BB9S9DJ: {
        private: 59,
        mention: 90,
        general: 7,
      },
    },
  },
  GA64M5QDM5: {
    name: "Henri Becquerel",
    email: "ibymzquvrcpjmww@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/geisha_japanese_woman_avatar-512.png",
    granted: true,
    invitable: true,
    relations: {
      RMTWI9S0SZ: {
        private: 57,
        mention: 74,
        general: 61,
      },
      W5KYHODPWZ: {
        private: 37,
        mention: 97,
        general: 88,
      },
      IXUUCPL1PC: {
        private: 84,
        mention: 19,
        general: 81,
      },
      VMD2ECVNWR: {
        private: 30,
        mention: 2,
        general: 56,
      },
      "1DYZ7OS00O": {
        private: 39,
        mention: 5,
        general: 77,
      },
      AQJX5JSJQO: {
        private: 90,
        mention: 82,
        general: 18,
      },
      MB8BB9S9DJ: {
        private: 44,
        mention: 51,
        general: 61,
      },
    },
  },
  WBDPZ1FG2W: {
    name: "Pierre Curie",
    email: "gkxwgfiubfrincy@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/breaking_bad_chemisrty_avatar_heisenberg-512.png",
    granted: true,
    invitable: true,
    relations: {
      CK4RGUJ11B: {
        private: 23,
        mention: 57,
        general: 62,
      },
      AQJX5JSJQO: {
        private: 26,
        mention: 69,
        general: 49,
      },
      VMD2ECVNWR: {
        private: 68,
        mention: 64,
        general: 52,
      },
      "1DYZ7OS00O": {
        private: 59,
        mention: 74,
        general: 11,
      },
      IXUUCPL1PC: {
        private: 26,
        mention: 13,
        general: 97,
      },
      W5KYHODPWZ: {
        private: 2,
        mention: 77,
        general: 3,
      },
      MB8BB9S9DJ: {
        private: 13,
        mention: 29,
        general: 52,
      },
    },
  },
  "1DYZ7OS00O": {
    name: "Marie Curie",
    email: "wdfmwwsthifwntr@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/muslim_woman_paranja_avatar-512.png",
    granted: true,
    invitable: true,
    relations: {
      W5KYHODPWZ: {
        private: 90,
        mention: 98,
        general: 24,
      },
      WBDPZ1FG2W: {
        private: 59,
        mention: 74,
        general: 60,
      },
      RMTWI9S0SZ: {
        private: 62,
        mention: 88,
        general: 7,
      },
      CK4RGUJ11B: {
        private: 87,
        mention: 36,
        general: 50,
      },
      IXUUCPL1PC: {
        private: 36,
        mention: 29,
        general: 4,
      },
      MB8BB9S9DJ: {
        private: 97,
        mention: 8,
        general: 71,
      },
      AQJX5JSJQO: {
        private: 14,
        mention: 12,
        general: 36,
      },
      VMD2ECVNWR: {
        private: 1,
        mention: 86,
        general: 70,
      },
      GA64M5QDM5: {
        private: 39,
        mention: 5,
        general: 89,
      },
    },
  },
  CK4RGUJ11B: {
    name: "Lord Rayleigh",
    email: "hytwqqxtmsmhwfb@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/wrestler_luchador_fighter_man-512.png",
    granted: true,
    invitable: true,
    relations: {
      RMTWI9S0SZ: {
        private: 19,
        mention: 48,
        general: 81,
      },
      WBDPZ1FG2W: {
        private: 23,
        mention: 57,
        general: 5,
      },
      "1DYZ7OS00O": {
        private: 87,
        mention: 36,
        general: 41,
      },
      VMD2ECVNWR: {
        private: 74,
        mention: 95,
        general: 44,
      },
      W5KYHODPWZ: {
        private: 46,
        mention: 100,
        general: 69,
      },
      MB8BB9S9DJ: {
        private: 15,
        mention: 83,
        general: 97,
      },
      IXUUCPL1PC: {
        private: 8,
        mention: 70,
        general: 41,
      },
      AQJX5JSJQO: {
        private: 72,
        mention: 91,
        general: 19,
      },
    },
  },
  IXUUCPL1PC: {
    name: "Philipp Lenard",
    email: "gppeiqvzibzduvc@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/afro_man_male_avatar-512.png",
    granted: true,
    invitable: true,
    relations: {
      RMTWI9S0SZ: {
        private: 42,
        mention: 32,
        general: 71,
      },
      GA64M5QDM5: {
        private: 84,
        mention: 19,
        general: 2,
      },
      "1DYZ7OS00O": {
        private: 36,
        mention: 29,
        general: 76,
      },
      W5KYHODPWZ: {
        private: 48,
        mention: 89,
        general: 77,
      },
      VMD2ECVNWR: {
        private: 32,
        mention: 67,
        general: 31,
      },
      MB8BB9S9DJ: {
        private: 57,
        mention: 11,
        general: 80,
      },
      CK4RGUJ11B: {
        private: 8,
        mention: 70,
        general: 29,
      },
      AQJX5JSJQO: {
        private: 88,
        mention: 51,
        general: 76,
      },
      WBDPZ1FG2W: {
        private: 26,
        mention: 13,
        general: 14,
      },
    },
  },
  AQJX5JSJQO: {
    name: "	Joseph Thomson",
    email: "xcuftiyyhlohrmv@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/spider_insect_bug_avatar-512.png",
    granted: true,
    invitable: true,
    relations: {
      WBDPZ1FG2W: {
        private: 26,
        mention: 69,
        general: 32,
      },
      "1DYZ7OS00O": {
        private: 14,
        mention: 12,
        general: 89,
      },
      IXUUCPL1PC: {
        private: 88,
        mention: 51,
        general: 13,
      },
      CK4RGUJ11B: {
        private: 72,
        mention: 91,
        general: 34,
      },
      VMD2ECVNWR: {
        private: 5,
        mention: 74,
        general: 0,
      },
      GA64M5QDM5: {
        private: 90,
        mention: 82,
        general: 30,
      },
      RMTWI9S0SZ: {
        private: 51,
        mention: 24,
        general: 98,
      },
      MB8BB9S9DJ: {
        private: 95,
        mention: 41,
        general: 67,
      },
      W5KYHODPWZ: {
        private: 93,
        mention: 20,
        general: 100,
      },
    },
  },
  W5KYHODPWZ: {
    name: "Abraham Michelson",
    email: "xpkbkadvutbsupb@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/avocado_scream_avatar_food-512.png",
    granted: true,
    invitable: true,
    relations: {
      RMTWI9S0SZ: {
        private: 52,
        mention: 31,
        general: 79,
      },
      GA64M5QDM5: {
        private: 37,
        mention: 97,
        general: 7,
      },
      "1DYZ7OS00O": {
        private: 90,
        mention: 98,
        general: 72,
      },
      CK4RGUJ11B: {
        private: 46,
        mention: 100,
        general: 4,
      },
      IXUUCPL1PC: {
        private: 48,
        mention: 89,
        general: 99,
      },
      AQJX5JSJQO: {
        private: 93,
        mention: 20,
        general: 98,
      },
      VMD2ECVNWR: {
        private: 82,
        mention: 94,
        general: 12,
      },
      WBDPZ1FG2W: {
        private: 2,
        mention: 77,
        general: 81,
      },
      MB8BB9S9DJ: {
        private: 2,
        mention: 85,
        general: 86,
      },
    },
  },
  MB8BB9S9DJ: {
    name: "Gabriel Lippmann",
    email: "uxgxinbtstvseai@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/ozzy_male_avatar_rock_singer-512.png",
    granted: true,
    invitable: true,
    relations: {
      "1DYZ7OS00O": {
        private: 97,
        mention: 8,
        general: 81,
      },
      CK4RGUJ11B: {
        private: 15,
        mention: 83,
        general: 90,
      },
      IXUUCPL1PC: {
        private: 57,
        mention: 11,
        general: 12,
      },
      AQJX5JSJQO: {
        private: 95,
        mention: 41,
        general: 17,
      },
      W5KYHODPWZ: {
        private: 2,
        mention: 85,
        general: 68,
      },
      WBDPZ1FG2W: {
        private: 13,
        mention: 29,
        general: 49,
      },
      RMTWI9S0SZ: {
        private: 59,
        mention: 90,
        general: 63,
      },
      GA64M5QDM5: {
        private: 44,
        mention: 51,
        general: 96,
      },
    },
  },
  VMD2ECVNEW: {
    name: "Karl Ferdinand Braun",
    email: "uemafynrvelwjfv@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/punk_man_person_avatar-128.png",
    granted: true,
    invitable: true,
    relations: {
      FFMD2ECVNWR: {
        private: 0,
        mention: 14,
        general: 14,
      },
      RMTWI9SNEW: {
        private: 7,
        mention: 58,
        general: 78,
      },
      GA64M5QNEW: {
        private: 30,
        mention: 2,
        general: 22,
      },
      WBDPZ1FNEW: {
        private: 68,
        mention: 64,
        general: 55,
      },
      "1DYZ7OSNEW": {
        private: 1,
        mention: 86,
        general: 60,
      },
      CK4RGUJNEW: {
        private: 74,
        mention: 95,
        general: 34,
      },
      IXUUCPLNEW: {
        private: 32,
        mention: 67,
        general: 70,
      },
      AQJX5JSNEW: {
        private: 5,
        mention: 74,
        general: 53,
      },
      W5KYHODNEW: {
        private: 82,
        mention: 94,
        general: 81,
      },
    },
  },
  RMTWI9SNEW: {
    name: "Guglielmo Marconi",
    email: "jktwczsuhdyptmg@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/cloud_crying_rain_avatar-128.png",
    granted: true,
    invitable: true,
    relations: {
      GA64M5QNEW: {
        private: 57,
        mention: 74,
        general: 4,
      },
      IXUUCPLNEW: {
        private: 42,
        mention: 32,
        general: 20,
      },
      W5KYHODNEW: {
        private: 52,
        mention: 31,
        general: 57,
      },
      CK4RGUJNEW: {
        private: 19,
        mention: 48,
        general: 69,
      },
      VMD2ECVNEW: {
        private: 7,
        mention: 58,
        general: 92,
      },
      "1DYZ7OSNEW": {
        private: 62,
        mention: 88,
        general: 7,
      },
      AQJX5JSNEW: {
        private: 51,
        mention: 24,
        general: 49,
      },
      MB8BB9SNEW: {
        private: 59,
        mention: 90,
        general: 7,
      },
    },
  },
  GA64M5QNEW: {
    name: "Johannes Diderik van der Waals	",
    email: "ibymzquvrcpjmww@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/zombie_avatar_monster_dead-128.png",
    granted: true,
    invitable: true,
    relations: {
      RMTWI9SNEW: {
        private: 57,
        mention: 74,
        general: 61,
      },
      W5KYHODNEW: {
        private: 37,
        mention: 97,
        general: 88,
      },
      IXUUCPLNEW: {
        private: 84,
        mention: 19,
        general: 81,
      },
      VMD2ECVNEW: {
        private: 30,
        mention: 2,
        general: 56,
      },
      "1DYZ7OSNEW": {
        private: 39,
        mention: 5,
        general: 77,
      },
      AQJX5JSNEW: {
        private: 90,
        mention: 82,
        general: 18,
      },
      MB8BB9SNEW: {
        private: 44,
        mention: 51,
        general: 61,
      },
    },
  },
  WBDPZ1FNEW: {
    name: "	Wilhelm Wien",
    email: "gkxwgfiubfrincy@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/marilyn_monroe_artist_avatar-128.png",
    granted: true,
    invitable: true,
    relations: {
      CK4RGUJNEW: {
        private: 23,
        mention: 57,
        general: 62,
      },
      AQJX5JSNEW: {
        private: 26,
        mention: 69,
        general: 49,
      },
      VMD2ECVNEW: {
        private: 68,
        mention: 64,
        general: 52,
      },
      "1DYZ7OSNEW": {
        private: 59,
        mention: 74,
        general: 11,
      },
      IXUUCPLNEW: {
        private: 26,
        mention: 13,
        general: 97,
      },
      W5KYHODNEW: {
        private: 2,
        mention: 77,
        general: 3,
      },
      MB8BB9SNEW: {
        private: 13,
        mention: 29,
        general: 52,
      },
    },
  },
  "1DYZ7OSNEW": {
    name: "Gustaf Dalén",
    email: "wdfmwwsthifwntr@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/coffee_zorro_avatar_cup-128.png",
    granted: true,
    invitable: true,
    relations: {
      W5KYHODNEW: {
        private: 90,
        mention: 98,
        general: 24,
      },
      WBDPZ1FNEW: {
        private: 59,
        mention: 74,
        general: 60,
      },
      RMTWI9SNEW: {
        private: 62,
        mention: 88,
        general: 7,
      },
      CK4RGUJNEW: {
        private: 87,
        mention: 36,
        general: 50,
      },
      IXUUCPLNEW: {
        private: 36,
        mention: 29,
        general: 4,
      },
      MB8BB9SNEW: {
        private: 97,
        mention: 8,
        general: 71,
      },
      AQJX5JSNEW: {
        private: 14,
        mention: 12,
        general: 36,
      },
      VMD2ECVNEW: {
        private: 1,
        mention: 86,
        general: 70,
      },
      GA64M5QNEW: {
        private: 39,
        mention: 5,
        general: 89,
      },
    },
  },
  CK4RGUJNEW: {
    name: "Heike Kamerlingh Onnes",
    email: "hytwqqxtmsmhwfb@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/suicide_squad_woman_avatar_joker-128.png",
    granted: true,
    invitable: true,
    relations: {
      RMTWI9SNEW: {
        private: 19,
        mention: 48,
        general: 81,
      },
      WBDPZ1FNEW: {
        private: 23,
        mention: 57,
        general: 5,
      },
      "1DYZ7OSNEW": {
        private: 87,
        mention: 36,
        general: 41,
      },
      VMD2ECVNEW: {
        private: 74,
        mention: 95,
        general: 44,
      },
      W5KYHODNEW: {
        private: 46,
        mention: 100,
        general: 69,
      },
      MB8BB9SNEW: {
        private: 15,
        mention: 83,
        general: 97,
      },
      IXUUCPLNEW: {
        private: 8,
        mention: 70,
        general: 41,
      },
      AQJX5JSNEW: {
        private: 72,
        mention: 91,
        general: 19,
      },
    },
  },
  IXUUCPLNEW: {
    name: "Max von Laue",
    email: "gppeiqvzibzduvc@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/anime_spirited_away_no_face_nobody-128.png",
    granted: true,
    invitable: true,
    relations: {
      RMTWI9SNEW: {
        private: 42,
        mention: 32,
        general: 71,
      },
      GA64M5QNEW: {
        private: 84,
        mention: 19,
        general: 2,
      },
      "1DYZ7OSNEW": {
        private: 36,
        mention: 29,
        general: 76,
      },
      W5KYHODNEW: {
        private: 48,
        mention: 89,
        general: 77,
      },
      VMD2ECVNEW: {
        private: 32,
        mention: 67,
        general: 31,
      },
      MB8BB9SNEW: {
        private: 57,
        mention: 11,
        general: 80,
      },
      CK4RGUJNEW: {
        private: 8,
        mention: 70,
        general: 29,
      },
      AQJX5JSNEW: {
        private: 88,
        mention: 51,
        general: 76,
      },
      WBDPZ1FNEW: {
        private: 26,
        mention: 13,
        general: 14,
      },
    },
  },
  AQJX5JSNEW: {
    name: "William Henry Bragg",
    email: "xcuftiyyhlohrmv@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/old_man_male_portrait-128.png",
    granted: true,
    invitable: true,
    relations: {
      WBDPZ1FNEW: {
        private: 26,
        mention: 69,
        general: 132,
      },
      "1DYZ7OSNEW": {
        private: 14,
        mention: 12,
        general: 189,
      },
      IXUUCPLNEW: {
        private: 88,
        mention: 51,
        general: 113,
      },
      CK4RGUJNEW: {
        private: 72,
        mention: 91,
        general: 34,
      },
      VMD2ECVNEW: {
        private: 5,
        mention: 74,
        general: 0,
      },
      GA64M5QNEW: {
        private: 90,
        mention: 82,
        general: 30,
      },
      RMTWI9SNEW: {
        private: 51,
        mention: 24,
        general: 98,
      },
      MB8BB9SNEW: {
        private: 95,
        mention: 41,
        general: 67,
      },
      W5KYHODNEW: {
        private: 93,
        mention: 20,
        general: 100,
      },
    },
  },
  W5KYHODNEW: {
    name: "William Lawrence Bragg",
    email: "xpkbkadvutbsupb@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/halloween_jason_friday_movie-128.png",
    granted: true,
    invitable: true,
    relations: {
      RMTWI9SNEW: {
        private: 52,
        mention: 31,
        general: 79,
      },
      GA64M5QNEW: {
        private: 37,
        mention: 97,
        general: 7,
      },
      "1DYZ7OSNEW": {
        private: 90,
        mention: 98,
        general: 72,
      },
      CK4RGUJNEW: {
        private: 46,
        mention: 100,
        general: 4,
      },
      IXUUCPLNEW: {
        private: 48,
        mention: 89,
        general: 99,
      },
      AQJX5JSNEW: {
        private: 93,
        mention: 20,
        general: 98,
      },
      VMD2ECVNEW: {
        private: 82,
        mention: 94,
        general: 12,
      },
      WBDPZ1FNEW: {
        private: 2,
        mention: 77,
        general: 81,
      },
      MB8BB9SNEW: {
        private: 2,
        mention: 85,
        general: 86,
      },
    },
  },
  MB8BB9SNEW: {
    name: "Charles Glover Barkla",
    email: "uxgxinbtstvseai@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/nun_sister_woman_avatar-128.png",
    granted: true,
    invitable: true,
    relations: {
      "1DYZ7OSNEW": {
        private: 97,
        mention: 8,
        general: 81,
      },
      CK4RGUJNEW: {
        private: 15,
        mention: 83,
        general: 90,
      },
      IXUUCPLNEW: {
        private: 57,
        mention: 11,
        general: 12,
      },
      AQJX5JSNEW: {
        private: 95,
        mention: 41,
        general: 17,
      },
      W5KYHODNEW: {
        private: 2,
        mention: 85,
        general: 68,
      },
      WBDPZ1FNEW: {
        private: 13,
        mention: 29,
        general: 49,
      },
      RMTWI9SNEW: {
        private: 59,
        mention: 90,
        general: 63,
      },
      GA64M5QNEW: {
        private: 44,
        mention: 51,
        general: 96,
      },
    },
  },
  VMD2ECVNEW1: {
    name: "Max Planck",
    email: "uemafynrvelwjfv@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/sheep_mutton_animal_avatar-128.png",
    granted: true,
    invitable: true,
    relations: {
      FFMD2ECVNWR: {
        private: 0,
        mention: 14,
        general: 14,
      },
      RMTWI9SNEW1: {
        private: 7,
        mention: 58,
        general: 78,
      },
      GA64M5QNEW1: {
        private: 30,
        mention: 2,
        general: 22,
      },
      WBDPZ1FNEW1: {
        private: 68,
        mention: 64,
        general: 55,
      },
      "1DYZ7OSNEW1": {
        private: 1,
        mention: 86,
        general: 60,
      },
      CK4RGUJNEW1: {
        private: 74,
        mention: 95,
        general: 34,
      },
      IXUUCPLNEW1: {
        private: 32,
        mention: 67,
        general: 70,
      },
      AQJX5JSNEW1: {
        private: 5,
        mention: 74,
        general: 53,
      },
      W5KYHODNEW1: {
        private: 82,
        mention: 94,
        general: 81,
      },
    },
  },
  RMTWI9SNEW1: {
    name: "Johannes Stark",
    email: "jktwczsuhdyptmg@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/baby_kid_toddler_child-128.png",
    granted: true,
    invitable: true,
    relations: {
      GA64M5QNEW1: {
        private: 57,
        mention: 74,
        general: 4,
      },
      IXUUCPLNEW1: {
        private: 42,
        mention: 32,
        general: 20,
      },
      W5KYHODNEW1: {
        private: 52,
        mention: 31,
        general: 57,
      },
      CK4RGUJNEW1: {
        private: 19,
        mention: 48,
        general: 69,
      },
      VMD2ECVNEW1: {
        private: 7,
        mention: 58,
        general: 92,
      },
      "1DYZ7OSNEW1": {
        private: 62,
        mention: 88,
        general: 7,
      },
      AQJX5JSNEW1: {
        private: 51,
        mention: 24,
        general: 49,
      },
      MB8BB9SNEW1: {
        private: 59,
        mention: 90,
        general: 7,
      },
    },
  },
  GA64M5QNEW1: {
    name: "Charles Édouard Guillaume",
    email: "ibymzquvrcpjmww@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/female_woman_avatar_portrait_1-128.png",
    granted: true,
    invitable: true,
    relations: {
      RMTWI9SNEW1: {
        private: 57,
        mention: 74,
        general: 61,
      },
      W5KYHODNEW1: {
        private: 37,
        mention: 97,
        general: 88,
      },
      IXUUCPLNEW1: {
        private: 84,
        mention: 19,
        general: 81,
      },
      VMD2ECVNEW1: {
        private: 30,
        mention: 2,
        general: 56,
      },
      "1DYZ7OSNEW1": {
        private: 39,
        mention: 5,
        general: 77,
      },
      AQJX5JSNEW1: {
        private: 90,
        mention: 82,
        general: 18,
      },
      MB8BB9SNEW1: {
        private: 44,
        mention: 51,
        general: 61,
      },
    },
  },
  WBDPZ1FNEW1: {
    name: "Charles Édouard Guillaume",
    email: "gkxwgfiubfrincy@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/indian_native_boy_kid-128.png",
    granted: true,
    invitable: true,
    relations: {
      CK4RGUJNEW1: {
        private: 23,
        mention: 57,
        general: 62,
      },
      AQJX5JSNEW1: {
        private: 26,
        mention: 69,
        general: 49,
      },
      VMD2ECVNEW1: {
        private: 68,
        mention: 64,
        general: 52,
      },
      "1DYZ7OSNEW1": {
        private: 59,
        mention: 74,
        general: 11,
      },
      IXUUCPLNEW1: {
        private: 26,
        mention: 13,
        general: 97,
      },
      W5KYHODNEW1: {
        private: 2,
        mention: 77,
        general: 3,
      },
      MB8BB9SNEW1: {
        private: 13,
        mention: 29,
        general: 52,
      },
    },
  },
  "1DYZ7OSNEW1": {
    name: "Ronald Ross",
    email: "wdfmwwsthifwntr@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/afro_woman_female_person-128.png",
    granted: true,
    invitable: true,
    relations: {
      W5KYHODNEW1: {
        private: 90,
        mention: 98,
        general: 24,
      },
      WBDPZ1FNEW1: {
        private: 59,
        mention: 74,
        general: 60,
      },
      RMTWI9SNEW1: {
        private: 62,
        mention: 88,
        general: 7,
      },
      CK4RGUJNEW1: {
        private: 87,
        mention: 36,
        general: 50,
      },
      IXUUCPLNEW1: {
        private: 36,
        mention: 29,
        general: 4,
      },
      MB8BB9SNEW1: {
        private: 97,
        mention: 8,
        general: 71,
      },
      AQJX5JSNEW1: {
        private: 14,
        mention: 12,
        general: 36,
      },
      VMD2ECVNEW1: {
        private: 1,
        mention: 86,
        general: 70,
      },
      GA64M5QNEW1: {
        private: 39,
        mention: 5,
        general: 89,
      },
    },
  },
  CK4RGUJNEW1: {
    name: "Niels Ryberg Finsen",
    email: "hytwqqxtmsmhwfb@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/indian_woman_hindi_avatar-128.png",
    granted: true,
    invitable: true,
    relations: {
      RMTWI9SNEW1: {
        private: 19,
        mention: 48,
        general: 81,
      },
      WBDPZ1FNEW1: {
        private: 23,
        mention: 57,
        general: 5,
      },
      "1DYZ7OSNEW1": {
        private: 87,
        mention: 36,
        general: 41,
      },
      VMD2ECVNEW1: {
        private: 74,
        mention: 95,
        general: 44,
      },
      W5KYHODNEW1: {
        private: 46,
        mention: 100,
        general: 69,
      },
      MB8BB9SNEW1: {
        private: 15,
        mention: 83,
        general: 97,
      },
      IXUUCPLNEW1: {
        private: 8,
        mention: 70,
        general: 41,
      },
      AQJX5JSNEW1: {
        private: 72,
        mention: 91,
        general: 19,
      },
    },
  },
  IXUUCPLNEW1: {
    name: "Ivan Pavlov",
    email: "gppeiqvzibzduvc@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/draw_pencil_addicted_love-128.png",
    granted: true,
    invitable: true,
    relations: {
      RMTWI9SNEW1: {
        private: 42,
        mention: 32,
        general: 71,
      },
      GA64M5QNEW1: {
        private: 84,
        mention: 19,
        general: 2,
      },
      "1DYZ7OSNEW1": {
        private: 36,
        mention: 29,
        general: 76,
      },
      W5KYHODNEW1: {
        private: 48,
        mention: 89,
        general: 77,
      },
      VMD2ECVNEW1: {
        private: 32,
        mention: 67,
        general: 31,
      },
      MB8BB9SNEW1: {
        private: 57,
        mention: 11,
        general: 80,
      },
      CK4RGUJNEW1: {
        private: 8,
        mention: 70,
        general: 29,
      },
      AQJX5JSNEW1: {
        private: 88,
        mention: 51,
        general: 76,
      },
      WBDPZ1FNEW1: {
        private: 26,
        mention: 13,
        general: 14,
      },
    },
  },
  AQJX5JSNEW1: {
    name: "Robert Koch",
    email: "xcuftiyyhlohrmv@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/boy_afro_kid_child-128.png",
    granted: true,
    invitable: true,
    relations: {
      WBDPZ1FNEW1: {
        private: 26,
        mention: 69,
        general: 32,
      },
      "1DYZ7OSNEW1": {
        private: 14,
        mention: 12,
        general: 89,
      },
      IXUUCPLNEW1: {
        private: 88,
        mention: 51,
        general: 13,
      },
      CK4RGUJNEW1: {
        private: 72,
        mention: 91,
        general: 34,
      },
      VMD2ECVNEW1: {
        private: 5,
        mention: 74,
        general: 0,
      },
      GA64M5QNEW1: {
        private: 90,
        mention: 82,
        general: 30,
      },
      RMTWI9SNEW1: {
        private: 51,
        mention: 24,
        general: 98,
      },
      MB8BB9SNEW1: {
        private: 95,
        mention: 41,
        general: 67,
      },
      W5KYHODNEW1: {
        private: 93,
        mention: 20,
        general: 100,
      },
    },
  },
  W5KYHODNEW1: {
    name: "Camillo Golgi",
    email: "xpkbkadvutbsupb@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/muslim_man_avatar-128.png",
    granted: true,
    invitable: true,
    relations: {
      RMTWI9SNEW1: {
        private: 52,
        mention: 31,
        general: 79,
      },
      GA64M5QNEW1: {
        private: 37,
        mention: 97,
        general: 7,
      },
      "1DYZ7OSNEW1": {
        private: 90,
        mention: 98,
        general: 72,
      },
      CK4RGUJNEW1: {
        private: 46,
        mention: 100,
        general: 4,
      },
      IXUUCPLNEW1: {
        private: 48,
        mention: 89,
        general: 99,
      },
      AQJX5JSNEW1: {
        private: 93,
        mention: 20,
        general: 98,
      },
      VMD2ECVNEW1: {
        private: 82,
        mention: 94,
        general: 12,
      },
      WBDPZ1FNEW1: {
        private: 2,
        mention: 77,
        general: 81,
      },
      MB8BB9SNEW1: {
        private: 2,
        mention: 85,
        general: 86,
      },
    },
  },
  MB8BB9SNEW1: {
    name: "Santiago Ramón y Cajal",
    email: "uxgxinbtstvseai@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/apple_watch_sick_illness_avatar-128.png",
    granted: true,
    invitable: true,
    relations: {
      "1DYZ7OSNEW1": {
        private: 97,
        mention: 8,
        general: 81,
      },
      CK4RGUJNEW1: {
        private: 15,
        mention: 83,
        general: 90,
      },
      IXUUCPLNEW1: {
        private: 57,
        mention: 11,
        general: 12,
      },
      AQJX5JSNEW1: {
        private: 95,
        mention: 41,
        general: 17,
      },
      W5KYHODNEW1: {
        private: 2,
        mention: 85,
        general: 68,
      },
      WBDPZ1FNEW1: {
        private: 13,
        mention: 29,
        general: 49,
      },
      RMTWI9SNEW1: {
        private: 59,
        mention: 90,
        general: 63,
      },
      GA64M5QNEW1: {
        private: 44,
        mention: 51,
        general: 96,
      },
    },
  },
  VMD2ECVNEW2: {
    name: "Charles Louis Alphonse Laveran",
    email: "uemafynrvelwjfv@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/sheep_mutton_animal_avatar-128.png",
    granted: true,
    invitable: true,
    relations: {
      FFMD2ECVNWR: {
        private: 0,
        mention: 14,
        general: 14,
      },
      RMTWI9SNEW2: {
        private: 7,
        mention: 58,
        general: 78,
      },
      GA64M5QNEW2: {
        private: 30,
        mention: 2,
        general: 22,
      },
      WBDPZ1FNEW2: {
        private: 68,
        mention: 64,
        general: 55,
      },
      "1DYZ7OSNEW2": {
        private: 1,
        mention: 86,
        general: 60,
      },
      CK4RGUJNEW2: {
        private: 74,
        mention: 95,
        general: 34,
      },
      IXUUCPLNEW2: {
        private: 32,
        mention: 67,
        general: 70,
      },
      AQJX5JSNEW2: {
        private: 5,
        mention: 74,
        general: 53,
      },
      W5KYHODNEW2: {
        private: 82,
        mention: 94,
        general: 81,
      },
    },
  },
  RMTWI9SNEW2: {
    name: "Élie Metchnikoff",
    email: "jktwczsuhdyptmg@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/baby_kid_toddler_child-128.png",
    granted: true,
    invitable: true,
    relations: {
      GA64M5QNEW2: {
        private: 57,
        mention: 74,
        general: 4,
      },
      IXUUCPLNEW2: {
        private: 42,
        mention: 32,
        general: 20,
      },
      W5KYHODNEW2: {
        private: 52,
        mention: 31,
        general: 57,
      },
      CK4RGUJNEW2: {
        private: 19,
        mention: 48,
        general: 69,
      },
      VMD2ECVNEW2: {
        private: 7,
        mention: 58,
        general: 92,
      },
      "1DYZ7OSNEW2": {
        private: 62,
        mention: 88,
        general: 7,
      },
      AQJX5JSNEW2: {
        private: 51,
        mention: 24,
        general: 49,
      },
      MB8BB9SNEW2: {
        private: 59,
        mention: 90,
        general: 7,
      },
    },
  },
  GA64M5QNEW2: {
    name: "Paul Ehrlich",
    email: "ibymzquvrcpjmww@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/female_woman_avatar_portrait_1-128.png",
    granted: true,
    invitable: true,
    relations: {
      RMTWI9SNEW2: {
        private: 57,
        mention: 74,
        general: 61,
      },
      W5KYHODNEW2: {
        private: 37,
        mention: 97,
        general: 88,
      },
      IXUUCPLNEW2: {
        private: 84,
        mention: 19,
        general: 81,
      },
      VMD2ECVNEW2: {
        private: 30,
        mention: 2,
        general: 56,
      },
      "1DYZ7OSNEW2": {
        private: 39,
        mention: 5,
        general: 77,
      },
      AQJX5JSNEW2: {
        private: 90,
        mention: 82,
        general: 18,
      },
      MB8BB9SNEW2: {
        private: 44,
        mention: 51,
        general: 61,
      },
    },
  },
  WBDPZ1FNEW2: {
    name: "Emil Theodor Kocher",
    email: "gkxwgfiubfrincy@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/indian_native_boy_kid-128.png",
    granted: true,
    invitable: true,
    relations: {
      CK4RGUJNEW2: {
        private: 23,
        mention: 57,
        general: 62,
      },
      AQJX5JSNEW2: {
        private: 26,
        mention: 69,
        general: 49,
      },
      VMD2ECVNEW2: {
        private: 68,
        mention: 64,
        general: 52,
      },
      "1DYZ7OSNEW2": {
        private: 59,
        mention: 74,
        general: 11,
      },
      IXUUCPLNEW2: {
        private: 26,
        mention: 13,
        general: 97,
      },
      W5KYHODNEW2: {
        private: 2,
        mention: 77,
        general: 3,
      },
      MB8BB9SNEW2: {
        private: 13,
        mention: 29,
        general: 52,
      },
    },
  },
  "1DYZ7OSNEW2": {
    name: "Albrecht Kossel",
    email: "wdfmwwsthifwntr@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/afro_woman_female_person-128.png",
    granted: true,
    invitable: true,
    relations: {
      W5KYHODNEW2: {
        private: 90,
        mention: 98,
        general: 24,
      },
      WBDPZ1FNEW2: {
        private: 59,
        mention: 74,
        general: 60,
      },
      RMTWI9SNEW2: {
        private: 62,
        mention: 88,
        general: 7,
      },
      CK4RGUJNEW2: {
        private: 87,
        mention: 36,
        general: 50,
      },
      IXUUCPLNEW2: {
        private: 36,
        mention: 29,
        general: 4,
      },
      MB8BB9SNEW2: {
        private: 97,
        mention: 8,
        general: 71,
      },
      AQJX5JSNEW2: {
        private: 14,
        mention: 12,
        general: 36,
      },
      VMD2ECVNEW2: {
        private: 1,
        mention: 86,
        general: 70,
      },
      GA64M5QNEW2: {
        private: 39,
        mention: 5,
        general: 89,
      },
    },
  },
  CK4RGUJNEW2: {
    name: "Allvar Gullstrand",
    email: "hytwqqxtmsmhwfb@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/indian_woman_hindi_avatar-128.png",
    granted: true,
    invitable: true,
    relations: {
      RMTWI9SNEW2: {
        private: 19,
        mention: 48,
        general: 81,
      },
      WBDPZ1FNEW2: {
        private: 23,
        mention: 57,
        general: 5,
      },
      "1DYZ7OSNEW2": {
        private: 87,
        mention: 36,
        general: 41,
      },
      VMD2ECVNEW2: {
        private: 74,
        mention: 95,
        general: 44,
      },
      W5KYHODNEW2: {
        private: 46,
        mention: 100,
        general: 69,
      },
      MB8BB9SNEW2: {
        private: 15,
        mention: 83,
        general: 97,
      },
      IXUUCPLNEW2: {
        private: 8,
        mention: 70,
        general: 41,
      },
      AQJX5JSNEW2: {
        private: 72,
        mention: 91,
        general: 19,
      },
    },
  },
  IXUUCPLNEW2: {
    name: "	Alexis Carrel",
    email: "gppeiqvzibzduvc@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/draw_pencil_addicted_love-128.png",
    granted: true,
    invitable: true,
    relations: {
      RMTWI9SNEW2: {
        private: 42,
        mention: 32,
        general: 71,
      },
      GA64M5QNEW2: {
        private: 84,
        mention: 19,
        general: 2,
      },
      "1DYZ7OSNEW2": {
        private: 36,
        mention: 29,
        general: 76,
      },
      W5KYHODNEW2: {
        private: 48,
        mention: 89,
        general: 77,
      },
      VMD2ECVNEW2: {
        private: 32,
        mention: 67,
        general: 31,
      },
      MB8BB9SNEW2: {
        private: 57,
        mention: 11,
        general: 80,
      },
      CK4RGUJNEW2: {
        private: 8,
        mention: 70,
        general: 29,
      },
      AQJX5JSNEW2: {
        private: 88,
        mention: 51,
        general: 76,
      },
      WBDPZ1FNEW2: {
        private: 26,
        mention: 13,
        general: 14,
      },
    },
  },
  AQJX5JSNEW2: {
    name: "Charles Richet",
    email: "xcuftiyyhlohrmv@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/boy_afro_kid_child-128.png",
    granted: true,
    invitable: true,
    relations: {
      WBDPZ1FNEW2: {
        private: 26,
        mention: 69,
        general: 32,
      },
      "1DYZ7OSNEW2": {
        private: 14,
        mention: 12,
        general: 89,
      },
      IXUUCPLNEW2: {
        private: 88,
        mention: 51,
        general: 13,
      },
      CK4RGUJNEW2: {
        private: 72,
        mention: 91,
        general: 34,
      },
      VMD2ECVNEW2: {
        private: 5,
        mention: 74,
        general: 0,
      },
      GA64M5QNEW2: {
        private: 90,
        mention: 82,
        general: 30,
      },
      RMTWI9SNEW2: {
        private: 51,
        mention: 24,
        general: 98,
      },
      MB8BB9SNEW2: {
        private: 95,
        mention: 41,
        general: 67,
      },
      W5KYHODNEW2: {
        private: 93,
        mention: 20,
        general: 100,
      },
    },
  },
  W5KYHODNEW2: {
    name: "Robert Bárány",
    email: "xpkbkadvutbsupb@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/muslim_man_avatar-128.png",
    granted: true,
    invitable: true,
    relations: {
      RMTWI9SNEW2: {
        private: 52,
        mention: 31,
        general: 79,
      },
      GA64M5QNEW2: {
        private: 37,
        mention: 97,
        general: 7,
      },
      "1DYZ7OSNEW2": {
        private: 90,
        mention: 98,
        general: 72,
      },
      CK4RGUJNEW2: {
        private: 46,
        mention: 100,
        general: 4,
      },
      IXUUCPLNEW2: {
        private: 48,
        mention: 89,
        general: 99,
      },
      AQJX5JSNEW2: {
        private: 93,
        mention: 20,
        general: 98,
      },
      VMD2ECVNEW2: {
        private: 82,
        mention: 94,
        general: 12,
      },
      WBDPZ1FNEW2: {
        private: 2,
        mention: 77,
        general: 81,
      },
      MB8BB9SNEW2: {
        private: 2,
        mention: 85,
        general: 86,
      },
    },
  },
  MB8BB9SNEW2: {
    name: "Jules Bordet",
    email: "uxgxinbtstvseai@example.com",
    avatar:
      "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/apple_watch_sick_illness_avatar-128.png",
    granted: true,
    invitable: true,
    relations: {
      FFMD2ECVNWR: {
        private: 0,
        mention: 14,
        general: 14,
      },
      RMTWI9S0SZ: {
        private: 0,
        mention: 58,
        general: 78,
      },
      GA64M5QDM5: {
        private: 0,
        mention: 2,
        general: 22,
      },
      WBDPZ1FG2W: {
        private: 0,
        mention: 64,
        general: 55,
      },
      "1DYZ7OS00O": {
        private: 0,
        mention: 86,
        general: 60,
      },
      CK4RGUJ11B: {
        private: 0,
        mention: 95,
        general: 34,
      },
      IXUUCPL1PC: {
        private: 0,
        mention: 67,
        general: 70,
      },
      AQJX5JSJQO: {
        private: 0,
        mention: 74,
        general: 53,
      },
      W5KYHODPWZ: {
        private: 0,
        mention: 94,
        general: 4781,
      },//
      "1DYZ7OSNEW2": {
        private: 97,
        mention: 8,
        general: 81,
      },
      CK4RGUJNEW2: {
        private: 15,
        mention: 83,
        general: 90,
      },
      IXUUCPLNEW2: {
        private: 57,
        mention: 11,
        general: 12,
      },
      AQJX5JSNEW2: {
        private: 95,
        mention: 41,
        general: 17,
      },
      W5KYHODNEW2: {
        private: 2,
        mention: 85,
        general: 68,
      },
      WBDPZ1FNEW2: {
        private: 13,
        mention: 29,
        general: 49,
      },
      RMTWI9SNEW2: {
        private: 59,
        mention: 90,
        general: 63,
      },
      GA64M5QNEW2: {
        private: 44,
        mention: 51,
        general: 96,
      },
    },
  },

};
const defaultArrayWithWeakRelations = {
  "U02AJTN056Y": {
    "name": "Eugen B.",
    "avatar": "https://secure.gravatar.com/avatar/88a3b7ad9ea2554cd55f92d1c1af9153.jpg?s=72&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0025-72.png",
    "granted": false,
    "invited": 1631647526.0001,
    "relations": {
      "U02ACMPPR18": {
        "private": 5,
        "mention": 0,
        "general": 0
      }
    }
  },
  "U02ACMPPR18": {
    "name": "Dewey D",
    "avatar": "https://secure.gravatar.com/avatar/5d2fe7990063eb369915bd6a15ecf444.jpg?s=72&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0012-72.png",
    "granted": true,
    "invited": null,
    "relations": {
      "U02AJTN056Y": {
        "private": 5,
        "mention": 0,
        "general": 0
      },
      "U02ACMXUZNW": {
        "private": 3,
        "mention": 0,
        "general": 0
      },
      "U02A5V9R8TG": {
        "private": 1,
        "mention": 0,
        "general": 0
      },
      "U02AJTMLMK6": {
        "private": 2,
        "mention": 0,
        "general": 0
      },
      "U02ACMXNNJW": {
        "private": 1,
        "mention": 0,
        "general": 0
      }
    }
  },
  "U02ACMX5ZH8": {
    "name": "Hewie D",
    "avatar": "https://secure.gravatar.com/avatar/3465ceb6b6ac9c04c00d6d6bc205021e.jpg?s=72&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0006-72.png",
    "granted": true,
    "invited": 1631647525.0001,
    "relations": {
      "U02ARALATCZ": {
        "private": 2,
        "mention": 0,
        "general": 0
      },
      "U02ACMXUZNW": {
        "private": 2,
        "mention": 0,
        "general": 0
      }
    }
  },
  "U02ARALATCZ": {
    "name": "Darius B.",
    "avatar": "https://secure.gravatar.com/avatar/3c427ae3ee88651dfa97ce40d90e4f33.jpg?s=72&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0025-72.png",
    "granted": false,
    "invited": 1631647527.0001,
    "relations": {}
  },
  "U02ACMXUZNW": {
    "name": "Ulric T.",
    "avatar": "https://secure.gravatar.com/avatar/c532f0a0a25034b68dd9dd0a4d3cf66c.jpg?s=72&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0008-72.png",
    "granted": false,
    "invited": 1631647525.0001,
    "relations": {
      "U02ACMPPR18": {
        "private": 2,
        "mention": 0,
        "general": 0
      },
      "U02ACMX5ZH8": {
        "private": 1,
        "mention": 0,
        "general": 0
      }
    }
  },
  "U02A5V9R8TG": {
    "name": "Leah I",
    "avatar": "https://secure.gravatar.com/avatar/8cead2ded4bbb9ed83b6e63d57111f9a.jpg?s=72&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0010-72.png",
    "granted": true,
    "invited": 1631647524.0001,
    "relations": {
      "U02ACMPPR18": {
        "private": 1,
        "mention": 0,
        "general": 0
      }
    }
  },
  "U02AJTMLMK6": {
    "name": "Oleh R.",
    "avatar": "https://secure.gravatar.com/avatar/f5fcaf93f2fd147872830a94eefb8bf2.jpg?s=72&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0003-72.png",
    "granted": false,
    "invited": 1631647526.0001,
    "relations": {}
  },
  "U029Y06R1RD": {
    "name": "Joanna D.(woman)",
    "avatar": "https://secure.gravatar.com/avatar/2e77627a73e6d576cdaec23aa2277910.jpg?s=72&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0013-72.png",
    "granted": false,
    "invited": 1631647523.0001,
    "relations": {
      "U02ACMX5ZH8": {
        "private": 2,
        "mention": 0,
        "general": 0
      }
    }
  },
  "U02ACMXNNJW": {
    "name": "Bobby J.",
    "avatar": "https://secure.gravatar.com/avatar/5f2b8fef0ed2892b710ec36baa89e385.jpg?s=72&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0008-72.png",
    "granted": false,
    "invited": 1631647525.0001,
    "relations": {}
  }
};
const shortLineData = {
  "1628218990.0002": 41,
  "1628262190.0002": 4
};

const debounce = (func, wait) => {
  let timeout;

  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);

  }
}


const EMPLOYER_OPTIONS = [
  { key: "unset", text: "Unset", value: [0, 0] },
  { key: "less15", text: "1-15", value: [0, 15] },
  { key: "16-50", text: "16-50", value: [16, 50] },
  { key: "51-100", text: "51-100", value: [51, 100] },
  { key: "100+", text: "more than 100", value: [101, 9999] },
];


const getCompanySize = (companySize) => {
  if (!companySize || companySize.length === 0) return [0, 0];

  const opt = EMPLOYER_OPTIONS.find(d => d.value[0] === companySize[0] && d.value[1] === companySize[1]);
  return opt?.value || EMPLOYER_OPTIONS[0].value;
}

const areArraysEqual = (array1, array2) => {
  // console.log("array1", array1, 'array2', array2);
  const array1Sorted = array1.slice().sort();
  const array2Sorted = array2.slice().sort();
  return array1Sorted.length === array2Sorted.length && array1Sorted.slice().sort().every(function(value, index) {
    return value === array2Sorted[index];
  });
}


export default {
  areArraysEqual,
  EMPLOYER_OPTIONS,
  getCompanySize,
  isMobileDevice,
  validateFullName,
  anyToNumber,
  positions,
  transform,
  resetTransform,
  resetPositions,
  getPositions,
  phonenumber,
  trimPhone,
  validateEmail,
  defaultDataArray,
  defaultArrayWithWeakRelations,
  shortLineData,
  debounce
};
