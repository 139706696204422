import React from "react"; //, { Component }
import {
  Input
} from "semantic-ui-react";
import './UserForInviteSearch.sass'
import userIcon from "../../img/user.svg";
import userIconDark from "../../img/user-dark.svg";

export default class UserForInviteSearch extends React.Component {

  componentDidMount() {

    const {
      projects,
      onFindUsers
    } = this.props;
    // console.log(" projects, filterProject, onFindUsers ", projects, onFindUsers);
    onFindUsers(projects);
  }
  // project
  filterProject = (query, project) => {
    return project.name.toLowerCase().includes(query.toLowerCase())

  }
  handleInputChange = (event) => {

    const query = event.currentTarget.value;
    const {
      projects,
      onFindUsers
    } = this.props;

    // const filteredProjects = projects.filter(project => !query || this.filterProject(query, project));
    let filteredProjects =
      // query ?
      projects.filter((project) =>
        !query || project.name.toLowerCase().includes(query.toLowerCase())
      )
      // : []
      ;
      // if(filteredProjects && filteredProjects.length > 0){
        filteredProjects = filteredProjects.filter((d) => {
          return !d.granted
        });
        filteredProjects.sort((a, b) => {
          // console.log("a,b",a,b);
          return (a.invited > b.invited) ? 1 : ((b.invited > a.invited) ? -1 : 0)
        });
      // }

      console.log("filteredProjects",filteredProjects);
    onFindUsers(filteredProjects);
  };

  render() {
    return (
      <div className="invite-users-holder"> 
                  <div className="search-container">
            {this.props.darkTheme ?
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-outside-1_2876:12683" maskUnits="userSpaceOnUse" x="2" y="2" width="19" height="19" fill="black">
                  <rect fill="white" x="2" y="2" width="19" height="19" />
                  <path d="M4 10C4 13.3137 6.68629 16 10 16C11.6348 16 13.117 15.3462 14.1991 14.2857C15.3105 13.1967 16 11.6789 16 10C16 6.68629 13.3137 4 10 4C6.68629 4 4 6.68629 4 10Z" />
                </mask>
                <path d="M19.4737 20.5343C19.7688 20.825 20.2436 20.8214 20.5343 20.5263C20.825 20.2312 20.8214 19.7564 20.5263 19.4657L19.4737 20.5343ZM13.6728 14.82L19.4737 20.5343L20.5263 19.4657L14.7255 13.7514L13.6728 14.82ZM14.1991 14.2857L15.249 15.3571L15.249 15.3571L14.1991 14.2857ZM10 14.5C7.51472 14.5 5.5 12.4853 5.5 10H2.5C2.5 14.1421 5.85786 17.5 10 17.5V14.5ZM5.5 10C5.5 7.51472 7.51472 5.5 10 5.5V2.5C5.85786 2.5 2.5 5.85786 2.5 10H5.5ZM10 5.5C12.4853 5.5 14.5 7.51472 14.5 10H17.5C17.5 5.85786 14.1421 2.5 10 2.5V5.5ZM14.5 10C14.5 11.2595 13.9846 12.3958 13.1493 13.2144L15.249 15.3571C16.6364 13.9976 17.5 12.0983 17.5 10H14.5ZM13.1493 13.2144C12.3361 14.0112 11.2266 14.5 10 14.5V17.5C12.043 17.5 13.8978 16.6811 15.249 15.3571L13.1493 13.2144Z" fill="#BDBDBD" mask="url(#path-1-outside-1_2876:12683)" />
              </svg>
              :
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-outside-1_2876:12673" maskUnits="userSpaceOnUse" x="2" y="2" width="19" height="19" fill="black">
                  <rect fill="white" x="2" y="2" width="19" height="19" />
                  <path d="M4 10C4 13.3137 6.68629 16 10 16C11.6348 16 13.117 15.3462 14.1991 14.2857C15.3105 13.1967 16 11.6789 16 10C16 6.68629 13.3137 4 10 4C6.68629 4 4 6.68629 4 10Z" />
                </mask>
                <path d="M19.4737 20.5343C19.7688 20.825 20.2436 20.8214 20.5343 20.5263C20.825 20.2312 20.8214 19.7564 20.5263 19.4657L19.4737 20.5343ZM13.6728 14.82L19.4737 20.5343L20.5263 19.4657L14.7255 13.7514L13.6728 14.82ZM14.1991 14.2857L15.249 15.3571L15.249 15.3571L14.1991 14.2857ZM10 14.5C7.51472 14.5 5.5 12.4853 5.5 10H2.5C2.5 14.1421 5.85786 17.5 10 17.5V14.5ZM5.5 10C5.5 7.51472 7.51472 5.5 10 5.5V2.5C5.85786 2.5 2.5 5.85786 2.5 10H5.5ZM10 5.5C12.4853 5.5 14.5 7.51472 14.5 10H17.5C17.5 5.85786 14.1421 2.5 10 2.5V5.5ZM14.5 10C14.5 11.2595 13.9846 12.3958 13.1493 13.2144L15.249 15.3571C16.6364 13.9976 17.5 12.0983 17.5 10H14.5ZM13.1493 13.2144C12.3361 14.0112 11.2266 14.5 10 14.5V17.5C12.043 17.5 13.8978 16.6811 15.249 15.3571L13.1493 13.2144Z" fill="#3D1D75" mask="url(#path-1-outside-1_2876:12673)" />
              </svg>

            }

          </div>
        <Input
          // icon="search"
          placeholder="Search"
          onChange={
            this.handleInputChange
          }
        />
      </div>

    );
  }
}