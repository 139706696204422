import React, { memo, useMemo, useRef, useEffect } from 'react';
// import PropTypes from "prop-types";
import * as d3 from "d3";
import './D3AreaChartWithSelection.sass';
import helpers from "../helpers";
import { setDefaultLocale } from 'react-datepicker';
// import { useDispatch, useSelector } from "react-redux";
// import { setClickedNode, setHoveredNode } from "../../redux/toolsSlice";

const D3AreaChartWithSelection = memo((props) => {


  const vizRef = useRef(null);
  //   const clickedNode = useSelector(state => state.tools.clickedNode);
  //   const clickedNodeId = useSelector(state => state.tools.clickedNodeId);
  //   const individualAccumulativeDataByClickedNode = useSelector(state => state.tools.individualAccumulativeDataByClickedNode);

  let that = useMemo(() => {
    let that = {};
    that.startDate = undefined; // Date format
    that.endDate = undefined; // Date format
    that.firstDraw = true;
    that.timeFormatDetailed = d3.timeFormat("%b %d, %Y %H:%M");
    // that.numberFormatter = d3.format("~s");
    that.isMobileDevice = helpers.isMobileDevice();
    that.graphClick = true;
    return that;
  }, []);


  useEffect(() => {
    window.addEventListener('resize', onResize, false);
    return () => {
      window.removeEventListener('resize', onResize, false);
    }
  }, []);

  let onResize = () => {
    init();
    updateData();
  };

  // componentDidUpdate() {
  //     // that.init();
  //     // if(that.firstDraw){
  //         that.updateData(props);
  //     // }
  // }

  useEffect(() => {
    that.startDate = new Date(props.startDate);
    that.endDate = new Date(props.endDate);
    init();
    updateData();
  }, [props.darkTheme, props.data, props.endDate, props.startDate]);


  // shouldComponentUpdate(nextProps, nextState) {
  //     that.graphClick = nextProps.graphClick; // ? true : that.graphClick;
  //     let isRefresh =
  //         nextProps.darkTheme !== props.darkTheme ||
  //         nextProps.data !== props.data ||
  //         //   nextProps.endTimeFrame !== props.endTimeFrame ||
  //         nextProps.endDate !== props.endDate ||
  //         nextProps.startDate !== props.startDate;
  //     if (that.startDate && that.endDate && that.startDate.getTime() === nextProps.startDate && that.endDate.getTime() === nextProps.endDate) {
  //         isRefresh = false;
  //     }
  //     let newFieldsExceptDate = nextProps.darkTheme !== props.darkTheme || nextProps.data !== props.data;
  //     if (!isRefresh && newFieldsExceptDate) {
  //         isRefresh = true;
  //     }
  //     // console.log("shouldComponentUpdate", nextProps.darkTheme, isRefresh);
  //     if((nextProps.data && nextProps.data.length === 0) && (props.data && props.data.length === 0)){
  //         isRefresh = false;
  //     }
  //     // if(nextProps.isIndividual){
  //     //     console.log("D3AreaChartWithSelection shouldComponentUpdate", isRefresh, nextProps.data, props.data);
  //     // }
  //     if (isRefresh || that.firstDraw) {
  //         that.firstDraw = false;
  //         that.startDate = new Date(nextProps.startDate);
  //         that.endDate = new Date(nextProps.endDate);
  //         // if(!(nextProps.startDate && nextProps.endDate)){ // because initial draw - invisible div
  //         //     return;
  //         // }
  //         that.init();
  //         that.updateData(nextProps);
  //         // that.updateData(nextProps);
  //         return true;
  //     }
  //     return false; // isRefresh || nextState !== that.state || nextProps.someField !== props.someField; // true;
  // }

  let init = () => {
    var self = that;
    self.element = vizRef && vizRef.current ? vizRef.current : document.querySelector('.d3-area-chart-wrapper');
    // console.log(vizRef);
    d3.select(self.element).selectAll("*").remove();
    // d3.selectAll(".tooltipArea").selectAll("*").remove();// find in all html
    self.margin = {
      top: 10,
      right: 25,
      bottom: 84,
      left: 16
    };
    self.margin2 = {
      top: 220,
      right: 25,
      bottom: 18,
      left: 16
    };

    let screenWidth = window.innerWidth || document.documentElement.clientWidth ||
      document.body.clientWidth;
    // let defaultWidht = self.element.offsetWidth ? self.element.offsetWidth : that.isMobileDevice
    //     ? 310
    //     : screenWidth < 970 ? 210 : 392;
    // console.log("screenWidth",screenWidth);
    let defaultWidht = self.element.offsetWidth ? self.element.offsetWidth : !!(screenWidth < 970) //that.isMobileDevice
      ? 300
      : 392;
    self.width = defaultWidht - self.margin.left - self.margin.right;
    self.height = 278 - self.margin.top - self.margin.bottom;
    self.height2 = 272 - self.margin2.top - self.margin2.bottom;

    // self.parseDate = d3.timeParse('%Y-%m-%dT%H:%M:%S %Z');
    // D3 scales = just math
    // x is a function that transforms from "domain" (data) into "range" (usual pixels)
    // domain gets set after the data loads
    self.x = d3.scaleTime().range([0, self.width]).nice();
    self.x2 = d3.scaleTime().range([0, self.width]).nice();
    self.y = d3.scaleLinear().range([self.height - 4, 0]).nice(4);
    self.y2 = d3.scaleLinear().range([self.height2 - 1.5, 0]).nice();



    self.xAxis = d3.axisBottom(self.x)
      .ticks(4)
      .tickSize((-self.height) + (-6))
      // .tickSizeOuter((-self.height) + 6)
      // .tickSizeInner((-self.height) + 6)
      // .tickSizeOuter(4)
      // .tickPadding(3)
      ;

    self.xAxis2 = d3.axisBottom(self.x2)
      .ticks(2)
      .tickFormat(d3.timeFormat("%b %d %Y"));

    self.yAxis = d3.axisRight(self.y)
      .ticks(4)
      .tickSize(self.width)
      .tickFormat(d => d || "");;

    self.brush = d3.brushX()
      .extent([
        [0, 0],
        [self.width, self.height2]
      ])
      // .on("brush start", brushStart)
      // .on("brush end", brushed)
      ;

    self.zoom = d3.zoom()
      .scaleExtent([1, Infinity])
      .translateExtent([
        [0, 0],
        [self.width, self.height]
      ])
      .extent([
        [0, 0],
        [self.width, self.height]
      ])
      .on("zoom", zoomed)
      .on("end", zoomend);

    self.line = d3.line()
      // .defined(d => d.milliseconds) // Omit empty values.
      // .curve(d3.curveBasisOpen)
      .x((d) => {
        return self.x(d.milliseconds);
      })
      .y(function (d) {
        return self.y(d.messages || 0) || 0;
      });

    self.area = d3.area()
      // .defined(d => d.milliseconds) 
      // .curve(d3.curveBasisOpen)
      .x((d) => {
        return self.x(d.milliseconds);
      })
      .y0(self.height)
      .y1((d) => {
        return self.y(d.messages || 0) || 0;
      });


    self.line2 = d3.line()
      // .curve(d3.curveBasisOpen)
      .x((d) => {
        return self.x2(d.milliseconds);
      })
      .y((d) => {
        return self.y2(d.messages || 0) || 0;
      });

    self.area2 = d3.area()
      // .curve(d3.curveBasisOpen)
      .x((d) => {
        return self.x2(d.milliseconds);
      })
      .y0(self.height2)
      .y1((d) => {
        return self.y2(d.messages || 0) || 0;
      });


    // Define the div for the tooltip
    // self.tooltip = d3
    //     .select("#tooltipArea")
    //     // .append("div")
    //     .attr("class", "tooltipArea")
    //     // .style("opacity", 0)
    //     .style("display", "none")
    //     // .style("z-index", 0)
    //     ;



    self.svg = d3
      .select(self.element)
      .append("svg")
      .attr("width", self.width + self.margin.left + self.margin.right)
      .attr("height", self.height + self.margin.top + self.margin.bottom)
      // .append("g")
      // .attr(
      //     "transform",
      //     "translate(" + self.margin.left + "," + self.margin.top + ")"
      // )
      .on("click", () => {
        if (!that.graphClick) {
          that.graphClick = true;
          // console.log("00");
          props.handleGraphClick(true);
        }
      })
      ;
    self.defs = self.svg.append("defs");
    self.defsId = Date.now();
    self.clip = self.defs.append("svg:clipPath")
      .attr("id", `clip-${self.defsId}`)
      .append("svg:rect")
      .attr("width", self.width)
      .attr("height", self.height)
      .attr("x", 0)
      .attr("y", 0);

    self.focus = self.svg.append("g")
      .attr("class", "focus")
      .attr("transform", "translate(" + self.margin.left + "," + self.margin.top + ")")
      .attr("clip-path", `url(#clip-${self.defsId})`);

    self.svg.append("rect")
      .attr("class", "frame")
      .attr("width", self.width)
      .attr("height", self.height)
      .attr("x", 0)
      .attr("y", 0)
      .attr("transform", "translate(" + self.margin.left + "," + self.margin.top + ")");

    self.svg.append("line")
      .attr("class", "main-line")
      .attr("x1", 0)
      .attr("y1", self.height + 0.25)
      .attr("x2", self.width + self.margin.left + self.margin.right)
      .attr("y2", self.height + 0.25)
      .attr("transform", "translate(" + 0 + "," + self.margin.top + ")");

    self.other = self.svg.append("g")
      .attr("class", "other")
      .attr("transform", "translate(" + self.margin.left + "," + self.margin.top + ")");

    self.context = self.svg.append("g")
      .attr("class", "context")
      .attr("transform", "translate(" + self.margin2.left + "," + self.margin2.top + ")");

    self.svg.append("line")
      .attr("class", "context-line")
      .attr("x1", 0)
      .attr("y1", self.height2)// + 0.25)
      .attr("x2", self.width)
      .attr("y2", self.height2)// + 0.25)
      .attr("transform", "translate(" + self.margin2.left + "," + self.margin2.top + ")");

    self.svg.append("line")
      .attr("class", "context-line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", self.width)
      .attr("y2", 0)
      .attr("transform", "translate(" + self.margin2.left + "," + self.margin2.top + ")");

    self.contextContent = self.context.append("g")
      .attr("class", "brushContent")
      .call(self.brush)
      .call(self.brush.move, self.x.range());

    self.brashHolder = self.context.append("g")
      .attr("class", "brushHolder")
      .call(self.brush)
      .call(self.brush.move, self.x.range());


    self.focusXAxis = self.other.append("g")
      .attr("class", "axis axis--x");

    self.focusYAxis = self.other.append("g")
      .attr("class", "axis axis--y");

    self.linearGradientFocus = self.defs.append("linearGradient")
      .attr("id", `area-gradient-${self.defsId}`);

    // Add the area.
    self.focusArea = self.focus.append("path")
      .style("fill", `url(#area-gradient-${self.defsId})`)
      .attr("class", "area");


    self.focusLine = self.focus.append("path")
      .attr("class", "line");

    self.contextLine = self.contextContent.append("path")
      .attr("class", "line");

    // set the gradient for selection
    self.linearGradientContext = self.defs.append("linearGradient")
      .attr("id", `area2-gradient-${self.defsId}`);

    // Add the area.
    self.contextArea = self.contextContent.append("path")
      .style("fill", `url(#area-gradient-${self.defsId})`)
      .attr("class", "area2");

    self.contextXAxis = self.contextContent.append("g")
      .attr("class", "axis axis--x x2");

    self.zoomG = self.svg.append("rect")
      .attr("class", "zoom");

    self.tooltipWidth = 134;
    let tooltipHeight = 52;
    let tooltipPadding = 8;
    self.tooltipCircle = self.svg.append("circle")
      .attr("class", "tooltipCircle")
      // .attr("fill", props.darkTheme ? "#000" : "#fff")
      .attr("stroke", "#27C245")
      .attr("stroke-width", 2)
      .style("display", "none")
      .attr("r", 4)
      .attr("cx", 0)
      .attr("cy", 0);
    self.tooltip = self.svg.append("g")
      .attr("class", "tooltipArea")
      .style("pointer-events", "none")
      .style("display", "none")
      .attr("transform", "translate(0,0)");
    self.tooltipRect = self.tooltip.append("rect")
      // .attr("class", "tooltipArea")
      .attr("width", self.tooltipWidth)
      .attr("height", tooltipHeight)
      // .attr("fill", props.darkTheme ? "#000" : "#fff")
      // .attr("stroke-width", 0)
      .attr("x", 0)
      .attr("y", 0)
      .attr("rx", 6)
      .attr("ry", 6);
    self.tooltip.append("text")
      .attr("x", tooltipPadding)
      .attr("y", tooltipHeight / 4)
      .style('text-anchor', "start")
      .style('dominant-baseline', "middle")
      .attr("fill", "#27C245")
      .text("Messages:");
    // self.tooltip.append("text")
    //     .attr("x", tooltipPadding)
    //     .attr("y", 3 * tooltipHeight / 4)
    //     .style('text-anchor', "start")
    //     .style('dominant-baseline', "middle")
    //     .text("Time:");
    self.tooltipMessage = self.tooltip.append("text")
      .attr("x", self.tooltipWidth - tooltipPadding)
      .attr("y", tooltipHeight / 4)
      .style('text-anchor', "end")
      .style('dominant-baseline', "middle")
      .attr("fill", "#27C245")
      .text("");
    self.tooltipTime = self.tooltip.append("text")
      .attr("x", self.tooltipWidth - tooltipPadding)
      .attr("y", 3 * tooltipHeight / 4)
      .style('text-anchor', "end")
      .style('dominant-baseline', "start")
      .attr("fill", "#666666")
      .text("");

  }

  let draw = () => {
    var self = that;


    // self.tooltipCircle
    //     .attr("fill", props.darkTheme ? "#000" : "#fff");
    // self.tooltipRect
    //     .attr("fill", props.darkTheme ? "#000" : "#fff");

    self.x.domain(d3.extent(self._data, (d) => {
      return d.milliseconds;
    }));
    self.yMax = d3.max(self._data, (d) => {
      return d.messages;
    })
    self.y.domain([0, self.yMax]);
    self.x2.domain(self.x.domain());
    self.y2.domain(self.y.domain());
    self.y.nice(4);
    // console.log(y.domain(), yMax);

    self.focusXAxis
      .attr("transform", "translate(0," + (self.height + 6) + ")")
      .call(self.xAxis
        // .tickValues([d3.min(data, d=>d.dateMsec),d3.max(data, d=>d.dateMsec)])
      );

    self.focusYAxis
      .call(self.yAxis
        // .tickValues([d3.min(data, d=>d.messages),d3.max(data, d=>d.messages)])
      );

    self.linearGradientFocus
      .attr("x1", "0%").attr("y1", "0%")
      .attr("x2", "0%").attr("y2", "100%")
      .selectAll("stop")
      .data([{
        offset: "0%",
        color: self.darkTheme ? "#686868" : "#C0EDC9"
      }, //27C245 , C0EDC9
      {
        offset: "100%",
        color: self.darkTheme ? "#222222 " : "#fff"
      }
      ])
      .enter().append("stop")
      .attr("offset", (d) => {
        return d.offset;
      })
      .attr("stop-color", (d) => {
        return d.color;
      });

    // Add the area.
    self.focusArea
      .datum(self._data)
      .attr("d", self.area);

      // console.log("self._data", self._data);
    self.focusLine
      .datum(self._data)
      .attr("d", self.line);

    self.contextLine
      .datum(self._data)
      .attr("d", self.line2);

    // set the gradient for selection
    self.linearGradientContext
      .attr("x1", "0%").attr("y1", "0%")
      .attr("x2", "0%").attr("y2", "100%")
      .selectAll("stop")
      .data([{
        offset: "0%",
        color: self.darkTheme ? "#686868" : "#C0EDC9"
      }, //27C245 , C0EDC9
      {
        offset: "100%",
        color: self.darkTheme ? "#222222 " : "#fff"
      }
      ])
      .enter().append("stop")
      .attr("offset", (d) => {
        return d.offset;
      })
      .attr("stop-color", (d) => {
        return d.color;
      });

    // Add the area.
    self.contextArea
      .datum(self._data)
      .attr("d", self.area2);

    self.contextXAxis
      .attr("transform", "translate(0," + self.height2 + ")")
      .call(self.xAxis2
        .tickValues([d3.min(self._data, d => d.dateMsec), d3.max(self._data, d => d.dateMsec)])
      );


    self.selector = self.brashHolder.selectAll(".brush-selector")
      .data([{
        type: "w"
      }, {
        type: "e"
      }])
      .enter()
      .append("rect")
      .attr("class", "brush-selector")
      .attr("x", 0)
      .attr("y", 0)
      .attr("rx", 2)
      .attr("ry", 2)
      .attr("width", 6)
      .attr("height", 20);

    self.selectorLine = self.brashHolder.selectAll(".brush-selector-line")
      .data([{
        type: "w"
      }, {
        type: "e"
      }])
      .enter()
      .append("line")
      .attr("class", "brush-selector-line")
      .attr("x1", 3)
      .attr("y1", 5)
      .attr("x2", 3)
      .attr("y2", 15)
      ;

    // var path = self.svg.select('.brush-selector-line').node();
    self.totLength = self.focusLine.node().getTotalLength();

    self.zoomG
      .attr("width", self.width)
      .attr("height", self.height)
      .attr("transform", "translate(" + self.margin.left + "," + self.margin.top + ")")
      .call(self.zoom)
      .on("mousewheel.zoom", null)
      .on("wheel.zoom", null)
      .on("click.zoom", null)
      .on("dblclick.zoom", null)
      .on("mouseover", function () {
        self.tooltip.style("display", null);
        self.tooltipCircle.style("display", null);
      })
      .on("mouseout", function () {
        self.tooltip.style("display", "none");
        self.tooltipCircle.style("display", "none");
      })
      .on("mousemove", function () {

        // var x0 = d3.event.pageX,
        //     per = self.width / x0,
        //     point = d3.event.pageY,//self.focusLine.getPointAtLength(self.totLength / per),
        //     y0 = point;//self.y.invert(point.y);

        //  self.tooltip.attr("transform", "translate(" + point.x + "," + point.y + ")");
        // self.tooltip
        //     .style("z-index", 15)
        //     .style("display", "block")
        //     .html(`<p>${y0}</p>`)
        //     .style("width", "184px")
        //     .style("height", "auto")
        //     .style("left", d3.event.clientX + 10 + "px")
        //     .style("top", d3.event.clientY - 18 + "px")
        //     // .style("left", d.x + 20 + initTransform.x * (initTransform.k - 1) + "px")
        //     // .style("top", d.y - 28 + initTransform.y * (initTransform.k - 1) + "px")
        //     // .style("transform", `translate(${initTransform.x}px, ${initTransform.y}px) scale(${initTransform.k})`)
        //     .style("background", props.darkTheme ? "#222222" : "#fff")
        //     .style("color", props.darkTheme ? "#bfbfbf" : "#000")
        //     .style("padding", "8px")
        //     .style("font-family", "Roboto")
        //     .style("font-style", "normal")
        //     .style("font-weight", "normal")
        //     .style("font-size", "12px")
        //     .style("line-height", "16px")
        //     .style("box-shadow", "0px 2px 12px rgba(0, 0, 0, 0.1)");
        // var x0 = x.invert(d3.mouse(that)[0]),
        //         i = bisectDate(data, x0, 1),
        //         d0 = data[i - 1],
        //         d1 = data[i],
        //         d = x0 - d0.date > d1.date - x0 ? d1 : d0;
        try {
          var mouse = d3.mouse(this);
          // console.log("mouse", mouse);
          var xDate = self.x.invert(mouse[0]); // use 'invert' to get date corresponding to distance from mouse position relative to svg
          // console.log("xDate", xDate);
          var bisect = d3.bisector(d => d.milliseconds).left; // retrieve row index of date on parsed csv
          var index = bisect(self._data, xDate);
          // let message = self._data[index] ? self._data[index].messages : (self._data[index+1] ? self._data[index+1].messages : 0);
          // let xDateMs = xDate ? xDate.getTime() : 0;
          let message = self._data[index] ? self._data[index].messages : 0;
          xDate = self._data[index] ? self._data[index].milliseconds : 0;
          // console.log("message", self._data[index], message);
          let left = self.x(xDate);
          let top = self.y(message) || 0;
          self.tooltipCircle.attr("cx", (self.margin.left + left) || null).attr("cy", (top + self.margin.top) || null);
          self.tooltip
            .attr("transform", `translate(${self.margin.left + (left <= self.width / 2 ? left + 10 : (left - self.tooltipWidth - 10))},${top - self.margin.top})`)
          self.tooltipMessage.text(message ? +message.toFixed() : 0); // self.numberFormatter(message.toFixed()) 
          self.tooltipTime.text(self.timeFormatDetailed(new Date(xDate)));
        } catch (error) {
          self.tooltip.style("display", "none");
          self.tooltipCircle.style("display", "none");
        }
        // .style("left", d3.event.clientX + 10 + "px")
        // .style("top", d3.event.clientY - 18 + "px");
      })
      ;


    // self.brush.extent([
    //         [0, 0],
    //         [self.width, self.height2]
    //     ]);
    // console.log("self.startDate,self.endDate", [self.startDate, self.endDate]);
    // self.brush.extent([[self.startDate,0],[ self.endDate,  self.height]]);
    // self.brush.call(brush.move, [0.3, 0.5].map(x));

    let newRange = [self.startDate, self.endDate].map(d => self.x(d));
    // console.log("newRange", newRange);
    self.svg.select(".brushContent").call(self.brush.move, newRange);
    self.svg.select(".brushHolder").call(self.brush.move, newRange);
    self.p = d3.brushSelection(self.brashHolder.node());
    if (!self.p) {
      return
    }
    self.brashHolder.selectAll('.brush-selector').attr('transform', (d, i) => 'translate(' + [(self.p[i] - 3), (self.height2 / 2 - 10)] + ')');
    self.brashHolder.selectAll('.brush-selector-line').attr('transform', (d, i) => 'translate(' + [(self.p[i] - 3), (self.height2 / 2 - 10)] + ')');

    // .call(self.brush.move, self.x.range());
    // self.contextContent.select(".brushContent")
    // .call(self.brush);

    // self.brashHolder.select(".brushHolder")
    //     .call(self.brush);
    yScaleUpdate(self.startDate.getTime(), self.endDate.getTime());

    brushStart();
    self.brush.on("brush", brushed).on("end", brushend);

    self.svg.select(".brushContent").call(self.brush.move, newRange);
    self.svg.select(".brushHolder").call(self.brush.move, newRange);
    brushStart();
    // props.handleGraphClick(false);

    // console.log("data", self._data);

  }

  let yScaleUpdate = (startDateMs, endDateMs) => {
    let self = that;
    self._dataFiltered = self._data.filter(d => d.milliseconds >= startDateMs && d.milliseconds <= endDateMs);
    self.yMax = d3.max(self._dataFiltered, (d) => d.messages) * 1.1;
    self.y.domain([0, self.yMax]);
    // self.other.select(".axis--y").call(self.yAxis);
    self.focusYAxis.call(self.yAxis);
  }

  let brushStart = () => {
    var self = that;
    if (!self.graphClick) {
      self.graphClick = true;
      // console.log("01");
      props.handleGraphClick(true);
    }
    self.p = d3.brushSelection(self.brashHolder.node());
    // console.log("brushStart", self.p);
    self.brashHolder.selectAll('.brush-selector').attr('transform', (d, i) => 'translate(' + [(self.p[i] - 3), (self.height2 / 2 - 10)] + ')');
    self.brashHolder.selectAll('.brush-selector-line').attr('transform', (d, i) => 'translate(' + [(self.p[i] - 3), (self.height2 / 2 - 10)] + ')');
  }

  let brushed = () => {
    // console.log("brushed", e);
    var self = that;
    if (!self.graphClick) {
      self.graphClick = true;
      // console.log("02");
      props.handleGraphClick(true);
    }
    if (d3.event.sourceEvent && d3.event.sourceEvent.type === "zoom")
      return; // ignore brush-by-zoom
    // console.log("brushed", self, self.x);
    // self.x.domain(brush.empty() ? x2.domain() : brush.extent());
    self.s = d3.event.selection || self.x2.range();
    let xDomain = self.s.map(self.x2.invert, self.x2);
    self.x.domain(xDomain);
    // self.focus.select(".line").attr("d", self.line);
    self.focus.select(".line").attr("d", d => self.line(d));
    self.focus.select(".area").attr("d", self.area);
    yScaleUpdate(xDomain[0].getTime(), xDomain[1].getTime());
    // console.log("self.x", self.x.domain(), self.s);

    self.other.select(".axis--x").call(self.xAxis);
    self.svg.select(".zoom").call(self.zoom.transform, d3.zoomIdentity
      .scale(self.width / (self.s[1] - self.s[0]))
      .translate(-self.s[0], 0));
    if (d3.brushSelection(self.brashHolder.node())) {
      self.p = d3.brushSelection(self.brashHolder.node());
      // console.log("brushed", self.p);
      self.brashHolder.selectAll('.brush-selector').attr('transform', (d, i) => 'translate(' + [(self.p[i] - 3), (self.height2 / 2 - 10)] + ')');
      self.brashHolder.selectAll('.brush-selector-line').attr('transform', (d, i) => 'translate(' + [(self.p[i] - 3), (self.height2 / 2 - 10)] + ')');
    }
  };

  let brushend = () => {
    var self = that;
    if (!self.graphClick) {
      self.graphClick = true;
      // console.log("03");
      props.handleGraphClick(true);
    }
    // console.log("brushend",d3.event.sourceEvent);
    if (d3.event.sourceEvent && d3.event.sourceEvent.type === "zoom")
      return; // ignore brush-by-zoom

    if (!d3.event.sourceEvent) return; // Only transition after input.
    if (!d3.event.selection) return; // Ignore empty selections.
    // console.log("brushed", self, self.x);
    // self.x.domain(brush.empty() ? x2.domain() : brush.extent());
    self.s = d3.event.selection || self.x2.range();
    let xDomain = self.s.map(self.x2.invert, self.x2);
    self.x.domain(xDomain);
    self.focus.select(".line").attr("d", self.line);
    self.focus.select(".area").attr("d", self.area);
    yScaleUpdate(xDomain[0].getTime(), xDomain[1].getTime());
    // console.log("self.x", self.x.domain(), self.s);

    self.other.select(".axis--x").call(self.xAxis);
    self.svg.select(".zoom").call(self.zoom.transform, d3.zoomIdentity
      .scale(self.width / (self.s[1] - self.s[0]))
      .translate(-self.s[0], 0));
    if (d3.brushSelection(self.brashHolder.node())) {
      self.p = d3.brushSelection(self.brashHolder.node());
      // console.log("brushed", self.p);
      self.brashHolder.selectAll('.brush-selector').attr('transform', (d, i) => 'translate(' + [(self.p[i] - 3), (self.height2 / 2 - 10)] + ')');
      self.brashHolder.selectAll('.brush-selector-line').attr('transform', (d, i) => 'translate(' + [(self.p[i] - 3), (self.height2 / 2 - 10)] + ')');
    }

    if (that.startDate.getTime() === xDomain[0].getTime() && that.endDate.getTime() === xDomain[1].getTime()) {
      return;
    }
    that.startDate = xDomain[0];
    that.endDate = xDomain[1];
    // console.log("brushend", that.startDate, that.endDate);
    if(that.startDate.getTime() && that.endDate.getTime()){
      props.updateTimeFrames(that.startDate.getTime(), that.endDate.getTime()); // >> API
    }
    // props.updateTimeFrames(that.monthAgo, that.state.dateNow)
  };

  let zoomed = () => {
    var self = that;
    if (!self.graphClick) {
      self.graphClick = true;
      // console.log("04");
      props.handleGraphClick(true);
    }
    if (d3.event.sourceEvent && d3.event.sourceEvent.type === "brush")
      return; // ignore zoom-by-brush
    self.t = d3.event.transform;
    // console.log("zoomed", self, self.svg.select(".brush"));
    self.x.domain(self.t.rescaleX(self.x2).domain());
    self.focus.select(".line").attr("d", self.line);
    self.focus.select(".area").attr("d", self.area);
    self.other.select(".axis--x").call(self.xAxis);
    self.svg.select(".brushContent").call(self.brush.move, self.x.range().map(self.t.invertX, self.t));
    self.svg.select(".brushHolder").call(self.brush.move, self.x.range().map(self.t.invertX, self.t));
    self.p = d3.brushSelection(self.brashHolder.node());
    self.brashHolder.selectAll('.brush-selector').attr('transform', (d, i) => 'translate(' + [(self.p[i] - 3), (self.height2 / 2 - 10)] + ')');
    self.brashHolder.selectAll('.brush-selector-line').attr('transform', (d, i) => 'translate(' + [(self.p[i] - 3), (self.height2 / 2 - 10)] + ')');
  }
  let zoomend = () => {
    var self = that;
    if (!self.graphClick) {
      self.graphClick = true;
      // console.log("05");
      props.handleGraphClick(true);
    }
    if (d3.event.sourceEvent && d3.event.sourceEvent.type === "brush")
      return; // ignore zoom-by-brush

    // console.log("zoomend", d3.event.sourceEvent, d3.event.transform);
    if (!d3.event.sourceEvent) return; // Only transition after input.
    if (!d3.event.transform) return; // Ignore empty transform.
    self.t = d3.event.transform;
    // console.log("zoomed", self, self.svg.select(".brush"));
    let xDomain = self.t.rescaleX(self.x2).domain();
    // console.log(that.startDate.getTime() , xDomain[0].getTime() , that.endDate.getTime() , xDomain[1].getTime());
    if (that.startDate.getTime() === xDomain[0].getTime() && that.endDate.getTime() === xDomain[1].getTime()) {
      return;
    }
    that.startDate = xDomain[0];
    that.endDate = xDomain[1];
    // console.log("zoomend", that.startDate, that.endDate, d3.event.sourceEvent, d3.event.transform);
    console.log("updateTimeFrames", that.startDate.getTime(), that.endDate.getTime());
    if(that.startDate.getTime() && that.endDate.getTime()){
      props.updateTimeFrames(that.startDate.getTime(), that.endDate.getTime()); // >> API
    }
  }

  let updateData = () => {
    var self = that;

    // self._data = (data || []);
    // self._data.forEach(d => {
    //     d.milliseconds = new Date(d.milliseconds);
    //     d.dateMsec = d.milliseconds.getTime();
    //     d.messages = +d.messages;
    // });
    // console.log("OPTS",props);
    self.darkTheme = props.darkTheme || "";
    // let startDate = opts.startDate > opts.endDate ? opts.endDate : opts.startDate;
    // let endDate = opts.endDate < opts.startDate ? opts.startDate : opts.endDate;
    // self.startDate = new Date(startDate);// * 1000
    // self.endDate = new Date(endDate);// * 1000 

    // if(!(props.startDate && props.endDate)){ // because initial draw - invisible div
    //     return;
    // }
    self.startDate = new Date(props.startDate);// * 1000
    self.endDate = new Date(props.endDate);// * 1000 
    self._data = (props.data || []).map(d => {
      // // return(
      //     d.dateMsec = +d.milliseconds * 1000;
      //     d.date = new Date(d.milliseconds);
      //     // d.dateMsec = d.milliseconds;
      //     d.messages = +d.messages;
      //     return d
      //     // )

      return {
        // date: new Date(d.milliseconds);
        milliseconds: new Date(d.milliseconds),
        dateMsec: d.milliseconds,
        messages: +d.messages || 0
        // return d
      }
    });
    self._data.sort((a, b) => {
      return a.dateMsec - b.dateMsec
    });
    // console.log("self._data", self._data);
    // Get the data again
    // d3.csv("data-alt.csv", function(error, data) {

    draw();
  }


  return (
    <>
      <div
        className={props.darkTheme ? "d3-area-chart-wrapper dark" : "d3-area-chart-wrapper "}
        // id="areaSvg"
        ref={vizRef}
      ></div>

      {/* <div className="tooltipArea" id="tooltipArea"></div> */}
    </>
  );
});

export default D3AreaChartWithSelection;
