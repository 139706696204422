import React, { Component } from "react";
import "./ExportContainer.sass";
import "../../components/DescriptionModalWindow.sass";
import { Button, Modal, Select, Checkbox } from "semantic-ui-react";//Dimmer, Loader,
import * as d3 from "d3";

export default class ExportContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedExport: "json",
      mainChartSelected: true, 
      linearChartSelected: true, 
      // jsonExportObject: {},
      // csvGraphObj: [],
      openExportModal: false,
    };
    this.jsonExportObject = {};
    this.csvGraphObj = [];
    this.exportOptions = [
      { key: "json", text: "JSON", value: "json" },
      { key: "csv", text: "CSV 'Matrix'", value: "csv" },
    ];
    this.timeFormatDetailed = d3.timeFormat("%b %d %Y %H:%M:%S");
    // this.jsonExportObject = {};
    this.exportName = "";
  }


  jsonDataPrepare = ()=> {
    let data = this.props;
    // console.log("this.props", this.props)

    let jsonExportObject  = {};
    
    let lineData = this.props && data.lineChartData ?  JSON.parse(JSON.stringify(data.lineChartData)) : [];

    let nodesData = data && data.exportNodesArr ? data.exportNodesArr : [];
    
    let dateRanges = data && data.dateRanges ? data.dateRanges : undefined;

    let workSpaceID = data.workSpaceID;

    lineData = lineData.map(d=>{
      d.milliseconds = +d.milliseconds;
      d.timeStamp = this.timeFormatDetailed(new Date(d.milliseconds));
      d.messages = +d.messages;
      return d
    })

    jsonExportObject={
      "dateRanges": dateRanges,
      "workSpaceID": workSpaceID,
      "usersData": nodesData,
      "communicationIntensity": lineData 
    }
    this.exportName = `pavooq ${dateRanges} `;//${workSpaceID ? "workspace: " + workSpaceID : ""
    this.jsonExportObject = jsonExportObject;
    // this.setState({
    //   jsonExportObject
    // });
    // console.log("this.jsonExportObject",this.jsonExportObject);
  }


  csvDataPrepare = ()=> {
    this.csvGraphObj = this.props.csvGraphObj;
    // this.setState({
    //   csvGraphObj: data
    // });
  }

  componentDidMount() {
    // console.log("export this.props",this.props);
    this.jsonDataPrepare();
    this.csvDataPrepare();
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   // console.log("ForcePage, shouldComponentUpdate", !(nextProps.nodes === this.props.nodes && nextProps.links === this.props.links && nextProps.relationSelected === this.props.relationSelected))
  //   // console.log("export nextProps",nextProps);
  //   if (nextProps !== this.props){
  //     this.jsonDataPrepare(nextProps,this.state.mainChartSelected,this.state.linearChartSelected);
  //     this.csvDataPrepare(nextProps.csvGraphObj);
  //   };
  //   return true// nextProps !== this.props
    
  // }

  handleSelect = (event, { name, value }) => {
    console.log(name,value);
    this.setState({
      selectedExport: value, //this.sanitizer(event.target.value)
    });
  };
  hendleCheckBoxChange = (d, { name, checked }) => {
    // const { name, checked } = e;
    // console.log("hendleCheckBoxChange e",name, checked);
    this.setState({
      [name]: checked
    });
  };

  //export JSON as file
  downloadObjectAsJson = () => {
    this.jsonDataPrepare();
    this.csvDataPrepare();

    let exportObj = this.jsonExportObject;
    let exportName = this.exportName;
    let mainChartSelected = this.state.mainChartSelected;
    let linearChartSelected = this.state.linearChartSelected;

    let exportFile = JSON.parse(JSON.stringify(exportObj));
    exportFile.usersData = mainChartSelected ? exportObj.usersData : [];
    exportFile.communicationIntensity = linearChartSelected ? exportObj.communicationIntensity : [];

    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportFile));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
    exportFile = {};
  }

  
  // //export CSV as file
  downloadObjectAsCsv = () => {
    this.jsonDataPrepare();
    this.csvDataPrepare();
    let exportObj = this.csvGraphObj;
    let exportName = this.exportName;
    // let csvContent = "data:text/csv;charset=utf-8," + exportObj.map(e => e.join(",")).join("\n");
    let csvContent = "data:text/csv;charset=utf-8," + exportObj;
    console.log("downloadObjectAsCsv exportObj",csvContent);
    var encodedUri = encodeURI(csvContent);
    console.log("downloadObjectAsCsv",csvContent);
    var downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute("href", encodedUri);
    downloadAnchorNode.setAttribute("download", exportName + ".csv");
    document.body.appendChild(downloadAnchorNode); // Required for FF

    downloadAnchorNode.click(); // This will download the data file named "my_data.csv".
    downloadAnchorNode.remove();
  }

  render() {

    // console.log("this.state.mainChartSelected",this.state.mainChartSelected,"this.state.linearChartSelected",this.state.linearChartSelected);
    return (
      <Modal
        size="mini"
        closeIcon={<div className="close-container"></div>}
        className={
          this.props.darkTheme ? "customModal export-wrapper dark" : "customModal export-wrapper"
        }
        centered={true} //false
        open={this.state.openExportModal}
        onClose={() => this.setState({ openExportModal: false })}
        onOpen={() => this.setState({ openExportModal: true })}
        trigger={
          <div
            id="exportOpenerID"
            className={
              !!(
                this.props.demoDash
              )
                ? "export-container disabled"//
                : "export-container"// disabled
            }
          ></div>
        } //{<Button>Show Modal</Button>}
      >
        <Modal.Header>Export</Modal.Header>
        <Modal.Content>
          {/* <Modal.Description></Modal.Description> */}
          <div>
            <label htmlFor="#exportFormat">
              Select file type for export
            </label>
            <Select
              fluid
              // search
              className="custom-select"
              // label='Select file type for export'
              // value={this.companySize}
              options={this.exportOptions}
              defaultValue={this.state.selectedExport}
              placeholder="Select format"
              name="exportFormat"
              id="exportFormat"
              selection
              onChange={this.handleSelect}
            />
          </div>
          <div className="checkbox-wrapper">
            <p>
              Select export details
            </p>
            {this.state.selectedExport === "json" && (
              <>
                <Checkbox
                  className="checkbox-custom field"
                  name="mainChartSelected"
                  label="Users and their relations"
                  disabled={this.state.selectedExport!=="json"}
                  defaultChecked={this.state.mainChartSelected}
                  onClick={this.hendleCheckBoxChange}
                />
                <Checkbox
                  className="checkbox-custom field"
                  name="linearChartSelected"
                  label="Сommunication intensity over time"
                  defaultChecked={this.state.linearChartSelected}
                  onClick={this.hendleCheckBoxChange}
                />
              </>
            )}
            {this.state.selectedExport === "csv" && (
              <>
                <p className='subtext'>Can be exported as a matrix only</p>
              </>
            )}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="borderless-button"//"cancel-button"
            onClick={() => this.setState({ openExportModal: false})}
          >
            Cancel
          </Button>
          <Button
            // className="wide"
            onClick={() =>{
              
              //  this.setState({ isError: false })
              if(this.state.selectedExport==="json"){
                this.downloadObjectAsJson();
              } else if (this.state.selectedExport==="csv"){
                this.downloadObjectAsCsv();
              }
              this.setState({ openExportModal: false })
            }
            }
          >
            Export
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
