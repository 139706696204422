import React, { Component } from 'react';
import { Link } from "react-router-dom";//withRouter
import { Button} from 'semantic-ui-react';//Checkbox, Popup, Input 
import './EmailConfirmationError.sass';
// import slackIcon from "../../img/slack-icon.svg";//"../../img/slack-icon.svg"

export default class EmailConfirmationError extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount(){
    
    document.title = "Pavooq Slack Reggistration Error";
  }




  render() {

    return (
      <div className={this.props.isAuthenticated ? "EmailConfirmationError" : "EmailConfirmationError unautetificated"}>
          <div className="slack-connection-wrapper">
          <Link id="comeBackToLogin" className="logo-medium-container" to={`/`}>
            <div className="logo-big-container"></div>
          </Link>
            <h2>
            Oops! Something went wrong...
            </h2>

              <div className="conent">
                <div className="error-message-container">
                  <div className="avatar-empty-container"></div>
                  <p >
                    {/* Email confirmation failed */}
                    {/* <br /> */}
                    Please try again or write me at <a id="loginSupport" href="mailto:yevhen.bereza@pavooq.com"> yevhen.bereza@pavooq.com</a></p>
                </div>
                  <div className="button-container">
                  {this.props.isAuthenticated ? (
              <Button
                className="big-button"
                as={Link}
                to="/forcepage"
                // target="_self"
              >
                Proceed
              </Button>
            ):(
              <Button
                className="big-button"
                as={"a"}
                href="https://pavooq.com/"//#rec286082479
                target="_self"
              >
                Proceed
              </Button>
            )}
                  </div>
              </div>


          </div>
      </div>
    )
  }
}
