
import React, { memo, useMemo, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Dimmer, Loader, Dropdown } from "semantic-ui-react"; //, Search, Popup 
import * as d3 from "d3";
// import "./ForcePage.css";
import "./ForcePage.sass";
import D3forceChart from "../../components/D3forceChart";
import D3AreaChartWithSelection from "../../components/D3AreaChartWithSelection";
// import DescriptionModalWindow from "../../components/DescriptionModalWindow";
import NodesTable from "../NodesTable/NodesTable";
import IndividualConnectionsTable from "../NodesTable/IndividualConnectionsTable";
import IndividualMessageIntensivity from "../NodesTable/IndividualMessageIntensivity";
import ExportContainer from "../ExportContainer/ExportContainer";
import ExportContainerIndividual from "../ExportContainer/ExportContainerIndividual";
// import DataJson from './force_v1.json';
// // import CustomCalendarComponent from "./CustomCalendarComponent.js";
// import DatePicker from "react-datepicker";
import helpers from "../../helpers";
import InactiveList from "../InactiveList/InactiveList";
// import { thresholdSturges } from "d3";
// import slackIcon from "../img/slack-icon.svg";
// import DashboardSearch from "../../components/DashboardSearch";
import SearchedUserHolder from "../../components/SearchedUserHolder";
import DatePickerComponent from "../../components/DatePickerComponent";
import { useSelector, useDispatch } from "react-redux"; // useDispatch, 
// import { setClickedNode, setHoveredNode } from "../../redux/toolsSlice";
import { setClickedNode } from "../../redux/toolsSlice"; //setDropdownSelectedItemForClickedUser
// setIndividualAccumulativeDataByClickedAndDropdownNode, clearIndividualAccumulativeDataByClickedAndDropdownNode

import userIcon from "../../img/user.svg";
import userIconDark from "../../img/user-dark.svg";

// let isGeneralAreaChartVisible = localStorage.getItem("isGeneralAreaChartVisible");
// let isIndividualConnectsChartVisible = localStorage.getItem("isIndividualConnectsChartVisible");
// let isIndividualStatisticVisible = localStorage.getItem("isIndividualStatisticVisible");

// import { set } from 'immer/dist/internal';
// import { setDefaultLocale } from 'react-datepicker';

const ForcePage = memo((props) => {

  const dispatch = useDispatch();
  const clickedNode = useSelector(state => state.tools.clickedNode);
  const clickedNodeId = useSelector(state => state.tools.clickedNodeId);
  // const dropdownSelectedItemForClickedUserInArr = useSelector(state => state.tools.dropdownSelectedItemForClickedUserInArr);
  // const hoveredNode = useSelector(state => state.tools.hoveredNode);
  const [refresh, setRefresh] = useState(false);
  // const [links, setLinks] = useState(undefined);
  // const [nodes, setNodes] = useState(undefined);
  // const [privateClicked, setPrivateClicked] = useState(false);
  // const [mentionClicked, setMentionClicked] = useState(false);
  // const [generalClicked, setGeneralClicked] = useState(false);
  const [relationSelected, setRelationSelected] = useState(props.relationSelected);
  const [isGeneralAreaChartVisible, setIsGeneralAreaChartVisible] = useState(true);
  const [isIndividualConnectsChartVisible, setIsIndividualConnectsChartVisible] = useState(true);
  const [isIndividualStatisticVisible, setIsIndividualStatisticVisible] = useState(true);

  const [dateSwitcherSelected, setDateSwitcherSelected] = useState(props.dateSwitcherSelected);
  const [demoDashVisibility, setDemoDashVisibility] = useState(true);
  const [wordCloudVisible, setWordCloudVisible] = useState(false);
  // const [dateNow, setDateNow] = useState(Date.now());
  const [startDate, setStartDate] = useState(props.startTimeFrame);
  const [endDate, setEndDate] = useState(props.endTimeFrame);
  // const [openModal, setOpenModal] = useState(false);
  const [dateSelectorOpened, setDateSelectorOpened] = useState(false);

  const [isANMRendered, setIsANMRendered] = useState(false);
  const [isDensityRendered, setIsDensityRendered] = useState(false);
  const [isInfluencersRendered, setIsInfluencersRendered] = useState(false);
  const [influencersArrayAll, setInfluencersArrayAll] = useState([]);
  const [influencersArrayPrivate, setInfluencersArrayPrivate] = useState([]);
  const [isInactiveUserlistRendered, setIsInactiveUserlistRendered] = useState(false);
  // const [searchQuery, setSearchQuery] = useState(ubdefined);
  const [graphClick, setGraphClick] = useState(false);
  // const [clickedNode, setClickedNode] = useState(null);

  const individualAccumulativeDataByClickedNode = useSelector(state => state.tools.individualAccumulativeDataByClickedNode);
  const individualAccumulativeDataByClickedAndDropdownNode = useSelector(state => state.tools.individualAccumulativeDataByClickedAndDropdownNode);//individualAccumulativeDataByClickedAndDropdownNode
  const nestedAccObjectOfSendersAndRecieversArr = useSelector(state => state.tools.nestedAccObjectOfSendersAndRecieversArr);

  const [selectOptions, setSelectOptions] = useState([]);
  const [dropdownSelectedItemForClickedUser, setDropdownSelectedItemForClickedUser] = useState(undefined);
  const [dropdownSelectedUserId, setDropdownSelectedUserId] = useState(undefined);

  const [isMissingConnection, setIsMissingConnection] = useState(false);
  const [isIndividualMessagesRendered, setIsIndividualMessagesRendered] = useState(false);
  const [isIndividualContactsVisible, setIsIndividualContactsVisible] = useState(false);
  const [isUserStatusRendered, setIsUserStatusRendered] = useState(false);

  const [relatedLinksWeakAll, setRelatedLinksWeakAll] = useState([]);
  const [relatedLinksWeakPrivate, setRelatedLinksWeakPrivate] = useState([]);
  const [relatedNodesWeakAll, setRelatedNodesWeakAll] = useState([]);
  const [relatedNodesWeakPrivate, setRelatedNodesWeakPrivate] = useState([]);
  const [relatedNodes, setRelatedNodes] = useState([]);
  const [individualConnectionNodes, setIndividualConnectionNodes] = useState([]);



  let that = useMemo(() => {
    let that = {};
    that.dateSwitcherSelected = props.dateSwitcherSelected;
    that.oneDayMsec = 86400000;
    that.oneWeekMsec = 604800017;
    that.oneMonthMsec = 2629800000;
    that.dayAgo = props.maxDate - that.oneDayMsec; //props.endTimeFrame,
    that.weekAgo = props.maxDate - that.oneWeekMsec; //props.endTimeFrame,
    that.monthAgo = props.maxDate - that.oneMonthMsec; //props.endTimeFrame,
    that.densityVisibilityToggle = [];
    that.nodes = undefined;
    that.links = undefined;
    that.linksForGraph = undefined;
    that.userArrForSearch = undefined;
    that.mockData = helpers.defaultDataArray;
    that.emptyMockData = {};
    that.nodeSelected = undefined;
    that.clickedNode = undefined;
    // hoveredNode = undefined;
    that.startDate = props.startTimeFrame; // || new Date(Date.now() - 2629800000);
    that.endDate = props.endTimeFrame; // || new Date(Date.now());
    that.startDateArea = props.startTimeFrame; // || new Date(Date.now() - 2629800000);
    that.endDateArea = props.endTimeFrame; // || new Date(Date.now());
    that.timeFormat = d3.timeFormat("%b %d, %Y");
    that.timeFormatDetailed = d3.timeFormat("%b %d, %Y %H:%M:%S");
    that.anm = { anmAll: 0, anmPrivate: 0 };
    that.influencersAll = 0;
    that.influencersDirect = 0;
    that.allDensity = 0;
    that.privateDensity = 0;
    that.inactiveUsersAll = [];
    that.inactiveUsersAllLength = 0;
    that.inactiveUsersDirect = [];
    that.inactiveUsersDirectLength = 0;

    that.influencersArrayAll = [];
    that.influencersArrayPrivate = [];
    that.influencersAllLength = undefined;
    that.influencersPrivateLength = undefined;
    that.options = [
      { key: "less15", text: "1-15", value: [0, 15] },
      { key: "16-50", text: "16-50", value: [16, 50] },
      { key: "51-100", text: "51-100", value: [51, 100] },
      { key: "100+", text: "more than 100", value: [101, 9999] },
    ];

    return that;
  }, []);


  let accumulativeArr = useMemo(() => {
    // console.log("accumulativeArr", clickedNodeId, individualAccumulativeDataByClickedNode[`${clickedNodeId}_${relationSelected}`]);
    return individualAccumulativeDataByClickedNode[`${clickedNodeId}_${relationSelected}`] || undefined;
  }, [individualAccumulativeDataByClickedNode, clickedNodeId, relationSelected]);


  let accumulativeDropdownUserArr = useMemo(() => {
    // console.log("dropdownSelectedUserId",dropdownSelectedUserId);
    // console.log("accumulativeArr", clickedNodeId, individualAccumulativeDataByClickedNode[`${clickedNodeId}_${relationSelected}`]);
    return individualAccumulativeDataByClickedAndDropdownNode[`${clickedNodeId}_${dropdownSelectedUserId}_${relationSelected}`] || undefined;
  }, [individualAccumulativeDataByClickedAndDropdownNode, clickedNodeId, dropdownSelectedUserId, relationSelected]);

  // let refresh = () => {
  //   setRefresh(!refresh);
  // };

  let expand = () => {
    setDateSelectorOpened(true);
  };

  let collapse = () => {
    setDateSelectorOpened(false);
  };

  let prepareIndividualStatistic = () => {
    // console.log("props.getIndividualStatisticData", (clickedNode && clickedNode.contacts && clickedNode.contacts.length > 0 && that.nodes));
    // Типове використання (не забудьте порівняти пропси):
    // console.log("clickedNode !== prevState.clickedNode",clickedNode !== prevState.clickedNode);
    // console.log("clickedNode",clickedNode,"prevState.clickedNode", prevState.clickedNode);
    // if (clickedNode !== prevState.clickedNode || relationSelected !==prevState.relationSelected|| props.startTimeFrame !== prevProps.startTimeFrame || props.endTimeFrame !== prevProps.endTimeFrame) { // || relationSelected !==prevState.startDate, endDate !==prevState.endDate
    // metrics for individual statistic
    // console.log("clickedNodeId", clickedNodeId);
    let _clickedNode = that.nodes.find(d => d.id === clickedNodeId);
    that.clickedNode = _clickedNode;
    // console.log("that.clickedNode, that.nodes", that.clickedNode, that.nodes);

    //  if(clickedNode && clickedNodeId && that.nodes) {
    //    updatedClickedNode = that.nodes.find(d=>d.id === clickedNodeId);
    //    dispatch(setClickedNode(updatedClickedNode));
    //  }

    let _relatedLinksWeakAll = [];
    let _relatedLinksWeakPrivate = [];
    let _relatedNodesWeakAll = [];
    let _relatedNodesWeakPrivate = [];
    let _relatedNodes = [];
    let _individualConnectionNodes = [];
    let _selectOptions = [];

    if (_clickedNode && that.nodes) {

      //  _relatedNodes = that.links.filter(d => d.source.id === _clickedNode.id && d.values[relationSelected] > 0);
      //  _relatedLinksWeakAll = that.links.filter(d => d.target.id === _clickedNode.id && !d.doubleDirectedAll && d.values.all > 0);
      //  _relatedLinksWeakPrivate = that.links.filter(d => d.target.id === _clickedNode.id && !d.doubleDirectedPrivate && d.values.private > 0); links: [{target: "idghfh", source: "idfff"}, {}]
      _relatedNodes = that.links.filter(d => d.source.id === _clickedNode.id && d.values[relationSelected] > 0);
      _relatedLinksWeakAll = that.links.filter(d => d.target.id === _clickedNode.id && !d.doubleDirectedAll && d.values.all > 0);
      _relatedLinksWeakPrivate = that.links.filter(d => d.target.id === _clickedNode.id && !d.doubleDirectedPrivate && d.values.private > 0);

      // console.log( "relatedLinksWeakPrivate",relatedLinksWeakPrivate," relatedLinksWeakAll", relatedLinksWeakAll);
      _relatedNodesWeakAll = _relatedLinksWeakAll && _relatedLinksWeakAll.length > 0 ? _relatedLinksWeakAll.map(d => d.source) : [];
      _relatedNodesWeakPrivate = _relatedLinksWeakPrivate && _relatedLinksWeakPrivate.length > 0 ? _relatedLinksWeakPrivate.map(d => d.source) : [];


      // console.log("_relatedNodes", _relatedNodes);
      if (_relatedNodes && _relatedNodes.length > 0) {
        _individualConnectionNodes = _relatedNodes.map(data => {
          data.target.sent = 0;
          data.target.received = 0;
          data.target.clickedAndRelatedNodesMessageSum = 0;
          data.source.contacts.forEach(d => {
            if (!!(data.target.id === d[0])) {
              data.target.sent = +data.target.sent + +d[1][relationSelected]
            }
          });
          data.target.contacts.forEach(d => {
            if (!!(data.source.id === d[0])) {
              data.target.received = +data.target.received + +d[1][relationSelected]
            }
          });
          data.target.clickedAndRelatedNodesMessageSum = data.target.sent + data.target.received;
          return data.target;
        });

        _selectOptions = _relatedNodes.map(d => {
          //image: { avatar: true, src: '/images/avatar/small/jenny.jpg' }
          return {
            key: d.target.id,
            text: d.target.name,
            value: d.target.id,
            sort: d.target.clickedAndRelatedNodesMessageSum, // d.target.received + d.target.sent,
            image: { avatar: true, src: d.target.avatar }
          }
        });
        _selectOptions.sort((a,b) => b.sort - a.sort);
        // console.log("_selectOptions", _selectOptions);

        let min = d3.min(_relatedNodes, d => d.target.clickedAndRelatedNodesMessageSum);
        let max = d3.max(_relatedNodes, d => d.target.clickedAndRelatedNodesMessageSum);
        let minMaxDif = max - min;
        let difOneThird = minMaxDif / 3;
        let lowToMiddle = min + difOneThird;
        let middleToHigh = min + (difOneThird * 2);
        _individualConnectionNodes.forEach(d => {
          if (d.clickedAndRelatedNodesMessageSum >= min && d.clickedAndRelatedNodesMessageSum < lowToMiddle) {
            d.relation = "c";
          }
          else if (d.clickedAndRelatedNodesMessageSum >= lowToMiddle && d.clickedAndRelatedNodesMessageSum < middleToHigh) {
            d.relation = "b";
          }
          else {
            d.relation = "a";
          }
          d.relationNum = d.clickedAndRelatedNodesMessageSum;
        });
      }

      setRelatedNodes(_relatedNodes);
      setRelatedLinksWeakAll(_relatedLinksWeakAll);
      setRelatedLinksWeakPrivate(_relatedLinksWeakPrivate);
      setRelatedNodesWeakAll(_relatedNodesWeakAll);
      setRelatedNodesWeakPrivate(_relatedNodesWeakPrivate);
      setIndividualConnectionNodes(_individualConnectionNodes);
      setSelectOptions(_selectOptions);
    }

    // console.log("_selectOptions", _selectOptions);
    if (_clickedNode && _clickedNode.contacts && _clickedNode.contacts.length > 0 && that.nodes) {



      let clickedNodeInArr = [_clickedNode];
      if(!props.demoDash && props.isAuthenticated){
        props.getIndividualStatisticData(clickedNodeId, clickedNodeInArr, _individualConnectionNodes, "accumulativeArr");
      }


      // !!!why this if we have it inside another useEffect, under this useEffect
      // individualAccumulativeDataByClickedNode && individualAccumulativeDataByClickedNode.length > 0 && // why this if you don't use it inside if {...}
      // && !dropdownSelectedItemForClickedUser // we need first user in dropdown for each selected user from the graph
      if (_selectOptions && _selectOptions.length) {
        let clickedNodeInArr = [_clickedNode];
        let node = _relatedNodes.find(d => {
          return d.target.id === _selectOptions[0].value;
        });
        let dropdownSelectedItemForClickedUserInArr = [node.target]; // dropdownSelectedItemForClickedUser >> !!!! why push empty ...User
        setDropdownSelectedUserId(node.target.id);
        // dispatch(setDropdownSelectedItemForClickedUser(selectOptions[0].value));
        // console.log("_selectOptions[0].value",_selectOptions[0].value,);//"node",node);
        setDropdownSelectedItemForClickedUser(_selectOptions[0].value);
        // console.log("accumulativeArrfghkjhjkhkjl", clickedNodeInArr, dropdownSelectedItemForClickedUserInArr);
        if(!props.demoDash && props.isAuthenticated){
          props.getIndividualStatisticData(clickedNodeId, clickedNodeInArr, dropdownSelectedItemForClickedUserInArr, "individualAccumulativeDataByClickedAndDropdownNode");
        }
      } else {
        // dispatch(setDropdownSelectedItemForClickedUser(undefined));
        setDropdownSelectedItemForClickedUser(undefined);
        //  dispatch(setIndividualAccumulativeDataByClickedAndDropdownNode([])); !! must be {} with key/values fields 
        // dispatch(clearIndividualAccumulativeDataByClickedAndDropdownNode());
      }

    }
    // else if (clickedNode && accumulativeArr.length){ // otherwise clear individual chart
    //   props.setAccumulativeArr([]);
    // } rtTimeFrame, props.endTi

    // }
  }

  let prepareData = (props) => {
    //
    // nextProps.chartUserList !== props.chartUserList ||
    // nextProps.slackUserList !== props.slackUserList ||

    let isEmptyObj = !!props.chartUserList
      ? JSON.stringify(props.chartUserList) === "{}"
      : JSON.stringify(props.chartUserList) === "{}" || !props.chartUserList;

    let isEmptySlackMembersObj = !!props.slackUserList
      ? JSON.stringify(props.slackUserList) === "{}"
      : JSON.stringify(props.slackUserList) === "{}" || !props.slackUserList;

    // let data = !isEmptyObj ? props.chartUserList : !isEmptySlackMembersObj ? props.slackUserList : that.mockData;
    // console.log("data",data);
    // data = data[Object.keys(data)[0]] ? data[Object.keys(data)[0]] : {};

    // let data = isEmptyObj ? that.mockData : props.slackUserList;
    let _data = !isEmptyObj
      ? props.chartUserList
      : !isEmptySlackMembersObj
        ? props.slackUserList
        : that.mockData;
    let data = JSON.parse(JSON.stringify(_data));

    // console.log("data", data);

    let nodes = [];

    let links = [];

    let exportNodes = [];
    for (let [key, value] of Object.entries(data)) {
      // console.log(key, value);
      //[key, value]
      let id = key;
      // let email = value.email;
      let avatar = value.avatar;
      let name = value.name;
      let messageAmount;
      let granted = value.granted;
      let invited = value.invited;
      let invitable = value.invitable;
      // let relations = value.relations;
      // let private,mention,general,all
      for (let relationKey of Object.keys(value.relations)) {//(let [relationKey, relationVal] of Object.entries(value.relations)) {
        // console.log("relationKey,relationVal",value.relations,relationKey,relationVal);
        value.relations[relationKey].all =
          // Math.round(value.relations[relationKey].private) +
          // Math.round(value.relations[relationKey].mention) +
          // Math.round(value.relations[relationKey].general);
          +value.relations[relationKey].private.toFixed(1) +
          +value.relations[relationKey].mention.toFixed(1) +
          +value.relations[relationKey].general.toFixed(1);
      }
      // value.relations.all = Math.round(value.relation.private) +  Math.round(value.relation.mention) +  Math.round(value.relation.general);

      let values = Object.entries(value.relations);
      messageAmount = values.reduce(
        (acc, d, i, arr) => {
          // console.log("reduce ", d[1], " arr", arr);
          // acc.private = acc.private + d[1].private;
          // acc.mention = acc.mention + d[1].mention;
          // acc.general = acc.general + d[1].general;
          // acc.all = acc.private + acc.mention + acc.general;
          // acc.private = Math.round(acc.private + d[1].private);//.toFixed();
          // acc.mention = Math.round(acc.mention + d[1].mention);//.toFixed();
          // acc.general = Math.round(acc.general + d[1].general);//.toFixed();
          acc.private = +acc.private + +d[1].private.toFixed(1); //.toFixed();
          acc.mention = +acc.mention + +d[1].mention.toFixed(1); //.toFixed();
          acc.general = +acc.general + +d[1].general.toFixed(1); //.toFixed();
          // acc.privateAndMention = acc.private + acc.mention;
          // acc.privateAndGeneral = acc.private + acc.general;
          // acc.mentionAndGeneral = acc.mention + acc.general;
          acc.all = acc.private + acc.mention + acc.general;
          return acc;
        },
        {
          private: 0,
          mention: 0,
          general: 0,
          all: 0,
        }
      );
      // for (let [k, v] of Object.entries(value.relations)) {
      //     console.log("k", k, "v", v);
      //     messageAmount = v.private + v.mention + v.general;
      // }

      // console.log("values", values);
      let obj = {
        id,
        name,
        // email,
        avatar,
        contacts: values,
        contactCount: values.length,
        values,
        messageAmount,
        granted,
        invited,
        invitable,
      };

      nodes.push(obj);

      let exportObj = {
        id,
        name,
        // email,
        avatar,
        contacts: values.map((d) => {
          let el = { ...d };
          el[1].private = +el[1].private.toFixed(1);
          el[1].mention = +el[1].mention.toFixed(1);
          el[1].general = +el[1].general.toFixed(1);
          // el[1].private = Math.floor(el[1].private);
          // el[1].mention = Math.floor(el[1].mention);
          // el[1].general = Math.floor(el[1].general);
          return d;
        }),
        // contactCount: values.length,
        // relations,
        messageAmount,
        // granted,
        // invited: invited ? that.timeFormatDetailed(invited * 1000): false,
        // invitable,
      };

      exportNodes.push(exportObj);

      that.exportNodesArr = JSON.parse(JSON.stringify(exportNodes));
    }

    // let filteredLinks = [];
    // let linksBySourceAndTarget = {};
    let users = Object.entries(data);
    users.forEach((user) => {
      let source = user[0]; //  === id;
      let relations = Object.entries(user[1].relations || {});
      // user.push(relations); // [2]
      let link;
      relations.forEach((d) => {
        let target = d[0];

        //fing one side connection
        let doubleDirectedAll = !!(
          data[source].relations[target] &&
          data[target].relations[source] &&
          (data[source].relations[target].private ||
            data[source].relations[target].general ||
            data[source].relations[target].mention) &&
          (data[target].relations[source].private ||
            data[target].relations[source].general ||
            data[target].relations[source].mention)
        );

        let doubleDirectedPrivate = !!(
          data[source].relations[target] &&
          data[target].relations[source] &&
          data[source].relations[target].private &&
          data[target].relations[source].private
        );

        // one way = sum, where user[1] === data[source]

        // let general = (data[source].relations[target] ? data[source].relations[target].general : 0) + (data[target].relations[source] ? data[target].relations[source].general : 0);
        // let mention = (data[source].relations[target] ? data[source].relations[target].mention : 0) + (data[target].relations[source] ? data[target].relations[source].mention : 0);
        // let _private = (data[source].relations[target] ? data[source].relations[target].private : 0) + (data[target].relations[source] ? data[target].relations[source].private : 0);

        // two way
        let general = doubleDirectedAll
          ? (data[source].relations[target]
            ? data[source].relations[target].general
            : 0) +
          (data[target].relations[source]
            ? data[target].relations[source].general
            : 0)
          : data[source].relations[target]
            ? data[source].relations[target].general
            : 0;
        let mention = doubleDirectedAll
          ? (data[source].relations[target]
            ? data[source].relations[target].mention
            : 0) +
          (data[target].relations[source]
            ? data[target].relations[source].mention
            : 0)
          : data[source].relations[target]
            ? data[source].relations[target].mention
            : 0;
        let _private = doubleDirectedPrivate
          ? (data[source].relations[target]
            ? data[source].relations[target].private
            : 0) +
          (data[target].relations[source]
            ? data[target].relations[source].private
            : 0)
          : data[source].relations[target]
            ? data[source].relations[target].private
            : 0;
        let all = general + mention + _private;
        // compile link object
        link = {
          source,
          target,
          doubleDirectedAll,
          doubleDirectedPrivate,
          values: {
            all,
            general,
            mention,
            private: _private,
          },
        };
        links.push(link);
      });
    });


    nodes.sort((a, b) => b.messageAmount.private - a.messageAmount.private);


    if (props.hideEmptyUsersCondition) {

      nodes = nodes.filter((d) => {
        return d.messageAmount.private;
      });

      links = links.filter((d) => {
        return d.values.private;
      });
      links = links.filter((el) => {
        return nodes.some((f) => {
          return f.id === el.source; // && f.projectid === el.projectid;
        });
      });
      links = links.filter((el) => {
        return nodes.some((f) => {
          return f.id === el.target; // && f.projectid === el.projectid;
        });
      });
    }

    //Metrics
    let nodesAmount = nodes && nodes.length ? nodes.length : 0;

    //density for directed chart https://en.wikipedia.org/wiki/Dense_graph

    let linksWithPrivate = links.filter((d) =>
      d.values.private ? 1 : 0
    ).length;
    let linksWithAll = links.filter((d) => (d.values.all ? 1 : 0)).length;

    let maxLinks = nodesAmount * (nodesAmount - 1);
    that.allDensity = Math.round((linksWithAll / maxLinks) * 100);
    that.privateDensity = Math.round((linksWithPrivate / maxLinks) * 100);

    // console.log("MAXIMUM NODES AMOUNT", maxLinks,
    // "All DENSITY", that.allDensity,
    // "PRIVATE DENSITY", that.privateDensity);

    // console.log("links", links);
    // console.log("nodes", nodes);
    nodes.forEach(d => {
      d.messageAmountCounter = d.messageAmount[relationSelected];
      let contactByRelArr = d.contacts.filter((e, i) => {
        return e[1][relationSelected]
      });
      d.contactCountByRelations = contactByRelArr.length;
    })

    that.nodes = JSON.parse(JSON.stringify(nodes));
    let nodeByID = that.nodes.reduce((acc, el) => {
      acc[el.id] = el;
      return acc;
    }, {});
    that.nodeByID = nodeByID;

    that.links = JSON.parse(JSON.stringify(links));
    that.linksForGraph = JSON.parse(JSON.stringify(links))
    that.links.forEach(link => {
      link.target = nodeByID[link.target] || {};
      link.source = nodeByID[link.source] || {};
    });

    that.userArrForSearch = Object.values(that.nodes);
    // props.loadingToggler();
    // });

    //Inactive:
    // The number of users (nodes) who didn't send messages in the selected period.
    // On hover shows the list of the users.
    // All/Direct
    that.inactiveUsersAll = that.nodes.filter((d) => d.messageAmount.all === 0);
    that.inactiveUsersAllLength = that.inactiveUsersAll.length;
    that.inactiveUsersDirect = that.nodes.filter(
      (d) => d.messageAmount.private === 0
    );
    that.inactiveUsersDirectLength = that.inactiveUsersDirect.length;
    // console.log("inactiveUsersAllLength", that.inactiveUsersAllLength, "inactiveUsersDirectLength", that.inactiveUsersDirectLength);

    // ANM (average number of messages):

    let startDay = props.startTimeFrame;
    let endDay = props.endTimeFrame;
    let msecAmount = endDay - startDay;
    let daysAmount = msecAmount / that.oneDayMsec || 0;
    // console.log("daysAmount", daysAmount);

    let messageAllSum = that.nodes.reduce((acc, d) => {
      // console.log("d.messageAmount.all",d.messageAmount.all);
      return (acc = acc + d.messageAmount.all);
    }, 0);

    let messagePrivateSum = that.nodes.reduce((acc, d) => {
      // console.log("d.messageAmount.private",d.messageAmount.private);
      return (acc = acc + d.messageAmount.private);
    }, 0);
    // console.log("messageAllSum",messageAllSum,"messagePrivateSum",messagePrivateSum);
    // that.anm = { anmAll: (Math.round((messageAllSum / daysAmount) / nodesAmount)), anmPrivate: (Math.round((messagePrivateSum / daysAmount) / nodesAmount)) };
    that.anm = {
      anmAll: Math.ceil(messageAllSum / daysAmount) || 0,
      anmPrivate: Math.ceil(messagePrivateSum / daysAmount) || 0,
    };
    // console.log("ANM (average number of messages): ", that.anm);
    // INFLUENCERS: 
    // this.nodes = 
    that.nodes.forEach((d, j) => {
      d.contactsByRelAll = d.contacts.filter((e, i) => {
        return e[1]['all']
      });
      d.contactByRelDirect = d.contacts.filter((e, i) => {
        return e[1]['private']
      });
      d.contactsByRelAllCount = d.contactsByRelAll.length;
      d.contactByRelDirectCount = d.contactByRelDirect.length;
      // return d
    });
    // console.log("that.nodes", that.nodes);
    let contactsByRelAllCounts = that.nodes.map(d => d.contactsByRelAllCount).sort(d3.ascending);
    let contactByRelDirectCounts = that.nodes.map(d => d.contactByRelDirectCount).sort(d3.ascending);
    // console.log("contactsByRelAllCounts, contactByRelDirectCounts", contactsByRelAllCounts, contactByRelDirectCounts);
    // let minAll = d3.min(that.nodes, d => d.contactsByRelAllCount);
    // let maxAll = d3.max(that.nodes, d => d.contactsByRelAllCount);
    // let minPrivate = d3.min(that.nodes, d => d.contactByRelDirectCount);
    // let maxPrivate = d3.max(that.nodes, d => d.contactByRelDirectCount);

    // console.log("minAll ", minAll, "maxAll", maxAll);
    // console.log("minPrivate ", minAll, "maxAll", maxPrivate);
    // let minMaxDifAll = maxAll - minAll; // 12 - 3 = 9 >> [3...6, 6...9, 9...12]
    // let minMaxDifPrivate = maxPrivate - minPrivate;
    // let difOneThirdAll = minMaxDifAll / 3; // 9 / 3 = 3
    // let difOneThirdPrivate = minMaxDifPrivate / 3;
    // // let lowToMiddleAll = minAll + difOneThirdAll;
    // // let lowToMiddlePrivate = minPrivate + difOneThirdPrivate;
    // let middleToHighAll = minAll + (difOneThirdAll * 2); // 3 + 3 * 2 = 9
    // let middleToHighPrivate = minPrivate + (difOneThirdPrivate * 2);

    let middleToHighAll = d3.quantile(contactsByRelAllCounts, 0.65); // 3 + 3 * 2 = 9
    let middleToHighPrivate = d3.quantile(contactByRelDirectCounts, 0.65);
    // console.log("middleToHighAll ", middleToHighAll, "middleToHighPrivate", middleToHighPrivate);

    that.influencersArrayAll = that.nodes.filter(d => d.contactsByRelAllCount >= middleToHighAll);
    that.influencersArrayPrivate = that.nodes.filter(d => d.contactByRelDirectCount >= middleToHighPrivate);
    // console.log("that.influencersArrayAll ", that.influencersArrayAll, "that.influencersArrayPrivate", that.influencersArrayPrivate);
    setInfluencersArrayAll(that.influencersArrayAll);
    setInfluencersArrayPrivate(that.influencersArrayPrivate);


    that.influencersAllLength = that.influencersArrayAll && that.influencersArrayAll.length ? that.influencersArrayAll.length : 0;
    that.influencersPrivateLength = that.influencersArrayPrivate && that.influencersArrayPrivate.length ? that.influencersArrayPrivate.length : 0;
    prepareIndividualStatistic();
    // });
    // ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  };


  useEffect(() => {
    // if we have new userList we need check if old clickedNode exist in this list, if no - unselect that node
    // if(clickedNodeId && props.chartUserList && props.chartUserList && !props.chartUserList[clickedNodeId]){
    if(clickedNodeId && !(props.chartUserList && props.chartUserList[clickedNodeId])){
      dispatch(setClickedNode(undefined));
    }
  }, [props.chartUserList]);


  let graphRefresh = useMemo(() => {
    document.title = "Pavooq Dashboard";
    // that.automaticSwitherSelected();
    // let isRefresh =
    //   nextProps.chartUserList !== props.chartUserList ||
    //   nextProps.slackUserList !== props.slackUserList ||
    //   nextProps.lineChartData !== props.lineChartData ||
    //   nextProps.gridChartCondition !== props.gridChartCondition ||
    //   nextProps.hideEmptyUsersCondition !==
    //   props.hideEmptyUsersCondition ||
    // nextProps.csvGraphObj !== props.csvGraphObj; // ||
    // nextProps.dateSwitcherSelected !== props.dateSwitcherSelected ||
    // // nextProps.noDataToDisplay !== props.noDataToDisplay; //||
    // nextProps.startTimeFrame !== props.startTimeFrame ||
    // nextProps.endTimeFrame !== props.endTimeFrame ;//||
    // nextProps.monthFilterEnabled !== props.monthFilterEnabled ||
    // nextProps.weekFilterEnabled !== props.weekFilterEnabled ||
    // nextProps.dayFilterEnabled !== props.dayFilterEnabled ||
    // nextProps.customFilterEnabled !== props.customFilterEnabled;
    that.dayAgo = props.maxDate - that.oneDayMsec; //props.endTimeFrame,
    that.weekAgo = props.maxDate - that.oneWeekMsec; //props.endTimeFrame,
    that.monthAgo = props.maxDate - that.oneMonthMsec; //props.endTimeFrame,
    prepareData(props); // we have prepareIndividualStatistic inside prepareData

    // setRefresh(!refresh);
    // refresh();
    return {}
  }, [props.chartUserList, relationSelected, props.slackUserList, props.lineChartData, props.gridChartCondition, props.hideEmptyUsersCondition, props.csvGraphObj]);

  // useEffect(()=>{

  //   if(clickedNode && clickedNodeId && that.nodes) {
  //     let updatedClickedNode = that.nodes.find(d=>d.id === clickedNodeId);
  //     dispatch(setClickedNode(updatedClickedNode));
  //   }
  // },[clickedNode, relationSelected, startDate,endDate, props.startTimeFrame, props.endTimeFrame]);


  useEffect(() => { // let componentDidUpdate = (prevProps, prevState, snapshot) => {
    prepareIndividualStatistic();
  }, [clickedNodeId]); // relationSelected, startDate, endDate, props.startTimeFrame, props.endTimeFrame


  let chartScalePositionHolder = (newTransform) => {
    if (newTransform) {
      // check it - was if(newTransform){
      helpers.transform.x = newTransform.x;
      helpers.transform.y = newTransform.y;
      helpers.transform.k = newTransform.k;
    }
    // console.log("newTransform", newTransform, that.transform);
    return helpers.transform;
  };
  let invitedUserHolder = (users) => {
    props.getSlackUserListToInvite(false);
    props.updateInvitationTimeOfUsersObj(users);
    setRefresh(!refresh);

    prepareData(props);
    // setInvitedUsers(users)
  };

  // let clickedNodeHolderUpdateChart = (
  //   _clickedNode,
  //   chartClickedCallback,
  //   clickInTable
  // ) => {
  //   // only once => in D3ForceChart
  //   // console.log("clickedNodeHolderUpdateChart", clickedNode, !!chartClickedCallback);
  //   // clickedNode = clickedNode;
  //   // hoveredNode = hoveredNode;
  //   if (chartClickedCallback) {
  //     that.chartClickedCallback = chartClickedCallback;
  //     // that.clickInTable = false;
  //   }
  //   // else { // from table
  //   //   // that.clickInTable = true;
  //   // }
  //   // console.log(!!that.chartClickedCallback, ",clickInTable=", clickInTable, ",clickedNode=", clickedNode);
  //   if (that.chartClickedCallback) {
  //     that.chartClickedCallback(_clickedNode, clickInTable);
  //   }
  //   // return (clickedNode) => { // clickedNodeHolder(clickedNode) => in NodesTable
  //   //   clickedNode = clickedNode;
  //   //   if(clickedNode){
  //   //     callback(clickedNode);
  //   //   }
  //   // }
  // if(!props.demoDash && props.isAuthenticated){
  //   // props.getIndividualStatisticData(clickedNode,relatedNodes,"accumulativeArr");
      // }
  //   dispatch(setClickedNode(_clickedNode));

  // };

  // let hoveredNodeHolderUpdateChart = (_hoveredNode, chartHoveredCallback) => {
  //   // only once => in D3ForceChart
  //   // console.log("hoveredNodeHolder", hoveredNode, chartHoveredCallback);
  //   // hoveredNode = _hoveredNode;
  //   if (chartHoveredCallback) {
  //     that.chartHoveredCallback = chartHoveredCallback;
  //   }
  //   if (that.chartHoveredCallback) {
  //     that.chartHoveredCallback(_hoveredNode);
  //   }
  //   // return (hoveredNode) => { // hoveredNodeHolder(hoveredNode) => in NodesTable
  //   //   hoveredNode = hoveredNode;
  //   //   if(hoveredNode){
  //   //     callback(hoveredNode);
  //   //   }
  //   // }
  // };

  // let clickedNodeHolderUpdateTable = (_clickedNode, tableClickedCallback) => {
  //   // only once => in D3ForceChart
  //   // console.log("clickedNodeHolderUpdateTable", clickedNode, tableClickedCallback);
  //   // clickedNode = clickedNode;
  //   // hoveredNode = hoveredNode;
  //   if (tableClickedCallback) {
  //     that.tableClickedCallback = tableClickedCallback;
  //     // that.clickInTable = true;
  //   }
  //   //  else {
  //   //   that.clickInTable = false;
  //   // }
  //   if (that.tableClickedCallback) {
  //     // (clickedNode || hoveredNode)
  //     that.tableClickedCallback(_clickedNode, true); //, hoveredNode
  //   }
  //   dispatch(setClickedNode(_clickedNode));
  // };

  // let hoveredNodeHolderUpdateTable = (_hoveredNode, tableHoveredCallback) => {
  //   // only once => in in D3ForceChart
  //   // console.log("hoveredNode", hoveredNode, tableCallback);
  //   // hoveredNode = hoveredNode;
  //   if (tableHoveredCallback) {
  //     that.tableHoveredCallback = tableHoveredCallback;
  //   }
  //   if (that.tableHoveredCallback) {
  //     that.tableHoveredCallback(_hoveredNode);
  //   }
  // };

  let handleSelectUserOfClickedNode = (e, val) => {
    let node = relatedNodes.find(d => {
      return d.target.id === val.value;
    });
    // console.log("handleSelectUserOfClickedNode", node);
    // dispatch(setDropdownSelectedItemForClickedUser(val.value));
    setDropdownSelectedItemForClickedUser(val.value);
    let clickedNodeInArr = [clickedNode];
    let dropdownSelectedItemForClickedUserInArr = [node.target];
    setDropdownSelectedUserId(node.target.id);
    if(!props.demoDash && props.isAuthenticated){
      props.getIndividualStatisticData(clickedNodeId, clickedNodeInArr, dropdownSelectedItemForClickedUserInArr, "individualAccumulativeDataByClickedAndDropdownNode");
    }
  };

  let handleGraphClick = (state) => {
    // console.log("you clicked outside search pannel");
    if (state) {
      collapse()
    }
    // if(!props.demoDash && props.isAuthenticated){
    // props.getIndividualStatisticData(clickedNode,relatedNodes,"accumulativeArr");
  // }
    setGraphClick(state);
  };

  let updateTimeFramesLocal = (startDate, endDate) => {

    // if(clickedNodeId && that.nodes) {
    //   let updatedClickedNode = that.nodes.find(d=>d.id === clickedNodeId);
    //   dispatch(setClickedNode(updatedClickedNode));
    // }
    props.updateTimeFrames(startDate, endDate, "customTime");
    that.dateSwitcherSelected = "customTime";
    setStartDate(startDate);
    setEndDate(endDate);
    setDateSwitcherSelected("customTime");
  };

  let setDateRange = (date) => {
    let startDatePrev =
      startDate !== undefined
        ? startDate
        : props.startTimeFrame; // || (startDate ? startDate.getTime() : undefined);
    let endDatePrev =
      endDate !== undefined
        ? endDate
        : props.endTimeFrame;
    date = date.getTime();
    let _startDate, _endDate;
    if (!startDatePrev && !endDatePrev) {
      _startDate = date;
      setStartDate(date);
      // Дата начала установлена, установите дату окончания
    } else if (startDatePrev && !endDatePrev) {
      if (date < startDatePrev) {
        // console.log("date < startDate");
        _startDate = date; // endDate = date;
        that.startDate = date;
        _endDate = startDatePrev; // that.startDate=

        setStartDate(_startDate);
        setEndDate(_endDate);
      } else if (date - startDatePrev < that.oneDayMsec) {
        // console.log("date === startDate");
        // date.setHours(date.getHours() - 1);
        date = date - 3600000;
        //   Date.prototype.addHours= function(h){
        //     that.setHours(that.getHours()+h);
        //     return that;
        // }
        _startDate = date; // endDate = date;
        that.startDate = date;
        _endDate = startDatePrev; // that.startDate
        setStartDate(_startDate);
        setEndDate(_endDate);
      } else {
        // console.log("endDate = date");
        _endDate = date;
        setEndDate(_endDate);
      }
    } else if (startDatePrev && endDatePrev && date !== startDatePrev) {
      _startDate = date;
      _endDate = null;
      // that.endDate = null;
      setStartDate(_startDate);
      setEndDate(_endDate);
      // console.log("start,", startDatePrev, "end", endDatePrev);
    }

    // console.log(that.startDate, that.endDate, startDate, endDate);
    // console.log(startDate !== that.startDate , endDate !== that.endDate , startDate , endDate);
    if (!!(_endDate !== that.endDate && startDatePrev && _endDate)) {
      // console.log(startDate, endDate, endDate);
      collapse();
      // props.dateSwitcherSelectedChange("customTime");
      that.startDateArea = that.startDate;
      that.endDateArea = _endDate;
      props.updateTimeFrames(that.startDate, _endDate, "customTime"); // >> API
    }
    if (_startDate !== undefined) {
      that.startDate = _startDate;
    }
    if (_endDate !== undefined) {
      that.endDate = _endDate;
    }
  };

  let toggleDemoModal = (e) => {
    setDemoDashVisibility(!demoDashVisibility);
  };

  // let wordCloudVisChanger = () => {
  //   setWordCloudVisible(true);
  // };

  let handleDateChange = (e) => {
    const { value } = e.target;
    // console.log("handleDateChange", value);
    // let oldSelectedItem = dateSwitcherSelected;
    if (dateSwitcherSelected === value) {
      return;
    } else {
      // const { ...value } = e.target;
      // console.log(value);
      if (value === "lastDay") {
        collapse();
        // props.updateTimeFrames(dateNow, that.dayAgo);
        props.updateTimeFrames(that.dayAgo, props.maxDate, value);
        // props.dateSwitcherSelectedChange(value);

        setStartDate(that.dayAgo);
        setEndDate(props.endTimeFrame);
        setDateSwitcherSelected(value);

      } else if (value === "lastWeek") {
        collapse();
        props.updateTimeFrames(that.weekAgo, props.maxDate, value);
        // props.dateSwitcherSelectedChange(value);

        setStartDate(that.weekAgo);
        setEndDate(props.endTimeFrame);
        setDateSwitcherSelected(value);

      } else if (value === "lastMonth") {
        collapse();
        props.updateTimeFrames(that.monthAgo, props.maxDate, value);
        // props.dateSwitcherSelectedChange(value);

        setStartDate(that.monthAgo);
        setEndDate(props.endTimeFrame);
        setDateSwitcherSelected(value);

      } else if (value === "customTime") {
        expand();

        setDateSwitcherSelected(value);
      }
    }
  };


  let ANMVisibilityToggle = (event, statement) => {
    event.preventDefault();
    event.stopPropagation();
    // if (event && event.target && event.target.id) {
    // console.log("BOOOM",event, event.target.id, ":",statement);
    setIsANMRendered(statement);
    // }
  }

  let densityVisibilityToggle = (event, statement) => {
    event.preventDefault();
    event.stopPropagation();
    // if (event && event.target && event.target.id) {
    // console.log("BOOOM",event, event.target.id, ":",statement);
    setIsDensityRendered(statement);
    // }
  }

  let influencersVisibilityToggle = (event, statement) => {
    event.preventDefault();
    event.stopPropagation();
    // if (event && event.target && event.target.id) {
    // console.log("BOOOM",event, event.target.id, ":",statement);
    setIsInfluencersRendered(statement);
    // }
  }

  let inactiveUserlistToggle = (event, statement) => {
    event.preventDefault();
    event.stopPropagation();
    // if (event && event.target && event.target.id) {
    // console.log("BOOOM", event, event.target.id, ":", statement);
    setIsInactiveUserlistRendered(statement);
    // }
  }

  let userStatusToggle = (event, statement) => {
    event.preventDefault();
    event.stopPropagation();
    // if (event && event.target && event.target.id) {
    // console.log("BOOOM",event, event.target.id, ":",statement);
    setIsUserStatusRendered(statement);
    // }
  }

  let individualMessagesToggle = (event, statement) => {
    event.preventDefault();
    event.stopPropagation();
    // if (event && event.target && event.target.id) {
    // console.log("BOOOM",event, event.target.id, ":",statement);
    setIsIndividualMessagesRendered(statement);
    // }
  }

  let individualContactsToggle = (event, statement) => {
    event.preventDefault();
    event.stopPropagation();
    // if (event && event.target && event.target.id) {
    // console.log("BOOOM",event, event.target.id, ":",statement);
    setIsIndividualContactsVisible(statement);
    // }
  }

  let missingConnectionToggle = (event, statement) => {
    event.preventDefault();
    event.stopPropagation();
    // if (event && event.target && event.target.id) {
    // console.log("BOOOM",event, event.target.id, ":",statement);
    setIsMissingConnection(statement);
    // }
  }

  // console.log("foundUsers", foundUsers);
  let theme = props.darkTheme;
  let _startDate =
    startDate !== undefined
      ? startDate
      : props.startTimeFrame; // || (startDate ? startDate.getTime() : undefined);
  let _endDate =
    endDate !== undefined
      ? endDate
      : props.endTimeFrame; // || (startDate ? endDate.getTime() : undefined);

  let startDateArea =
    that.startDateArea !== undefined
      ? that.startDateArea
      : props.startTimeFrame;
  let endDateArea =
    that.endDateArea !== undefined
      ? that.endDateArea
      : props.endTimeFrame;

  let _dateSwitcherSelected = dateSwitcherSelected
    ? dateSwitcherSelected
    : props.dateSwitcherSelected;
  // console.log("startDate", startDate, " endDate", endDate, "dateSwitcherSelected", dateSwitcherSelected);
  // let minDate = props.minDate
  //   ? new Date(props.minDate)
  //   : new Date();
  // let maxDate = props.maxDate
  //   ? new Date(props.maxDate)
  //   : new Date();
  let startDateFromat = new Date(_startDate);
  let endDateFormat = new Date(_endDate);
  let startDateFormated = that.timeFormat(startDateFromat);
  let endDateFormated = that.timeFormat(endDateFormat);
  let areaChartVisible = !!(
    !props.demoDash &&
    props.lineChartData &&
    props.lineChartData.length &&
    _startDate &&
    (_endDate || _endDate === null)
  );
  // console.log("areaChartVisible", props.demoDash , props.lineChartData && props.lineChartData.length , (startDate) , (endDate || endDate === null));
  // console.log("areaChartVisible result", !!(!props.demoDash && props.lineChartData && props.lineChartData.length && (startDate) && (endDate || endDate === null)));

  let dateRanges =
    startDateFormated && endDateFormated
      ? `${startDateFormated} - ${endDateFormated}`
      : "";

  // console.log("dateRanges", dateRanges);
  let isDayDisabled = !!(
    props.demoDash ||
    props.isLoading ||
    !areaChartVisible ||
    !props.dayFilterEnabled
  );

  let isWeekDisabled = !!(
    props.demoDash ||
    props.isLoading ||
    !areaChartVisible ||
    !props.weekFilterEnabled
  );

  let isMonthDisabled = !!(
    props.demoDash ||
    props.isLoading ||
    !areaChartVisible ||
    !props.monthFilterEnabled
  );
  // console.log("isMonthDisabled", props.demoDash , props.isLoading , !areaChartVisible , !(props.monthFilterEnabled));
  // console.log("isMonthDisabled result", isMonthDisabled);

  let isCustomDisabled =
    (props.demoDash &&
      !props.lineChartData &&
      !props.minDate &&
      !props.maxDate) ||
    props.isLoading ||
    !areaChartVisible ||
    !props.customFilterEnabled;

  let isPreviousDateRanges = _startDate === null || _endDate === null;
  // console.log("nestedAccObjectOfSendersAndRecieversArr", nestedAccObjectOfSendersAndRecieversArr,"accumulativeDropdownUserArr",accumulativeDropdownUserArr);

  return (
    <div className="ForcePage">
      <div className="force-wrapper">
        {/* <div className={`filters-wrapper ${props.tutorialVIsible}`}> */}
        <div className={`filters-wrapper ${props.tutorialVIsible}`}>
          {" "}
          {/* ${props.demoDash? `filters-wrapper flex`: */}
          {!!(props.demoDash && !props.isLoading) && (
            <div
              className={`force-btn-group demo-dash ${props.tutorialVIsible}`}
            >
              <div
                className={
                  demoDashVisibility
                    ? "demo-dash-container bottom-borderless"
                    : "demo-dash-container"
                }
              >
                <div
                  className={
                    demoDashVisibility ? "demo-container" : "none"
                  }
                ></div>

                <div
                  className="form_radio_group bottom demo-group"
                  onClick={toggleDemoModal}
                  id="toggleDemoModalID"
                >
                  <p className="demo-header">Your first steps</p>
                  <div className="form_radio_group-item chevron">
                    <article
                      className={
                        demoDashVisibility
                          ? "pwrd-icon-hidden"//"chevron-container rotated"
                          : "pwrd-icon"//"chevron-container "
                      }
                    ></article>
                  </div>
                </div>
                <div>
                  <div
                    className={
                      demoDashVisibility
                        ? "demo-dash-content"
                        : "none"
                    }
                  >
                    <div className="description">
                      {/* <p>Welcome to Pavooq analytic platform</p>

                      <p>
                        To start work with the platform you need to do several
                        easy steps:
                      </p> */}

                      <div className="list-item">
                        <div className="list-unit">1</div>
                        <p>Confirm your email.</p>
                      </div>

                      <div className="list-item">
                        <div className="list-unit">2</div>
                        <Link
                          disabled={!props.isEmailConfirmed}
                          className={!props.isEmailConfirmed ? "disabled" : ""}//menu-itm middle-itm
                          id="connectslackRedirrectLink"
                          to="/connectslack"
                          name="connectslack"
                        // onClick={(e) => that.handleItemClick("connectslack")}
                        >
                          {/* <span> */}
                          Connect your Slack workspace
                          {/* </span> */}
                          <div
                            className="connect-container" //disabled
                          ></div>
                        </Link>
                      </div>

                      <div className="list-item">
                        <div className="list-unit">3</div>
                        <p>Send invites to your team.</p>
                      </div>

                      <div className="list-item">
                        <div className="list-unit">4</div>
                        <p>Wait for users' approval.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* search  */}
          {!!(!props.demoDash && !props.isLoading) && (
            <SearchedUserHolder
              projects={that.userArrForSearch}
              // foundUsers={foundUsers}
              tutorialVIsible={props.tutorialVIsible}
              graphClick={graphClick}
              handleGraphClick={handleGraphClick}
              darkTheme={props.darkTheme}
            />
          )}
          {/* relation filters */}
          <div
            // className={`force-btn-group centered-swither ${props.tutorialVIsible
            //   } ${props.demoDash ? "demo-visible" : ""}`}
            className={`force-btn-group centered-swither ${props.tutorialVIsible}`}
          >
            {/* <Button.Group widths='3'>
            <Button compact name='privateClicked' className={privateClicked ? "active-btn" : ""} onClick={that.handleChange}>Private</Button>
            <Button compact name='mentionClicked' className={mentionClicked ? "active-btn" : ""} onClick={that.handleChange}>Mention</Button>
            <Button compact name='generalClicked' className={generalClicked ? "active-btn" : ""} onClick={that.handleChange}>General</Button>
          </Button.Group> */}
            {/* <div> */}
            <div className="form_radio_group">
              <div className="form_radio_group-item">
                <input
                  id="radio-all"
                  type="radio"
                  name="relationSelected"
                  value="all"
                  onClick={() => {
                    props.handleRelationSelectedChange("all");
                    setRelationSelected("all");
                    setWordCloudVisible(false);
                  }}//handleRelationChange}
                  defaultChecked={relationSelected === "all"}
                />{" "}
                {/* checked */}
                <label id="radion-all-label" htmlFor="radio-all">All</label>
              </div>
              <div className="form_radio_group-item">
                <input
                  id="radio-direct"
                  type="radio"
                  name="relationSelected"
                  value="private"
                  onClick={() => {
                    props.handleRelationSelectedChange("private");
                    setRelationSelected("private");
                    setWordCloudVisible(false);
                  }}//handleRelationChange}
                  defaultChecked={relationSelected === "private"}
                />
                <label id="radion-direct-label" htmlFor="radio-direct">Direct</label>
              </div>
              {/* <div className="form_radio_group-item">
                <input
                  id="radio-wordcloud"
                  type="radio"
                  name="relationSelected"
                  value="wordcloud"
                  disabled="disabled"
                  onClick={wordCloudVisChanger}
                  checked={relationSelected === "wordcloud"}
                />
                <label htmlFor="radio-wordcloud">Wordcloud</label>
              </div> */}
            </div>
            {/* </div> */}
          </div>

          
          <div className='statistics-container'>
                        {/* INDIVIDUAL STATISTIC BLOCK */}

          {/* {clickedNode && */}
          { !props.demoDash &&           <div
            className={!!(props.individualStatisticVisibility && clickedNode) ? `individual-statistic-container ${props.expandIndividualStatistic ? "bottom-borderless" : ""}` : `invisible`}
          >
            <div className="individual-statistic-user">
              <div className="left-block">
                <img className="user-avatar" src={!!(clickedNode && clickedNode.avatar) ? clickedNode.avatar : that.darkTheme ? userIconDark : userIcon} alt="" />
                <p>
                  {!!(clickedNode && clickedNode.name) && clickedNode.name}
                </p>
              </div>
              <div
                className="close-container"
                onClick={() => props.toggleIndividualStatistic()}
              ></div>
            </div>
            <div className="individual-statistic-wrapper unscrolable">
              {!props.demoDash && (
                <div className="metrics-container">
                  <div
                    className="metrics-item anm-container"
                    id="isUserStatusRendered"
                    onMouseEnter={(e) => userStatusToggle(e, true)}
                    onMouseLeave={(e) => userStatusToggle(e, false)}
                    onClick={(e) =>
                      userStatusToggle(e, !isUserStatusRendered)
                    }
                  >
                    <span className="subtext">Status</span>

                    <div className={`user-activity-status ${clickedNode && clickedNode.invitable ? "active" : "inactive"
                      }`}></div>
                    <div
                      className={
                        isUserStatusRendered
                          ? "individual-statistic-user-status"
                          : "individual-statistic-user-status invisible"
                      }
                    >
                      <p>
                        <span>Status</span> - is the same as we currently have an active / inactive user
                      </p>
                    </div>
                  </div>
                  <div
                    className="metrics-item density-container"
                    id="isIndividualMessagesRendered"
                    onMouseEnter={(e) => individualMessagesToggle(e, true)}
                    onMouseLeave={(e) => individualMessagesToggle(e, false)}
                    onClick={(e) =>
                      individualMessagesToggle(e, !isIndividualMessagesRendered)
                    }
                  >
                    <span className="subtext">Messages</span>
                    <div className="bigtext">
                      {!!(relationSelected && that.clickedNode && that.clickedNode.messageAmount && that.clickedNode.messageAmount[relationSelected]) ? that.clickedNode.messageAmount[relationSelected] : 0}
                    </div>
                    <div
                      className={
                        isIndividualMessagesRendered
                          ? "individual-messages"
                          : "individual-messages invisible"
                      }
                    >
                      <p>
                        <span>Messages</span> - the amount of messages sent by user
                      </p>
                    </div>
                  </div>

                  <div
                    className="metrics-item influencers-container"
                    id="isIndividualContactsVisible"
                    onMouseEnter={(e) => individualContactsToggle(e, true)}
                    onMouseLeave={(e) => individualContactsToggle(e, false)}
                    onClick={(e) =>
                      individualContactsToggle(e, !isIndividualContactsVisible)
                    }
                  >
                    <span className="subtext">Contacts </span>
                    <div className="bigtext">
                      {!!(individualConnectionNodes && individualConnectionNodes.length && individualConnectionNodes.length > 0) ? individualConnectionNodes.length : 0}
                    </div>
                    <div
                      className={
                        isIndividualContactsVisible
                          ? "individual-contacts-info"
                          : "individual-contacts-info invisible"
                      }
                    >
                      <p>
                        <span>Contacts </span> - the number of people with whom the user corresponded (as now) in both directions
                      </p>
                    </div>
                  </div>

                  {/* <Popup
                    content={<div className="missing-connections-count">
                      <p>
                        <span>Missing (Contacts)</span> - is the number of people with whom the user did not have full communication (as now on the column dashed line with an arrow)
                      </p>
                      <InactiveList
                        relationSelected={relationSelected}
                        inactiveUsersAll={relatedNodesWeakAll}
                        inactiveUsersDirect={relatedNodesWeakPrivate}
                      />
                    </div>}
                    on={['click', 'hover']}
                    position='bottom left'
                    trigger={<div
                      className="metrics-item inactive-container"
                      id="isMissingConnection"
                    >
                      <span className="subtext">Missing </span>
                      <div className="bigtext">
                        {relationSelected === "private" ? //relatedNodesWeakAll
                          relatedLinksWeakPrivate && relatedLinksWeakPrivate.length > 0
                            ?
                            relatedLinksWeakPrivate.length : 0
                          :
                          relatedLinksWeakAll && relatedLinksWeakAll.length > 0
                            ?
                            relatedLinksWeakAll.length
                            :
                            0
                        }
                      </div>
                    </div>}
                  /> */}

                  <div
                    className="metrics-item inactive-container"
                    id="isMissingConnection"
                    onMouseEnter={(e) => missingConnectionToggle(e, true)}
                    onMouseLeave={(e) => missingConnectionToggle(e, false)}
                    onClick={(e) =>
                      missingConnectionToggle(e, !isMissingConnection)
                    }
                  >
                    <span className="subtext">Missing </span>
                    <div className="bigtext">
                      {relationSelected === "private" ? //relatedNodesWeakAll
                        relatedLinksWeakPrivate && relatedLinksWeakPrivate.length > 0
                          ?
                          relatedLinksWeakPrivate.length : 0
                        :
                        relatedLinksWeakAll && relatedLinksWeakAll.length > 0
                          ?
                          relatedLinksWeakAll.length
                          :
                          0

                      }
                    </div>
                    <div
                      className={
                        isMissingConnection
                          ? "missing-connections-count"
                          : "missing-connections-count invisible"
                      }
                    >
                      <p>
                        <span>Missing (Contacts)</span> - is the number of people with whom the user did not have full communication (as now on the column dashed line with an arrow)
                      </p>
                      <InactiveList
                        relationSelected={relationSelected}
                        inactiveUsersAll={relatedNodesWeakAll}
                        inactiveUsersDirect={relatedNodesWeakPrivate}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className={
                props.expandIndividualStatistic ? "user-data-table" : "none"
              }
            >
              <div className="individual-statistic-wrapper interactive-scroll">
                {/* {!props.demoDash && ( */}
                  <div
                    className="text-pannel"
                    // className={
                    //   props.tableVisibility ? "text-pannel" : "invisible"
                    // }
                  >
                    <p>Individual activity</p>
                    <div className='date-and-icon-group'>
                      <p className="date-label">
                        {!!(!props.isLoading &&
                          areaChartVisible &&
                          startDateFormated) &&
                          dateRanges}
                      </p>
                      <div className="relation-type-label">
                        {/* {`${
                    relationSelected === "private"
                      ? "Direct"
                      : "All"
                  }`} */}
                        {/* Messages */}
                        <div
                          className={
                            isIndividualConnectsChartVisible
                              ? "pwrd-icon"
                              : "pwrd-icon-hidden"
                          }
                          id="isIndividualConnectsChartVisible"
                          onClick={(e) => { e.preventDefault(); setIsIndividualConnectsChartVisible(!isIndividualConnectsChartVisible) }}
                          alt=""
                          srcSet=""
                        ></div>
                      </div>
                    </div>
                  </div>
                {/* // )} */}

                {!!(areaChartVisible && accumulativeArr && accumulativeArr.length && clickedNodeId) && (
                  <div className={isIndividualConnectsChartVisible ? "area-chart-container" : "invisible"}>
                    <D3AreaChartWithSelection
                      data={accumulativeArr}
                      // isIndividual={true}
                      startDate={
                        isPreviousDateRanges
                          ? startDateArea
                          : _startDate === null
                            ? props.startTimeFrame
                            : _startDate
                      }
                      endDate={
                        isPreviousDateRanges
                          ? endDateArea
                          : _endDate === null
                            ? props.endTimeFrame
                            : _endDate
                      }
                      darkTheme={theme}
                      updateTimeFrames={updateTimeFramesLocal}
                      handleGraphClick={handleGraphClick}
                    />
                  </div>
                )}
                {(areaChartVisible && clickedNodeId && !accumulativeArr && (clickedNode && clickedNode.contacts && clickedNode.contacts.length !== 0)) && (
                  <div className={isIndividualConnectsChartVisible ? "area-chart-container" : "invisible"}>
                    <div className="no-data-to-display">Loading...</div>
                  </div>
                )}
                {(areaChartVisible && clickedNodeId && ((accumulativeArr && accumulativeArr.length === 0) || (clickedNode && clickedNode.contacts && clickedNode.contacts.length === 0))) && (
                  <div className={isIndividualConnectsChartVisible ? "area-chart-container" : "invisible"}>
                    <div className="no-data-to-display">No data to display</div>
                  </div>
                )}

                {!!props.isLoading && (
                  <div className="table-loader">
                    {/* <Dimmer active className={theme ? "" : "inverted"}> */}
                    <Loader>Loading</Loader>
                    {/* </Dimmer> */}
                  </div>
                )}
                {!!(
                  (clickedNode && clickedNode.contacts && clickedNode.contacts.length > 0 && that.nodes) &&
                  individualConnectionNodes &&
                  individualConnectionNodes.length &&
                  // that.links &&
                  relationSelected &&
                  !props.isLoading
                ) && (
                    <IndividualConnectionsTable
                      // className={areaChartVisible ? "short-table" : "long-table"}
                      areaChartVisible={areaChartVisible}
                      nodes={individualConnectionNodes}
                      // relationSelected={relationSelected}
                      darkTheme={theme}
                    />
                  )}
                {/* SCROLL TEST  */}
                {/* {!props.demoDash && ( */}
                  <div
                    className="text-pannel"
                    // className={
                    //   props.tableVisibility ? "text-pannel" : "invisible"
                    // }
                  >
                    <p>Relation</p>
                    <div className='date-and-icon-group'>
                      <p className="date-label">
                        {!props.isLoading &&
                          areaChartVisible &&
                          startDateFormated &&
                          dateRanges}
                      </p>
                      <div className="relation-type-label">

                        <div
                          className={
                            isIndividualStatisticVisible
                              ? "pwrd-icon"
                              : "pwrd-icon-hidden"
                          }
                          id="isIndividualStatisticVisible"
                          onClick={(e) => { e.preventDefault(); setIsIndividualStatisticVisible(!isIndividualStatisticVisible) }}
                          alt=""
                          srcSet=""
                        ></div>
                      </div>
                    </div>
                  </div>
                {/* )} */}

                {!!(clickedNode && !props.demoDash && selectOptions && selectOptions.length > 0) &&
                  <Dropdown
                    // fluid
                    fluid
                    // search
                    className="select-dropdown"
                    // label='companySize'
                    options={selectOptions}
                    value={dropdownSelectedItemForClickedUser}
                    // placeholder="Select User"
                    name="Select connected users"
                    id="individualStatisticRelatedUsers"
                    // selection
                    onChange={(e, val) => {
                      handleSelectUserOfClickedNode(e, val)
                    }}
                  />}
                {/* {!!(areaChartVisible && accumulativeArr && accumulativeArr.length && clickedNodeId) && (
                  <div className={isIndividualConnectsChartVisible ? "area-chart-container" : "invisible"}> */}

                {(areaChartVisible && accumulativeDropdownUserArr && accumulativeDropdownUserArr.length > 0 && dropdownSelectedItemForClickedUser) && (
                  <div className={isIndividualStatisticVisible ? "area-chart-container" : "invisible"}>
                    <D3AreaChartWithSelection
                      data={accumulativeDropdownUserArr} // was setIndividualAccumulativeDataByClickedAndDropdownNode
                      startDate={
                        isPreviousDateRanges
                          ? startDateArea
                          : _startDate === null
                            ? props.startTimeFrame
                            : _startDate
                      }
                      endDate={
                        isPreviousDateRanges
                          ? endDateArea
                          : _endDate === null
                            ? props.endTimeFrame
                            : _endDate
                      }
                      darkTheme={theme}
                      updateTimeFrames={updateTimeFramesLocal}
                      handleGraphClick={handleGraphClick}
                    />
                  </div>
                )}
                {(areaChartVisible && clickedNodeId && !accumulativeDropdownUserArr && (clickedNode && clickedNode.contacts && clickedNode.contacts.length !== 0)) && (
                  <div className={isIndividualConnectsChartVisible ? "area-chart-container" : "invisible"}>
                    <div className="no-data-to-display">Loading...</div>
                  </div>
                )}
                {(areaChartVisible && clickedNodeId && accumulativeDropdownUserArr && accumulativeDropdownUserArr.length === 0 && !dropdownSelectedItemForClickedUser) && (
                  <div className={isIndividualConnectsChartVisible ? "area-chart-container" : "invisible"}>
                    <div className="no-data-to-display">No data to display</div>
                  </div>
                )}

                {
                  clickedNode &&
                  accumulativeDropdownUserArr &&
                  accumulativeDropdownUserArr.length > 0 &&
                  nestedAccObjectOfSendersAndRecieversArr &&
                  nestedAccObjectOfSendersAndRecieversArr.length > 0 &&
                  dropdownSelectedItemForClickedUser
                  && (
                    <IndividualMessageIntensivity
                      // className={areaChartVisible ? "short-table" : "long-table"}
                      areaChartVisible={areaChartVisible}
                      nodes={nestedAccObjectOfSendersAndRecieversArr}
                      // relationSelected={relationSelected}
                      darkTheme={theme}
                    />
                  )}
              </div>

            </div>
            <div className="form_radio_group bottom">
              <span className="form_radio_group-item date-item">
                <ExportContainerIndividual
                  // props.chartUserList
                  workSpaceID={props.workSpaceID || ""}
                  dateRanges={dateRanges}
                  exportNodesArr={individualConnectionNodes}
                  accumulativeDropdownUserArr={accumulativeDropdownUserArr || []}
                  dropdownSelectedItemForClickedUser={dropdownSelectedItemForClickedUser || undefined}
                  clickedNode={clickedNode}
                  nestedAccObjectOfSendersAndRecieversArr={nestedAccObjectOfSendersAndRecieversArr || []}
                  // lineChartData={props.lineChartData || []}
                  darkTheme={props.darkTheme}
                  demoDash={props.demoDash}
                />

                {/* <div className={!!(that.nodes && that.nodes.length === 0 && that.links && that.links.length === 0) ? "export-container disabled" : "export-container"}></div> */}
              </span>
              {!props.demoDash && (
                <div
                  className={
                    props.expandIndividualStatistic
                      ? "invisible"
                      : "text-pannel bottom"
                  }
                >
                  <p className="date-label">
                    {!props.isLoading &&
                      areaChartVisible &&
                      dateRanges &&
                      dateRanges}
                  </p>
                </div>
              )}
              <div
                className={`form_radio_group-item chevron ${props.isLoading ? "disabled" : ""
                  }`}
                onClick={() => props.expandIndividualStatisticOnNodeClick()}
              >
                <div
                  className={
                    props.expandIndividualStatistic
                      ? "chevron-container rotated"
                      : "chevron-container"
                  }
                ></div>
              </div>
            </div>
          </div> 

          }

          {/* <div className="force-btn-group date-swither"> */}
          <div
            id="main-table"
            className={
              props.tableVisibility
                ? "date-btn-container bottom-borderless"
                : "date-btn-container"
            }
          >
            <div className="form_radio_group square-corners">
              <div className="form_radio_group-item date-item">
                <input
                  id="radio-date"
                  type="radio"
                  name="dateSelected"
                  value="lastDay"
                  disabled={isDayDisabled}
                  onClick={handleDateChange}
                  onChange={handleDateChange}
                  checked={!!(_dateSwitcherSelected === "lastDay")}
                />
                <label id="radion-24h-label" htmlFor="radio-date">Last 24h</label>
              </div>

              <div className="form_radio_group-item date-item">
                <input
                  id="radio-week"
                  type="radio"
                  name="lastWeek"
                  value="lastWeek"
                  disabled={isWeekDisabled}
                  onClick={handleDateChange}
                  onChange={handleDateChange}
                  checked={!!(_dateSwitcherSelected === "lastWeek")}
                />
                <label id="radion-7days-label" htmlFor="radio-week">Last 7 Days</label>
              </div>

              <div className="form_radio_group-item date-item">
                <input
                  id="radio-month"
                  type="radio"
                  name="lastMonth"
                  value="lastMonth"
                  disabled={isMonthDisabled}
                  onClick={handleDateChange}
                  onChange={handleDateChange}
                  checked={!!(_dateSwitcherSelected === "lastMonth")}
                />
                <label id="radion-30days-label" htmlFor="radio-month">Last 30 Days</label>
              </div>

              <div>
                <DatePickerComponent
                  dateSelectorOpened={dateSelectorOpened}
                  minDate={new Date(props.minDate)}
                  maxDate={new Date(props.maxDate)}
                  lineChartData={props.lineChartData}
                  darkTheme={props.darkTheme}
                  startDate={new Date(_startDate)}
                  endDate={new Date(_endDate)}
                  setDateRange={setDateRange}
                  graphClick={graphClick}
                  handleGraphClick={handleGraphClick}
                  collapse={collapse}
                />
                {/* <div
                  onMouseLeave={collapse}
                  className={
                    dateSelectorOpened
                      ? "datePicker-container opened"
                      : "datePicker-container closed"
                  }
                >
                  {!!(
                    props.minDate &&
                    props.maxDate &&
                    props.lineChartData
                  ) && (
                    <>
                      <DatePicker
                        className={props.darkTheme ? "dark" : ""}
                        selected={new Date(startDate)}
                        onChange={setDateRange}
                        startDate={new Date(startDate)}
                        endDate={new Date(endDate)}
                        selectsRange
                        inline
                        minDate={new Date(props.minDate)}
                        maxDate={new Date(props.maxDate)}
                      />
                    </>
                  )}
                </div> */}

                <div
                  className="form_radio_group-item date-item relative"
                  onBlur={collapse}
                >
                  <input
                    id="radio-custom"
                    type="radio"
                    name="customTime"
                    value="customTime"
                    onClick={handleDateChange}
                    onChange={handleDateChange}
                    disabled={isCustomDisabled}
                    // disabled={true}
                    checked={!!(_dateSwitcherSelected === "customTime")}
                  />
                  <label
                    className="calendar-holder"
                    htmlFor="radio-custom"
                    id="radion-custom-label"
                    onClick={() => setDateSelectorOpened(!dateSelectorOpened)}
                  >
                    <div className="calendar-container"></div>
                    Custom
                  </label>
                </div>
              </div>
            </div>
            {/* GENERAL LINE CHART AND GENERAL TABLE */}
            {/* <div
              className={
                props.tableVisibility ? `user-data-table ${!isGeneralAreaChartVisible && "long"}` : "none"
              }
            > */}
            <div className={props.tableVisibility ? "" : "none"}>
              {!props.demoDash && (
                <div className="metrics-container">
                  <div
                    className="metrics-item anm-container"
                    id="isANMRendered"
                    onMouseEnter={(e) => ANMVisibilityToggle(e, true)}
                    onMouseLeave={(e) => ANMVisibilityToggle(e, false)}
                    onClick={(e) =>
                      ANMVisibilityToggle(e, !isANMRendered)
                    }
                  >
                    <span className="subtext">Average</span>
                    <div className="bigtext">
                      {relationSelected === "all"
                        ? that.anm.anmAll
                        : that.anm.anmPrivate}
                    </div>
                    <div
                      className={
                        isANMRendered
                          ? "anm-info"
                          : "anm-info invisible"
                      }
                    >
                      <p>
                        <span>Average</span> - the average number of messages
                        per day.
                      </p>
                    </div>
                  </div>
                  <div
                    className="metrics-item density-container"
                    id="isDensityRendered"
                    onMouseEnter={(e) => densityVisibilityToggle(e, true)}
                    onMouseLeave={(e) => densityVisibilityToggle(e, false)}
                    onClick={(e) =>
                      densityVisibilityToggle(e, !isDensityRendered)
                    }
                  >
                    <span className="subtext">Density</span>
                    <div className="bigtext">
                      {relationSelected === "all"
                        ? that.allDensity + "%"
                        : that.privateDensity + "%"}
                    </div>
                    <div
                      className={
                        isDensityRendered
                          ? "density-info"
                          : "density-info invisible"
                      }
                    >
                      <p>
                        <span>Density</span> - the proportion of direct ties
                        in a workspace relative to the total number possible.
                      </p>
                    </div>
                  </div>

                  <div
                    id="isInfluencersRendered"
                    className="metrics-item influencers-container"
                    onMouseEnter={(e) => influencersVisibilityToggle(e, true)}
                    onMouseLeave={(e) => influencersVisibilityToggle(e, false)}
                    onClick={(e) =>
                      influencersVisibilityToggle(e, !isInfluencersRendered)
                    }
                  >
                    <span className="subtext">Influencers</span>
                    <div className="bigtext">
                      {relationSelected === "all"
                        ? that.influencersAllLength
                        : that.influencersPrivateLength}
                      {/* 0 */}

                    </div>

                    <div
                      className={
                        isInfluencersRendered
                          ? "influencers-info"
                          : "influencers-info invisible"
                      }
                    >
                      <p>
                        <span>influencers</span> - this metric shows the most influential users in your workspace
                      </p>
                      <InactiveList //that.influencersArrayAll  that.influencersArrayPrivate
                        relationSelected={relationSelected}
                        inactiveUsersAll={influencersArrayAll}
                        inactiveUsersDirect={influencersArrayPrivate}
                      />
                    </div>
                  </div>

                  <div
                    className="metrics-item inactive-container"
                    id="isInactiveUserlistRendered"
                    onMouseEnter={(e) => inactiveUserlistToggle(e, true)}
                    onMouseLeave={(e) => inactiveUserlistToggle(e, false)}
                    onClick={(e) =>
                      inactiveUserlistToggle(e, !isInactiveUserlistRendered
                      )
                    }
                  >
                    <span className="subtext">Inactive</span>
                    <div className="bigtext">
                      {relationSelected === "all"
                        ? that.inactiveUsersAllLength
                        : that.inactiveUsersDirectLength}
                    </div>
                    <div
                      className={
                        isInactiveUserlistRendered
                          ? "inactive-userlist"
                          : "inactive-userlist invisible"
                      }
                    // onMouseLeave={() => that.isInactiveUserlistVisible(false)}
                    >
                      <p>
                        <span>Inactive</span> - the number of users who didn't
                        send any direct messages.
                      </p>
                      {/* <br /> */}
                      <InactiveList
                        relationSelected={relationSelected}
                        inactiveUsersAll={that.inactiveUsersAll}
                        inactiveUsersDirect={that.inactiveUsersDirect}
                      />
                      {/* {that.inactiveUsersRender()} */}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* </div> */}
            <div
              className={
                props.tableVisibility ? `user-data-table ${!isGeneralAreaChartVisible && "long"} interactive-scroll` : "none"
              }
            >
              <div>
                {/* {!props.demoDash && (
                  <div className="metrics-container">
                    <div
                      className="metrics-item anm-container"
                      id="isANMRendered"
                      onMouseEnter={(e) => ANMVisibilityToggle(e, true)}
                      onMouseLeave={(e) => ANMVisibilityToggle(e, false)}
                      onClick={(e) =>
                        ANMVisibilityToggle(e, !isANMRendered)
                      }
                    >
                      <span className="subtext">Average</span>
                      <div className="bigtext">
                        {relationSelected === "all"
                          ? that.anm.anmAll
                          : that.anm.anmPrivate}
                      </div>
                      <div
                        className={
                          isANMRendered
                            ? "anm-info"
                            : "anm-info invisible"
                        }
                      >
                        <p>
                          <span>Average</span> - the average number of messages
                          per day.
                        </p>
                      </div>
                    </div>
                    <div
                      className="metrics-item density-container"
                      id="isDensityRendered"
                      onMouseEnter={(e) => densityVisibilityToggle(e, true)}
                      onMouseLeave={(e) => densityVisibilityToggle(e, false)}
                      onClick={(e) =>
                        densityVisibilityToggle(e, !isDensityRendered)
                      }
                    >
                      <span className="subtext">Density</span>
                      <div className="bigtext">
                        {relationSelected === "all"
                          ? that.allDensity + "%"
                          : that.privateDensity + "%"}
                      </div>
                      <div
                        className={
                          isDensityRendered
                            ? "density-info"
                            : "density-info invisible"
                        }
                      >
                        <p>
                          <span>Density</span> - the proportion of direct ties
                          in a workspace relative to the total number possible.
                        </p>
                      </div>
                    </div>

                    <div
                      id="isInfluencersRendered"
                      className="metrics-item influencers-container"
                      onMouseEnter={(e) => influencersVisibilityToggle(e, true)}
                      onMouseLeave={(e) => influencersVisibilityToggle(e, false)}
                      onClick={(e) =>
                        influencersVisibilityToggle(e, !isInfluencersRendered)
                      }
                    >
                      <span className="subtext">Influencers</span>
                      <div className="bigtext">
                        0
                      </div>

                      <div
                        className={
                          isInfluencersRendered
                            ? "influencers-info"
                            : "influencers-info invisible"
                        }
                      >
                        <p>
                          <span>influencers</span> - the description will be here. Now you see the test text
                        </p>
                      </div>
                    </div>

                    <div
                      className="metrics-item inactive-container"
                      id="isInactiveUserlistRendered"
                      onMouseEnter={(e) => inactiveUserlistToggle(e, true)}
                      onMouseLeave={(e) => inactiveUserlistToggle(e, false)}
                      onClick={(e) =>
                        inactiveUserlistToggle(e, !isInactiveUserlistRendered
                        )
                      }
                    >
                      <span className="subtext">Inactive</span>
                      <div className="bigtext">

                        {relationSelected === "all"
                          ? that.inactiveUsersAllLength
                          : that.inactiveUsersDirectLength}
                      </div>
                      <div
                        className={
                          isInactiveUserlistRendered
                            ? "inactive-userlist"
                            : "inactive-userlist invisible"
                        }
                      // onMouseLeave={() => that.isInactiveUserlistVisible(false)}
                      >
                        <p>
                          <span>Inactive</span> - the number of users who didn't
                          send any direct messages.
                        </p>

                        <InactiveList
                          relationSelected={relationSelected}
                          inactiveUsersAll={that.inactiveUsersAll}
                          inactiveUsersDirect={that.inactiveUsersDirect}
                          clickedNode={clickedNode}
                        />
                      </div>
                    </div>
                  </div>
                )} */}
                {!props.demoDash && (
                  <div
                    className={
                      props.tableVisibility ? "text-pannel" : "invisible"
                    }
                  >
                    <p>General activity</p>
                    <div className='date-and-icon-group'>
                      <p className="date-label">
                        {!props.isLoading &&
                          areaChartVisible &&
                          startDateFormated &&
                          dateRanges}
                      </p>
                      <div className="relation-type-label">
                        {/* {`${
                    relationSelected === "private"
                      ? "Direct"
                      : "All"
                  }`} */}
                        {/* Messages */}
                        <div
                          className={
                            isGeneralAreaChartVisible
                              ? "pwrd-icon"
                              : "pwrd-icon-hidden"
                          }
                          id="isGeneralAreaChartVisible"
                          onClick={(e) => { e.preventDefault(); setIsGeneralAreaChartVisible(!isGeneralAreaChartVisible) }}
                          alt=""
                          srcSet=""
                        ></div>
                      </div>
                    </div>
                  </div>
                )}

                {areaChartVisible && (
                  <div className={isGeneralAreaChartVisible ? "area-chart-container" : "invisible"}>
                    <D3AreaChartWithSelection
                      data={props.lineChartData}
                      startDate={
                        isPreviousDateRanges
                          ? startDateArea
                          : _startDate === null
                            ? props.startTimeFrame
                            : _startDate
                      }
                      endDate={
                        isPreviousDateRanges
                          ? endDateArea
                          : _endDate === null
                            ? props.endTimeFrame
                            : _endDate
                      }
                      darkTheme={theme}
                      updateTimeFrames={updateTimeFramesLocal}
                      handleGraphClick={handleGraphClick}
                    />
                  </div>
                )}
                {!!props.isLoading && (
                  <div className="table-loader">
                    {/* <Dimmer active className={theme ? "" : "inverted"}> */}
                    <Loader>Loading</Loader>
                    {/* </Dimmer> */}
                  </div>
                )}
                {!!(
                  that.nodes &&
                  that.nodes.length &&
                  // that.links &&
                  relationSelected &&
                  !props.isLoading
                ) && (
                    <NodesTable
                      // className={areaChartVisible ? "short-table" : "long-table"}
                      areaChartVisible={areaChartVisible}
                      nodes={that.nodes}
                      relationSelected={relationSelected}
                      darkTheme={theme}
                    />
                  )}
              </div>

            </div>
            <div className="form_radio_group bottom">
              <span className="form_radio_group-item date-item">
                <ExportContainer
                  // props.chartUserList
                  workSpaceID={props.workSpaceID || ""}
                  dateRanges={dateRanges}
                  exportNodesArr={that.exportNodesArr}
                  lineChartData={props.lineChartData || []}
                  csvGraphObj={props.csvGraphObj}
                  darkTheme={props.darkTheme}
                  demoDash={props.demoDash}
                />

                {/* <div className={!!(that.nodes && that.nodes.length === 0 && that.links && that.links.length === 0) ? "export-container disabled" : "export-container"}></div> */}
              </span>
              {!props.demoDash && (
                <div
                  className={
                    props.tableVisibility
                      ? "invisible"
                      : "text-pannel bottom"
                  }
                >
                  <p className="date-label">
                    {!props.isLoading &&
                      areaChartVisible &&
                      dateRanges &&
                      dateRanges}
                  </p>
                </div>
              )}
              <div
                className={`form_radio_group-item chevron ${props.isLoading ? "disabled" : ""
                  }`}
                onClick={() => props.toggleTable()}
              >
                <div
                  className={
                    props.tableVisibility
                      ? "chevron-container rotated"
                      : "chevron-container"
                  }
                ></div>
              </div>
            </div>
          </div>


          </div>
          {/* } */}
          {/* D3ForceChart BLOCK */}
          {/* </div> */}
        </div>
        <div className={"chart-holder " + props.tutorialVIsible}>
          {!!(
            // !that.nodes &&
            props.isLoading
            // && that.links
          ) && (
              <Dimmer active className={theme ? "" : "inverted"}>
                <Loader>Loading</Loader>
              </Dimmer>
            )}
          {!!(
            that.nodes &&
            that.nodes.length &&
            // that.links &&
            relationSelected &&
            !wordCloudVisible &&
            !props.isLoading
          ) && (
              <D3forceChart
                graphClick={graphClick}
                tutorialVIsible={props.tutorialVIsible}
                nodes={that.nodes}
                links={that.linksForGraph}
                relationSelected={relationSelected}
                darkTheme={theme}
                handleGraphClick={handleGraphClick}
                chartScalePositionHolder={chartScalePositionHolder}
                toggleTable={props.toggleTable}
                toggleIndividualStatistic={props.toggleIndividualStatistic}
                apiUrl={props.apiUrl || ""}
                apiToken={props.apiToken}
                invitedUserHolder={invitedUserHolder}
                pavooqAccessRefresh={props.pavooqAccessRefresh}
                gridChartCondition={props.gridChartCondition}
                workSpaceID={props.workSpaceID || ""}
              />
            )}
          {!!(
            that.nodes &&
            that.nodes.length === 0 &&
            // that.links &&
            // that.links.length === 0 &&
            !wordCloudVisible &&
            !props.isLoading
          ) && (
              <div className="no-data-wrapper">
                <p>No data to display</p>
              </div>
            )}
          {/* {!!((that.nodes && that.nodes.length !== 0 && that.links && that.links.length < 1) && !wordCloudVisible) &&
            <div className="no-data-wrapper">
              <p>
                No enough data to build the chart.
          <br />
          Please add some members to your team.
          </p>
            </div>
          } */}
          {!!wordCloudVisible && (
            <div className="no-data-wrapper">
              <div className="wordcloud-contaier">
                {/* WordCloud will be here soon */}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <DescriptionModalWindow
        content="MODAL WINDOW TEXT WILL BE HERE"
        darkTheme={theme}
      /> */}
    </div>
  );
}
  //, (prevProps, prevState)=>{
  // return prevProps !== props
  // }
);

export default ForcePage;