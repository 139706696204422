import React, { Component } from "react";
import {
  Checkbox,
  Input,
  Modal,
  Button,
  Select,
  Form,
} from "semantic-ui-react";
import "./Profile.sass";
import * as d3 from "d3";
import PasswordStrengthBar from "react-password-strength-bar";
import helpers from "../../helpers";
import dompurify from "dompurify";

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientData: {},
      clientCompany: "",
      loginData: {},
      isLoading: false,
      fieldsChangedButNotPublished: false,
      isFirstNameValid: true,
      isLastNameValid: true,
      isCompanyValid: true,
      companyId: undefined,
      isPhoneValid: true,
      userUpdated: false,
      consentUpdate: false,
      phone: "",
      email: "",
      consent: this.props.consent,
      firstName: "",
      lastName: "",
      id: "",
      company: "",
      companySize: helpers.EMPLOYER_OPTIONS[0].value,
      services: {
        slask: false,
        googleChat: false,
        telegram: false,
        microsoftTeams: false,
        zoom: false,
        skype: false,
        mattermost: false,
        googleMeet: false,
        whatsApp: false,
        signal: false,
        googleWorkspace: false,
        ownServer: false,
        otherService: false,
      },
      deleteAccountTrigger: false,
      theme: localStorage.getItem("theme") || "default",
      password: "",
      isPasswordValid: false,
      isPasswordVisible: false,
      passwordChanged: false,
      isPasswordError: false,
      isAuthenticated: false,
      companyIsNotRegistered: this.props.companyIsNotRegistered,
      fieldWasPublished: false,
      changeTarget: "",
      needToChangeEmail: false,
      needToChangePassword: false,
      confirmDelete: false,
      isError: false,
      errorText: "Please try reloading the page.",
      accountRemovingAccepted: false,
      slackRemovingAccepted: false,
    };

    this.sanitizer = dompurify.sanitize;
    this.isMobileDevice = helpers.isMobileDevice();
    this.validateEmail = helpers.validateEmail.bind(this);
    this.minPassScore = 1;
    this.themeToggler = this.props.themeToggler;
    this.themeOpts = [
      { key: "default", value: "default", text: "Default" },
      { key: "dark", value: "dark", text: "Dark" },
    ];
    this.oldEmail = this.props.email;
    this.oldFirstName = this.props.firstName;
    this.oldLastName = this.props.lastName;
    this.oldPassword = this.state.password ? this.state.password : "";
    this.oldCompany = this.props.company;
    this.companySize = [this.props.companyMinSize, this.props.companyMaxSize];
    this.oldPhone = this.props.phone;
    this.timeout = 2000;
    this.services = {
      slask: false,
      googleChat: false,
      telegram: false,
      microsoftTeams: false,
      zoom: false,
      skype: false,
      mattermost: false,
      googleMeet: false,
      whatsApp: false,
      signal: false,
      googleWorkspace: false,
      office365: false,
      ownServer: false,
      otherService: false,
    };
  }

  updateServices =(arr, isToggle)=> {
    
    let servisecObj = this.services;
    for (const key in this.state.services) {
      if (arr.includes(key)) {
        if (isToggle) {
          servisecObj[key] = !servisecObj[key];
        } else {
          servisecObj[key] = true;
        }
      }
    }
    return servisecObj;
  }

  componentDidMount() {
    document.title = "Pavooq Profile";

    this.oldEmail = this.props.email;
    this.oldFirstName = this.props.firstName;
    this.oldLastName = this.props.lastName;
    this.oldPassword = "";
    this.oldCompany = this.props.company;
    this.oldCompanySize = [this.props.companyMinSize, this.props.companyMaxSize];
    this.oldPhone = this.props.phone;
    this.oldServices = this.updateServices(this.props.services);
    this.oldServicesArr = Object.entries(this.oldServices).reduce((acc, [k, v]) => {
      if (v === true) acc.push(k);
      return acc;
    }, []);
    this.setState({
      consent: this.props.consent || false,
      email: this.props.email || "",
      firstName: this.props.firstName || "",
      lastName: this.props.lastName || "",
      phone: this.props.phone || "",
      company: this.props.company || "",
      services: this.updateServices(this.props.services),
      clientCompany: this.props.clientCompany || "",
      companyCreated: this.props.companyCreated,
      companySize: helpers.getCompanySize([this.props.companyMinSize, this.props.companyMaxSize]),
    });
  }
  
  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.consent !== this.props.consent ||
      nextProps.email !== this.props.email ||
      nextProps.firstName !== this.props.firstName ||
      nextProps.lastName !== this.props.lastName ||
      nextProps.phone !== this.props.phone ||
      nextProps.company !== this.props.company ||
      nextProps.companyMinSize !== this.props.companyMinSize ||
      nextProps.companyMaxSize !== this.props.companyMaxSize ||
      nextProps.clientCompany !== this.props.clientCompany ||
      nextProps.companyCreated !== this.props.companyCreated
    ) {
      this.oldEmail = nextProps.email;
      this.oldFirstName = nextProps.firstName;
      this.oldLastName = nextProps.lastName;
      this.oldPassword = this.state.password ? this.state.password : "";
      this.oldCompany = nextProps.company;
      this.oldCompanySize = helpers.getCompanySize([nextProps.companyMinSize, nextProps.companyMaxSize]);
      this.oldServices = this.updateServices(nextProps.services);
      this.oldServicesArr = Object.entries(this.oldServices).reduce((acc, [k, v]) => {
        if (v === true) acc.push(k);
        return acc;
      }, []);
      this.oldPhone = nextProps.phone;

      this.setState({
        consent: nextProps.consent,
        email: nextProps.email,
        firstName: nextProps.firstName,
        lastName: nextProps.lastName,
        phone: nextProps.phone,
        company: nextProps.company,
        companySize: helpers.getCompanySize([nextProps.companyMinSize, nextProps.companyMaxSize]),      
        services: this.updateServices(nextProps.services),
        clientCompany: nextProps.clientCompany,
        companyCreated: nextProps.companyCreated,
      });

      return true;
    } else {
      return true;
    }
  }


  togglePass = (event) => {
    event.preventDefault();
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible,
    });
  };

  handleChangePWD = (event) => {
    if (event >= this.minPassScore) {
      this.setState({
        isPasswordValid: true,
      });
    } else {
      this.setState({
        isPasswordValid: false,
      });
    }
  };

  handleDelete() {
    fetch(`${this.props.apiUrl}/account`, {
      method: "DELETE",
      accept: "*/*",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          this.props.slackAccessDeletionLogic();
          this.props.handleLogout(undefined, "deletionTrigger");
        } else {
          if (res.status === 401) {
            this.props.pavooqAccessRefresh();
          } else {
            this.onFail(res);
          }
        }
      })

      .catch((error) => {
        this.onFail(error);
      });
  }

  handleSelect = (event, { name, value }) => {
    this.setState({
      fieldsChangedButNotPublished: true,
      [name]: value,
    });
    this.companySize = value;
  };

  handleChange = (event) => {
    // console.log("handleChange event", event.target.name);
    if (event && event.target && event.target.name) {
      if (event.target.name === "email") {
        this.setState({
          fieldsChangedButNotPublished: true,
          isEmailValid: this.validateEmail(event.target.value),
          [event.target.name]: this.sanitizer(event.target.value),
        });
        this.firstName = this.sanitizer(event.target.value);
      }
      if (event.target.name === "firstName") {
        this.setState({
          fieldsChangedButNotPublished: true,
          isFirstNameValid: event.target.value.length > 0 ? true : false,
          [event.target.name]: this.sanitizer(event.target.value),
        });
        this.firstName = this.sanitizer(event.target.value);
      }
      if (event.target.name === "lastName") {
        this.setState({
          fieldsChangedButNotPublished: true,
          isLastNameValid: event.target.value.length > 0 ? true : false,
          [event.target.name]: this.sanitizer(event.target.value),
        });
        this.lastName = this.sanitizer(event.target.value);
      }
      if (event.target.name === "company") {
        this.setState({
          fieldsChangedButNotPublished: true,
          isCompanyValid: event.target.value.length > 0 ? true : false,
          [event.target.name]: this.sanitizer(event.target.value),
        });
        this.company = this.sanitizer(event.target.company);
      }
      if (event.target.name === "password") {
        this.setState({
          fieldsChangedButNotPublished: true,
          password: event.target.value.length > 0 ? true : false,
          isPasswordError: false,
          [event.target.name]: this.sanitizer(event.target.value),
        });
        this.password = this.sanitizer(event.target.password);
      }
      if (event.target.name === "phone") {
        let myString = event.target.value.replace(/\D/g, "");
        this.setState({
          fieldsChangedButNotPublished: true,
          isPhoneValid:
            myString.length > 0 && myString.length < 20 ? true : false,
          [event.target.name]: this.sanitizer(event.target.value),
        });

        this.phone = this.sanitizer(event.target.value);
      }
    }
  };

  onFail = (e) => {
    if (e) {
      this.setState({
        isError: true,
        errorText: e + "" || "Please try reloading the page.",
      });
    }
  };

  errorContainerRender = () => {
    return (
      <Modal
        size="mini"
        closeIcon={<div className="close-container"></div>}
        className={this.props.darkTheme ? "customModal dark" : "customModal"}
        centered={true} //false
        open={this.state.isError}
        onClose={() => this.setState({ isError: false })}
        onOpen={() => this.setState({ isError: true })}
      >
        <Modal.Header>Opps..</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            An error has occurred.
            <br />
            {this.state.errorText ? this.state.errorText : ""}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions className="center">
          <Button
            className="wide"
            onClick={() => this.setState({ isError: false })}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  updateConsent = () => {
    let consent = !this.state.consent;
    this.setState({ consent });
    fetch(`${this.props.apiUrl}/account`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ consent }),
    })
      .then((res) => {
        if (res.ok) {
          this.props.changeUserConsent(consent);

          this.setState({
            consentUpdate: true,
          });
          setTimeout(() => {
            this.setState({
              consentUpdate: false,
            });
          }, this.timeout);
        } else {
          this.props.profileAPILoader();
          this.setState({
            firstName: this.oldFirstName,
            fieldsChangedButNotPublished: true,
            fieldWasPublished: false,
            errorText: res.statusText + "" || "Please try reloading the page.",
          });
        }
      })
      .catch((e) => {
        console.log("ERROR", e);
      });
  };

  userUpdate = () => {
    let firstname = undefined;
    let lastname = undefined;
    let phone = undefined;
    let company = undefined;
    let companySize = undefined;
    let servicesLocal = [];
    for (let service in this.state.services) {
      if (this.state.services[service] === true) {
        servicesLocal.push(service);
      }
    }
    // console.log("servicesLocal",servicesLocal);

    if (this.state.firstName !== this.oldFirstName) {
      firstname = this.state.firstName;
    }
    if (this.state.lastName !== this.oldLastName) {
      lastname = this.state.lastName;
    }
    if (`+${this.state.phone.replace(/\D/g, "")}` !== this.oldPhone) {
      phone = `+${this.state.phone.replace(/\D/g, "")}`;
    }

    if (firstname || lastname || phone) {
      let userUpdageBody = {
        firstname,
        lastname,
        phone,
      };

      fetch(`${this.props.apiUrl}/account`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(userUpdageBody),
      })
        .then((res) => {
          if (res.ok) {
            this.props.profileAPILoader();
            if (firstname) {
              this.oldFirstName = firstname;
            }
            if (lastname) {
              this.oldLastName = lastname;
            }
            if (phone) {
              this.oldPhone = phone;
            }

            this.setState({
              fieldsChangedButNotPublished: false,
              fieldWasPublished: true,
              userUpdated: true,
            });
            setTimeout(() => {
              this.setState({
                fieldWasPublished: false,
                changeTarget: "",
                userUpdated: false,
              });
            }, this.timeout);
          } else {
            this.props.profileAPILoader();
            this.setState({
              firstName: this.oldFirstName,
              fieldsChangedButNotPublished: true,
              fieldWasPublished: false,
              errorText:
                res.statusText + "" || "Please try reloading the page.",
            });
          }
        })
        .catch((e) => {
          console.log("ERROR", e);
          this.props.profileAPILoader();
          this.setState({
            firstName: this.oldFirstName,
            fieldsChangedButNotPublished: true,
            fieldWasPublished: false,
            errorText: e + "" || "Please try reloading the page.",
          });
        });
    }

    if (this.state.company !== this.oldCompany) {
      company = this.state.company;
    }
    if (this.state.companySize[1] !== this.oldCompanySize[1]) {
      companySize = this.state.companySize;
    }
    let servicesArr = Object.entries(this.services).reduce((acc, [k, v]) => {
      if (v === true) acc.push(k);
      return acc;
    }, []);
    // let oldServicesArr = Object.entries(this.oldServices).reduce((acc, [k, v]) => {
    //   if (v === true) acc.push(k);
    //   return acc;
    // }, []);
    // console.log("!helpers.areArraysEqual(servicesLocal, oldServicesArr)",servicesLocal, this.oldServicesArr);
    // if (!helpers.areArraysEqual(servicesLocal, this.oldServicesArr)) {
    //   servicesLocal = this.state.services;
    // }
    // console.log(firstname, lastname, phone, company, companySize);
  
    if (company || (companySize?.length > 0 && companySize[1] > 0) || (servicesLocal?.length > 0 && !helpers.areArraysEqual(servicesLocal, this.oldServicesArr))) {
      //company update
      //post company if there is no company registered
      if (
        (this.state.isCompanyValid &&
          this.state.companyIsNotRegistered &&
          company &&
          company.length > 0) ||
        (companySize?.length > 0 && companySize[1] > 0) ||
        (servicesLocal?.length > 0 && !helpers.areArraysEqual(servicesLocal, this.oldServicesArr))
      ) {
        if (this.state.company !== this.oldCompany) {
          company = this.state.company;
        }
        if (this.state.companySize[1] !== this.oldCompanySize[1]) {
          companySize = this.state.companySize;
        }
        if (servicesLocal?.length > 0 && !helpers.areArraysEqual(servicesLocal, this.oldServicesArr)) {
          servicesLocal = servicesArr;
        }
        let companyPostBody = {
          name: company,
          minsize: this.state.companySize[0],
          maxsize: this.state.companySize[1],
          toolset: servicesArr,
          // minsize: 0,
          // maxsize: 0,
        };
        // console.log("companyPostBody", companyPostBody);
        // if (this.state.email && this.state.password) {
        fetch(`${this.props.apiUrl}/company`, {
          method: "POST",
          headers: {
            // Accept: "application/json",
            "Content-Type": "application/json",
          },
          credentials: "include", //'same-origin',
          body: JSON.stringify(companyPostBody),
        })
          // .then((res) => res.json())
          .then((res) => {
            // console.log("res", res, res.ok);
            if (res && res.ok) {
              this.props.profileAPILoader();
              this.oldCompany = company;
              this.setState({
                company: this.company,
                companySize:this.companySize,
                fieldsChangedButNotPublished: false,
                fieldWasPublished: true,
                companyIsNotRegistered: false,
                // changeTarget: targetName,
                // isCompanyPublished: true,
                userUpdated: true,
              });
              //  () => {
              setTimeout(() => {
                this.setState({
                  fieldWasPublished: false,
                  // changeTarget: "",
                  // isCompanyPublished: false,
                  userUpdated: false,
                });
              }, this.timeout);
              // });
            } else {
              this.props.profileAPILoader();
              // this.oldCompany = this.props.company;
              this.setState({
                company: this.oldCompany,
                companySize:this.oldCompanySize,
                companyIsNotRegistered: true,
                fieldsChangedButNotPublished: true,
                fieldWasPublished: false,
                // isCompanyPublished: false,
                errorText: "Please try reloading the page.",
              });
            }
          })
          .catch((e) => {
            console.log("ERROR", e);
            // this.onFail(e);
            // this.oldCompany = this.props.company;
            this.setState({
              company: this.oldCompany,
              companySize:this.oldCompanySize,
              // isCompanyPublished: false,
              companyIsNotRegistered: true,
              fieldsChangedButNotPublished: true,
              fieldWasPublished: false,
              errorText: e + "" || "Please try reloading the page.",
            });
          });
      }
      //update company if it exists
      //company update
      if (
        (this.state.isCompanyValid &&
        !this.state.companyIsNotRegistered &&
        company &&
        company.length > 0) ||
        (companySize?.length > 0 && companySize[1] > 0) ||
        (servicesLocal?.length > 0 && !helpers.areArraysEqual(servicesLocal, this.oldServicesArr))
      ) {
        if (this.state.company !== this.oldCompany) {
          company = this.state.company;
        }
        if (this.state.companySize[1] !== this.oldCompanySize[1]) {
          companySize = this.state.companySize;
        }
        if (servicesLocal?.length > 0 && !helpers.areArraysEqual(servicesLocal, this.oldServicesArr)) {
          servicesLocal = servicesArr;
        }
        let companyPatchBody = {
          name: company,
          minsize: this.state.companySize[0],
          maxsize: this.state.companySize[1],
          toolset: servicesArr,
          // minsize: undefined,
          // maxsize: undefined,
        };
        fetch(`${this.props.apiUrl}/company`, {
          ///companies/${this.state.clientCompany["ID"]}
          method: "PATCH",
          headers: {
            // Accept: "application/json",
            "Content-Type": "application/json",
          },
          credentials: "include", //'same-origin',
          body: JSON.stringify(companyPatchBody),
        })
          // .then((res) => res.json())
          .then((res) => {
            // console.log("res", res, res.ok);
            if (res && res.ok) {
              this.props.profileAPILoader();
              this.oldCompany = company;
              this.setState({
                userUpdated: true,
                company: this.company,
                companySize:this.companySize,
                fieldsChangedButNotPublished: false,
                fieldWasPublished: true,
                // changeTarget: targetName,
                // isCompanyPublished: true,
              });
              //  () => {
              setTimeout(() => {
                this.setState({
                  fieldWasPublished: false,
                  userUpdated: false,
                  // changeTarget: "",
                  // isCompanyPublished: false,
                });
              }, this.timeout);
              // });
            } else {
              this.props.profileAPILoader();
              // this.oldCompany = this.props.company;
              this.setState({
                company: this.oldCompany,
                companySize:this.oldCompanySize,
                // isCompanyPublished: false,
                fieldsChangedButNotPublished: true,
                fieldWasPublished: false,
                errorText: "Please try reloading the page.",
              });
            }
          })
          .catch((e) => {
            console.log("ERROR", e);
            // this.onFail(e);
            // this.oldCompany = this.props.company;
            this.setState({
              company: this.oldCompany,
              companySize:this.oldCompanySize,
              // isCompanyPublished: false,
              fieldsChangedButNotPublished: true,
              fieldWasPublished: false,
              errorText: e + "" || "Please try reloading the page.",
            });
          });
        // }
      }
    }
  };

  userEmailUpdate = (event) => {
    let targetName = event.target.name;
    if (
      targetName === "emailButton" &&
      this.state.isEmailValid &&
      this.state.email &&
      this.state.email.length > 0
    ) {
      if (this.state.email === this.oldEmail) {
        return;
      }
      let userUpdageBody = {
        email: this.state.email,
      };

      fetch(`${this.props.apiUrl}/account`, {
        // old /customers/${this.state.loginnedUserData["ID"]}
        method: "PATCH",
        headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include", //'same-origin',
        body: JSON.stringify(userUpdageBody),
      })
        // .then((res) => res.json())
        .then((res) => {
          // console.log("res", res, res.ok);
          if (res.ok) {
            this.oldEmail = this.state.email;
            return this.setState(
              { emailChanged: true, needToChangeEmail: false },
              () => {
                setTimeout(() => {
                  this.setState({
                    emailChanged: false,
                  });
                }, this.timeout);
              }
            );
            // res.json(s)
          } else if (res.status === 409) {
            this.setState({
              email: this.oldEmail,
              emailChanged: false,
              isEmailError: true,
              errorText: "Email address already in use.",
            });
          }
        })
        .catch((e) => {
          console.log("ERROR", e);
          // this.onFail(e);
          this.setState({
            email: this.oldEmail,
            emailChanged: false,
            isEmailError: true,
            errorText: e + "" || "Please try reloading the page.",
          });
        });
      // }
    }
  };

  userPasswordUpdate = (event) => {
    let targetName = event.target.name;
    if (
      targetName === "passwordButton" &&
      this.state.isPasswordValid &&
      this.state.password &&
      this.state.password.length > 0
    ) {
      if (this.state.password === this.oldPassword) {
        return;
      }
      let userUpdageBody = {
        password: this.state.password,
      };
      fetch(`${this.props.apiUrl}/account`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(userUpdageBody),
      })
        .then((res) => {
          if (res.ok) {
            this.oldPassword = this.state.password;
            return this.setState(
              { passwordChanged: true, needToChangePassword: false },
              () => {
                setTimeout(() => {
                  this.setState({
                    passwordChanged: false,
                  });
                }, this.timeout);
              }
            );
          }
        })
        .catch((e) => {
          console.log("ERROR", e);
          this.setState({
            password: this.oldPassword,
            passwordChanged: false,
            isPasswordError: true,
            errorText: e + "" || "Please try reloading the page.",
          });
        });
      // }
    }
  };

  formReset = () => {
    this.firstName = this.oldFirstName;
    this.lastName = this.oldLastName;
    this.phone = this.oldPhone;
    this.company = this.oldCompany;
    this.companySize = this.oldCompanySize;
    this.setState({
      firstName: this.oldFirstName,
      lastName: this.oldLastName,
      phone: this.oldPhone,
      company: this.oldCompany,
      companySize: this.oldCompanySize,
      fieldsChangedButNotPublished: false,
      isFirstNameValid: true,
      isLastNameValid: true,
      isCompanyValid: true,
      companyId: undefined,
      isPhoneValid: true,
    });
  };

  handleServiceSelector = (event) => {
    this.setState({ services: this.updateServices([event.target.id], true) });

    this.userUpdate();
  };

  render() {
    // console.log('this.state.services',this.state.services)
    let parseDate = d3.timeFormat("%b %d, %Y");
    let companyCreated = this.props.companyCreated
      ? parseDate(new Date(this.props.companyCreated * 1000))
      : "Undefined";
    this.firstName =
      this.state.firstName === undefined
        ? this.props.firstName
        : this.state.firstName;
    this.lastName =
      this.state.lastName === undefined
        ? this.props.lastName
        : this.state.lastName;
    this.company =
      this.state.company === undefined
        ? this.props.company
        : this.state.company;
    this.phone =
      this.state.phone === undefined ? this.props.phone : this.state.phone;

    let enableProfileUpdateButton = !!(
      this.state.fieldsChangedButNotPublished &&
      this.state.isFirstNameValid &&
      this.state.isLastNameValid &&
      this.state.isPhoneValid &&
      this.state.isCompanyValid &&
      this.state.companySize[1] > 0 && 
      (this.firstName !== this.oldFirstName ||
        this.lastName !== this.oldLastName ||
        this.company !== this.oldCompany ||
        this.companySize !== this.oldCompanySize ||
        `+${this.phone.replace(/\D/g, "")}` !== this.oldPhone)
    );

    return (
      <div className="Profile">
        <div className="profile-wrapper">
          <span className="profile-label">Profile</span>
          <div className="content-box">
            <div className="profile-content">
              <span className="item-label">First Name</span>

              <div className="item-content">
                <Input
                  focus
                  className={!this.state.isFirstNameValid ? "error" : ""}
                  // disabled
                  autoComplete="new-password"
                  name="firstName"
                  id="firstName"
                  placeholder="First Name"
                  value={this.firstName}
                  onPaste={this.handleChange}
                  onChange={this.handleChange}
                />
                {!this.state.isFirstNameValid && (
                  <div className="email-error">
                    Please enter valid first name
                  </div>
                )}
              </div>
            </div>
            <div className="profile-content">
              <span className="item-label">Last Name</span>

              <div className="item-content">
                <Input
                  focus
                  className={!this.state.isLastNameValid ? "error" : ""}
                  // disabled
                  autoComplete="new-password"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  value={this.lastName}
                  onPaste={this.handleChange}
                  onChange={this.handleChange}
                />
                {!this.state.isLastNameValid && (
                  <div className="email-error">
                    Please enter valid last name
                  </div>
                )}
              </div>
            </div>

            <div className="profile-content">
              <span className="item-label">
                {this.isMobileDevice ? "Phone" : "Phone number"}
              </span>

              <div className="item-content">
                <Input
                  focus
                  className={!this.state.isPhoneValid ? "error" : ""} // : (this.state.isPhonePublished) ? "success" : ""}
                  // disabled
                  autoComplete="new-password"
                  name="phone"
                  id="phone"
                  type="tel"
                  placeholder="Phone number (optional)"
                  value={this.phone}
                  onPaste={this.handleChange}
                  onChange={this.handleChange}
                />
                {!this.state.isPhoneValid && (
                  <div className="email-error">
                    Please enter valid phone number (+XXXXXXXXXXXX)
                  </div>
                )}
              </div>
            </div>

            <div className="profile-content">
              <span className="item-label">
                {this.isMobileDevice ? "Company" : "Company Name"}
              </span>

              <div className="item-content">
                <Input
                  focus
                  // disabled
                  className={!this.state.isCompanyValid ? "error" : ""} // : (this.state.isCompanyPublished) ? "success" : ""}
                  autoComplete="new-password"
                  id="company"
                  name="company"
                  // onChange={this.handleChange}
                  placeholder="Company Name"
                  onPaste={this.handleChange}
                  onChange={this.handleChange}
                  value={this.company}
                />
                {!this.state.isCompanyValid && (
                  <div className="email-error">
                    Please enter valid company name
                  </div>
                )}
              </div>
            </div>

            <div className="profile-content">
              <span className="item-label">
                {this.isMobileDevice ? "Size" : "Company Size"}
              </span>

              <div className="item-content">
                <Select
                  fluid
                  className={this?.state?.companySize[1] === 0 ? "error" : ""}
                  options={helpers.EMPLOYER_OPTIONS}
                  defaultValue={this.state.companySize}
                  value={this.state.companySize}
                  placeholder="Company Size"
                  name="companySize"
                  id="companySize"
                  selection
                  onChange={this.handleSelect}
                />
                {this?.state?.companySize[1] === 0 && (
                  <div className="email-error">
                    Please enter valid company size
                  </div>
                )}
              </div>
            </div>

            <div className="profile-content">
              {/* <div
               className="profile-buttons-container"
              > */}
              <span className="item-label"></span>
              <div className="item-content profile-buttons-container">
                <button className="borderless-button" onClick={this.formReset}>
                  Cancel
                </button>
                <Button
                  className="big-button"
                  disabled={!enableProfileUpdateButton}
                  // onBlur={this.userUpdate}
                  name="profileChangeButton"
                  onClick={this.userUpdate}
                >
                  {this.state.fieldWasPublished ? "Updated" : "Save"}
                </Button>
                {this.state.userUpdated && (
                  <div className="success-message">Your data was saved</div>
                )}
              </div>
              {/* </div> */}
            </div>
            <hr />
            {/* // */}
            <div className="profile-content  flex-column">
              <p>Please mark the services you use for work communications</p>
              <Form
                autoComplete="new-password"
                className="service-items-container"
                onSubmit={this.thirdStepHandleSubmit}
              >
                {/* onSubmit={this.handleSubmit} > */}

                {/* <div className="service-items-container"> */}
                <div
                  className={
                    this.state.services.slask ? "service-item active" : "service-item"
                  }
                  id="slask"
                  onClick={this.handleServiceSelector}
                >
                  <div className="slask"></div>
                  Slack
                </div>
                <div
                  className={
                    this.state.services.googleChat
                      ? "service-item active"
                      : "service-item"
                  }
                  id="googleChat"
                  onClick={this.handleServiceSelector}
                >
                  <div className="google-chat"></div>
                  Google Chat
                </div>
                <div
                  className={
                    this.state.services.telegram ? "service-item active" : "service-item"
                  }
                  id="telegram"
                  onClick={this.handleServiceSelector}
                >
                  <div className="telegram"></div>
                  Telegram
                </div>
                <div
                  className={
                    this.state.services.microsoftTeams
                      ? "service-item active"
                      : "service-item"
                  }
                  id="microsoftTeams"
                  onClick={this.handleServiceSelector}
                >
                  <div className="microsoft-teams"></div>
                  Microsoft Teams
                </div>
                <div
                  className={
                    this.state.services.zoom ? "service-item active" : "service-item"
                  }
                  id="zoom"
                  onClick={this.handleServiceSelector}
                >
                  <div className="zoom"></div>
                  Zoom
                </div>
                <div
                  className={
                    this.state.services.skype ? "service-item active" : "service-item"
                  }
                  id="skype"
                  onClick={this.handleServiceSelector}
                >
                  <div className="skype"></div>
                  Skype
                </div>
                <div
                  className={
                    this.state.services.mattermost
                      ? "service-item active"
                      : "service-item"
                  }
                  id="mattermost"
                  onClick={this.handleServiceSelector}
                >
                  <div className="mattermost"></div>
                  Mattermost
                </div>
                <div
                  className={
                    this.state.services.googleMeet
                      ? "service-item active"
                      : "service-item"
                  }
                  id="googleMeet"
                  onClick={this.handleServiceSelector}
                >
                  <div className="google-meet"></div>
                  Google Meet
                </div>
                <div
                  className={
                    this.state.services.whatsApp ? "service-item active" : "service-item"
                  }
                  id="whatsApp"
                  onClick={this.handleServiceSelector}
                >
                  <div className="whatsapp"></div>
                  WhatsApp
                </div>
                <div
                  className={
                    this.state.services.signal ? "service-item active" : "service-item"
                  }
                  id="signal"
                  onClick={this.handleServiceSelector}
                >
                  <div className="signal"></div>
                  Signal
                </div>
                <div
                  className={
                    this.state.services.googleWorkspace
                      ? "service-item active"
                      : "service-item"
                  }
                  id="googleWorkspace"
                  onClick={this.handleServiceSelector}
                >
                  <div className="google-workspace"></div>
                  Google Workspace
                </div>
                <div
                  className={
                    this.state.services.office365
                      ? "service-item active"
                      : "service-item"
                  }
                  id="office365"
                  onClick={this.handleServiceSelector}
                >
                  <div className="office-365"></div>
                  Office 365
                </div>
                <div
                  className={
                    this.state.services.ownServer
                      ? "service-item active"
                      : "service-item"
                  }
                  id="ownServer"
                  onClick={this.handleServiceSelector}
                >
                  <div className="own-server"></div>
                  Own Server
                </div>
                <div
                  className={
                    this.state.services.otherService
                      ? "service-item active"
                      : "service-item"
                  }
                  id="otherService"
                  onClick={this.handleServiceSelector}
                >
                  <div className="other-service"></div>
                  Other
                </div>
              </Form>
            </div>

            <hr />
            {/* // */}
            <div className="profile-content">
              <span className="item-label">Email</span>

              <div className="item-content email-container">
                <div className="user-email">
                  {this.oldEmail ? this.oldEmail : this.state.email}
                </div>
                {/* EMAIL CHANGE PART */}

                {!this.state.needToChangeEmail && (
                  <>
                    <button
                      className="borderless-button email-trigger"
                      onClick={(e) => {
                        this.setState({
                          needToChangeEmail: true,
                        });
                      }}
                      //className="login-link field pass-recover  pass-change disable-links"
                    >
                      Change email
                    </button>
                    {this.state.emailChanged && (
                      <div className="success-message">
                        {" "}
                        <div className="message-sent-success"></div>
                        <p>
                          A confirmation message has been sent to{" "}
                          {this.state.email}. Follow the link in the message to
                          confirm the change.
                        </p>
                      </div>
                    )}
                  </>
                )}
                {this.state.needToChangeEmail && (
                  <div className="email-change-wrapper">
                    <div className="info-message">
                      <div className="info-container"></div>
                      <p>
                        A confirmation message will be sent to your new email
                        address. Follow the link in the message to verify the
                        change of address for your profile.
                      </p>
                    </div>
                    <div className="email">
                      <Input
                        className={this.state.isEmailError ? "error" : ""}
                        name="email"
                        id="email"
                        onPaste={this.handleChange}
                        onChange={this.handleChange}
                        type="email"
                        value={this.state.email || this.props.email}
                      />
                      {/* isEmailError */}
                      {this.state.isEmailError && (
                        <div className="email-error">
                          {this.state.errorText}
                          {/* Email address already in use. */}
                        </div>
                      )}
                    </div>
                    <div className="email-buttons-container">
                      <button
                        className="borderless-button"
                        onClick={(e) =>
                          this.setState({
                            needToChangeEmail: false,
                            email: this.oldEmail,
                          })
                        }
                      >
                        Cancel
                      </button>
                      <Button
                        disabled={!this.state.isEmailValid}
                        className="big-button"
                        onClick={this.userEmailUpdate}
                        name="emailButton"
                      >
                        Change and Verify Email
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <hr />

            <div className="profile-content">
              <span className="item-label">Newsletters</span>
              <div className="item-content">
                <div className="pricing-plan-container">
                  I want to receive product updates
                  <div>
                    {this.state.consentUpdate && (
                      <div className="success-message-consent">
                        Your data was saved
                      </div>
                    )}
                  </div>
                  <Checkbox
                    className="custom-checkbox"
                    toggle
                    // defaultChecked={this.props.gridChartCondition}
                    onChange={this.updateConsent}
                    checked={this.state.consent}
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="profile-content">
              <span className="item-label">Password</span>

              <div className="item-content">
                {this.state.needToChangePassword && (
                  <div className="password-change-wrapper">
                    <div className="password">
                      <Input
                        className={this.state.isPasswordError ? "error" : ""}
                        name="password"
                        id="password"
                        onPaste={this.handleChange}
                        onChange={this.handleChange}
                        type={
                          this.state.isPasswordVisible ? "text" : "password"
                        }
                        icon={
                          <div
                            className={
                              this.state.isPasswordVisible
                                ? "pwrd-icon"
                                : "pwrd-icon-hidden"
                            }
                            onClick={this.togglePass}
                            alt=""
                            srcSet=""
                          ></div>
                        }
                        value={this.state.password || this.props.password}
                      />
                      <PasswordStrengthBar
                        className="PasswordStrengthBar"
                        minScore={this.minPassScore}
                        minLength={6}
                        onChangeScore={this.handleChangePWD}
                        password={this.state.password}
                        barColors={[
                          this.props.darkTheme ? " #686868" : "#9A8AB6",
                          "#E01E5A",
                          "#DCE01E",
                          "#DCE01E",
                          "#27C245",
                        ]}
                      />
                    </div>
                    <div className="password-buttons-container">
                      <button
                        className="borderless-button"
                        onClick={(e) =>
                          this.setState({
                            needToChangePassword: false,
                            password: "",
                          })
                        }
                      >
                        Cancel
                      </button>
                      <Button
                        disabled={!this.state.isPasswordValid}
                        className="big-button"
                        onClick={this.userPasswordUpdate}
                        name="passwordButton"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                )}
                {!this.state.needToChangePassword && (
                  <>
                    <button
                      className="borderless-button"
                      onClick={(e) => {
                        this.setState({
                          needToChangePassword: true,
                        });
                      }}
                      //className="login-link field pass-recover  pass-change disable-links"
                    >
                      Change password
                    </button>
                    {this.state.passwordChanged && (
                      <div className="success-message">
                        Your new password saved
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            <hr />

            <div className="profile-content">
              <span className="item-label">Pricing Plan</span>
              <div className="item-content">
                <div className="pricing-plan-container">
                  <div>
                    <span className="text-bold">Free</span>
                    <span>(Start from {companyCreated})</span>
                  </div>
                  <Button className="login-link big-button disabled">
                    Upgrade plan
                  </Button>
                </div>
              </div>
            </div>

            <hr />

            <div className="profile-content">
              <span className="item-label">Account</span>
              <div className="item-content">
                <div className="pricing-plan-container">
                  <div>
                    <span>Registered on {companyCreated}</span>
                  </div>
                  <Modal
                    // size="small"
                    dimmer={this.props.darkTheme ? undefined : "inverted"} //'blurring'//
                    closeIcon={<span className="close-container"></span>}
                    className={
                      this.props.darkTheme
                        ? "customModal cancel dark"
                        : "customModal cancel"
                    }
                    centered={true} //false
                    open={this.state.deleteAccountTrigger}
                    onClose={() =>
                      this.setState({
                        deleteAccountTrigger: false,
                        confirmDelete: false,
                      })
                    }
                    onOpen={() => this.setState({ deleteAccountTrigger: true })}
                    trigger={
                      <button
                        className="borderless-button error" // disable-links login-link field pass-recover error
                        // to={`/`}
                      >
                        Delete Account
                      </button>
                    }
                  >
                    <Modal.Header>Attention:</Modal.Header>
                    <Modal.Content>
                      <Modal.Description>
                        This action is irreversible.
                        <br />
                        By clicking on Delete Account your workspace will be
                        unconnected from Pavooq platform and all your data will
                        be deleted too.
                        <br />
                        Are you sure you want to delete your account?
                      </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions className="delete-acc">
                      <Checkbox
                        label="I'm sure"
                        onChange={() =>
                          this.setState({
                            confirmDelete: !this.state.confirmDelete,
                          })
                        }
                      />
                      <div>
                        <Button
                          className="cancel-button"
                          onClick={() =>
                            this.setState({
                              deleteAccountTrigger: false,
                              confirmDelete: false,
                            })
                          }
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={!this.state.confirmDelete}
                          // color="red"
                          className="error"
                          onClick={() => this.handleDelete()}
                        >
                          Delete
                        </Button>
                      </div>
                    </Modal.Actions>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
          {this.errorContainerRender()}
        </div>
      </div>
    );
  }
}
