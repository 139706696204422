import React, { Component } from "react";
import { Button } from "semantic-ui-react";//, TextArea
import "./DescriptionModalWindow.sass";
import slackIcon from "../img/slack-icon.svg";
import dompurify from 'dompurify';

export default class Tutorial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tutorialHome: true,
      gettingStarted: false,
      graph: false,
      switchers: false,
      details: false,
      export: false,
      profile: false,
      settings: false,
      support: false,
      activeItem: "tutorialHome",
      itemName: "",
      clientIssueMessage: "",
      clientIssueError: false,
      isIssueTextAreaVisible: true,
      issueWereSent: false,
    };
    this.sanitizer = dompurify.sanitize;
    this.apiUrl = process.env.REACT_APP_API_URL || undefined;
    this.handleItemSwitch = this.handleItemSwitch.bind(this);
    this.hendleIssueSend = this.hendleIssueSend.bind(this);
  }
  handleItemSwitch(e) {
    // console.log(e,e.target.title);
    if (e.target.id !== "tutorialHome") {
      this.setState({
        [e.target.id]: !this.state[e.target.id],
        [this.state.activeItem]: false,
        activeItem: e.target.id,
        itemName: e.target.title,
      });
    } else {
      this.setState({
        [e.target.id]: !this.state[e.target.id],
        [this.state.activeItem]: false,
        activeItem: "tutorialHome",
        itemName: e.target.title,
      });
    }
    // console.log("e",e);
    // console.log("[this.state.activeItem]",  [this.state.activeItem]);
    // console.log("this.state.activeItem",this.state.activeItem);
  }
  handleTexareaChange = (e) => {
    console.log("clientIssue", e.target.value);
    if (e && e.target && e.target.value) {
      this.setState({
        clientIssueMessage: this.sanitizer(e.target.value)
      });
    }
  };
  hendleIssueSend() {

    fetch(`${this.apiUrl}/support`, {
      // mode: "no-cors",
      method: "POST",
      // credentials: 'include',
      headers: {
        // Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: 'include',//'same-origin',
      body: JSON.stringify({ message: this.state.clientIssueMessage }),
    })
      .then((res) => {
        console.log("res", res, res.ok);
        if (res.ok) {
          // return res.json();
          this.setState(
            { isIssueTextAreaVisible: false, issueWereSent: true },
            () => {
              setTimeout(() => {
                this.setState({
                  isIssueTextAreaVisible: true,
                  clientIssueMessage: "",
                });
              }, 2000);
            }
          );
        } else {

          if (res.status === 401) {
            console.log("unaunteficated slack");
            // this.handleLogout();
            this.props.pavooqAccessRefresh();
          } else {
            console.log("ERROR", res);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ clientIssueError: true })
        // this.onFail(error);
      });

  }
  render() {
    return (
      <div className="tutor-content">
        <div className="tutorial-header">
          <div className="tutorial-title-container">
            <div
              onClick={this.handleItemSwitch}
              title="Tutorial"
              id="tutorialHome"
              className={
                this.state.activeItem !== "tutorialHome" ? "" : "active"
              }
            >
              Tutorial
            </div>
            {this.state.activeItem !== "tutorialHome" && (
              <>
                <div className="arrow-right"></div>
                <div className="active"> {this.state.itemName}</div>
              </>
            )}
          </div>
          <div
            className="close-container"
            onClick={this.props.tutorialToggler}
          ></div>
        </div>
        {this.state.activeItem === "tutorialHome" && (
          <div id="tutorialContainer">
            <div
              onClick={this.handleItemSwitch}
              id="gettingStarted"
              title="Getting Started"
              className="item-container"
            >
              <div className="rocket-container"></div>
              <div>Getting Started</div>
            </div>
            <div
              onClick={this.handleItemSwitch}
              id="graph"
              title="Graph"
              className="item-container"
            >
              <div className="graph-container"></div>
              <div>Graph</div>
            </div>
            <div
              onClick={this.handleItemSwitch}
              id="switchers"
              title="Switchers"
              className="item-container"
            >
              <div className="switcher-container"></div>
              <div>Filters</div>
            </div>
            <div
              onClick={this.handleItemSwitch}
              id="details"
              title="Details"
              className="item-container"
            >
              <div className="details-container"></div>
              <div>Period & Details</div>
            </div>
            <div
              onClick={this.handleItemSwitch}
              id="export"
              title="Export"
              className="item-container"
            >
              <div className="export-container"></div>
              <div>Export</div>
            </div>
            <div
              onClick={this.handleItemSwitch}
              id="profile"
              title="Profile"
              className="item-container"
            >
              <div className="user-container"></div>
              <div>Profile</div>
            </div>
            <div
              onClick={this.handleItemSwitch}
              id="settings"
              title="Settings"
              className="item-container"//disabled
            >
              <div className="gear-container"></div>
              <div>Settings</div>
            </div>
            <div
              onClick={this.handleItemSwitch}
              id="support"
              title="Support"
              className="item-container" //disabled
            >
              <div className="support-container"></div>
              <div>Support</div>
            </div>
          </div>
        )}
        {this.state.activeItem === "gettingStarted" && (
          <div id="gettingStartedContainer">
            <p>
              After registration on Pavooq you need to connect your Slack
              workspace to see the communication graph.
            </p>
            <p>Click on top menu:</p>
            <div className="chain-container">
              <div className="menu-itm">
                <div className="connect-container"></div>
                <span>Connect my Slack Workspace</span>
              </div>
            </div>
            <p>
              Depend on your Slack rights you can choose one of the options.
            </p>
            <p>If you have admin rights in your workspace click on:</p>
            <button className="field big-button slack-btn">
              <img src={slackIcon} alt="" srcSet="" />
              <span>Connect my workspace</span>
            </button>
            {/* <p>
              If you don't have admin rights in your workspace then you need to{" "}
              <b>copy the link</b> and send it to your Slack workspace admin.
            </p>
            <div className="input-container">
              <Input
                className="copy-input custom-input"
                action={{
                  // color: '#3D1D75',
                  // labelPosition: "right",
                  // icon: 'copy',
                  content: "Copy",
                  readOnly: true,
                }}
                defaultValue="https://pavooq.com/"
              // defaultValue={
              //   this.state.invitationToSlackLink
              // } //defaultValue
              />
            </div>
            <p>
              After you connect a workspace all users get the invite message.
            </p> */}
            <p>
              <b>Dear colleagues! </b>
            </p>
            <p>
              <b>
                We're starting using Pavooq for analyzing our communication.
              </b>
            </p>
            <p>
              <b> Please grant access to you Slack and press Approve</b>
            </p>
          </div>
        )}
        {this.state.activeItem === "graph" && (
          <div id="graphContainer">
            <p>
              The graph display communication between users in your workspace.
            </p>
            <p>Each node is a user.</p>
            <div className="avatar-default center"></div>
            <p>
              We display a user name, his Slack's avatar, and a quantity of
              outgoing sent messages.
            </p>
            <div className="avatarFeatures">
              <div className="avatar-with-label">
                <div className="avatar-default-features"></div>
                <div>User name with avatar</div>
              </div>
              <div className="avatar-with-label">
                <div className="avatar-default-features2"></div>
                <div>Outgoing sent messages</div>
              </div>
            </div>
            <p>
              A line between two users displays when someone sent a direct
              message.
            </p>
            <div className="avatartRelations">
              <hr />
              <div className="avatar-default"></div>
              <div className="avatar-default2"></div>
            </div>
            <p>
              A dashed line with an arrow shows when a user sent a direct message and didn't get a reply.
            </p>
            <div className="avatartRelations one-side-connection">
              <div class="arrow-right"></div>
              <hr />
              <div className="avatar-default"></div>
              <div className="avatar-default2"></div>
            </div>
            <p>The nodes are movable.</p>
          </div>
        )}
        {this.state.activeItem === "switchers" && (
          <div id="switchersContainer">
            <p><b>All</b></p>
            <p>This option shows all connections (chats and direct messages) between nodes on the graph.</p>
            <br />
            <p><b>Direct</b></p>
            <p>This option shows only direct connections (direct messages) between nodes on the graph.</p>
            <br />
          </div>
        )}
        {this.state.activeItem === "details" && (
          <div id="detailsContainer">
            <p><b>Periods</b></p>
            <p>The time periods from the current date.</p>
            <br />
            <p><b>Chart</b></p>
            <p>The chart shows the number of messages for the selected period. It also shows the date and time when on hover. </p>
            <br />
            <p><b>Table</b></p>
            <p>The table shows Users, the number of Contacts, and the number of Messages for the selected period.</p>
            <br />
          </div>
        )}
        {this.state.activeItem === "export" && (
          <div id="exportContainer">
            {/* <p><b>Cinnected Users</b></p> */}
            <p>This option allows you to export data in CSV ‘Matrix’, JSON formats.</p>
            {/* <br/> */}
          </div>
        )}
        {this.state.activeItem === "profile" && (
          <div id="profileContainer">
            <p>This page contains your private information which you provided during registration.</p>
            <p>You can change First Name, Last Name, Company Name by clicking on a relative field. The changes save after you click outside a field.</p>
            <div className="input-example-container"></div>
            <p className="input-image-description">Click to make changes</p>
            <div className="input2-example-container"></div>
            <p className="input-image-description">Click outside to save changes</p>
            <p>
              By clicking on <b>Delete Account</b> your workspace will be unconnected from Pavooq platform and all your data will be deleted too.
            </p>
          </div>
        )}
        {this.state.activeItem === "settings" && (
          <div id="settingsContainer">
            <p><b>Cinnected Users</b></p>
            <p>The list of users in your Slack workspace who have accepted an invite (in Slack) from Pavooq bot.</p>
            <br />
            <p><b>Add new users to Pavooq</b></p>
            <p>The list of users in your Slack workspace whom you can send an invite and who have not accepted an invite (in Slack) from Pavooq bot.</p>
            <br />
            <p><b>Activation link</b></p>
            <p>The link which you can send to users in your Slack workspace separately from the invite.</p>
            <br />
            <p><b>Grid condition</b></p>
            <p>This option helps you to organize the positions of the nodes as a grid on the graph.</p>
            <br />
            <p><b>Hide empty nodes</b></p>
            <p>This option hides nodes who have not written any private messages.</p>
            <br />
            <p><b>Slack workspace</b></p>
            <p>This option shows your Slack workspace status Connected/Disconnected to Pavooq.</p>
            <br />
            <p>Also, you can <b>Remove access</b> to your Slack workspace.</p>
            {/* <p>
              On this page, you can copy the <b>Activation link</b> and send it
              to users in your Slack workspace. The link helps you to add users
              to Pavooq analytics system.
            </p>
            <Button
              className="field big-button"
            >
              Copy activation link
            </Button>
            <p>
              After you sent the link, users <b>need to click on it</b> in
              Slack.
            </p>
            <p>
              Also, you can withdraw access to your Slack account, by clicking on the <b>Remove access</b> button.
            </p>
            <p>This action is <b>irreversible</b></p>
            <Button
              className="field big-button error"
            >
              Remove access
            </Button> */}
          </div>
        )}
        {this.state.activeItem === "support" && (
          <div id="supportContainer">
            <p>Describe your problem and we'll try to fix it.</p>
            <p>Or if you want to say something to us, you are welcome.</p>
            {this.state.isIssueTextAreaVisible && (
              <div>
                <div className="item-content">
                  <textarea
                    className={this.state.clientIssueError ? "error" : ""}
                    // id="clientIssueMessage"
                    type="text"
                    placeholder="Your message"
                    onChange={this.handleTexareaChange}
                  />
                  {this.state.clientIssueError && (
                    <div className="email-error">
                      Error has been occurred, please retry again
                    </div>
                  )}
                </div>
                <Button
                  disabled={!this.state.clientIssueMessage}
                  onClick={this.hendleIssueSend}
                >
                  Send
                </Button>
              </div>
            )}
            {!!(
              !this.state.isIssueTextAreaVisible && this.state.issueWereSent
            ) && (
                <div className="thanks-issue-message">
                  <p>
                    <b>Thanks for your message!</b>
                  </p>
                </div>
              )}
          </div>
        )}
      </div>
    );
  }
}
