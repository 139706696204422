import React from "react";
import { Route, Switch } from "react-router-dom"; //Router,
import AppliedRoute from "./components/AppliedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import NotFound from "./containers/NotFound/NotFound";
import Login from "./containers/Login/Login";
import SignUp from "./containers/SignUp/SignUp";
import RecoverPassword from "./containers/RecoverPassword/RecoverPassword";
import EnterNewPWRD from "./containers/RecoverPasswordSuccess/RecoverPasswordSuccess";
import RecoverPasswordError from "./containers/RecoverPasswordError/RecoverPasswordError";
// import Home from "./containers/Home/Home";
// import PacingPage from './containers/PacingPage/PacingPage';
import ForcePage from "./containers/ForcePage/ForcePage";
import Profile from "./containers/Profile/Profile";
import Settings from "./containers/Settings/Settings";
import ConnectSlack from "./containers/ConnectSlack/ConnectSlack";
import SuccessfullSlackInstalation from "./containers/SuccessfullSlackInstalation/SuccessfullSlackInstalation";
import FailedSlackInstalation from "./containers/FailedSlackInstalation/FailedSlackInstalation";
import SendInvitesToSlackUsers from "./containers/SendInvitesToSlackUsers/SendInvitesToSlackUsers";

import EmailConfirmationSuccess from "./containers/EmailConfirmationSuccess/EmailConfirmationSuccess";
import EmailConfirmationError from "./containers/EmailConfirmationError/EmailConfirmationError";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import GoogleAuthError from "./containers/GoogleAuthError/GoogleAuthError";
export default ({ childProps }) => {
  const redirrectPath =
    childProps.isAuthenticated && !!childProps.initLocation
      ? childProps.initLocation
      : "forcepage";
  return (
    <Switch>
      {/* // for both */}
      <AppliedRoute
        path={`/login?redirectTo=${redirrectPath}`}
        exact
        component={Login}
        props={childProps}
      />
      <AppliedRoute
        path="/email/success"
        exact
        component={EmailConfirmationSuccess}
        props={childProps}
      />
      <AppliedRoute
        path="/email/error"
        exact
        component={EmailConfirmationError}
        props={childProps}
      />
      <AppliedRoute
        path="/oauth/success"
        exact
        component={SuccessfullSlackInstalation}
        props={childProps}
      />
      {/* <AppliedRoute
    path="/oauth/error"
    exact
    component={FailedSlackInstalation}
    props={childProps}
  /> */}
      <AppliedRoute
        path="/oauth/error/cancel"
        exact
        component={FailedSlackInstalation}
        props={childProps}
      />
      <AppliedRoute
        path="/oauth/google/error"
        exact
        component={GoogleAuthError}
        props={childProps}
      />
      <AppliedRoute
        path="/oauth/error/external"
        exact
        component={FailedSlackInstalation}
        props={childProps}
      />
      <AppliedRoute
        path="/oauth/error/internal"
        exact
        component={FailedSlackInstalation}
        props={childProps}
      />
      <AppliedRoute
        path="/oauth/error/exists"
        exact
        component={FailedSlackInstalation}
        props={childProps}
      />
      <AppliedRoute
        path="/oauth/error/mismatch"
        exact
        component={FailedSlackInstalation}
        props={childProps}
      />
      <AppliedRoute exact path="/oauth/google/success" props={childProps}>
        <Redirect to="/forcepage" />
      </AppliedRoute>
      <AppliedRoute exact path="/" props={childProps}>
        <Redirect to="/forcepage" />
      </AppliedRoute>
      <UnauthenticatedRoute
        path="/login"
        exact
        component={Login}
        props={childProps}
      />
      <UnauthenticatedRoute
        path="/recoverpassword"
        exact
        component={RecoverPassword}
        props={childProps}
      />
      <AppliedRoute
        path="/reset/success"
        exact
        component={EnterNewPWRD}
        props={childProps}
      />
      <AppliedRoute
        path="/reset/error"
        exact
        component={RecoverPasswordError}
        props={childProps}
      />
      <UnauthenticatedRoute
        path="/signup"
        exact
        component={SignUp}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/inviteslackmembers" //report
        exact
        component={SendInvitesToSlackUsers}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/profile" //report
        exact
        component={Profile}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/settings" //report
        exact
        component={Settings}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/connectslack" //report
        exact
        component={ConnectSlack}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/forcepage" //report
        exact
        component={ForcePage}
        props={childProps}
      />
      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
};
