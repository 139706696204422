import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Button, Checkbox, Modal, Loader } from "semantic-ui-react";
// import DescriptionModalWindow from "../../components/DescriptionModalWindow";
// import "./Settings.css";
import "./SendInvitesToSlackUsers.sass";
import userIcon from "../../img/user.svg";
import userIconDark from "../../img/user-dark.svg";
import UserForInviteSearch from "../UserForInviteSearch/UserForInviteSearch";
import * as d3 from "d3";
export default class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDelete: false,
      isError: false,
      errorText: "Please try reloading the page.",
      // usersInvitationPart

      invitationText: "",
      selectedUsers: {},
      isApiSending: false,
      respondSuccessfull: false,
      error: false,
      // toggleAllUsersSelection: false,
      openSlackDeletion: false,
      // grantedUsers
      userList: [],
      foundUsers: [],
      firstMessageInWorkspace: undefined
    };
    this.alreadyInvitedUsers = undefined;
    this.toggleAllUsersSelection = true;
    this.parseDate = d3.timeFormat("%b %d, %Y %H:%M");
  }
  componentDidMount() {
    document.title = "Pavooq Slack Connections";
    this.dataProcessor(this.props);
    this.refresh();
  };
  refresh = () => {
    this.setState({ refresh: !this.state.refresh });
  };
  shouldComponentUpdate(nextProps, nextState) {
    // console.log("shouldComponentUpdate", nextProps);
    let isRefresh = nextProps.slackUserList !== this.props.slackUserList || nextProps.membersAPILoaded !== this.props.membersAPILoaded;
    if (isRefresh) {
      this.dataProcessor(nextProps);
    }
    return true; // isRefresh || nextState !== this.state || nextProps.someField !== this.props.someField; // true;
  };

  dataProcessor(props) {
    if (props && props.slackUserList) {
      console.log("props.slackUserList", props.slackUserList);
      let isEmptyObj =
        JSON.stringify(props.slackUserList) === "{}" || !props.slackUserList;
      if (props && props.slackUserList && !isEmptyObj) {
        // console.log("this.props.slackUserList",props.slackUserList);
        // console.log("firstSlackAcc", Object.keys(props.slackUserList));
        let firstSlackAcc = props.slackUserList;
        let firstMessagesArr = Object.values(firstSlackAcc).map(d=>{
          let date = d.active.since * 1000;
          return date
        });
        let youngestMessage = d3.min(firstMessagesArr);
        youngestMessage = new Date(youngestMessage);
        let firstMessageInWorkspace = this.parseDate(youngestMessage) || undefined;
        this.setState({
          userList: Object.values(firstSlackAcc),
          foundUsers: Object.values(firstSlackAcc),
          firstMessageInWorkspace
        });
      }
    }
  }
  handleUserSelect = (e, user) => {
    let userId = user.id || "";
    this.setState((d) => {
      let { selectedUsers } = d;

      if (selectedUsers[userId]) {
        delete selectedUsers[userId];
      } else {
        selectedUsers[userId] = user;
      }
      // console.log("handleUserSelect", selectedUsers);
      return { selectedUsers };
    });
  };

  selectAll = () => {
    // console.log("this.toggleAllUsersSelection", this.toggleAllUsersSelection);
    let selectAllObj = this.state.foundUsers.filter((d) => !d.granted && !d.invited);
    let obj = selectAllObj.reduce((acc, d, i) => {
      acc[d.id] = d;
      return acc;
    }, {});

    this.setState({
      selectedUsers: this.toggleAllUsersSelection ? obj : {}
    });
    this.toggleAllUsersSelection = !this.toggleAllUsersSelection;

  };

  slackUserListToInviteRender = (data = []) => {
    // data.sort((a, b) => {
    //   // console.log("a,b",a,b);
    //   return (a.invited > b.invited) ? 1 : ((b.invited > a.invited) ? -1 : 0)
    // });
    return data.map((d, i) => {
      if (!d.granted) {
        return (
          <div
            className={
              this.state.selectedUsers[d.id] && !d.invited
                ? "user-holder selected"
                : !!(
                  d &&
                  d.invited
                )
                  ? "user-holder invited"
                  : "user-holder"
            } //this.state.selectedUsers
            key={d.id + "container" + i}
            onClick={(e) => !!(d && !d.invited) ? this.handleUserSelect(e, d) : console.log("nothing")}
          >
            <img
              className="user-avatar"
              src={
                d.avatar
                  ? d.avatar
                  : this.props.darkTheme
                    ? userIconDark
                    : userIcon
              }
              alt="user"
              srcSet=""
            />
            {/* <Checkbox
              checked={d.checked}
              // onClick={(e, data) => this.handleUserToInviteSelector(e, data)}
              key={d.id + "_" + i}
              name={d.id}
              label={d.name}
            /> */}
            <p>{d && d.name ? d.name : d && d.id ? d.id : "Unknown user"}</p>
            {(d && d.invited) && <p className="date-container">Invited: {this.parseDate(new Date(d.invited * 1000))}</p>}
          </div>
        );
      }
    });
    //
  };
  grantedUserCouner = (data = []) => {

    let grantedUsersCount = "0";
    grantedUsersCount = data.filter((d) => d.granted);
    return (
      <h5>
        Connected Users
        {this.state && !this.props.demoDash
          ? " (" + grantedUsersCount.length + ")"
          : ""}
      </h5>
    );
    //
  };
  grantedUsersRender = (data = []) => {
    return data.map((d, i) => {
      if (d.granted) {
        return (
          <div
            className={"user-holder granted"}
            key={d.id + "container" + i}
          // onClick={(e) => this.handleUserSelect(e, d)}
          >
            <img
              className="user-avatar"
              src={
                d.avatar
                  ? d.avatar
                  : this.props.darkTheme
                    ? userIconDark
                    : userIcon
              }
              alt="user"
              srcSet=""
            />
            {/* <Checkbox
              checked={d.checked}
              // onClick={(e, data) => this.handleUserToInviteSelector(e, data)}
              key={d.id + "_" + i}
              name={d.id}
              label={d.name}
            /> */}
            {d && d.name ? d.name : d && d.id ? d.id : "Unknown user"}
          </div>
        );
      }
    });
    //
  };

  handleSlackDelete() {
    // console.log(
    //   "link",
    //   this.props.apiUrl
    // );
    fetch(`${this.props.apiUrl}/providers/slack?workspace=${this.props.workSpaceID}`, {
      // old /customers/${this.state.loginnedUserData.ID}
      // mode: "no-cors",
      accept: "*/*",
      method: "DELETE",
      // headers: {
      //   // Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      credentials: 'include',//'same-origin',
    })
      .then((res) => {
        // console.log("res", res, res.ok);
        if (res.ok) {
          // return res.json();
          // console.log(" slack remove access res", res);
        } else {
          // console.log("res.status", res.status);
          if (res.status === 401) {
            this.props.pavooqAccessRefresh();
            // this.props.handleLogout();
            // this.setState({ isAuthenticated: false });
          } else {
            console.log(" slack remove access res", res);
            this.props.onFail(res);
          }
        }
      })
      .then((data) => {
        // data = JSON.parse(JSON.stringify(data));
        // console.log("profile API  ", data);
        this.setState({ openSlackDeletion: false, confirmDelete: true });
        this.props.slackAccessDeletionLogic();
      })
      .catch((error) => {
        console.log(" slack remove access res", error);
        this.props.onFail(error);
      });

  };
  handleTexareaChange = (e) => {
    // console.log("clientIssue", e.target.value);
    if (e && e.target && e.target.value) {
      this.setState({
        invitationText: e.target.value,
      });
    }
  };

  inviteSlackUsers = () => {

    if (!this.state.selectedUsers || JSON.stringify(this.state.selectedUsers) === "{}" || Object.keys(this.state.selectedUsers).length === 0) {
      return
    }
    this.alreadyInvitedUsers = undefined;
    // console.log("this.state.selectedUsers", this.state.selectedUsers);
    let sentInvitesByWorkSpace = {
      [this.props.workSpaceID]: this.state.selectedUsers,
    };
    // console.log(" this.state.selectedUsers", this.state.selectedUsers);
    let userUpdageBody = {};
    this.state.invitationText ?
      userUpdageBody = {
        message: this.state.invitationText + "",
        members: sentInvitesByWorkSpace || {},
      } :
      userUpdageBody = {
        members: sentInvitesByWorkSpace || {},
      }
    // console.log("this.props.apiUrl", this.props.apiUrl);
    // if (this.state.email && this.state.password) {
    fetch(`${this.props.apiUrl}/providers/slack/invite`, {//?workspace=${this.props.workSpaceID}
      // old "customers"
      method: "POST",
      headers: {
        // Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: 'include',//'same-origin',
      body: JSON.stringify(userUpdageBody),
    })
      // .then((resp) => {
      //   if(!resp.ok)
      // })
      .then((res) => {
        //     isApiSending respondSuccessfull error
        // console.log("res", res, res.ok);
        if (res.ok) {
          // console.log("inviteSlackUsers 1", res);
          return res.json();
        } else {
          if (res.status === 409) {
            this.alreadyInvitedUsers = undefined;
            console.log("inviteSlackUsers ERROR", res);
            this.props.onFail("inviteSlackUsers error");
          }
          if (res.status === 401) {
            this.alreadyInvitedUsers = undefined;
            this.props.pavooqAccessRefresh();
            // console.log("unaunteficated slack");
            // this.handleLogout();
          } else {
            this.alreadyInvitedUsers = undefined;
            console.log("ERROR", res);
            this.props.onFail("inviteSlackUsers error");
          }
        }
      })
      .then((response) => {
        if (!this.state.isError) {
          this.props.getSlackUserListToInvite(false);
          this.props.updateInvitationTimeOfUsersObj(this.state.selectedUsers);
          this.alreadyInvitedUsers = this.state.selectedUsers;
          this.setState({ respondSuccessfull: true, error: false, selectedUsers: {} });

        } else {
          console.log("inviteSlackUsers ERROR", response);
          this.alreadyInvitedUsers = undefined;
          this.setState({ isLoading: false, selectedUsers: {} });
          this.props.onFail("inviteSlackUsers error");
        }
      })
      .catch((e) => {
        console.log("inviteSlackUsers ERROR", e);
        this.alreadyInvitedUsers = undefined;
        this.setState({
          error: true,
          isLoading: false,
          selectedUsers: {},
          respondSuccessfull: false,
        });
        this.props.onFail(e);
      });
  };

  render() {

    // let isEmptyObj =
    //   JSON.stringify(this.state.selectedUsers) === "{}" ||
    //   !this.state.selectedUsers;
    let emptyInnitialObj = !this.props.slackUserList || JSON.stringify(this.props.slackUserList) === "{}" || Object.keys(this.props.slackUserList).length === 0;
    let isEmptyObj = !this.state.selectedUsers || JSON.stringify(this.state.selectedUsers) === "{}" || Object.keys(this.state.selectedUsers).length === 0;

    let invitedUsersSize = this.alreadyInvitedUsers ? Object.keys(this.alreadyInvitedUsers).length : 0;

    let seletedUsersSize = Object.keys(this.state.selectedUsers).length;
    // console.log("this.props.slackUserList", this.props.slackUserList, " this.props.membersAPILoaded", this.props.membersAPILoaded, "this.state.foundUsers.length", this.state.foundUsers.length, "this.state.userList", this.state.userList, "this.alreadyInvitedUsers", this.alreadyInvitedUsers, "isEmptyObj", isEmptyObj, "emptyInnitialObj", emptyInnitialObj);
    // console.log( "isEmptyObj", isEmptyObj, "emptyInnitialObj", emptyInnitialObj);


    return (
      <div className="SettingsPage">
        <div className="settings-wrapper">
          <h5 className="connection-title">Connection</h5>

          {/* <div className='content-box'> */}
          {/* {!this.props.demoDash && ( */}
          <>
            <div className="invitationForm">{this.grantedUserCouner(this.state.userList)}
              {!this.props.membersAPILoaded && (
                <Loader active inline='centered' />
              )}
              {!!(emptyInnitialObj && this.props.membersAPILoaded) && (
                <div className="slackInvitationsContainer do-not-connected">
                  <div className="no-data-center do-not-connected">
                    <p>
                      No Users
                    </p>
                  </div>
                </div>
              )}
              {!!(!emptyInnitialObj && this.props.membersAPILoaded) && (
                <div className="slackInvitationsContainer">

                  {!!(!isEmptyObj && this.state.userList.length === 0 && this.props.membersAPILoaded) && (
                    <div className="no-data-center">
                      <p>
                        No Users
                      </p>
                    </div>
                  )}
                  {this.grantedUsersRender(this.state.userList)}
                </div>
              )}
            </div>
            <div className="invitationForm">
              <h5>Add new users to Pavooq</h5>

              {/* {!!(!emptyInnitialObj && this.props.membersAPILoaded) && ( */}
              <>
                <div className={`item-content  ${emptyInnitialObj ? "invisible" : ""}`}>
                  { !!(this.state.userList && this.state.userList.length !== 0 ) &&
                  <UserForInviteSearch
                    darkTheme={this.props.darkTheme}
                    projects={this.state.userList || []}
                    // filterProject={ (query,project) => (project.name == query) }
                    onFindUsers={projects => this.setState({ foundUsers: projects })}
                  />}
                </div>

                <button className={`select-all ${emptyInnitialObj ? "invisible" : ""}`} onClick={this.selectAll}>
                  Select all
                </button>

                <div className={`slackInvitationsContainer ${emptyInnitialObj ? " do-not-connected" : ""}`}>
                  {!!(!emptyInnitialObj && this.state.foundUsers.length === 0 && this.props.membersAPILoaded) && (
                    <div className="no-data-center">
                      <p>
                        No Users
                      </p>
                    </div>
                  )}
                  {!this.props.membersAPILoaded && (
                    <Loader active inline='centered' />
                  )}


                  {this.slackUserListToInviteRender(this.state.foundUsers)}
                </div>
              </>
              {/* )} */}

              {!!(emptyInnitialObj && this.props.membersAPILoaded) && (
                <div className="slackInvitationsContainer do-not-connected">
                  <div className="no-data-center do-not-connected">
                    <p>
                      Workspace not connected
                    </p>
                  </div>
                </div>
              )}
              {/* <h5>Message to users</h5> */}

              <Modal
                // size="small"
                dimmer={this.props.darkTheme ? undefined : "inverted"} //'blurring'//
                closeIcon={<span className="close-container"></span>}
                className={
                  this.props.darkTheme
                    ? "customModal cancel dark"
                    : "customModal cancel"
                }
                centered={true} //false
                open={this.state.openSlackInviteModal}
                onClose={() => this.setState({ openSlackInviteModal: false })}
                onOpen={() => this.setState({ openSlackInviteModal: true })}
                trigger={
                  <Button
                    className={
                      this.state.respondSuccessfull
                        ? "big-button success"
                        : this.state.error
                          ? "big-button error"
                          : "big-button"
                    }
                    disabled={isEmptyObj}
                  >
                    {/* <img src={slackIcon} alt="" srcSet="" /> */}
                    {/* <span> */}
                    {this.state.respondSuccessfull
                      ? "Sent " +
                      invitedUsersSize +
                      " invites"
                      : this.state.isError
                        ? "Action has been failed"
                        : "Send invites to selected members (" +
                        seletedUsersSize +
                        ")"}
                    {/* </span> */}
                  </Button>
                }
              >
                <Modal.Header>Send Invites</Modal.Header>
                <Modal.Content>
                  <Modal.Description>
                    <div className="item-content">
                      <textarea
                        className={
                          this.state.clientIssueError
                            ? "error custom-textarea"
                            : "custom-textarea"
                        }
                        type="text"
                        placeholder="Custom invitation text (Optional)"
                        onChange={this.handleTexareaChange}
                      />
                    </div>
                  </Modal.Description>
                </Modal.Content>
                <Modal.Actions className="delete-acc">
                  <div>
                    <Button
                      className="cancel-button"
                      onClick={() =>
                        this.setState({ openSlackInviteModal: false })
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      // disabled={!this.state.confirmDelete}
                      // color="red"
                      // className="big-button"
                      onClick={() => {
                        this.inviteSlackUsers();
                        this.setState({ openSlackInviteModal: false });
                      }}
                    >
                      Send
                    </Button>
                  </div>
                </Modal.Actions>
              </Modal>
            </div>

            <hr />
          </>
          {/* )} */}
          {/* <div className="settings-activation-link">
            <p>Activation link</p>
            <Input
              className="copy-input item-content"
              defaultValue={this.props.slackUrl}
            />
            <Popup
              content={`Copied !`}
              on="click"
              position="bottom right"
              trigger={
                <Button
                  className="copy-button"
                  onClick={(e) => {
                    let link = this.props.slackUrl.replace(/['"]+/g, "");
                    // console.log("copied", " this.props.slackUrl", link);
                    navigator.clipboard.writeText(link);
                  }}
                >
                  Copy
                </Button>
              }
            />
          </div> */}

          {/* <div className="settings-item">
            <div className="item-text">
              <span>
                Activation link
              </span>
              <p>
                All team user need to add access for Pavooq bot using this link
            </p>
            </div>
            <Button disabled>
              Copy activation link
            </Button>
          </div> */}


          {/* <hr /> */}

          {/* <div className="settings-item">
            <div className="item-text">
              <span>Grid condition</span>
              <p>
                Use grid to set nodes positions
              </p>
            </div>
            <Checkbox
              className="custom-checkbox"
              toggle
              defaultChecked={this.props.gridChartCondition}
              onChange={this.props.gridToggler}
              value={this.props.gridChartCondition}
            />
          </div>

          <hr />

          <div className="settings-item">
            <div className="item-text">
              <span>Hide inactive nodes</span>
              <p>
                Turn on to hide nodes who have not written any private messages
              </p>
            </div>
            <Checkbox
              className="custom-checkbox"
              toggle
              defaultChecked={this.props.hideEmptyUsersCondition}
              onChange={this.props.emptyNodesToggler}
              value={this.props.hideEmptyUsersCondition}
            />
          </div>

          <hr /> */}

          <div className="settings-item">
            <div className="item-text">
              <span>Slack workspace</span>
              <p>
                {this.props.demoDash && !this.state.firstMessageInWorkspace ? `Did not connected yet` : `Connected from ${this.state.firstMessageInWorkspace}`}
              </p> 
              {/*  First message in this workspace since */}
            </div>

            <Modal
              // size="small"
              dimmer={this.props.darkTheme ? undefined : "inverted"} //'blurring'//
              closeIcon={<span className="close-container"></span>}
              className={
                this.props.darkTheme
                  ? "customModal cancel dark"
                  : "customModal cancel"
              }
              centered={true} //false
              open={this.state.openSlackDeletion}
              onClose={() =>
                this.setState({ openSlackDeletion: false, confirmDelete: false })
              }
              onOpen={() => this.setState({ openSlackDeletion: true })}
              trigger={
                <Button className="error" disabled={this.props.demoDash}>
                  Remove access
                </Button>
              } //{<Button>Show Modal</Button>}
            >
              <Modal.Header>Attention:</Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  This action is irreversible.
                  <br />
                  Are you sure you want to remove access to your Slack
                  workspace?
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions className="delete-acc">
                <Checkbox
                  label="I'm sure"
                  onChange={() =>
                    this.setState({
                      confirmDelete: !this.state.confirmDelete,
                    })
                  }
                />
                <div>
                  <Button
                    className="cancel-button"
                    onClick={() =>
                      this.setState({ openSlackDeletion: false, confirmDelete: false })
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!this.state.confirmDelete}
                    // color="red"
                    className="error"
                    onClick={() => this.handleSlackDelete()}
                  >
                    Delete
                  </Button>
                </div>
              </Modal.Actions>
            </Modal>
          </div>

        </div>

        {/* </div> */}
        {/* <DescriptionModalWindow
          content="MODAL WINDOW TEXT WILL BE HERE"
          darkTheme={this.props.darkTheme}
        /> */}
      </div>
    );
  }
}
