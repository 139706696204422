import React, { Component } from "react";import "./App.sass";
import "./slackConnectModal.sass";
import "./components/DescriptionModalWindow.sass";
import { Link, withRouter } from "react-router-dom";
import {
  Menu,
  Checkbox,
  Modal,
  Button,
  Dimmer,
  Loader,
  Popup,
} from "semantic-ui-react"; //, Popup, Modal, Button, Input,Item
// import { Auth, API } from "aws-amplify"; // https://aws-amplify.github.io/docs/js/authentication
import Routes from "./Routes";
import helpers from "./helpers";
// import DescriptionModalWindow from "./components/DescriptionModalWindow";
// import helpers from "../../helpers";
// import SlackLogin from "./components/SlackLogin";
import Tutorial from "./components/Tutorial";
// import slackIcon from "./img/slack-icon.svg";
import CookieConsent from "react-cookie-consent"; //, { Cookies }

import { IframePolicy } from "./components/IframePolicy";
import { connect } from "react-redux";
import * as d3 from "d3";
import {hotjar} from "react-hotjar";

// import { useSelector } from "react-redux"; // useDispatch,
import {
  setIndividualAccumulativeDataByClickedNode,
  setIndividualAccumulativeDataByClickedAndDropdownNode,
  setNestedAccObjectOfSendersAndRecieversArr,
  setClickedNode,
  setHoveredNode,
  clearIndividualAccumulativeDataByClickedNode,
  setDropdownSelectedItemForClickedUser,
  clearIndividualAccumulativeDataByClickedAndDropdownNode,
  setIsMobileDevice,
} from "./redux/toolsSlice";

class App extends Component {
  constructor(props) {
    super(props); // always first
    helpers.resetPositions(); // for chart - reset initial position for each data reloading
    helpers.resetTransform(); // for chart - reset initial zoom for each data reloading
    let gridChartCondition = localStorage.getItem("gridChartCondition");
    let hideEmptyUsersCondition = localStorage.getItem(
      "hideEmptyUsersCondition"
    );
    this.state = {
      initLocation: "",
      mainLoader: true,
      isUserKeepedLoggedin:
        localStorage.getItem("isUserKeepedLoggedin") || false,
      isUserApiFinished: false,
      isAuthenticated: false,
      user: undefined,
      email: undefined,
      unverifiedEmailModal: false,
      isEmailConfirmed: null,
      loginnedUserData: undefined,
      firstName: undefined,
      lastName: undefined,
      consent: false,
      phone: undefined,
      company: undefined,
      companyMinSize: 0,
      companyMaxSize: 0,
      services: [],
      clientCompany: undefined,
      companyCreated: undefined,
      companyIsNotRegistered: false,
      // loginnedUserData: {},
      activeItem: "forcepage", // this.props.history.location.pathname.substr(1, this.props.history.location.pathname.length) ||
      darkTheme: localStorage.getItem("darkTheme") || false,
      openCancelModal: true,
      slackConnectionModalOpen: false,
      isSlackError: false,
      slackKeyReceived: false,
      slackUrl: "", //"https://pavooq.com/",
      copyPopupOpen: false,
      tutorialVIsible: "",
      demoDash: false,
      tableVisibility: false,
      individualStatisticVisibility: false,
      expandIndividualStatistic: false,
      gridChartCondition:
        gridChartCondition === undefined ? true : !!gridChartCondition,
      hideEmptyUsersCondition:
        hideEmptyUsersCondition === undefined
          ? false
          : !!hideEmptyUsersCondition,
      csvGraphObj: undefined,
      slackUserList: undefined,
      chartUserList: undefined,
      lineChartData: undefined,
      workSpaceID: undefined,
      isLoading: true,
      errorText: "",
      // dateNow: Date.now(),
      startTimeFrame: undefined, //Date.now() - 2629800000,// dateNow: new Date(),,
      endTimeFrame: undefined, //Date.now(),
      minDate: undefined,
      maxDate: undefined,
      dateSwitcherSelected: "",
      monthFilterEnabled: false,
      weekFilterEnabled: false,
      dayFilterEnabled: false,
      customFilterEnabled: false,
      membersAPILoaded: false,
      // accumulativeArr: [],
      relationAccumulativeArr: [],
      isMenu: false,
    };

    let oneDayMsec = 86400000;
    let oneWeekMsec = 604800017;
    let oneMonthMsec = 2629800000;
    this.dayAgo = this.state.maxDate - oneDayMsec; //this.props.endTimeFrame,
    this.weekAgo = this.state.maxDate - oneWeekMsec; //this.props.endTimeFrame,
    this.monthAgo = this.state.maxDate - oneMonthMsec; //this.props.endTimeFrame,
    this.granularity = "hour"; // valid levels of accuracy: year, quarter, month, week, day, hour, minute, second.
    this.intervalTime = 900000; // 15 minutes //180000;// 3 minutes // 10000;// ten seconds

    // this.formatTime = d3.timeFormat(" %H:%M %b %e, %Y");
    this.apiUrl = window.__RUNTIME_CONFIG__.REACT_APP_API_URL || undefined; //process.env
    this.relationSelected = "private"; //"private",//"all"//"mentions"
    // this.id = undefined;
    // this.email = undefined;
    // this.password = undefined;
    // this.company = undefined;
    this.isError = false;

    this.timeParse = d3.timeParse("%b %d, %Y");
    this.isMobileDevice = helpers.isMobileDevice();
  }

  // setAccumulativeArr = () =>{
  //   this.setState({accumulativeArr: []});
  // }
  getFormattedDate = (date, format = "%m/%d/%Y") => {
    return d3.timeFormat(format)(date);
  };
  // getParsedTime = (date, format = "%m/%d/%Y") => {
  //   return (d3.timeParse(format))(date);
  // }
  set_cookie(name, value) {
    document.cookie = name + "=" + value + "; Path=/;";
  }
  delete_cookie(name) {
    document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }
  get_cookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const redirect = urlParams.get("redirect");
    console.log("App redirrect in component mount", redirect);

    this.setState({ initLocation: redirect || "" });
    this.sessionCheck();
    if (this.isMobileDevice) {
      this.props.setIsMobileDevice(this.isMobileDevice);
    }

    let localStoreOpenCancelModal;
    if (localStorage.getItem("localStoreOpenCancelModal")) {
      localStoreOpenCancelModal = JSON.parse(
        localStorage.getItem("localStoreOpenCancelModal")
      );
      this.setState({ openCancelModal: localStoreOpenCancelModal });
    }
    this.isCookieAcepted = this.get_cookie("CookieConsent");

    if (localStoreOpenCancelModal) {
      this.setState({
        openCancelModal: localStoreOpenCancelModal,
        isLoading: true,
      });
    }
    const page = this.props.location.pathname;
    hotjar.initialize("3127440", {page, user: this.state?.user?.email || ''});
  }

  componentWillUnmount() {
    if (this.slackMembersAPItimeout) {
      clearTimeout(this.slackMembersAPItimeout);
    }
    if (this.slackMembersAPIinterval) {
      clearInterval(this.slackMembersAPIinterval);
    }
    if (this.copyPopuptimeout) {
      clearTimeout(this.copyPopuptimeout);
    }
  }

  pavooqAccessRefresh = () => {
    this.handleLogout();
  };

  slackAccessDeletionLogic = () => {
    // console.log("this.props.history",
    // this.props.history);
    this.props.history.push("/forcepage");
    this.setState({
      csvGraphObj: undefined,
      slackUserList: {},
      chartUserList: {},
      lineChartData: undefined,
      minDate: undefined,
      maxDate: undefined,
      demoDash: true,
    });
  };
  toggleLoading = () => {
    // console.log("toggleLoading");
    this.setState((prevState) => ({
      isLoading: !prevState.isLoading,
    }));
  };

  saveUserSessionLocally = (bulean) => {
    localStorage.setItem("isUserKeepedLoggedin", bulean);
  };

  handleRelationSelectedChange = (relation) => {
    // console.log(relation);
    this.relationSelected = relation;
  };

  handleItemClick = (name) => {
    // console.log("name", name);
    this.setState({ activeItem: name, isMenu: false });
  };

  userHasAuthenticated = (authenticated) => {
    // console.log("authenticated",authenticated);
    if (!!authenticated) {
      // console.log("members interval has been started");
      this.slackMembersAPItimeout = setTimeout(
        function () {
          // Do something

          this.slackMembersAPIinterval = setInterval(
            () => this.getSlackUserListToInvite(false),
            this.intervalTime
          );
          this.slackMembersAPItimeout = null;
        }.bind(this),
        this.intervalTime
      );

      // ...elsewhere...

      if (this.slackMembersAPItimeout) {
        clearTimeout(this.slackMembersAPItimeout);
        this.slackMembersAPItimeout = null;
      }
    } else {
      clearInterval(this.slackMembersAPIinterval);
    }
    this.setState({ isAuthenticated: authenticated, isLoading: true });
  };

  themeToggler = () => {
    const darkTheme = !this.state.darkTheme;

    // console.log("themeToggler darkTheme",darkTheme);
    if (darkTheme) {
      localStorage.setItem("darkTheme", darkTheme);
    } else {
      // localStorage.setItem("darkTheme", true);
      localStorage.setItem("darkTheme", "");
    }

    this.setState({
      darkTheme,
    });
  };

  gridToggler = () => {
    const gridChartCondition = !this.state.gridChartCondition;
    helpers.resetPositions();
    // console.log("gridToggler gridChartCondition", gridChartCondition);
    if (gridChartCondition) {
      localStorage.setItem("gridChartCondition", gridChartCondition);
    } else {
      // localStorage.setItem("darkTheme", true);
      localStorage.setItem("gridChartCondition", "");
    }

    this.setState({
      gridChartCondition,
    });
  };

  emptyNodesToggler = () => {
    const hideEmptyUsersCondition = !this.state.hideEmptyUsersCondition;
    helpers.resetPositions();
    // console.log("gridToggler hideEmptyUsersCondition", hideEmptyUsersCondition);
    if (hideEmptyUsersCondition) {
      localStorage.setItem("hideEmptyUsersCondition", hideEmptyUsersCondition);
    } else {
      // localStorage.setItem("darkTheme", true);
      localStorage.setItem("hideEmptyUsersCondition", "");
    }

    this.setState({
      hideEmptyUsersCondition,
    });
  };

  handleLogout = (e, deleteTrigger) => {
    if (!this.state.isAuthenticated) {
      return;
    }
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    // sessionDelete = () => {//responseAccessToken

    fetch(`${this.apiUrl}/authorization`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((res) => {
        try {
          if (res.ok) {
            // if backend burned token -
            this.delete_cookie("access_token");
            // document.cookie.remove('access_token');
            clearInterval(this.slackMembersAPIinterval);
            if (deleteTrigger) {
              window.location.replace("https://pavooq.com/");
            } else {
              if (
                !(
                  (
                    this.props.location.pathname === `/login` ||
                    this.props.location.pathname === `/signup` ||
                    this.props.location.pathname === `/oauth/success` ||
                    this.props.location.pathname === `/oauth/google/success` ||
                    this.props.location.pathname === `/oauth/google/error` ||
                    this.props.location.pathname === `/oauth/error/cancel` ||
                    this.props.location.pathname === `/oauth/error/internal` ||
                    this.props.location.pathname === `/oauth/error/external` ||
                    this.props.location.pathname === `/oauth/error/exists` ||
                    this.props.location.pathname === `/oauth/error/mismatch` ||
                    this.props.location.pathname === `/email/success` ||
                    this.props.location.pathname === `/email/error`
                  ) //||
                  // this.props.location.pathname === `/inviteslackmembers`
                )
              ) {
                this.props.history.push("/login");
              }
            }

            localStorage.removeItem("localStoreOpenCancelModal");
            localStorage.removeItem("gridChartCondition");
            localStorage.removeItem("hideEmptyUsersCondition");
            localStorage.removeItem("isUserKeepedLoggedin");
            helpers.resetPositions(); // for chart - reset initial position for each data reloading
            helpers.resetTransform(); // for chart - reset initial zoom for each data reloading

            this.userHasAuthenticated(false);

            this.props.setClickedNode(undefined);
            this.props.setHoveredNode(undefined);
            this.props.clearIndividualAccumulativeDataByClickedNode();
            this.props.setDropdownSelectedItemForClickedUser(undefined);
            this.props.clearIndividualAccumulativeDataByClickedAndDropdownNode();
            this.props.setNestedAccObjectOfSendersAndRecieversArr([]);
            // this.setUserData(undefined);
            this.relationSelected = "private";

            this.setState({
              tableVisibility: false,
              individualStatisticVisibility: false,
              expandIndividualStatistic: false,
              tutorialVIsible: "",
              isAuthenticated: false,
              demoDash: true,
              user: false,
              isLoading: true,
              isEmailConfirmed: false,
              // activeItem: "login",
              csvGraphObj: undefined,
              slackUserList: undefined,
              chartUserList: undefined,
              lineChartData: undefined,
              minDate: undefined,
              maxDate: undefined,
              startTimeFrame: undefined, // dateNow: new Date(),,
              endTimeFrame: undefined,
              dateSwitcherSelected: undefined,
              //
              email: undefined,
              loginnedUserData: undefined,
              firstName: undefined,
              lastName: undefined,
              consent: false,
              phone: undefined,
              company: undefined,
              companyMinSize: 0,
              companyMaxSize: 0,
              services: [],
              clientCompany: undefined,
              companyCreated: undefined,
              // accumulativeArr: [],
              relationAccumulativeArr: [],
              // open: false,
              // clientData: undefined
            });
            //window.location.href = "url"
            //window.location.replace('https://pavooq.com/');
            // return console.log(res.json());
          } else {
            if (res.status === 401) {
              // if backend burned token -
              this.delete_cookie("access_token");
              // document.cookie.remove('access_token');
              clearInterval(this.slackMembersAPIinterval);
              if (deleteTrigger) {
                window.location.replace("https://pavooq.com/");
              } else {
                if (
                  !(
                    (
                      this.props.location.pathname === `/login` ||
                      this.props.location.pathname === `/signup` ||
                      this.props.location.pathname === `/oauth/success` ||
                      this.props.location.pathname ===
                        `/oauth/google/success` ||
                      this.props.location.pathname === `/oauth/google/error` ||
                      this.props.location.pathname === `/oauth/error/cancel` ||
                      this.props.location.pathname ===
                        `/oauth/error/internal` ||
                      this.props.location.pathname ===
                        `/oauth/error/external` ||
                      this.props.location.pathname === `/oauth/error/exists` ||
                      this.props.location.pathname ===
                        `/oauth/error/mismatch` ||
                      this.props.location.pathname === `/email/success` ||
                      this.props.location.pathname === `/email/error` ||
                      this.props.location.pathname === `/reset/success` ||
                      this.props.location.pathname === `/reset/error` ||
                      this.props.location.pathname === `/recoverpassword`
                    ) //||
                    // this.props.location.pathname === `/inviteslackmembers`
                    // || (this.props.location.pathname === `/settings`)
                    // || (this.props.location.pathname === `/profile`)
                    // || (this.props.location.pathname === `/inviteslackmembers`)
                  )
                ) {
                  this.props.history.push("/login");
                }
              }

              localStorage.removeItem("localStoreOpenCancelModal");
              localStorage.removeItem("gridChartCondition");
              localStorage.removeItem("isUserKeepedLoggedin");
              helpers.resetPositions(); // for chart - reset initial position for each data reloading
              helpers.resetTransform(); // for chart - reset initial zoom for each data reloading

              this.userHasAuthenticated(false);

              this.props.setClickedNode(undefined);
              this.props.setHoveredNode(undefined);
              this.props.clearIndividualAccumulativeDataByClickedNode();
              this.props.setDropdownSelectedItemForClickedUser(undefined);
              this.props.clearIndividualAccumulativeDataByClickedAndDropdownNode();
              this.props.setNestedAccObjectOfSendersAndRecieversArr([]);
              // this.setUserData(undefined);

              this.setState({
                tutorialVIsible: "",
                tableVisibility: false,
                individualStatisticVisibility: false,
                expandIndividualStatistic: false,
                isAuthenticated: false,
                isEmailConfirmed: false,
                user: false,
                isLoading: true,
                demoDash: true,
                // activeItem: "login",
                csvGraphObj: undefined,
                slackUserList: undefined,
                chartUserList: undefined,
                lineChartData: undefined,
                minDate: undefined,
                maxDate: undefined,
                startTimeFrame: undefined, // dateNow: new Date(),,
                endTimeFrame: undefined,
                dateSwitcherSelected: undefined,
                //
                email: undefined,
                loginnedUserData: undefined,
                firstName: undefined,
                lastName: undefined,
                consent: false,
                phone: undefined,
                company: undefined,
                companyMinSize: 0,
                companyMaxSize: 0,
                services: [],
                clientCompany: undefined,
                companyCreated: undefined,
                // accumulativeArr: undefined,
                relationAccumulativeArr: undefined,
                // open: false,
                // clientData: undefined
              });
              //window.location.href = "url"
              //window.location.replace('https://pavooq.com/');
              // console.log(
              //   "session delete 401 ", res
              // )
            } else {
              console.log("getSlackAutorisationLink ERROR", res);
              // this.pavooqAccessRefresh();
              this.onFail(res);
            }
          }
        } catch (error) {
          console.log("Something went wrong!");
        }
      })
      // .then((response) => {
      //   // if backend burned token -
      //   this.delete_cookie('access_token');
      //   // document.cookie.remove('access_token');
      //   clearInterval(this.slackMembersAPIinterval);
      // if (deleteTrigger) {
      //   window.location.replace('https://pavooq.com/');
      // } else {
      //   if(!(( this.props.location.pathname === `/login`)
      //   || ( this.props.location.pathname === `/signup`)
      //   || ( this.props.location.pathname === `/oauth/success`)
      //   || ( this.props.location.pathname === `/oauth/google/success`)
      //   || (this.props.location.pathname === `/oauth/google/error`)
      //   || ( this.props.location.pathname === `/oauth/error/cancel`)
      //   || ( this.props.location.pathname === `/oauth/error/internal`)
      //   || ( this.props.location.pathname === `/oauth/error/external`)
      //   || ( this.props.location.pathname === `/oauth/error/exists`)
      //   || ( this.props.location.pathname === `/oauth/error/mismatch`)
      //   || ( this.props.location.pathname === `/email/success`)
      //   || ( this.props.location.pathname === `/email/error`)
      //   )){
      //     this.props.history.push("/login");
      //   }
      // }
      //   localStorage.removeItem("localStoreOpenCancelModal");
      //   localStorage.removeItem("gridChartCondition");
      //   localStorage.removeItem("hideEmptyUsersCondition");
      //   localStorage.removeItem("isUserKeepedLoggedin");
      //   helpers.resetPositions(); // for chart - reset initial position for each data reloading
      //   helpers.resetTransform(); // for chart - reset initial zoom for each data reloading

      //   this.userHasAuthenticated(false);
      //   this.setUserData(undefined);
      //   this.setState({
      //     tutorialVIsible: "",
      //     isAuthenticated: false,
      //     user: false,
      //     isLoading: true,
      //     // activeItem: "login",
      //     slackUserList: undefined,
      //     chartUserList: undefined,
      //     lineChartData: undefined,
      //     minDate: undefined,
      //     maxDate: undefined,
      //     startTimeFrame: undefined,// dateNow: new Date(),,
      //     endTimeFrame: undefined,
      //     dateSwitcherSelected: undefined,
      //     // open: false,
      //     // clientData: undefined
      //   });
      //   //window.location.href = "url"
      //   //window.location.replace('https://pavooq.com/');
      // })
      .catch((e) => {
        this.onFail(e);
        // console.log("getSlackAutorisationLink ERROR", e);
      });
    // };
  };

  setGlobalCompanyParameters = (company) => {
    this.company = company;
    this.setState({ company });
  };

  setUserData = (user) => {
    // If deprecated !!!
    // console.log("USER", user);
    // this.id = user && user.id ? user.id : undefined;
    let email = user && user.email ? user.email : undefined;
    let firstName = user && user.email ? user.email : undefined;
    let lastName = user && user.email ? user.email : undefined;
    let consent = user && user.consent ? user.consent : false;
    let phone = user && user.phone ? user.phone : undefined;
    let password = user && user.password ? user.password : undefined;
    this.setState({ email, firstName, lastName, phone, password, consent });
  };

  changeUserConsent = (consent) => {
    this.setState({ consent });
  };

  toggleCheckBox = () => {
    const willingToRelocate = !this.state.willingToRelocate;
    this.setState({ willingToRelocate });
    // Note: if you have other logic here that depends on your newly updated state, you should add in a callback function to setState, since setState is asynchronous
  };

  copyCodeToClipboard = (e) => {
    const el = e.target;
    // const value = el.value;
    el.select();
    document.execCommand("copy");
  };

  copyToClipboard = () => {
    // console.log("run copyToClipboard");
    const el = document.createElement("textarea");
    el.value = "https://pavooq.com/";
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  onFail = (e, isLoading) => {
    // console.log("fail");
    if (e) {
      console.log("ERROR", e);
      this.setState({
        isError: true,
        isLoading: !!isLoading,
        errorText: e,
      }); // isLoading: false
      // this.respSlackError = e;
    }
  };
  errorContainerRender = () => {
    return (
      <Modal
        size="mini"
        closeIcon={<div className="close-container"></div>}
        className={this.state.darkTheme ? "customModal dark" : "customModal"}
        centered={true} //false
        open={this.state.isError}
        onClose={() => this.setState({ isError: false })}
        onOpen={() => this.setState({ isError: true })}
        // trigger={

        // } //{<Button>Show Modal</Button>}
      >
        <Modal.Header>Opps..</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            An error has occurred.
            <br />
            Please try to reload the page.
            {/* {this.state.errorText ? this.state.errorText : ""} */}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions className="center">
          <Button
            className="wide"
            onClick={() => this.setState({ isError: false })}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
  tutorialToggler = () => {
    // ? "right-sidebar" : "right-sidebar none"
    if (this.state.tutorialVIsible === "") {
      this.setState({
        tutorialVIsible: "right-sidebar",
        isMenu: false,
      });
    } else {
      this.setState({
        tutorialVIsible: "",
        isMenu: false,
      });
    }
    //   this.setState({ tutorialVIsible: !this.state.tutorialVIsible });
    //   console.log("this.state.tutorialVIsible", this.state.tutorialVIsible);
  };

  // copyPopupOpen
  handleOpenPopup = () => {
    // console.log("popup");
    this.setState({ copyPopupOpen: true });

    this.copyPopuptimeout = setTimeout(() => {
      this.setState({ copyPopupOpen: false });
    }, 100);
  };

  handleClosePopup = () => {
    this.setState({ copyPopupOpen: false });
    clearTimeout(this.copyPopuptimeout);
  };

  getChartData = (
    teamID,
    callback,
    dataField,
    granularity,
    timeSince,
    timeUntill,
    senders,
    recipients
  ) => {
    if (!this.state.isAuthenticated) {
      return;
    }
    // console.log("getCHartData args",teamID, dataField, granularity, timeSince, timeUntill, senders,  recipients );
    // if(teamID && callback && dataField){
    // console.log("timeSince",timeSince, "timeUntill",timeUntill );
    let sendersSrting = senders
      ? senders.reduce((acc, d, i, arr) => {
          return (acc = `${acc}&senders=${d}`);
        }, "")
      : "";

    let recipientsSrting = recipients
      ? recipients.reduce((acc, d, i, arr) => {
          return (acc = `${acc}&recipients=${d}`);
        }, "")
      : "";
    // //&since=1611273600.0&until=1636156800.0'
    // let sinceSrting = timeSince ? `&since=${timeSince / 1000}` : "";

    // let untilSrting = timeUntill ? `&until=${timeUntill / 1000}` : "";
    return fetch(
      `${this.apiUrl}/providers/slack/chart?workspace=${teamID}&granularity=${granularity}${sendersSrting}${recipientsSrting}`, //${sinceSrting}${untilSrting}
      {
        //43200 - 12 hours, 86400 - 24 hours //${timeSinceQuerry}${timeUntillQuerry} &since=1625155320.0036&until=1625165442.0045
        method: "GET",

        credentials: "include", //'same-origin',
      }
    )
      .then((res) => {
        if (res.status === 204) {
          // console.log("res should be empty", res);
          if (callback && dataField) {
            this.setState({
              [dataField]: undefined, // was lineChartData
            });
            return []; // must be non undefined
          } else {
            return [];
          }
        } else if (res.ok) {
          return res.json();
          // return undefined; // set undefined for test
        } else {
          if (res.status === 401) {
            console.log("unaunteficated slack");
            // this.handleLogout();
            this.pavooqAccessRefresh();
          } else if (res.status === 404) {
            console.log(
              "404 - NO DATA TO RENDER CHART, CONNECT YOUR SLACK WORKSPACE"
            );
            if (!senders && !recipients) {
              // stupid solution, should be optimized
              if (callback && dataField) {
                this.setState({
                  [dataField]: undefined, // was lineChartData
                });
              } else {
                return [];
              }
            } else {
              return [];
            }
          } else {
            this.onFail(res, true);
          }
        }
      })
      .then((response) => {
        // console.log(`/providers/slack/graph?teamID=${teamID}${timeSinceQuerry}${timeUntillQuerry}`, response);

        // let isSlackInviteEmptyObj = JSON.stringify(response) === "{}" || !response;
        // if (!!(!this.state.isError && !isSlackInviteEmptyObj)) {
        if (callback) {
          callback(response); // this.processChartData(response);
        } else {
          if (response) {
            let data = response; //  helpers.shortLineData;//response;

            // let dataArray =[];
            data = Object.entries(data || {}).map((d) => {
              let obj = {
                milliseconds: Math.round(+d[0] * 1000),
                messages: Math.round(d[1]),
              };
              return obj;
            });
            // data.sort(d => d.milliseconds);
            data.sort((a, b) => a.milliseconds - b.milliseconds);
            return data;
            // return response;
          } else {
            if (!senders && !recipients) {
              // stupid solution, should be optimized
              if (!callback || !dataField) {
                this.setState({
                  [dataField]: undefined, // was lineChartData
                });
              } else {
                return [];
              }
            }
          }
        }
        // console.log("UPDATING TIME FRAMES AFTER CHART LOADING", "minDate", this.state.minDate, "maxDate", this.state.maxDate, "startTimeFrame", this.state.startTimeFrame, "endTimeFrame", this.state.endTimeFrame, "dateSwitcherSelected", this.state.dateSwitcherSelected);
        // }
      })
      .catch((e) => {
        console.log("getChartData ERROR", e);
        this.onFail(e, true);
        // this.onFail(" error");
      });
    // }
  };

  processChartData = (response) => {
    let data = response; //  helpers.shortLineData;//response;

    // let dataArray =[];
    data = Object.entries(data || {}).map(([key, value]) => {
      let obj = {
        milliseconds: Math.round(+key * 1000),
        messages: Math.round(value),
      };
      return obj;
    });
    // data.sort(d => d.milliseconds);
    data.sort((a, b) => a.milliseconds - b.milliseconds);
    if (
      this.state.startTimeFrame === undefined &&
      this.state.endTimeFrame === undefined
    ) {
      let minDate = null;
      let maxDate = null;
      if (data && data.length) {
        minDate = data[0].milliseconds;
        maxDate = data[data.length - 1].milliseconds;
      }
      // let minDate = Math.min(_minDate, _maxDate);
      // let maxDate = Math.max(_minDate, _maxDate);

      let oneDayMsec = 86400000;
      let oneWeekMsec = 604800017;
      let oneMonthMsec = 2629800000;
      this.dayAgo = maxDate - oneDayMsec; //this.props.endTimeFrame,
      this.weekAgo = maxDate - oneWeekMsec; //this.props.endTimeFrame,
      this.monthAgo = maxDate - oneMonthMsec; //this.props.endTimeFrame,

      // console.log(this.monthAgo , this.weekAgo , this.dayAgo , minDate, maxDate);
      if (
        this.monthAgo >= minDate &&
        this.weekAgo >= minDate &&
        this.dayAgo >= minDate
      ) {
        // console.log("lastMonth");
        this.setState({
          minDate: minDate,
          maxDate: maxDate,
          demoDash: JSON.stringify(response) === "{}",
          lineChartData: data,
          startTimeFrame: this.monthAgo, //,
          endTimeFrame: maxDate,
          dateSwitcherSelected: "lastMonth",
          monthFilterEnabled: true,
          weekFilterEnabled: true,
          dayFilterEnabled: true,
          customFilterEnabled: true,
        });
      } else if (
        this.monthAgo < minDate &&
        this.weekAgo >= minDate &&
        this.dayAgo >= minDate
      ) {
        // console.log("lastWeek");
        this.setState({
          minDate: minDate,
          maxDate: maxDate,
          demoDash: JSON.stringify(response) === "{}",
          lineChartData: data,
          startTimeFrame: this.weekAgo, // this.weekAgo,
          endTimeFrame: maxDate,
          dateSwitcherSelected: "lastWeek",
          monthFilterEnabled: false,
          weekFilterEnabled: true,
          dayFilterEnabled: true,
          customFilterEnabled: true,
        });
      } else if (
        this.monthAgo < minDate &&
        this.weekAgo < minDate &&
        this.dayAgo >= minDate
      ) {
        // console.log("lastDay");
        this.setState({
          minDate: minDate,
          maxDate: maxDate,
          demoDash: JSON.stringify(response) === "{}",
          lineChartData: data,
          startTimeFrame: this.dayAgo, // this.dayAgo,
          endTimeFrame: maxDate,
          dateSwitcherSelected: "lastDay",
          monthFilterEnabled: false,
          weekFilterEnabled: false,
          dayFilterEnabled: true,
          customFilterEnabled: true,
        });
      } else {
        // console.log("customTime ");
        this.setState({
          minDate: minDate,
          maxDate: maxDate,
          demoDash: JSON.stringify(response) === "{}",
          lineChartData: data,
          startTimeFrame: minDate, //maxDate,
          endTimeFrame: maxDate,
          dateSwitcherSelected: "customTime",
          monthFilterEnabled: false,
          weekFilterEnabled: false,
          dayFilterEnabled: false,
          customFilterEnabled: true,
        });
      }
    } else {
      this.setState({
        lineChartData: data,
      });
    }
    // console.log("UPDATING TIME FRAMES AFTER CHART LOADING", "minDate", this.state.minDate, "maxDate", this.state.maxDate, "startTimeFrame", this.state.startTimeFrame, "endTimeFrame", this.state.endTimeFrame, "dateSwitcherSelected", this.state.dateSwitcherSelected);
    // }
  };

  processIndividualStatisticData = (response) => {
    let data = response; //  helpers.shortLineData;//response;

    // let dataArray =[];
    data = Object.entries(data || {}).map((d) => {
      let obj = {
        milliseconds: Math.round(+d[0] * 1000),
        messages: Math.round(d[1]),
      };
      return obj;
    });
    // data.sort(d => d.milliseconds);
    data.sort((a, b) => a.milliseconds - b.milliseconds);

    return data;
    // console.log("UPDATING TIME FRAMES AFTER CHART LOADING", "minDate", this.state.minDate, "maxDate", this.state.maxDate, "startTimeFrame", this.state.startTimeFrame, "endTimeFrame", this.state.endTimeFrame, "dateSwitcherSelected", this.state.dateSwitcherSelected);
    // }
  };

  getIndividualStatisticData = (
    clickedNodeId,
    clickedNodeInArr,
    relatedNodesArr,
    dataField
  ) => {
    // console.log("getIndividualStatisticData", clickedNodeId, clickedNodeInArr, relatedNodesArr, dataField); //
    let that = this;
    if (!this.state.isAuthenticated) {
      return;
    }
    // console.log("getIndividualStatisticData",clickedNodeId, this.props.individualAccumulativeDataByClickedNode);
    // if(clickedNodeId&& this.props.individualAccumulativeDataByClickedNode[`${clickedNodeId}_${this.relationSelected}`]){
    //     console.log(this.props.individualAccumulativeDataByClickedNode[`${clickedNodeId}_${this.relationSelected}`][0].milliseconds , this.state.startTimeFrame
    //     , this.props.individualAccumulativeDataByClickedNode[`${clickedNodeId}_${this.relationSelected}`][this.props.individualAccumulativeDataByClickedNode[`${clickedNodeId}_${this.relationSelected}`].length - 1].milliseconds , this.state.endTimeFrame);
    // }
    // console.log("getIndividualStatisticData", clickedNodeId, this.props.individualAccumulativeDataByClickedNode[`${clickedNodeId}_${this.relationSelected}`]);
    // console.log("dataField", dataField);
    // if (clickedNodeId
    //   && dataField === 'accumulativeArr'
    //   && this.props.individualAccumulativeDataByClickedNode[`${clickedNodeId}_${this.relationSelected}`]
    //   && this.props.individualAccumulativeDataByClickedNode[`${clickedNodeId}_${this.relationSelected}`].length
    //   && this.props.individualAccumulativeDataByClickedNode[`${clickedNodeId}_${this.relationSelected}`][0].milliseconds === this.state.startTimeFrame
    //   && this.props.individualAccumulativeDataByClickedNode[`${clickedNodeId}_${this.relationSelected}`][this.props.individualAccumulativeDataByClickedNode[`${clickedNodeId}_${this.relationSelected}`].length - 1].milliseconds === this.state.endTimeFrame
    // ) { // doesn't work with milliseconds
    //   this.setState({ refresh: !this.state.refresh });
    //   return;
    // }
    let accumulativeArr = [];
    let nestedAccObjectOfSendersAndRecieversArr = [];
    if (!clickedNodeInArr && !relatedNodesArr) {
      if (clickedNodeId) {
        if (dataField === "accumulativeArr") {
          this.props.setIndividualAccumulativeDataByClickedNode({
            key: `${clickedNodeId}_${this.relationSelected}`,
            values: [],
          });
        } else if (
          dataField === "individualAccumulativeDataByClickedAndDropdownNode"
        ) {
          this.props.setIndividualAccumulativeDataByClickedAndDropdownNode({
            key: `${clickedNodeId}_${this.relationSelected}`,
            values: [],
          });
        }
      }
      return;
    }
    // console.log("3");
    // console.log("clickedNodeInArr ",clickedNodeInArr,
    // "relatedNodesArr ", relatedNodesArr);
    let clickedNodeIDInArr = clickedNodeInArr.filter((d) => d).map((d) => d.id); //!!!why undefined
    let relatedNodesIDArr = relatedNodesArr.filter((d) => d).map((d) => d.id);
    // console.log("this.state.startTimeFrame",this.state.startTimeFrame,"this.state.endTimeFrame", this.state.endTimeFrame, );
    let senderData = this.getChartData(
      //teamID, callback, dataField, granularity, timeSince, timeUntill, senders,  recipients
      this.state.workSpaceID,
      undefined, //this.processIndividualStatisticData,
      undefined, // "lineChartData",
      this.granularity,
      this.state.startTimeFrame,
      this.state.endTimeFrame,
      clickedNodeIDInArr,
      relatedNodesIDArr
    );

    let recipinetsData = this.getChartData(
      //teamID, callback, dataField, granularity, timeSince, timeUntill, senders,  recipients
      this.state.workSpaceID,
      undefined, //this.processIndividualStatisticData,
      undefined, //"lineChartData",
      this.granularity,
      this.state.startTimeFrame,
      this.state.endTimeFrame,
      relatedNodesIDArr,
      clickedNodeIDInArr
    );
    // console.log("this.state.startTimeFrame", new Date(this.state.startTimeFrame), "this.state.endTimeFrame", new Date(this.state.endTimeFrame));

    Promise.all([senderData, recipinetsData]).then((data) => {
      // if (!data || (data && (!data[0] || !data[1]) || data && (data[0].length === 0) || data && (data[1].length === 0))) {
      // if (data && (data[0].length > 0 || data[1].length > 0) ) {
      if (
        data &&
        ((data[0] && data[0].length > 0) ||
          (data[1] && data[1].length > 0) ||
          !data[0] ||
          data[0].length === 0 ||
          !data[1] ||
          data[1].length === 0)
      ) {
        // console.log("promise alll ",);
        let sendersDataRes = data[0];
        let recipinetsDataRes = data[1];

        // console.log("lineChartData", this.state.lineChartData);
        let startLineDate =
          this.state.lineChartData && this.state.lineChartData.length
            ? this.state.lineChartData[0].milliseconds
            : that.state.startTimeFrame;
        let endLineDate =
          this.state.lineChartData && this.state.lineChartData.length
            ? this.state.lineChartData[this.state.lineChartData.length - 1]
                .milliseconds
            : that.state.endTimeFrame;

        if (
          sendersDataRes &&
          sendersDataRes.length > 0 &&
          recipinetsDataRes &&
          recipinetsDataRes.length > 0
        ) {
          // console.log("sendersDataRes.length  > 0 && recipinetsDataRes.length > 0 case");

          let obj = {};
          // let objSenders = {};
          // let objRecipinets = {};
          // let accObjectOfSendersAndRecievers = {};
          sendersDataRes.forEach((d) => {
            obj[d.milliseconds] = (obj[d.milliseconds] || 0) + +d.messages;
            // objSenders[d.milliseconds] = (objSenders[d.milliseconds] || 0) + +d.messages;
          });
          recipinetsDataRes.forEach((d) => {
            obj[d.milliseconds] = (obj[d.milliseconds] || 0) + +d.messages;
            // objRecipinets[d.milliseconds] = (objRecipinets[d.milliseconds] || 0) + +d.messages;
          });

          let accObjectOfSendersAndRecievers = {};
          sendersDataRes.forEach((d) => {
            if (!accObjectOfSendersAndRecievers[d.milliseconds]) {
              accObjectOfSendersAndRecievers[d.milliseconds] = {
                milliseconds: d.milliseconds,
                sent: d.messages,
                received: 0,
                day: this.getFormattedDate(
                  new Date(d.milliseconds),
                  "%b %d, %Y"
                ),
              };
            } else {
              accObjectOfSendersAndRecievers[d.milliseconds].sent =
                accObjectOfSendersAndRecievers[d.milliseconds].sent +
                d.messages;
            }
            // accObjectOfSendersAndRecievers[d.milliseconds] = { sent: (accObjectOfSendersAndRecievers[d.milliseconds].messages || 0) }
            // objSenders[d.milliseconds] = (objSenders[d.milliseconds] || 0) + +d.messages;
          });
          recipinetsDataRes.forEach((d) => {
            if (!accObjectOfSendersAndRecievers[d.milliseconds]) {
              accObjectOfSendersAndRecievers[d.milliseconds] = {
                milliseconds: d.milliseconds,
                sent: 0,
                received: d.messages,
                day: this.getFormattedDate(
                  new Date(d.milliseconds),
                  "%b %d, %Y"
                ),
              };
            } else {
              accObjectOfSendersAndRecievers[d.milliseconds].received =
                accObjectOfSendersAndRecievers[d.milliseconds].received +
                d.messages;
            }
          });

          let accObjectOfSendersAndRecieversArr = Object.values(
            accObjectOfSendersAndRecievers
          );
          accObjectOfSendersAndRecieversArr.sort(
            (a, b) => a.milliseconds - b.milliseconds
          );
          accObjectOfSendersAndRecieversArr =
            accObjectOfSendersAndRecieversArr.filter((d) => {
              // console.log("d",d);
              return (
                d.milliseconds >= this.state.startTimeFrame &&
                d.milliseconds <= this.state.endTimeFrame
              );
            });
          nestedAccObjectOfSendersAndRecieversArr = d3
            .nest()
            .key((d) => d.day)
            .entries(accObjectOfSendersAndRecieversArr);
          nestedAccObjectOfSendersAndRecieversArr.forEach((d) => {
            d.sent = d3.sum(d.values, (d) => d.sent);
            d.received = d3.sum(d.values, (d) => d.received);
            d.milliseconds = d.values[0].milliseconds;
            // d.timeMseconds = new Date(d.key);
          }); //nestedAccObjectOfSendersAndRecieversArr

          // nestedAccObjectOfSendersAndRecieversArr.forEach(d => {
          //   let timeMseconds = Date.now(this.timeParse(d.key));
          //   console.log(d.key,timeMseconds);
          //   return {
          //     key: d.key,
          //     milliseconds: d.values[0].milliseconds,
          //     sent: d3.sum(d.values, d => d.sent),
          //     received: d3.sum(d.values, d => d.received),
          //   }
          // });//nestedAccObjectOfSendersAndRecieversArr
          // console.log("nestedAccObjectOfSendersAndRecieversArr", nestedAccObjectOfSendersAndRecieversArr);
          // console.log("endTimeFrame",this.state.endTimeFrame, "this.state.startTimeFrame", this.state.startTimeFrame);

          accumulativeArr = Object.entries(obj || {}).map(([key, value]) => ({
            milliseconds: +key,
            messages: +value,
          }));

          accumulativeArr = accumulativeArr.sort(
            (a, b) => +a.milliseconds - +b.milliseconds
          );
          if (
            accumulativeArr &&
            accumulativeArr[0].milliseconds > startLineDate
          ) {
            let startItm = [{ milliseconds: startLineDate, messages: 0 }];
            accumulativeArr = [...startItm, ...accumulativeArr];
          }
          if (
            accumulativeArr &&
            accumulativeArr[accumulativeArr.length - 1].milliseconds <
              endLineDate
          ) {
            let endItm = [{ milliseconds: endLineDate, messages: 0 }];
            accumulativeArr = [...accumulativeArr, ...endItm];
          }
          accumulativeArr = accumulativeArr.sort(
            (a, b) => +a.milliseconds - +b.milliseconds
          );

          // this.props.setNestedAccObjectOfSendersAndRecieversArr(nestedAccObjectOfSendersAndRecieversArr);
          // console.log("promise accumulativeArr", accumulativeArr);
        } else if (
          sendersDataRes &&
          sendersDataRes.length > 0 &&
          recipinetsDataRes &&
          recipinetsDataRes.length === 0
        ) {
          // console.log("sendersDataRes.length  > 0 && recipinetsDataRes.length === 0 case");

          let obj = {};
          // let objSenders = {};
          // let objRecipinets = {};
          // let accObjectOfSendersAndRecievers = {};
          sendersDataRes.forEach((d) => {
            obj[d.milliseconds] = (obj[d.milliseconds] || 0) + +d.messages;
            // objSenders[d.milliseconds] = (objSenders[d.milliseconds] || 0) + +d.messages;
          });

          let accObjectOfSendersAndRecievers = {};
          sendersDataRes.forEach((d) => {
            if (!accObjectOfSendersAndRecievers[d.milliseconds]) {
              accObjectOfSendersAndRecievers[d.milliseconds] = {
                milliseconds: d.milliseconds,
                sent: d.messages,
                received: 0,
                day: this.getFormattedDate(
                  new Date(d.milliseconds),
                  "%b %d, %Y"
                ),
              };
            } else {
              accObjectOfSendersAndRecievers[d.milliseconds].sent =
                accObjectOfSendersAndRecievers[d.milliseconds].sent +
                d.messages;
            }
            // accObjectOfSendersAndRecievers[d.milliseconds] = { sent: (accObjectOfSendersAndRecievers[d.milliseconds].messages || 0) }
            // objSenders[d.milliseconds] = (objSenders[d.milliseconds] || 0) + +d.messages;
          });
          recipinetsDataRes.forEach((d) => {
            if (!accObjectOfSendersAndRecievers[d.milliseconds]) {
              accObjectOfSendersAndRecievers[d.milliseconds] = {
                milliseconds: d.milliseconds,
                sent: 0,
                received: d.messages,
                day: this.getFormattedDate(
                  new Date(d.milliseconds),
                  "%b %d, %Y"
                ),
              };
            } else {
              accObjectOfSendersAndRecievers[d.milliseconds].received =
                accObjectOfSendersAndRecievers[d.milliseconds].received +
                d.messages;
            }
          });

          let accObjectOfSendersAndRecieversArr = Object.values(
            accObjectOfSendersAndRecievers
          );
          accObjectOfSendersAndRecieversArr.sort(
            (a, b) => a.milliseconds - b.milliseconds
          );
          accObjectOfSendersAndRecieversArr =
            accObjectOfSendersAndRecieversArr.filter((d) => {
              // console.log("d",d);
              return (
                d.milliseconds >= this.state.startTimeFrame &&
                d.milliseconds <= this.state.endTimeFrame
              );
            });
          nestedAccObjectOfSendersAndRecieversArr = d3
            .nest()
            .key((d) => d.day)
            .entries(accObjectOfSendersAndRecieversArr);
          nestedAccObjectOfSendersAndRecieversArr.forEach((d) => {
            d.sent = d3.sum(d.values, (d) => d.sent);
            d.received = d3.sum(d.values, (d) => d.received);
            d.milliseconds = d.values[0].milliseconds;
            // d.timeMseconds = new Date(d.key);
          }); //nestedAccObjectOfSendersAndRecieversArr

          // nestedAccObjectOfSendersAndRecieversArr.forEach(d => {
          //   let timeMseconds = Date.now(this.timeParse(d.key));
          //   console.log(d.key,timeMseconds);
          //   return {
          //     key: d.key,
          //     milliseconds: d.values[0].milliseconds,
          //     sent: d3.sum(d.values, d => d.sent),
          //     received: d3.sum(d.values, d => d.received),
          //   }
          // });//nestedAccObjectOfSendersAndRecieversArr
          // console.log("nestedAccObjectOfSendersAndRecieversArr", nestedAccObjectOfSendersAndRecieversArr);
          // console.log("endTimeFrame",this.state.endTimeFrame, "this.state.startTimeFrame", this.state.startTimeFrame);

          // console.log("nestedAccObjectOfSendersAndRecieversArr", nestedAccObjectOfSendersAndRecieversArr);
          // console.log("endTimeFrame",this.state.endTimeFrame, "this.state.startTimeFrame", this.state.startTimeFrame);

          accumulativeArr = Object.entries(obj || {}).map(([key, value]) => ({
            milliseconds: +key,
            messages: +value,
          }));

          accumulativeArr = accumulativeArr.sort(
            (a, b) => +a.milliseconds - +b.milliseconds
          );
          if (
            accumulativeArr &&
            accumulativeArr[0].milliseconds > startLineDate
          ) {
            let startItm = [{ milliseconds: startLineDate, messages: 0 }];
            accumulativeArr = [...startItm, ...accumulativeArr];
          }
          if (
            accumulativeArr &&
            accumulativeArr[accumulativeArr.length - 1].milliseconds <
              endLineDate
          ) {
            let endItm = [{ milliseconds: endLineDate, messages: 0 }];
            accumulativeArr = [...accumulativeArr, ...endItm];
          }
          accumulativeArr = accumulativeArr.sort(
            (a, b) => +a.milliseconds - +b.milliseconds
          );

          // this.props.setNestedAccObjectOfSendersAndRecieversArr(nestedAccObjectOfSendersAndRecieversArr);
          // console.log("promise accumulativeArr", accumulativeArr);
        } else if (
          sendersDataRes &&
          sendersDataRes.length === 0 &&
          recipinetsDataRes &&
          recipinetsDataRes.length > 0
        ) {
          // console.log("sendersDataRes.length  === 0 && recipinetsDataRes.length > 0 case");

          let obj = {};
          // let objSenders = {};
          // let objRecipinets = {};
          // let accObjectOfSendersAndRecievers = {};
          recipinetsDataRes.forEach((d) => {
            obj[d.milliseconds] = (obj[d.milliseconds] || 0) + +d.messages;
            // objRecipinets[d.milliseconds] = (objRecipinets[d.milliseconds] || 0) + +d.messages;
          });

          let accObjectOfSendersAndRecievers = {};
          sendersDataRes.forEach((d) => {
            if (!accObjectOfSendersAndRecievers[d.milliseconds]) {
              accObjectOfSendersAndRecievers[d.milliseconds] = {
                milliseconds: d.milliseconds,
                sent: d.messages,
                received: 0,
                day: this.getFormattedDate(
                  new Date(d.milliseconds),
                  "%b %d, %Y"
                ),
              };
            } else {
              accObjectOfSendersAndRecievers[d.milliseconds].sent =
                accObjectOfSendersAndRecievers[d.milliseconds].sent +
                d.messages;
            }
            // accObjectOfSendersAndRecievers[d.milliseconds] = { sent: (accObjectOfSendersAndRecievers[d.milliseconds].messages || 0) }
            // objSenders[d.milliseconds] = (objSenders[d.milliseconds] || 0) + +d.messages;
          });
          recipinetsDataRes.forEach((d) => {
            if (!accObjectOfSendersAndRecievers[d.milliseconds]) {
              accObjectOfSendersAndRecievers[d.milliseconds] = {
                milliseconds: d.milliseconds,
                sent: 0,
                received: d.messages,
                day: this.getFormattedDate(
                  new Date(d.milliseconds),
                  "%b %d, %Y"
                ),
              };
            } else {
              accObjectOfSendersAndRecievers[d.milliseconds].received =
                accObjectOfSendersAndRecievers[d.milliseconds].received +
                d.messages;
            }
          });

          let accObjectOfSendersAndRecieversArr = Object.values(
            accObjectOfSendersAndRecievers
          );
          accObjectOfSendersAndRecieversArr.sort(
            (a, b) => a.milliseconds - b.milliseconds
          );
          accObjectOfSendersAndRecieversArr =
            accObjectOfSendersAndRecieversArr.filter((d) => {
              // console.log("d", d);
              return (
                d.milliseconds >= this.state.startTimeFrame &&
                d.milliseconds <= this.state.endTimeFrame
              );
            });
          nestedAccObjectOfSendersAndRecieversArr = d3
            .nest()
            .key((d) => d.day)
            .entries(accObjectOfSendersAndRecieversArr);
          nestedAccObjectOfSendersAndRecieversArr.forEach((d) => {
            d.sent = d3.sum(d.values, (d) => d.sent);
            d.received = d3.sum(d.values, (d) => d.received);
            d.milliseconds = d.values[0].milliseconds;
            // d.timeMseconds = new Date(d.key);
          }); //nestedAccObjectOfSendersAndRecieversArr

          // nestedAccObjectOfSendersAndRecieversArr.forEach(d => {
          //   let timeMseconds = Date.now(this.timeParse(d.key));
          //   console.log(d.key,timeMseconds);
          //   return {
          //     key: d.key,
          //     milliseconds: d.values[0].milliseconds,
          //     sent: d3.sum(d.values, d => d.sent),
          //     received: d3.sum(d.values, d => d.received),
          //   }
          // });//nestedAccObjectOfSendersAndRecieversArr
          // console.log("nestedAccObjectOfSendersAndRecieversArr", nestedAccObjectOfSendersAndRecieversArr);
          // console.log("endTimeFrame",this.state.endTimeFrame, "this.state.startTimeFrame", this.state.startTimeFrame);

          // console.log("nestedAccObjectOfSendersAndRecieversArr", nestedAccObjectOfSendersAndRecieversArr);
          // console.log("endTimeFrame",this.state.endTimeFrame, "this.state.startTimeFrame", this.state.startTimeFrame);

          accumulativeArr = Object.entries(obj || {}).map(([key, value]) => ({
            milliseconds: +key,
            messages: +value,
          }));

          accumulativeArr = accumulativeArr.sort(
            (a, b) => +a.milliseconds - +b.milliseconds
          );
          if (
            accumulativeArr &&
            accumulativeArr[0].milliseconds > startLineDate
          ) {
            let startItm = [{ milliseconds: startLineDate, messages: 0 }];
            accumulativeArr = [...startItm, ...accumulativeArr];
          }
          if (
            accumulativeArr &&
            accumulativeArr[accumulativeArr.length - 1].milliseconds <
              endLineDate
          ) {
            let endItm = [{ milliseconds: endLineDate, messages: 0 }];
            accumulativeArr = [...accumulativeArr, ...endItm];
          }
          accumulativeArr = accumulativeArr.sort(
            (a, b) => +a.milliseconds - +b.milliseconds
          );

          // console.log("promise accumulativeArr", accumulativeArr);
        } else {
          nestedAccObjectOfSendersAndRecieversArr = [];
          accumulativeArr = [];
        }
        if (clickedNodeId) {
          if (dataField === "accumulativeArr") {
            this.props.setNestedAccObjectOfSendersAndRecieversArr(
              nestedAccObjectOfSendersAndRecieversArr
            );
            this.props.setIndividualAccumulativeDataByClickedNode({
              key: `${clickedNodeId}_${this.relationSelected}`,
              values: accumulativeArr,
            });
          } else if (
            dataField === "individualAccumulativeDataByClickedAndDropdownNode"
          ) {
            // this.props.setIndividualAccumulativeDataByClickedAndDropdownNode(accumulativeArr);
            let relatedNodeId = relatedNodesArr[0].id;
            // console.log("relatedNodesArr",relatedNodesArr,"relatedNodeId",relatedNodeId);
            this.props.setNestedAccObjectOfSendersAndRecieversArr(
              nestedAccObjectOfSendersAndRecieversArr
            );
            this.props.setIndividualAccumulativeDataByClickedAndDropdownNode({
              key: `${clickedNodeId}_${relatedNodeId}_${this.relationSelected}`,
              values: accumulativeArr,
            });
          }
          // this.setState({refresh: !this.state.refresh}); // !!!
        }
      } else {
        if (clickedNodeId) {
          if (dataField === "accumulativeArr") {
            this.props.setIndividualAccumulativeDataByClickedNode({
              key: `${clickedNodeId}_${this.relationSelected}`,
              values: [],
            });
          } else if (
            dataField === "individualAccumulativeDataByClickedAndDropdownNode"
          ) {
            this.props.setIndividualAccumulativeDataByClickedAndDropdownNode({
              key: `${clickedNodeId}_${this.relationSelected}`,
              values: [],
            });
          }
        }
        return;
      }
    });
  };

  getCsvData = (json) => {
    // (teamID, timeSince, timeUntill)
    // console.log("getCsvData json",json);
    let obj = json; //[teamID];
    // obj = obj.map(d=>{
    //   d.idWithNames = d.name + " " + [d]
    // });
    for (let key in obj) {
      obj[key].idAndName = `${obj[key].name.replaceAll(",", " ")} (${
        obj[key].id
      })`;
    }

    // console.log("getCsvData obj", obj);
    let keys = Object.keys(obj || {});

    let headersString = "";
    keys.forEach((keyCol) => {
      headersString = `${headersString},${obj[keyCol].idAndName}`;
    });
    keys.forEach((keyRow) => {
      let values = "";

      // keys.forEach(keyCol => {
      //   values = `${values},${keyCol === keyRow ? 0 : (json[teamID][keyRow] && json[teamID][keyRow].relations[keyCol] ? json[teamID][keyRow].relations[keyCol].private : 0)}`;
      // });

      keys.forEach((keyCol) => {
        values = `${values},${
          keyCol === keyRow
            ? 0
            : json[keyRow] && json[keyRow].relations[keyCol]
            ? json[keyRow].relations[keyCol].private
            : 0
        }`;
      });
      headersString = `${headersString}\n${obj[keyRow].idAndName}${values}`;
    });

    return headersString;
  };

  getGraphData = (teamID, timeSince, timeUntill) => {
    if (!this.state.isAuthenticated) {
      return;
    }
    let timeSinceFloat = timeSince ? timeSince / 1000 : undefined;
    let timeUntillFloat = timeUntill ? timeUntill / 1000 : undefined;
    let timeSinceQuerry = timeSinceFloat
      ? "&since=" + Math.round(timeSinceFloat)
      : "";
    let timeUntillQuerry = timeUntillFloat
      ? "&until=" + Math.round(timeUntillFloat)
      : "";

    fetch(
      `${this.apiUrl}/providers/slack/graph?workspace=${teamID}${timeSinceQuerry}${timeUntillQuerry}`,
      {
        // &since=1625155320.0036&until=1625165442.0045
        method: "GET",

        credentials: "include",
      }
    )
      .then((res) => {
        if (res.status === 204) {
          // console.log("res should be empty", res);
          this.setState({
            isLoading: false,
            chartUserList: this.state.slackUserList,
          });
          return (res = this.state.slackUserList); // undefined
        } else if (res.ok) {
          return res.json();
          // return undefined; // set undefined for test
        } else {
          if (res.status === 401) {
            console.log("unaunteficated slack");
            // this.handleLogout();
            this.pavooqAccessRefresh();
          } else if (res.status === 404) {
            console.log(
              "404 - NO DATA TO RENDER GRAPH, CONNECT YOUR SLACK WORKSPACE"
            );
            this.setState({
              isLoading: false,
              chartUserList: this.state.slackUserList,
            });
          } else {
            this.onFail(res);
          }
        }
      })
      .then((response) => {
        // let isSlackInviteEmptyObj = JSON.stringify(response) === "{}" || !response;
        // console.log("providers/slack/graph JSON.stringify(response) === {}", JSON.stringify(response) === "{}" );

        // if (!!(!this.state.isError && !isSlackInviteEmptyObj)) {
        // console.log("getGraphData 2", response);
        // // need remove !!!
        // response = helpers.defaultDataArray || response;
        for (const key in response) {
          // teamID
          // for (const key in response[teamID]) {
          // response[teamID][key].id = key;
          response[key].id = key;
          // response[globalKey][key].name = response[globalKey][key].name;
          // response[globalKey][key].avatar = response[globalKey][key].avatar;
          // }
        }
        // console.log("RESP", response);
        // let obj = {[teamID]: response}
        //chartUserList
        // prevDate...!== this.state.date...
        helpers.resetPositions();
        if (response && this.state.slackUserList) {
          for (let key in response) {
            response[key].invitable = !!this.state.slackUserList[key]
              ? true
              : false;
          }
          // console.log("DONE", response);
        }

        let csvGraphObj = this.getCsvData(response); //teamID,

        this.setState({
          chartUserList: response,
          demoDash: JSON.stringify(response) === "{}",
          isLoading: false,
          csvGraphObj,
        });
        // }
      })
      .catch((e) => {
        console.log("getGraphData catch", e);
        this.onFail(e);
        // this.onFail("getGraphData error");
      });
  };

  getSlackUserListToInvite = (onlyMembersAPI) => {
    this.setState({
      membersAPILoaded: false,
    });

    fetch(`${this.apiUrl}/providers/slack/members`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          if (res.status === 401) {
            console.log("unaunteficated slack");
            this.pavooqAccessRefresh();
          } else {
            this.onFail(res);
          }
        }
      })
      .then((response) => {
        let isSlackInviteEmptyObj =
          JSON.stringify(response) === "{}" || !response;

        if (!!(!this.state.isError && !isSlackInviteEmptyObj)) {
          for (const globalKey in response) {
            for (const key in response[globalKey]) {
              response[globalKey][key].id = key;
              response[globalKey][key].invitable = true;
            }
          }
          let slackKeys = Object.keys(response || {});
          this.setState((prevState) => ({
            workSpaceID: slackKeys[0],
            slackUserList: response[slackKeys[0]],
            membersAPILoaded: true,
            demoDash: JSON.stringify(response) === "{}",
            isLoading: onlyMembersAPI, // prevState.isLoading || onlyMembersAPI// true,
          }));
          if (onlyMembersAPI && this.state.isAuthenticated) {
            this.getChartData(
              slackKeys[0],
              this.processChartData,
              "lineChartData",
              this.granularity,
              undefined,
              undefined,
              undefined,
              undefined
            ).then(() => {
              // console.log("this.state.startTimeFrame", this.state.startTimeFrame, "this.state.endTimeFrame",this.state.endTimeFrame);
              this.getGraphData(
                slackKeys[0],
                this.state.startTimeFrame,
                this.state.endTimeFrame
              );
            });
          }
        } else if (!!(!this.state.isError && isSlackInviteEmptyObj)) {
          this.setState({
            workSpaceID: undefined,
            slackUserList: response,
            membersAPILoaded: true,
            demoDash: JSON.stringify(response) === "{}",
            isLoading: false,
          });
        } else {
          // console.log("getSlackUserListToInvite ERROR", response);

          this.onFail(response);
          this.setState({
            membersAPILoaded: true,
          });
          // this.onFail("getSlackUserListToInvite error");
        }
      })
      .catch((e) => {
        console.log("getSlackUserListToInvite ERROR", e);
        this.onFail(e);
        this.setState({
          membersAPILoaded: true,
        });
        // this.onFail("getSlackUserListToInvite error");
      });
  };
  updateTimeFrames = (startTimeFrame, endTimeFrame, dateSwitcherSelected) => {
    // end >> start
    this.setState({ isLoading: true });
    // console.log("timeSince",new Date(startTimeFrame),"timeUntill", new Date(endTimeFrame));
    if (this.demoTimeout) {
      clearTimeout(this.demoTimeout);
    }

    this.demoTimeout = setTimeout(
      () => {
        this.getGraphData(this.state.workSpaceID, startTimeFrame, endTimeFrame);
      },
      this.demoTimeout ? 500 : 100
    );
    this.setState({ startTimeFrame, endTimeFrame, dateSwitcherSelected });
  };
  profileAPILoader = () => {
    fetch(`${this.apiUrl}/account`, {
      ///customers/me
      method: "GET",

      credentials: "include", //'same-origin',
    })
      .then((res) => {
        // console.log("res", res, res.ok);
        if (res.ok) {
          return res.json();
        } else {
          // console.log("res.status", res.status);
          if (res.status === 401) {
            this.pavooqAccessRefresh();
            // this.props.handleLogout();
            // this.setState({ isAuthenticated: false });
          } else {
            console.log("ERROR", res);
            this.onFail(res);
          }
        }
      })
      .then((data) => {
        // data = JSON.parse(JSON.stringify(data));
        // console.log("profile API  ", data);
        let email = data && data.email ? data.email : "";
        let firstName = data && data.firstname ? data.firstname : "";
        let lastName = data && data.lastname ? data.lastname : "";
        // let password = data && data.password ? data.password : "";
        let phone = data && data.phone ? data.phone : "";
        let confirmed = data && data.confirmed ? data.confirmed : false;
        let consent = data && data.consent ? data.consent : false;
        // let password = data && data.password ? data.password : "";

        // this.props.setUserData(data);
        // this.oldFirstName = firstName;
        // this.oldLastName = lastName;
        // this.oldPhone = phone;
        this.setState({
          email,
          loginnedUserData: data,
          firstName,
          lastName,
          phone,
          companyCreated: Math.round(data.created),
          isEmailConfirmed: confirmed,
          consent,
          // password: password,
        });
      })
      .catch((error) => {
        console.log(error);
        this.onFail(error);
        // this.setState({ dataForce: [] })
      });

    // get his company
    fetch(`${this.apiUrl}/company`, {
      // mode: "no-cors",
      method: "GET",
      credentials: "include", //'same-origin',
    })
      // d3.json(link)
      // .then(res => res.json())
      // .then((resp) => {
      //   if(!resp.ok)
      // })
      .then((res) => {
        // console.log("res", res, res.ok);
        if (res.ok) {
          return res.json();
        } else {
          // console.log("res.status", res.status);
          if (res.status === 401) {
            // this.props.handleLogout();
            // this.setState({ isAuthenticated: false });
            this.pavooqAccessRefresh();
          } else if (res.status === 404) {
            this.setState({ companyIsNotRegistered: true });
          } else {
            console.log("ERROR", res);
            this.onFail(res);
          }
        }
      })
      .then((data) => {
        // data = JSON.parse(JSON.stringify(data));
        // console.log("company API  ", data);
        // this.oldCompany = data.name;\
        this.setState({
          company: data.name || "",
          clientCompany: data,
          companyIsNotRegistered: false,
          services: data.toolset || [],
          companyMinSize: data.minsize,
          companyMaxSize: data.maxsize,
        });
      })
      .catch((error) => {
        console.log(error);
        // this.onFail(error);
        // this.setState({ dataForce: [] })
      });
  };
  sessionCheck = () => {
    //responseAccessToken

    fetch(`${this.apiUrl}/authorization`, {
      method: "GET",

      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          this.userHasAuthenticated(true);
          let historyPath = window.location.pathname;
          this.profileAPILoader();
          // this.getSlackAutorisationLink();
          this.getSlackUserListToInvite(true);
          this.setState({ mainLoader: false });
          if (
            !!(
              historyPath &&
              (historyPath === `/settings` ||
                historyPath === `/profile` ||
                historyPath === `/forcepage` ||
                historyPath === `/reset/success` ||
                historyPath === `/reset/error` ||
                historyPath === `/oauth/success` ||
                historyPath === `/oauth/google/success` ||
                historyPath === `/oauth/google/error` ||
                historyPath === `/oauth/error/cancel` ||
                historyPath === `/oauth/error/internal` ||
                historyPath === `/oauth/error/external` ||
                historyPath === `/oauth/error/exists` ||
                historyPath === `/oauth/error/mismatch` ||
                historyPath === `/email/success` ||
                historyPath === `/email/error` ||
                historyPath === `/reset/success` ||
                historyPath === `/reset/error` ||
                historyPath === `/inviteslackmembers`)
            )
          ) {
            return;
          } else {
            this.props.history.push(historyPath ? historyPath : "/forcepage");
          }

          return true; //res.json();
        } else {
          if (res.status === 401) {
            this.pavooqAccessRefresh();
            this.setState({ mainLoader: false });
          } else {
            console.log("getSlackAutorisationLink ERROR", res);
            this.pavooqAccessRefresh();
            this.onFail(res);
            this.setState({ mainLoader: false });
          }
          return false;
        }
      })
      // .then((response) => {

      // })
      .catch((e) => {
        this.pavooqAccessRefresh();
        console.log("getSlackAutorisationLink ERROR", e);
        this.setState({ mainLoader: false });
      });
  };

  // getSlackAutorisationLink = () => {

  //   //responseAccessToken

  //   fetch(`${this.apiUrl}/providers/slack/install`, {
  //     method: "GET",

  //     credentials: "include", //'same-origin',
  //   })
  //     .then((res) => {
  //       // console.log("res", res, res.ok);
  //       if (res.ok) {
  //         // console.log("getSlackAutorisationLink", res);
  //         return res.json();
  //       } else {
  //         // if (res.status === 409) {
  //         //   console.log("getSlackAutorisationLink ERROR", res);
  //         //   // this.onFail("getSlackAutorisationLink error");
  //         // }
  //         if (res.status === 401) {
  //           // console.log("unaunteficated slack");
  //           // this.handleLogout();
  //           this.pavooqAccessRefresh();
  //           // } else if (res.status === 404) {
  //           //   console.log("unaunteficated slack");
  //           //   // this.handleLogout();
  //           //   this.pavooqAccessRefresh();
  //         } else {
  //           console.log("getSlackAutorisationLink ERROR", res);
  //           this.onFail(res);
  //         }
  //       }
  //     })
  //     .then((response) => {
  //       // console.log(response);
  //       if (!this.state.isError) {
  //         // console.log("getSlackAutorisationLink", response);
  //         this.setState({ slackUrl: response });
  //       } else {
  //         console.log("getSlackAutorisationLink ERROR", response);
  //         // this.onFail("getSlackAutorisationLink error");
  //       }
  //     })
  //     .catch((e) => {
  //       console.log("getSlackAutorisationLink ERROR", e);
  //       // this.onFail("getSlackAutorisationLink error");
  //     });
  //   // }
  // };

  updateInvitationTimeOfUsersObj = (updatedUsers) => {
    // console.log("updateInvitationTimeOfUsersObj run", updatedUsers);
    this.setState({ isLoading: true });
    let updatedUsersArr = Object.values(updatedUsers);
    updatedUsersArr = updatedUsersArr.map((d) => {
      let invitationTimeInMs = Date.now();
      d.invited = invitationTimeInMs / 1000;
      return d;
    });

    let originalUserObj = this.state.chartUserList;
    // this.state.chartUserList[this.state.workSpaceID];
    let originalUsersArr = Object.values(originalUserObj);
    // console.log("UPDATE OF DATA: ",
    //   " updatedUsersArr ", updatedUsersArr,
    //   " originalUsersArr ", originalUsersArr,
    // );
    originalUsersArr = originalUsersArr.map((d, i) => {
      // console.log("d", d);
      let d2 = updatedUsersArr.find((d2) => d2.id === d.id);
      // console.log("d2 ? { ...d, ...d2 } : d", d2 ? { ...d, ...d2 } : d);
      return d2 ? { ...d, ...d2 } : d;
    });
    let updatedOriginalObj = originalUsersArr.reduce((acc, d, i) => {
      acc[d.id] = d;
      return acc;
    }, {});
    // console.log("{ [this.state.workSpaceID]: updatedOriginalObj }", { [this.state.workSpaceID]: updatedOriginalObj });
    this.setState({
      chartUserList: updatedOriginalObj, // { [this.state.workSpaceID]: updatedOriginalObj },
      isLoading: false,
    });
    //compare and update Object By Property Of Other Object
    //arra1 = arra1.map(item => {
    // let item2 = arra2.find(i2 => i2.transid === item.transid);
    // return item2 ? { ...item, ...item2 } : item;
    // });
  };

  // openTableOnNodeClick = (e) => {
  //   this.setState({
  //     tableVisibility: true,
  //   });
  // };

  toggleTable = (bulean) => {
    // console.log("toggleTable arg", bulean)
    this.setState((prevState) => ({
      tableVisibility: bulean ? bulean : !prevState.tableVisibility,
    }));
  };

  openIndividualStatisticOnNodeClick = (e) => {
    this.setState({
      individualStatisticVisibility: true,
    });
  };
  expandIndividualStatisticOnNodeClick = (bulean) => {
    // console.log("expandIndividualStatisticOnNodeClick", bulean);
    this.setState((prevState) => ({
      expandIndividualStatistic: bulean
        ? bulean
        : !prevState.expandIndividualStatistic,
    }));
  };

  toggleIndividualStatistic = (bulean) => {
    let isClickedNode = bulean
      ? bulean
      : !this.state.individualStatisticVisibility;
    if (!isClickedNode) {
      // close the "individual-statistic-container" window
      this.props.setClickedNode(undefined);
    }
    // console.log("isClickedNode", isClickedNode, this.isMobileDevice);
    // console.log("tableVisibility", isClickedNode && this.isMobileDevice ? false : this.state.tableVisibility, this.state.tableVisibility);
    this.setState({
      individualStatisticVisibility: isClickedNode,
      tableVisibility:
        isClickedNode && this.isMobileDevice
          ? false
          : this.state.tableVisibility,
    }); // {..prevState.obj, newField: "Bla-bla"}
    // this.setState(prevState => ({
    //   individualStatisticVisibility: bulean ? bulean : !prevState.individualStatisticVisibility,
    // }));
  };

  render() {
    // console.log(" this.state.services,", this.state.services);
    // console.log(" App csvGraphObj",this.state.csvGraphObj);
    // console.log("startTimeFrame, endTimeFrame",this.state.startTimeFrame, this.state.endTimeFrame);
    // console.log("this.state.copyPopupOpen", this.state.copyPopupOpen);
    // this.slackRedirectUrl = window.location.href || undefined;
    // let protocolName = window.location.protocol || undefined;
    // if (this.slackRedirectUrl) {
    //   this.slackRedirectUrl = this.slackRedirectUrl.split("/")[2];
    // }
    // this.slackRedirectUrl = `${protocolName}//${this.slackRedirectUrl}/auth/install`; //location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');

    // console.log("url", this.slackRedirectUrl);
    // console.log("companyCreated",this.state.companyCreated);
    const activeItem = this.state.activeItem;
    // console.log("this.props.history",this.props.history);

    // console.log("consent", this.state.consent);
    // any global data here
    const childProps = {
      initLocation: this.state.initLocation,
      apiUrl: this.apiUrl,
      profileAPILoader: this.profileAPILoader,
      email: this.state.email,
      loginnedUserData: this.state.loginnedUserData,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      consent: this.state.consent,
      phone: this.state.phone,
      isEmailConfirmed: this.state.isEmailConfirmed,
      // getSlackAutorisationLink: this.getSlackAutorisationLink,
      getSlackUserListToInvite: this.getSlackUserListToInvite,
      isAuthenticated: this.state.isAuthenticated,
      mainLoader: this.state.mainLoader,
      userHasAuthenticated: this.userHasAuthenticated,
      user: this.state.user,
      company: this.state.company,
      companyMinSize: this.state.companyMinSize,
      companyMaxSize: this.state.companyMaxSize,
      services: this.state.services,
      clientCompany: this.state.clientCompany,
      companyCreated: this.state.companyCreated,
      companyIsNotRegistered: this.state.companyIsNotRegistered,
      setGlobalCompanyParameters: this.setGlobalCompanyParameters,
      setUserData: this.setUserData,
      changeUserConsent: this.changeUserConsent,
      getIndividualStatisticData: this.getIndividualStatisticData,
      // clientData: this.state.clientData,
      // getClientData: this.getClientData.bind(this),
      isError: this.isError,
      // loginnedUserData: this.state.loginnedUserData,
      // themeToggler: this.themeToggler,
      darkTheme: this.state.darkTheme,
      activeItem: this.state.activeItem,
      handleItemClick: this.handleItemClick,
      saveUserSessionLocally: this.saveUserSessionLocally,
      refreshToken: this.refreshToken,
      pavooqAccessRefresh: this.pavooqAccessRefresh,
      // pavooqLogin: this.pavooqLogin,
      handleLogout: this.handleLogout,
      demoDash: this.state.demoDash,
      slackConnectionModalOpen: this.state.slackConnectionModalOpen,
      tutorialVIsible: this.state.tutorialVIsible,
      slackUrl: this.state.slackUrl,
      slackUserList: this.state.slackUserList,
      chartUserList: this.state.chartUserList,
      lineChartData: this.state.lineChartData,
      workSpaceID: this.state.workSpaceID,
      isLoading: this.state.isLoading,
      toggleLoading: this.toggleLoading,
      // loadingToggler: this.loadingToggler,
      slackAccessDeletionLogic: this.slackAccessDeletionLogic,
      updateInvitationTimeOfUsersObj: this.updateInvitationTimeOfUsersObj,
      onFail: this.onFail,
      updateTimeFrames: this.updateTimeFrames,
      // dateSwitcherSelectedChange: this.dateSwitcherSelectedChange,
      startTimeFrame: this.state.startTimeFrame,
      endTimeFrame: this.state.endTimeFrame,
      minDate: this.state.minDate,
      maxDate: this.state.maxDate,
      gridToggler: this.gridToggler,
      emptyNodesToggler: this.emptyNodesToggler,
      gridChartCondition: this.state.gridChartCondition,
      hideEmptyUsersCondition: this.state.hideEmptyUsersCondition,
      dateSwitcherSelected: this.state.dateSwitcherSelected,
      monthFilterEnabled: this.state.monthFilterEnabled,
      weekFilterEnabled: this.state.weekFilterEnabled,
      dayFilterEnabled: this.state.dayFilterEnabled,
      customFilterEnabled: this.state.customFilterEnabled,
      handleRelationSelectedChange: this.handleRelationSelectedChange,
      relationSelected: this.relationSelected,
      membersAPILoaded: this.state.membersAPILoaded,
      csvGraphObj: this.state.csvGraphObj,
      toggleTable: this.toggleTable,
      // openTableOnNodeClick: this.openTableOnNodeClick,
      tableVisibility: this.state.tableVisibility,
      individualStatisticVisibility: this.state.individualStatisticVisibility,
      expandIndividualStatistic: this.state.expandIndividualStatistic,
      openIndividualStatisticOnNodeClick:
        this.openIndividualStatisticOnNodeClick,
      expandIndividualStatisticOnNodeClick:
        this.expandIndividualStatisticOnNodeClick,
      toggleIndividualStatistic: this.toggleIndividualStatistic,
      // accumulativeArr: this.state.accumulativeArr,
      // setAccumulativeArr: this.setAccumulativeArr,
      relationAccumulativeArr: this.state.relationAccumulativeArr,
      // noDataToDisplay: this.state.noDataToDisplay,
      // isMobileDevice: this.isMobileDevice,
    };

    return (
      <>
        <div className={this.state.darkTheme ? "App dark" : "App"}>
          {this.state.mainLoader && (
            <Dimmer
              active
              className={
                this.state.darkTheme ? "solid-dimmer dark" : "solid-dimmer"
              }
            >
              <Loader inverted={this.state.darkTheme ? false : true}>
                Loading
              </Loader>
            </Dimmer>
          )}
          {this.state.isAuthenticated && (
            <Menu
              borderless
              className={
                activeItem === "login"
                  ? "navigation-bar invisible"
                  : "navigation-bar"
              }
            >
              {" "}
              {/* text */}
              {this.state.isAuthenticated && (
                <Menu.Menu>
                  {/* <div> */}
                  <Menu.Item>
                    <Link
                      className="logo"
                      id="appLogoSmall"
                      // active={activeItem === "forcepage"}
                      onClick={(e) => this.handleItemClick("forcepage")}
                      name={this.state.isAuthenticated ? `forcepage` : `login`}
                      // to={this.state.isAuthenticated ? `/home` : `/login`}
                      to={this.state.isAuthenticated ? `/forcepage` : `/login`}
                    >
                      {/* <img src={logoSmall} alt="" srcSet="" /> */}
                      <div className="logo-small-container">
                        {/* {this.state.demoDash && ( */}
                        <div className="demo">
                          {this.state.demoDash ? "DEMO" : "BETA"}
                        </div>
                        {/* )} */}
                      </div>
                    </Link>
                  </Menu.Item>

                  {/* </div> */}
                </Menu.Menu>
              )}
              {!!(
                this.state.isAuthenticated &&
                // !this.state.demoDash &&
                !this.state.isLoading
              ) && (
                <Menu.Menu className="forcepage-menu">
                  <Menu.Item active={activeItem === "forcepage"}>
                    <Link
                      id="navigateToForcePageID"
                      className="menu-itm middle-itm"
                      to="/forcepage"
                      name="forcepage"
                      onClick={(e) => this.handleItemClick("forcepage")}
                    >
                      {/* <img src={blocks} alt="" srcSet="" /> */}
                      <div className="blocks-container">
                        {" "}
                        {/* "blocks-container" */}
                      </div>
                      <span>{this.isMobileDevice ? "" : "Dashboard"}</span>
                    </Link>
                  </Menu.Item>
                </Menu.Menu>
              )}
              {/* {!!(
                this.state.isAuthenticated &&
                // this.state.demoDash &&
                !this.state.isLoading
              ) && (
                  <Menu.Menu // disabled className="disabled"
                    className="connectslack-menu"
                  >
                    <Menu.Item // disabled={true}
                      active={activeItem === "connectslack"}
                    >
                      {this.state.isEmailConfirmed
                        ?
                        <Link
                          className="menu-itm middle-itm" //disabled
                          id="navigateToConnectslackID"
                          to="/connectslack"
                          name="connectslack"
                          onClick={(e) => this.handleItemClick("connectslack")}
                        >
                          <div
                            className="connect-container" //disabled
                          ></div>
                          <span>
                            {this.isMobileDevice
                              ? ""
                              : "Connect my Slack space to Pavooq"}
                          </span>
                        </Link>
                        :
                        <Modal
                          size="mini"
                          closeIcon={<div className="close-container"></div>}
                          className={this.state.darkTheme ? "customModal dark" : "customModal"}
                          centered={true} //false
                          open={this.state.unverifiedEmailModal}
                          onClose={() => this.setState({ unverifiedEmailModal: false })}
                          onOpen={() => this.setState({ unverifiedEmailModal: true })}
                          trigger={
                            <button
                              className="menu-itm middle-itm" //disabled
                            // id="navigateToConnectslackID"
                            // to="/connectslack"
                            // name="connectslack"
                            // onClick={(e) => this.handleItemClick("connectslack")}
                            >
                              <div
                                className="connect-container" //disabled
                              ></div>
                              <span>
                                {this.isMobileDevice
                                  ? ""
                                  : "Connect my Slack space to Pavooq"}
                              </span>
                            </button>
                          } //{<Button>Show Modal</Button>}
                        >
                          <Modal.Header>Email confirmation</Modal.Header>
                          <Modal.Content>
                            <Modal.Description>
                              We sent you an email confirmation, please check it to proceed.
                            </Modal.Description>
                          </Modal.Content>
                          <Modal.Actions className="center">
                            <Button
                              className="widest"
                              onClick={() => this.setState({ unverifiedEmailModal: false })}
                            >
                              Got it
                            </Button>
                          </Modal.Actions>
                        </Modal>
                      }

                    </Menu.Item>
                  </Menu.Menu>
                )} */}
              {this.state.isAuthenticated && (
                <Menu.Menu
                  className="grouped-items"
                  style={
                    this.state.darkTheme
                      ? { backgroundColor: "#222222", color: "#f2f2f2" }
                      : { backgroundColor: "#fff", color: "#3D1D75" }
                  }
                >
                  <div
                    className={`is-mobile ${
                      this.state.isMenu ? "is-mobile-active" : ""
                    }`}
                    onClick={() =>
                      this.setState({ isMenu: !this.state.isMenu })
                    }
                  ></div>
                  <Menu.Item
                    style={{
                      display:
                        this.state.isMenu || !this.isMobileDevice
                          ? "flex"
                          : "none",
                    }}
                  >
                    <Menu.Item
                      className="theme-image-holder"
                      // active={activeItem === "profile"}
                    >
                      <div className="mode-container"></div>
                      <Checkbox
                        className="theme-toggler"
                        id="themeTogglerId"
                        toggle
                        defaultChecked={!this.state.darkTheme}
                        onChange={this.themeToggler}
                        // value={!this.state.darkTheme}
                      />
                    </Menu.Item>

                    <Popup
                      content="Tutorial"
                      style={
                        this.state.darkTheme
                          ? { backgroundColor: "#222222", color: "#f2f2f2" }
                          : { backgroundColor: "#fff", color: "#000" }
                      }
                      trigger={
                        <Menu.Item className="tutorial-item">
                          {!!this.state.isAuthenticated && (
                            <div
                              className={`help-contaier ${
                                this.state.tutorialVIsible ? "active" : ""
                              }`}
                              id="tutorialOpenerID"
                              onClick={this.tutorialToggler}
                            ></div>
                          )}
                        </Menu.Item>
                      }
                      on="hover"
                      basic
                      disabled={this.isMobileDevice}
                    />

                    <Popup
                      content="Settings"
                      style={
                        this.state.darkTheme
                          ? { backgroundColor: "#222222", color: "#f2f2f2" }
                          : { backgroundColor: "#fff", color: "#000" }
                      }
                      trigger={
                        <div
                          className={`settings-item item ${
                            activeItem === "settings" ? "active" : ""
                          }`}
                        >
                          <Link
                            id="navigateToSettingsID"
                            className="menu-itm"
                            to="/settings"
                            name="settings"
                            onClick={(e) => this.handleItemClick("settings")}
                          >
                            <div className="gear-container"></div>
                          </Link>
                        </div>
                      }
                      on="hover"
                      basic
                      disabled={this.isMobileDevice}
                    />

                    <Popup
                      content={
                        !this.state.isEmailConfirmed && this.state.demoDash
                          ? "Please confirm your email for first"
                          : "Connection"
                      }
                      style={
                        this.state.darkTheme
                          ? { backgroundColor: "#222222", color: "#f2f2f2" }
                          : { backgroundColor: "#fff", color: "#000" }
                      }
                      trigger={
                        <div // disabled={true}
                          className={`connectslack-item item ${
                            activeItem === "connectslack" ||
                            activeItem === "inviteslackmembers"
                              ? "active"
                              : ""
                          } ${!this.state.isEmailConfirmed ? "disabled" : ""}`}
                          // active={activeItem === "connectslack" || activeItem === "inviteslackmembers"}
                          // className="connectslack-item"
                          // disabled={!this.state.isEmailConfirmed}
                        >
                          {/* {this.state.isEmailConfirmed && */}
                          <Link
                            className="menu-itm" //disabled
                            id="navigateToConnectslackID"
                            to={
                              this.state.demoDash
                                ? "/connectslack"
                                : "/inviteslackmembers"
                            }
                            name="connectslack"
                            onClick={(e) =>
                              this.handleItemClick(
                                this.state.demoDash
                                  ? "connectslack"
                                  : "inviteslackmembers"
                              )
                            }
                          >
                            <div
                              className="connect-container" //disabled
                            ></div>
                            {/* <span>
                            {this.isMobileDevice
                              ? ""
                              : "Connect my Slack space to Pavooq"}
                          </span> */}
                          </Link>
                          {/* } */}
                        </div>
                      }
                      on="hover"
                      basic
                      disabled={this.isMobileDevice}
                    />

                    <Popup
                      content="Profile"
                      style={
                        this.state.darkTheme
                          ? { backgroundColor: "#222222", color: "#f2f2f2" }
                          : { backgroundColor: "#fff", color: "#000" }
                      }
                      trigger={
                        <div
                          className={`profile-item item ${
                            activeItem === "profile" ? "active" : ""
                          }`}
                        >
                          <Link
                            className="menu-itm"
                            id="navigateToProfileID"
                            to="/profile"
                            name="profile"
                            onClick={(e) => this.handleItemClick("profile")}
                          >
                            {/* <img src={userIcon} alt="" srcSet="" /> */}
                            <div className="user-container"></div>
                          </Link>
                        </div>
                      }
                      on="hover"
                      basic
                      disabled={this.isMobileDevice}
                    />

                    <Popup
                      content="Exit"
                      style={
                        this.state.darkTheme
                          ? { backgroundColor: "#222222", color: "#f2f2f2" }
                          : { backgroundColor: "#fff", color: "#000" }
                      }
                      trigger={
                        <div
                          className={`exit-item item ${
                            activeItem === "login" ? "active" : ""
                          }`}
                        >
                          <Link
                            className="menu-itm"
                            id="logoutButtonID"
                            to="/login"
                            name="logout"
                            onClick={this.handleLogout} //this.handleLogout("login")
                          >
                            {/* <img src={loogoutImg} alt="" srcSet="" /> */}{" "}
                            <div className="logout-container"></div>
                          </Link>
                        </div>
                      }
                      on="hover"
                      basic
                      disabled={this.isMobileDevice}
                    />
                  </Menu.Item>
                  <div id="preloadedImages"></div>

                  {/* {!this.state.isAuthenticated && <Menu.Item> <Link to={`/login`}>Log In</Link></Menu.Item>
          } */}
                </Menu.Menu>
              )}
            </Menu>
          )}

          <div
            className={
              this.state.isAuthenticated
                ? "page-content"
                : "page-content unautentificated"
            }
          >
            <Routes childProps={childProps} />
          </div>

          {/* <div className="preloadContainer">
            <div id=""></div>
          </div> */}
          <div id="tutorial" className={this.state.tutorialVIsible}>
            <Tutorial tutorialToggler={this.tutorialToggler} />
          </div>
        </div>
        {/* {!(this.isCookieAcepted) &&
        <div className={this.state.darkTheme ? "cookie-consent-container dark" : "cookie-consent-container"}> */}
        <CookieConsent
          // onAccept={() => {
          //   alert("yay!");
          // }}
          style={
            this.state.darkTheme
              ? { background: "#222222", color: "#f2f2f2" }
              : { background: "#fff", color: "#000" }
          }
          contentClasses="cookie-consent-text"
          // debug={true}
          buttonClasses={
            this.state.darkTheme
              ? "cookie-modal-button dark"
              : "cookie-modal-button"
          }
          // onDecline={() => {
          //   alert("nay!");
          // }}
        >
          {/* <div className="cookie-consent-content"> */}
          This site uses cookies. By continuing to use this website, you agree
          to their use.{" "}
          <span // style={{ fontSize: "10px" }}
          >
            See{" "}
            <Modal
              // size="mini"
              // dimmer={
              //   this.props.darkTheme ? undefined : "inverted"
              // }
              dimmer={this.state.darkTheme ? undefined : "inverted"} //'blurring'//
              closeIcon={<div className="close-container"></div>}
              className={
                this.state.darkTheme ? "customModal dark" : "customModal"
              }
              centered={true} //false
              open={this.state.openPolicyModal}
              onClose={() => this.setState({ openPolicyModal: false })}
              onOpen={() =>
                this.setState({
                  openPolicyModal: true,
                  // policyButtonAvailable: false,
                })
              }
              trigger={
                <button
                  id="readPolicy"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  className={
                    this.state.darkTheme
                      ? "cookie-borderless-button dark"
                      : "cookie-borderless-button"
                  }
                >
                  Privacy Policy
                </button>
              }
            >
              <Modal.Header>Privacy Policy</Modal.Header>
              <Modal.Content
              // scrolling
              // onScroll={this.handleScroll}
              >
                {/* <Modal.Description> */}
                <IframePolicy
                  src="https://docs.google.com/document/d/e/2PACX-1vQAi0y6ovCZTbkuRRxdOd0bYQAAo22W2NLw9hhlAkh0buKM3k9MRpW2DreUgOyULYsukS16hXOKcSjQ/pub?embedded=true"
                  // scrolling="no"
                  frameBorder="no"
                  height="520px"
                  width="100%"
                />
                {/* </Modal.Description> */}
              </Modal.Content>
              <Modal.Actions className="center">
                {/* <Button
                                // disabled={
                                //   this.state.policyButtonAvailable
                                //     ? false
                                //     : true
                                // }
                                // className={this.state.policyButtonAvailable ?'' :  'disabled' }
                                onClick={() =>
                                  this.setState({
                                    openPolicyModal: false,
                                    // privatPolicyAcepted: true,
                                  })
                                }
                              >
                                I have read and agree
                              </Button> */}
              </Modal.Actions>
            </Modal>
            for more info.
          </span>
          {/* </div> */}
        </CookieConsent>

        <Modal
          size="mini"
          closeIcon={<div className="close-container"></div>}
          className={this.state.darkTheme ? "customModal dark" : "customModal"}
          centered={true} //false
          open={this.state.unverifiedEmailModal}
          onClose={() => this.setState({ unverifiedEmailModal: false })}
          onOpen={() => this.setState({ unverifiedEmailModal: true })}
          // trigger={
          //   <button
          //     className="menu-itm middle-itm" //disabled
          //   // id="navigateToConnectslackID"
          //   // to="/connectslack"
          //   // name="connectslack"
          //   // onClick={(e) => this.handleItemClick("connectslack")}
          //   >
          //     <div
          //       className="connect-container" //disabled
          //     ></div>
          //     {/* <span>
          //       {this.isMobileDevice
          //         ? ""
          //         : "Connect my Slack space to Pavooq"}
          //     </span> */}
          //   </button>
          // } //{<Button>Show Modal</Button>}
        >
          <Modal.Header>Email confirmation</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              We sent you an email confirmation, please check it to proceed.
              {/* {this.state.errorText ? this.state.errorText : ""} */}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions className="center">
            <Button
              className="widest"
              onClick={() => this.setState({ unverifiedEmailModal: false })}
            >
              Got it
            </Button>
          </Modal.Actions>
        </Modal>
        {/* </div>
        } */}
        {this.errorContainerRender()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  individualAccumulativeDataByClickedNode:
    state.tools.individualAccumulativeDataByClickedNode,
  individualAccumulativeDataByClickedAndDropdownNode:
    state.tools.individualAccumulativeDataByClickedAndDropdownNode,
  nestedAccObjectOfSendersAndRecieversArr:
    state.tools.nestedAccObjectOfSendersAndRecieversArr,
  // clickedNodeId: state.tools.clickedNodeId
});
const mapDispatchToProps = {
  setIndividualAccumulativeDataByClickedNode,
  setIndividualAccumulativeDataByClickedAndDropdownNode,
  setNestedAccObjectOfSendersAndRecieversArr,
  setClickedNode,
  setHoveredNode,
  clearIndividualAccumulativeDataByClickedNode,
  setDropdownSelectedItemForClickedUser,
  clearIndividualAccumulativeDataByClickedAndDropdownNode,
  setIsMobileDevice,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
// export default withRouter(App);
// export default withRouter(App);
