import { configureStore } from '@reduxjs/toolkit'
import toolsReducer from './toolsSlice'

// export const store = configureStore({
export default configureStore({
  // If this is a single function, it will be directly used as the root reducer for the store.
  // If it is an object of slice reducers, like {users : usersReducer, posts : postsReducer}, 
  // configureStore will automatically create the root reducer by passing this object to the Redux combineReducers utility.
  reducer: {
    tools: toolsReducer,
  },
})


// import { combineReducers, createStore } from "@reduxjs/toolkit";
// import counterReducer from "./counterSlice";

// // assume that the counter slice will be combined with other slices
// const reducer = combineReducers({
//   counter: counterReducer
// });

// // create the store from the combined reducer
// const store = createStore(reducer);
// or 
// const store = configureStore({reducer});

// export default store;

// // typescript type for the combined state
// export type State = ReturnType<typeof reducer>;
