import React, { Component } from "react";
import { Link } from "react-router-dom"; //withRouter
import { Button } from "semantic-ui-react"; //Checkbox, Popup, Input
import "./GoogleAuthError.sass";
// import slackIcon from "../../img/slack-icon.svg";//"../../img/slack-icon.svg"

export default class GoogleAuthError extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    document.title = "Pavooq Google Auth Error";
  }

  render() {
    return (
      <div
        className={
          this.props.isAuthenticated
            ? "GoogleAuthError"
            : "GoogleAuthError unautetificated"
        }
      >
        <div className="slack-connection-wrapper">
          <Link id="comeBackToLogin" className="logo-medium-container" to={`/`}>
            <div className="logo-big-container"></div>
          </Link>
          <h2>Google authentication Error</h2>

          <div className="conent">
            <div className="error-message-container">
              <div className="avatar-empty-container"></div>
              <p>Oops! Something went wrong...</p>
            </div>
            <div className="button-container">
              <Button
                className="big-button"
                as={Link}
                to="/login"
                // target="_self"
              >
                Try again
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
