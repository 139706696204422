// "use strict";
import React, { memo, useMemo, useRef, useEffect } from 'react';
// import PropTypes from 'prop-types';
import * as d3 from "d3";
// import './D3forceChart.css';
import "./D3forceChart.sass";
import helpers from "../helpers";
// import { logDOM } from '@testing-library/react';
import userIcon from "../img/avatar-empty.svg";
import userIconDark from "../img/avatar-empty-dark.svg";
import clock from "../img/clock.svg";
import clockSelected from "../img/clock-selected.svg";
import clockDark from "../img/clock-dark.svg";
import clockSelectedDark from "../img/clock-selected-dark.svg";

import letter from "../img/letter.svg";
import letterSelected from "../img/letter-selected.svg";
import letterDark from "../img/letter-dark.svg";
import letterSelectedDark from "../img/letter-selected-dark.svg";

import archive from "../img/archive.svg";
import archiveSelected from "../img/archive-selected.svg";
import archiveDark from "../img/archive-dark.svg";
import archiveSelectedDark from "../img/archive-selected-dark.svg";
// import { Popup } from "semantic-ui-react";

import { useDispatch, useSelector } from "react-redux";
import { setClickedNode, setHoveredNode } from "../redux/toolsSlice";
// import { logRoles } from '@testing-library/dom';
// import { logRoles } from '@testing-library/dom';


const D3forceChart = memo((props) => {

  const dispatch = useDispatch();
  const vizRef = useRef(null);
  const zoomDimensionRef = useRef(null);
  const zoomOutRef = useRef(null);
  const zoomInRef = useRef(null);
  const plusMinusContainerRef = useRef(null);
  const zoomPannelRef = useRef(null);


  const isMobileDevice = useSelector(state => state.tools.isMobileDevice);
  const clickedNode = useSelector(state => state.tools.clickedNode);
  const hoveredNode = useSelector(state => state.tools.hoveredNode);
  const clickedNodeId = useSelector(state => state.tools.clickedNodeId);
  const hoveredNodeId = useSelector(state => state.tools.hoveredNodeId);

  let that = useMemo(() => {
    let that = {};
    that.toogleNode = undefined;
    that.clickNode = undefined;
    that.isMobileDevice = helpers.isMobileDevice();
    that.zoomDemention = 100;
    that.isZoombuttonVisible = false;
    that.firstCalc = true;
    that.graphClick = true;
    that.clickFromGrapth = false;
    that.clickedNodeId = clickedNodeId;
    that.nodeByID = {};
    that.formatTime = d3.timeFormat(" %H:%M %b %e, %Y");
    // that.isntFirstRender = false;
    that.gridChartCondition = props.gridChartCondition;
    return that;
  }, []);


  // let nodes = useMemo(() => {
  //   console.log("useMemo nodes");
  //   // return JSON.parse(JSON.stringify(props.nodes));
  //   return props.nodes;
  // }, [props.nodes]);


  // let links = useMemo(() => {
  //   // return JSON.parse(JSON.stringify(props.links));
  //   return props.links;
  // }, [props.links]);


  useEffect(() => { // componentDidMount
    // (new D3ForceChart).componentDidMount
    // that.init();
    // that.draw(props.data);
    // that.updateData(props.data);
    // console.log("componentDidMount",props);
    // console.log("D3 FORRCE CHART componentDidUpdate ", props);
    that.clickNode = undefined;
    chart(
      props.nodes,
      props.links,
      props.relationSelected,
      props.darkTheme
    );
    window.addEventListener("resize", onResize, false);
    return () => { // componentWillUnmount
      window.removeEventListener("resize", onResize, false);
    }
  }, []);


  useEffect(() => { // componentDidUpdate
    // console.log("componentDidUpdate", props.nodes, props.links, props.relationSelected, props.darkTheme, props.gridChartCondition);
    chart(
      props.nodes,
      props.links,
      props.relationSelected,
      props.darkTheme
    )
  }, [props.nodes, props.links, props.relationSelected, props.darkTheme, props.gridChartCondition]);

  useEffect(() => { // componentDidUpdate
    that.gridChartCondition = true;
  }, [props.relationSelected, props.darkTheme]);


  useEffect(() => { // componentDidUpdate
    // console.log("[clickedNode, hoveredNode]", that.toogleNode);
    if (that.toogleNode) {
      that.toogleNode(clickedNode, hoveredNode);
    }
  }, [clickedNodeId, hoveredNodeId]);


  useEffect(() => { // componentDidUpdate
    // console.log("[clickedNode, hoveredNode]", that.toogleNode);
    if (that.clickNode && clickedNode) {
      that.clickNode(that.nodeByID[clickedNodeId] || clickedNode);
      that.clickedNodeId = clickedNodeId;
    }
  }, [clickedNodeId]);

  // shouldComponentUpdate(nextProps, nextState) {
  //   // console.log("shouldComponentUpdate", nextProps.graphClick, that.graphClick);
  //   that.graphClick = nextProps.graphClick; // ? true : that.graphClick;
  //   // console.log("ForcePage, shouldComponentUpdate", !(nextProps.nodes === props.nodes && nextProps.links === props.links && nextProps.relationSelected === props.relationSelected))
  //   if (nextProps.relationSelected !== props.relationSelected) {
  //     // console.log("shouldComponentUpdate");
  //     that.gridChartCondition = true; // to get old position
  //   }
  //   if (nextProps.darkTheme !== props.darkTheme) {
  //     // console.log("shouldComponentUpdate");
  //     that.gridChartCondition = true; // to get old position
  //   }
  //   return !(
  //     nextProps.nodes === props.nodes &&
  //     nextProps.links === props.links &&
  //     nextProps.relationSelected === props.relationSelected &&
  //     nextProps.darkTheme === props.darkTheme &&
  //     nextProps.gridChartCondition === props.gridChartCondition
  //   ); // && nextProps.tutorialVIsible === props.tutorialVIsible
  // }

  let onResize = () => {
    chart(
      props.nodes,
      props.links,
      props.relationSelected,
      props.darkTheme
    );
  };

  let inviteSlackUsers = (data) => {
    let invitedUser = data;
    let sentInvitesByWorkSpace = {
      [props.workSpaceID]: invitedUser,
    };

    let userUpdageBody = {
      // message: "",
      members: sentInvitesByWorkSpace || {},
    };
    // console.log("invitedUser", invitedUser);
    // if (that.state.email && that.state.password) {
    fetch(`${props.apiUrl}/providers/slack/invite`, {
      // old "customers"
      method: "POST",
      headers: {
        // Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include", //'same-origin',
      body: JSON.stringify(userUpdageBody),
    })
      // .then((resp) => {
      //   if(!resp.ok)
      // })
      .then((res) => {
        //     isApiSending respondSuccessfull error
        // console.log("res", res, res.ok);
        if (res.ok) {
          // console.log("inviteSlackUsers ok", res);
          return res.json();
        } else {
          // props.toggleLoading();
          if (res.status === 409) {
            // console.log("inviteSlackUsers ERROR", res);
          } else if (res.status === 401) {
            // console.log("unaunteficated slack");
            // that.handleLogout();
            props.pavooqAccessRefresh();
          } else {
            // console.log("inviteSlackUsers ERROR", res);
          }
        }
      })
      .then((response) => {
        props.invitedUserHolder(invitedUser);
      })
      .catch((e) => {
        console.log("inviteSlackUsers ERROR", e);
        // props.toggleLoading();
      });
  };

  let chart = (_nodes_data, _links_data, relationSelected, darkTheme) => {
    let nodes_data = JSON.parse(JSON.stringify(_nodes_data));
    let links_data = JSON.parse(JSON.stringify(_links_data));
    that.nodeByID = nodes_data.reduce((acc, el) => {
      acc[el.id] = el;
      return acc;
    }, {});

    const zoomDimension = zoomDimensionRef && zoomDimensionRef.current ? zoomDimensionRef.current : document.querySelector("#zoomDimensionDiv");
    const plusMinusContainer = plusMinusContainerRef && plusMinusContainerRef.current ? plusMinusContainerRef.current : document.querySelector("#plusMinusContainer");
    const zoomIn = zoomInRef && zoomInRef.current ? zoomInRef.current : document.querySelector("#zoom_in");
    const zoomOut = zoomOutRef && zoomOutRef.current ? zoomOutRef.current : document.querySelector("#zoom_out");
    const zoomPannel = zoomPannelRef && zoomPannelRef.current ? zoomPannelRef.current : document.getElementById("zoomPannel");
    const element = vizRef && vizRef.current ? vizRef.current : document.getElementById("forceSvg");
    let zoomLevelIndex = 7;

    // var old_k = 1;
    // var zoomFactor = 2;
    const zoomLevels = [
      5, 10, 15, 20, 33, 50, 75, 100, 125, 150, 175, 200, 250, 300,
    ];
    const reversedZoomLevels = zoomLevels.slice(0, 8).reverse();
    // let staticNodesField = "static"; // direct // mentions // all // private
    let radius = that.isMobileDevice ? 36 : 56; // diameter
    let positions = helpers.getPositions();
    // console.log("positions", positions); // let positions = helpers.resetPositions();

    d3.select(element).selectAll("*").remove();
    d3.selectAll(".tooltip").selectAll("*").remove();
    //create somewhere to put the force directed graph

    let width = element.offsetWidth;
    // console.log("element.offsetHeight", element.offsetHeight, "element.offsetWidth", element.offsetWidth);
    let height = element.offsetHeight; // - 72;//that.isMobileDevice ? 550 : 838;
    let initTransform = props.chartScalePositionHolder();
    // let reTransform = {};
    initTransform.x =
      initTransform.x === undefined
        ? (1 - initTransform.k) * (width / initTransform.k) || 0
        : initTransform.x; // ONLY k = 1, k = 2
    initTransform.y =
      initTransform.y === undefined
        ? (1 - initTransform.k) * (height / initTransform.k) || 0
        : initTransform.y; // ONLY k = 1, k = 2
    // console.log("chart transform", initTransform);

    d3.select(zoomPannel)
      .on("mouseover", () => {
        // console.log("zoomPannel mouseOver");
        if (d3.event) {
          d3.event.stopPropagation();
        }
        // return plusMinusContainer.style.display="flex"
        that.isZoombuttonVisible = true;
        plusMinusVisSwither(that.isZoombuttonVisible);
      })
      .on("mouseout", () => {
        // console.log("zoomPannel mouseLeave");
        if (d3.event) {
          d3.event.stopPropagation();
        }
        // return plusMinusContainer.style.display="none"
        that.isZoombuttonVisible = false;
        // if (that.zoomDemention === '100%') {
        // setTimeout(() => {
        plusMinusVisSwither(that.isZoombuttonVisible);
        // }, 300);
        // }
        // }, 300);
        // plusMinusVisSwither(that.isZoombuttonVisible);
      });

    //add zoom capabilities
    var zoom_handler = d3
      .zoom()
      // .scale(props.chartScalePositionHolder.k)
      // .translate([props.chartScalePositionHolder.x, props.chartScalePositionHolder.y])
      .scaleExtent([0.05, 3])
      .on("zoom", zoom_actions)
      // .on("dblclick.zoom", null)
      .on("start", () => {
        svg.attr("cursor", "grabbing"); //svg.attr("cursor","grabbing")
      })
      .on("end", () => {
        svg.attr("cursor", "grab");
      });
    // Define the div for the tooltip
    var tooltip = d3
      .select("#tooltip")
      // .append("div")
      // .attr("class", "tooltip")
      .style("opacity", 0)
      .style("display", "none")
      .style("z-index", 0)
      .on("mouseover", (d) => {
        // console.log("ONHOVER DATA ", d);
        if (that.tooltipTimeout) {
          clearTimeout(that.tooltipTimeout);
        }
      })
      .on("mouseleave", () => {
        if (that.tooltipTimeout) {
          clearTimeout(that.tooltipTimeout);
        }
        // that.tooltipTimeout = setTimeout(() => {

        tooltip
          .transition()
          .duration(500)
          .style("opacity", 0)
          .style("display", "none")
          .style("z-index", 0);

        // }, 1000);
      });

    var svg = d3
      .select(element)
      .append("svg")
      .attr("class", "svg")
      .attr("width", width)
      .attr("height", height)
      .call(zoom_handler)
      // d3.select(zoomPannel)
      .on("dblclick.zoom", null) // doesnt work
      .on("click", svgClick);
    var gMain = svg.append("svg:g");
    //  .attr("transform",`translate(${height / 4},${width / 4}) scale(.4,.4)`)// scalable initial zoom
    // .on("dblclick.zoom", null)// doesnt work
    // Adds zoom functionality
    // .on("mouseup",()=>console.log("mouseup"))//svg.attr("cursor","grab"))
    // .on("mousedown",()=>console.log("mouseup"))//svg.attr("cursor","grabbing"))
    // .call(zoom_handler.transform, d3.zoomIdentity.translate(width/2,height/2)
    // .scale(props.chartScalePositionHolder.k)
    // .translate(props.chartScalePositionHolder.x, props.chartScalePositionHolder.y));

    // // remove any previous graphs
    // svg.selectAll('.gMain').remove();

    // var gMain = svg.append('g')
    //   .classed('gMain', true);

    // svg.call(zoom_handler);
    // svg.transition().duration(500)
    //     // .call(zoom_handler.transform, d3.zoomIdentity.translate(width/2,height/2)
    //     .call(zoom_handler, d3.zoomIdentity.translate(width/2,height/2)
    //     .scale(props.chartScalePositionHolder.k)
    //     .translate(props.chartScalePositionHolder.x, props.chartScalePositionHolder.y));
    // let transform = d3.zoomIdentity.scale(initTransform.k || 1).translate(initTransform.x || 0, initTransform.y || 0);
    // console.log("transform from", initTransform);
    // console.log("real transform", d3.zoomIdentity.translate(initTransform.x || 0, initTransform.y || 0).scale(initTransform.k || 1));
    // svg.call(zoom_handler.transform, transform.translate(initTransform.x || 0, initTransform.y || 0)); // Calls/inits handleZoom
    svg.call(
      zoom_handler.transform,
      d3.zoomIdentity
        .translate(initTransform.x || 0, initTransform.y || 0)
        .scale(initTransform.k || 1)
    ); // Calls/inits handleZoom

    //Zoom functions
    function zoom_actions() {
      if (!that.graphClick) {
        that.graphClick = true;
        // console.log("0");
        props.handleGraphClick(true);
      }
      let { transform } = d3.event; // || event; // old

      // if( type !== 'zoom' ){
      //   console.log("NOT zoom", type, d3.event);
      // }else{
      //   console.log("event", type, {...transform}, initTransform);
      // }

      // if( d3.event.type === "zoom"){
      // svg.attr("cursor","grabbing")
      // }
      if (isNaN(transform.k)) {
        return;
      }
      // set zoom step ///////
      // var delta =  transform.k - old_k;

      // transform.k = old_k + zoomFactor*delta
      // // svg.attr("transform", d3.event.transform);
      // old_k = transform.k
      // console.log("transform", transform.k);
      ///////

      props.chartScalePositionHolder({ ...transform });
      // transform.k = isNaN(transform.k) ? initTransform.k : transform.k;
      // transform.x = isNaN(transform.x) ? initTransform.x : transform.x;
      // transform.y = isNaN(transform.y) ? initTransform.y : transform.y;
      // reTransform = { ...transform };

      zoomLevels.forEach((d, i) => {
        if (d / 100 <= transform.k) {
          zoomLevelIndex = i;
        }
      });

      // let gMainWidth = svg.node().getBBox();
      // console.log("gMain width: ", gMainWidth);
      // console.log("zoomLevelIndex", zoomLevelIndex);

      //   console.log("d3.event", event, d3.event, "d3.event.transform ", transform)
      // console.log("d3.event", d3.event, "d3.event.transform ", d3.event.transform, "(d3.event.transform.k * 100).toFixed()", (d3.event.transform.k * 100).toFixed());
      that.zoomDemention = ((transform.k || 1) * 100).toFixed() + "%";
      zoomDimension.innerText = that.zoomDemention;
      if (that.zoomDemention === "100%") {
        // plusMinusContainer.style.opacity=0
        // setTimeout(() => {
        // plusMinusContainer.style.display="none"
        // that.isZoombuttonVisible = false;
        // }, 250);
      } else {
        // plusMinusContainer.style.display="flex"
        // that.isZoombuttonVisible = false;
        // setTimeout(() => {
        // plusMinusContainer.style.opacity=1
        // }, 250);

        // console.log("that.zoomDemention", that.zoomDemention);
        d3.select(zoomIn).classed(
          "disabled",
          () => that.zoomDemention === "300%"
        );
      }

      d3.select(zoomOut).classed("disabled", that.zoomDemention === "5%");

      // plusMinusVisSwither(that.isZoombuttonVisible);
      // console.log("transform", transform);
      // that.zoomDemention = (d3.event.transform.k * 100).toFixed();
      // props.chartScalePositionHolder({ ...transform });
      gMain.attr("transform", transform);
    }

    d3.select("#zoom_in").on("click", function () {
      // Smooth zooming
      zoomLevelIndex =
        zoomLevelIndex + 1 < zoomLevels.length
          ? zoomLevelIndex + 1
          : zoomLevels.length - 1;
      let level = zoomLevels[zoomLevelIndex];
      //   console.log("zoom_in zoomLevelIndex ", zoomLevelIndex, " level ", level);
      // zoom_handler.scaleBy(svg.transition().duration(750), 1.3);
      // zoom_handler.scaleBy(svg.transition().duration(750), level / 100);
      // props.chartScalePositionHolder({ ...d3.event.transform, k: level / 100 });
      zoom_handler.scaleTo(svg.transition().duration(250), level / 100);
      // let transform = d3.zoomIdentity.scale(level / 100).translate(props.chartScalePositionHolder.x || 0, props.chartScalePositionHolder.y || 0);
      // svg.call(zoom_handler.transform, d3.zoomIdentity.scale(level / 100));
      // svg.transition().duration(250).call(zoom_handler.scaleTo, level / 100);
    });

    d3.select("#zoom_out").on("click", function () {
      // Ordinal zooming
      zoomLevelIndex = zoomLevelIndex - 1 <= 0 ? 0 : zoomLevelIndex - 1;
      let level = zoomLevels[zoomLevelIndex];
      //   console.log("zoom_out zoomLevelIndex ", zoomLevelIndex, " level ", level);
      // zoom_handler.scaleBy(svg, 1 / 1.3);
      // zoom_handler.scaleBy(svg, (level / 100));
      // props.chartScalePositionHolder({ ...d3.event.transform, k: level / 100 });
      zoom_handler.scaleTo(svg.transition().duration(250), level / 100);
      // svg.transition().duration(250).call(zoom_handler.scaleTo, level / 100);
      // svg.call(zoom_handler.transform, d3.zoomIdentity.scale(level / 100));
    });

    d3.select("#zoomDimensionDiv").on("click", () => {//dblclick
      // props.chartScalePositionHolder({ k: 1, x: 0, y: 0 });

      // d3.select(zoomIn).classed(
      //   "disabled",
      //   () => that.zoomDemention !== "300%" || that.zoomDemention !== "5%"
      //   );
      //   d3.select(zoomOut)
      //   .classed(
      //   "disabled",
      //   () => that.zoomDemention !== "300%" || that.zoomDemention !== "5%"
      //   );

      zoomLevelIndex = 7;
      svg.call(
        zoom_handler.transform,
        d3.zoomIdentity.scale(1).translate(0, 0)
      );

      d3.select(zoomIn).classed(
        "disabled",
        () => that.zoomDemention !== "100%"
      );
    });

    // console.log("relationSelected", relationSelected);
    //calculates the array of messages, to define the scales for a line width
    let messagesArray = links_data.map((d) => {
      // console.log("relationSelected d",d.values);
      return d.values[relationSelected];
    });
    messagesArray.sort((a, b) => a - b);
    // console.log("messagesArray", messagesArray);

    let sortedLinks = links_data.filter((d) => {
      // console.log("d.values[relationSelected]",d.values[relationSelected] , 'relationSelected',relationSelected );
      return d.values[relationSelected]; // !== undefined && d.values[relationSelected] !== 0
      // return d.values["private"]; // !== undefined && d.values[relationSelected] !== 0
    });
    // console.log("sortedLinks", sortedLinks);

    var lineWidthScale = d3
      .scaleLinear()
      // .domain([0, d3.max(messagesArray, d => d)])
      .domain(d3.extent(messagesArray, (messagesArray, (d) => d)))
      .range([0.5, 8]);

    // console.log("D3FORCE CHART", nodes_data, links_data);

    let distance = radius + radius / 2;
    let links_data_filtered = links_data.filter((d) => d.values["private"]);
    let minMsgCount = d3.min(links_data_filtered, (d) => d.values["private"]); // || 0;
    let maxMsgCount = d3.max(links_data, (d) => d.values["private"]); // || 0;
    // console.log("maxMsgCount", maxMsgCount, "minMsgCount", minMsgCount);
    // let minMaxMsgCount = d3.extent([links_data, d => d.values["private"]]);
    var nodeDistanceScale = d3
      .scaleSqrt() // .scaleSqrt() // .scaleLinear() .scalePow() // .scaleLog()
      // .domain([0, d3.max(messagesArray, d => d)])
      .domain([maxMsgCount, minMsgCount])
      .range([distance, +(distance * Math.max(+nodes_data.length / 4, 4))]); //, 1000 //(((+nodes_data.length || 1) / 4) > 4 ? ((+nodes_data.length || 1) / 4) : 4)]);// radius * 4]); // 100 => 10, 144 => 12
    // 1,100,200 > 1,10,14
    // console.log("nodes_data.length / 4", nodes_data.length / 4);
    // var weightScale = d3.scaleLinear()
    //   .domain(d3.extent(messagesArray, (messagesArray, d => d)))
    //   .range([.1, 1]);

    // var linkStrengthScale = d3.scaleLinear().range([0, 0.45]);

    // linkStrengthScale.domain(
    //   d3.extent(sortedLinks, function (d) {
    //     if (
    //       d &&
    //       d.values[relationSelected] &&
    //       d.values[relationSelected] !== 0
    //     ) {
    //       // console.log("d.values", d.values);
    //       return d.values[relationSelected];
    //     }
    //   })
    // );

    //add forces
    //we're going to add a charge to each node
    //also going to add a centering force
    //and a link force
    // var link_force = d3.forceLink(links_data).id(d => d.id).distance((d) => nodeDistanceScale(d.values[relationSelected]) || radius);//.strength();
    var link_force = d3
      .forceLink(links_data)
      // .force("links", link_force);
      .id((d) => d.id)
      // .strength((d) => nodeDistanceScale(d.values["private"]) || null)
      .distance((d) => {
        // console.log("d distance", d && d.values ? d.values["private"] : d, "");
        // console.log("nodeDistanceScale(d.values[private]) || nodeDistanceScale(maxMsgCount)", nodeDistanceScale(d.values["private"]) || nodeDistanceScale(maxMsgCount));
        return (
          nodeDistanceScale(d.values["private"]) ||
          nodeDistanceScale(maxMsgCount)
        );
      }); //.strength();relationSelected
    // console.log("width / 2, height / 2", width / 2, height / 2, nodeDistanceScale.domain(), "props.gridChartCondition", props);
    // nodes_data.sort((a,b) => b.contacts.length - a.contacts.length);

    const forceX = d3.forceX(width / 2).strength(0.025);
    const forceY = d3.forceY(height / 2).strength(0.025);

    var simulation = d3
      .forceSimulation()
      //add nodes
      .nodes(nodes_data)
      // .alphaTarget(0).restart()
      .force(
        "collide",
        d3.forceCollide().radius(distance).strength(2).iterations(10)
      )
      // .force("collide", d3.forceCollide().radius(radius * 1.5).iterations(10))
      .force("link", link_force)
      .force(
        "charge",
        d3
          .forceManyBody()
          .strength(-800)
          .distanceMax(2000) //2000
          .distanceMin(0)
      ) //.strength(1) //charge_force
      .force("center", d3.forceCenter(width / 2, height / 2)) //width / 2, height / 2 //center_force
      .force("attraceForce", d3.forceManyBody().strength(-1000)) //.force("attraceForce",d3.forceManyBody().strength(0));
      .force("x", forceX)
      .force("y", forceY);
    // .force("links", link_force);
    // .stop();//stop the simulation here
    //add tick instructions:
    let isNotVisible = true;
    // let maxX = -Infinity;
    // let maxY = -Infinity;
    let iterationsAmmount = 600;
    let gridChartCondition = that.gridChartCondition; // true; // true - set position by distance

    simulation.on("tick", tickActions);
    let timeout;
    // console.log("positions", positions, nodes_data);
    // let nodesWithContanct = nodes_data.filter(d => d.contacts.length !== 0);
    // let nodesWithoutContacts = nodes_data.filter(d => d.contacts.length === 0);
    for (let i = 0; i < iterationsAmmount; ++i) {
      isNotVisible = i < iterationsAmmount - 1;
      simulation.tick();
      // if (i === iterationsAmmount - 1) {
      if (timeout) {
        clearTimeout(timeout);
      }
      if (!isNotVisible) {
        timeout = setTimeout(() => {
          // console.log("setTimeout");
          gridChartCondition = false;
          if (positions.isEmpty) {
            // console.log("!!!!!!!!!!! !positions");
            positions.isEmpty = false;
            nodes_data.forEach((d, i) => {
              positions[d.id] = {
                ...d,
              };
            });
            //  let maxX = d3.max(nodesWithContanct, d => d.x);
            //  let maxY = d3.max(nodesWithContanct, d => d.y);
            //  console.log("nodesWithoutContacts", nodesWithoutContacts.length, nodes_data.length, nodesWithoutContacts.length !== nodes_data.length, maxX, maxY);
            //  if(nodesWithoutContacts.length !== nodes_data.length){ // if not all nodes without contacts
            //   nodes_data.forEach(d => {
            //     if (d.contacts.length === 0){
            //       d.x = maxX + distance;
            //       d.y = maxY + distance;
            //       maxX = d.x;
            //       maxY = d.y;
            //       // d.fx = d.x;
            //       // d.fy = d.y;
            //       positions[d.id] = {
            //         ...d,
            //       };
            //       console.log("change position", d, positions[d.id]);
            //     }
            //   });
            //  }
            // console.log("positions", positions);
          }
          // console.log("nodes_data ", nodes_data, ";   links_data ", links_data);
          // console.log("setTimeout");
        }, 500);
      }
      // }

      // // STATIC NODE FIELDS
      // timeout = setTimeout(() => {
      //   gridChartCondition = false;
      //   if (!that.positions[staticNodesField]) {
      //     that.positions[staticNodesField] = {};
      //     nodes_data.forEach((d, i) => {
      //       that.positions[staticNodesField][d.id] = {
      //         ...d
      //       };
      //     });
      //     // console.log("positions", that.positions);
      //   }
      //   // console.log("nodes_data ", nodes_data, ";   links_data ", links_data);
      //   // console.log("setTimeout");
      // }, 500);
      // //
    } // in ^5.8.x >>> simulation.tick(300);
    // console.log("relationselected: ", relationSelected, "   positions: ", positions, );
    // simulation.stop();

    var drag = d3
      .drag()
      .on("start", dragstart)
      .on("drag", dragged)
      .on("end", dragended);

    // zoom_handler(svg);
    // zoom_handler(gMain);

    let markerSize = 10;
    // create arrowhead link end

    let arrowMarker = svg.append("defs");
    let linkColors = ["#F7F7F7", "#000000", "#404040", "#D2CBDD"];

    let markerEnd = (color) => {
      // console.log("COLOR IS ", color);
      arrowMarker.append("marker")
        // .attr("id", "arrowhead")

        .attr("id", color.replace("#", ""))
        .attr("viewBox", "-0 -5 10 10") //the bound of the SVG viewport for the current SVG fragment. defines a coordinate system 10 wide and 10 high starting on (0,-5)
        .attr("refX", 0) // x coordinate for the reference point of the marker. If circle is bigger, that need to be bigger.
        .attr("refY", 0)
        .attr("orient", "auto")
        .attr("markerUnits", "userSpaceOnUse")
        .attr("markerWidth", markerSize) //markerSize
        .attr("markerHeight", markerSize) //markerSize
        // .attr("xoverflow", "visible")
        .append("path")
        .attr("d", "M 0,-5 L 10 ,0 L 0,5")
        .style("fill", color);
      return "url(" + color + ")";
    };
    linkColors.forEach(d => markerEnd(d));

    // let arrowMarker = defs
    //   .append('marker')
    //   .attr("id", 'arrowhead')
    //   .attr('viewBox', '-0 -5 10 10') //the bound of the SVG viewport for the current SVG fragment. defines a coordinate system 10 wide and 10 high starting on (0,-5)
    //   .attr('refX', 0) // x coordinate for the reference point of the marker. If circle is bigger, that need to be bigger.
    //   .attr('refY', 0)
    //   .attr('orient', 'auto')
    //   .attr("markerUnits", "userSpaceOnUse")
    //   .attr('markerWidth', markerSize)//markerSize
    //   .attr('markerHeight', markerSize)//markerSize
    //   .attr('xoverflow', 'visible')
    //   .append('path')
    //   .attr('d', 'M 0,-5 L 10 ,0 L 0,5')
    //   .attr('fill',d=>{
    //     // console.log("d");
    //     return props.darkTheme ? "#404040" : "#D2CBDD"; // was  #E4E1EA #9A8AB6
    //   } )
    //   .style('stroke', 'none')
    //   ;

    //draw lines for the links
    var link = gMain
      .append("g")
      .attr("class", "link")
      .selectAll("line")
      .data(sortedLinks)
      .enter()
      .append("line")
      // .style("stroke-dasharray",d=> !!(relationSelected === "all" && !d.doubleDirectedAll) || !!(relationSelected === "private" && !d.doubleDirectedPrivate) ? "5,5" : "")//dashed array for line
      .style("stroke-dasharray", (d) =>
        !!(d.source && relationSelected === "all" && !d.doubleDirectedAll) ||
          !!(
            d.source &&
            relationSelected === "private" &&
            !d.doubleDirectedPrivate
          )
          ? "5,5"
          : ""
      ) //dashed array for line
      // .style('marker-end',d=> !!(relationSelected === "all" && !d.doubleDirectedAll) || !!(relationSelected === "private" && !d.doubleDirectedPrivate) ?  'url(#arrowhead)': "none")
      .style("marker-end", (d) =>
        !!(relationSelected === "all" && !d.doubleDirectedAll) ||
          !!(relationSelected === "private" && !d.doubleDirectedPrivate)
          ? `url(#${props.darkTheme ? "404040" : "D2CBDD"})`//'url(#arrowhead)'//markerEnd(props.darkTheme ? "#404040" : "#D2CBDD") // [ "#F7F7F7","#000000","#404040","#D2CBDD"];
          : "none"
      )
      .style("opacity", 0)
      .attr("stroke-width", (d) => {
        // console.log("lineWidthScale(d.values[relationSelected])", d.values[relationSelected]);
        return lineWidthScale(d.values[relationSelected]);
      });

    // var link = svg
    //     // .append("g")
    //     // .attr("class", "linkG")
    //     .selectAll('.link')
    //     .data(sortedLinks)
    //     .enter()
    //     .append('path')
    //     .attr('class', 'link')
    //     .attr('stroke', "#ddd")
    //     .attr("stroke-width", (d) => {
    //         // console.log("lineWidthScale(d.values[relationSelected])", d.values[relationSelected]);
    //         return lineWidthScale(d.values[relationSelected])
    //     })
    // // .attr('marker-end', 'url(#arrowhead)')
    // ;

    //draw nodes for the links
    var node = gMain
      .append("g")
      .attr("class", "nodeG")
      .selectAll("g")
      .data(nodes_data)
      .enter()
      .append("g")
      .classed("node", true)
      .classed("fixed", (d) => d.fx !== undefined)
      .style("opacity", 0);

    node.call(drag);

    node.on("click", (d) => clickNode(d, true));

    var circleShadow = node
      .append("circle")
      .attr("r", radius / 1.89)
      .attr("class", "circle-shadow")
      // .attr('fill','red')
      .attr("filter", "url(#blur)")
      .style("visibility", "hidden");

    //draw circles for the nodes
    var backGroundCircle = node
      .append("circle")
      .attr("r", radius / 2)
      .attr("class", "backGroundCircle") // .on("mouseover", mouseOver)
      .style("fill", darkTheme ? "#131313" : "#F7F7F7")
      // .on("mouseout", mouseOut)
      // .style("opacity", 0.1)
      .on("mouseenter", (d) => {
        mouseOver(d);
        // console.log("mouseenter DATA ", d);
        if (d.granted) {
          // tooltip.style("opacity", 0);
          return;
        }
        if (that.tooltipTimeout) {
          clearTimeout(that.tooltipTimeout);
        }
        tooltip
          .transition()
          .duration(200)
          .style("opacity", 1)
          .style("z-index", 15)
          .style("display", "block");
        tooltip
          .html(
            !d.invitable
              ? `<p class="border-bottom">Сannot send an invitation</p>
            <span class="subtext">that user has limited access or has been deleted</span>`
              : !d.invited 
              // d.invited  < Date.now() - 7 days ? can invite this user again : cannot invite this user until the week passed since invitation time
                ? `<p class="border-bottom">The user wasn't invited</p>
            <hr class=${props.darkTheme ? "dark " : ""}/>
            <Button id="tooltip-button" class="${props.darkTheme ? "tooltip-button dark" : "tooltip-button"
                }">Click to invite</Button>`
                : d.invited
                  ? `<p>Waiting for user approval</p>
            <span class="subtext">Sent on ${d.invited
                    ? that.formatTime(new Date(d.invited * 1000))
                    : "undefined"
                  }</span>`
                  : ""
          )
          .style("width", "184px")
          // .style("min-height","48px")
          // .style("max-height","104px")
          .style("height", "fit-content")
          .style("height", "-moz-max-content")
          .style(
            "left",
            d3.event.clientX <= width / 2
              ? d3.event.clientX + 5 + "px"
              : "unset"
          )
          .style(
            "right",
            d3.event.clientX > width / 2
              ? width - d3.event.clientX + radius / 2 + "px"
              : "unset"
          )
          .style(
            "top",
            d3.event.clientY <= height / 2
              ? d3.event.clientY - 48 + "px"
              : "unset"
          )
          .style(
            "bottom",
            d3.event.clientY > height / 2
              ? height - d3.event.clientY + 48 + "px"
              : "unset"
          )
          // .style("left", d.x + 20 + initTransform.x * (initTransform.k - 1) + "px")
          // .style("top", d.y - 28 + initTransform.y * (initTransform.k - 1) + "px")
          // .style("transform", `translate(${initTransform.x}px, ${initTransform.y}px) scale(${initTransform.k})`)
          .style("background", props.darkTheme ? "#222222" : "#fff")
          .style("color", props.darkTheme ? "#bfbfbf" : "#000")
          .style("padding", "8px")
          .style("font-family", "Roboto")
          .style("font-style", "normal")
          .style("font-weight", "normal")
          .style("font-size", "12px")
          .style("line-height", "16px")
          .style("box-shadow", "0px 2px 12px rgba(0, 0, 0, 0.1)");

        d3.select("#tooltip-button").on("click", () => {
          let userObj = { [d.id]: d };
          // console.log("userObj", userObj);
          inviteSlackUsers(userObj);
        });
      })
      // .on("mousemove", (d) => {
      //   div
      //     .style("pointer-event", "none")
      //     .style("left", d.x + 20 + "px")
      //     .style("top", d.y - 28 + "px");
      // })
      .on("mouseleave", (d) => {
        mouseOut(d);
        if (that.tooltipTimeout) {
          clearTimeout(that.tooltipTimeout);
        }
        that.tooltipTimeout = setTimeout(() => {
          tooltip
            .transition()
            .duration(500)
            .style("opacity", 0)
            .style("display", "none");
        }, 200);
      });

    //draw circles for the nodes
    var circle = node
      .append("circle")
      .attr("r", radius / 2)
      .attr("class", "circle")
      .style("opacity", (d) => (d.granted ? 1 : 0));

    var defs = gMain.append("defs");
    defs
      // .append("g+\]
      .append("clipPath")
      .attr("id", "avatar-clip")
      .append("circle")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", radius / 2); //(radius / 2))

    //draw avatar for the nodes
    var avatar = node
      .append("image")
      .attr("class", "avatar")
      .attr("xlink:href", (d) =>
        d.avatar ? d.avatar : props.darkTheme ? userIconDark : userIcon
      )
      //https://github.com/favicon.ico
      .attr("x", (d) => (d.avatar ? -(radius / 2) : -(24 / 2))) // -(radius / 2))
      .attr("y", (d) => (d.avatar ? -(radius / 2) : -(24 / 2))) // -(radius / 2))
      .attr("width", (d) => (d.avatar ? radius : 24)) // radius)
      .attr("height", (d) => (d.avatar ? radius : 24)) // radius)
      .style("opacity", (d) => (d.granted ? 1 : 0.3))
      .attr("clip-path", "url(#avatar-clip)");

    var avatarUninvitable = node
      .append("image")
      .attr("class", "avatar-archive")
      .attr("xlink:href", props.darkTheme ? archiveDark : archive)
      //https://github.com/favicon.ico
      .attr("x", -(24 / 2)) // -(radius / 2))
      .attr("y", -(24 / 2)) // -(radius / 2))
      .attr("width", 24) // radius)
      .attr("height", 24) // radius)
      .style("opacity", (d) => (!d.invitable ? 1 : 0));
    // .attr("clip-path", "url(#avatar-clip)")

    //draw avatar for the nodes
    var avatarLetter = node
      .append("image")
      .attr("class", "avatar-letter")
      .attr("xlink:href", props.darkTheme ? letterDark : letter)
      //https://github.com/favicon.ico
      .attr("x", -(24 / 2)) // -(radius / 2))
      .attr("y", -(24 / 2)) // -(radius / 2))
      .attr("width", 24) // radius)
      .attr("height", 24) // radius)
      .style("opacity", (d) =>
        d.invitable && !d.granted && !d.invited ? 1 : 0
      );
    // .attr("clip-path", "url(#avatar-clip)")
    //draw avatar for the nodes
    var avatarClock = node
      .append("image")
      .attr("class", "avatar-clock")
      .attr("xlink:href", props.darkTheme ? clockDark : clock)
      //https://github.com/favicon.ico
      .attr("x", -(24 / 2)) // -(radius / 2))
      .attr("y", -(24 / 2)) // -(radius / 2))
      .attr("width", 24) // radius)
      .attr("height", 24) // radius)
      .style("opacity", (d) =>
        d.invitable && !d.granted && d.invited ? 1 : 0
      );
    // .attr("clip-path", "url(#avatar-clip)")

    var filter = gMain
      .append("defs")
      .append("filter")
      .attr("id", "blur")
      .append("feGaussianBlur")
      .attr("stdDeviation", 1.3);

    var lablelRectShadow = node
      .append("rect")
      .attr("class", "lablelRect-shadow")
      .attr("x", (d) => {
        if (d && d.name) {
          let name = d.name;
          let nameArr = name.split(/(\s+)/).filter(function (e) {
            return e.trim().length > 0;
          });
          let cuttedname =
            nameArr[0] + " " + (nameArr[1] || "").slice(0, 1) + ".";
          // console.log(getTextWidth(d.cuttedname, 12, 'Roboto'));
          return 0 - (getTextWidth(cuttedname, 12, "Roboto") / 2 + 8);
        } else {
          let name = d.id;

          let nameArr = name.split(/(\s+)/).filter(function (e) {
            return e.trim().length > 0;
          });
          let cuttedname =
            "ID: " + nameArr[0] + " " + (nameArr[1] || "").slice(0, 1);
          // console.log(getTextWidth(d.cuttedname, 12, 'Roboto'));
        }
      })
      .attr("y", 32)
      .attr("width", (d) => {
        if (d && d.name) {
          let name = d.name;
          let nameArr = name.split(/(\s+)/).filter(function (e) {
            return e.trim().length > 0;
          });
          let cuttedname =
            nameArr[0] + " " + (nameArr[1] || "").slice(0, 1) + ".";
          // console.log(getTextWidth(d.cuttedname, 12, 'Roboto'));
          return getTextWidth(cuttedname, 12, "Roboto") + 16;
        } else {
          let name = d.id;
          let nameArr = name.split(/(\s+)/).filter(function (e) {
            return e.trim().length > 0;
          });
          let cuttedname =
            "ID: " + nameArr[0] + " " + (nameArr[1] || "").slice(0, 1);
          // console.log(getTextWidth(d.cuttedname, 12, 'Roboto'));
          return getTextWidth(cuttedname, 12, "Roboto") + 16;
        }
      })
      .attr("height", 18)
      .style("display", (d) => (d.granted ? "block" : "none"))
      // .attr('fill','red')
      .attr("rx", 3)
      .attr("ry", 3)
      .attr("filter", "url(#blur)")
      .style("visibility", "hidden");

    var lablelRect = node
      .append("rect")
      .attr("class", "lablelRect")
      .attr("x", (d) => {
        if (d && d.name) {
          let name = d.name;
          let nameArr = name.split(/(\s+)/).filter(function (e) {
            return e.trim().length > 0;
          });
          let cuttedname =
            nameArr[0] + " " + (nameArr[1] || "").slice(0, 1) + ".";
          // console.log(getTextWidth(d.cuttedname, 12, 'Roboto'));
          return 0 - (getTextWidth(cuttedname, 12, "Roboto") / 2 + 8);
        } else {
          let name = d.id;
          let nameArr = name.split(/(\s+)/).filter(function (e) {
            return e.trim().length > 0;
          });
          let cuttedname =
            "ID: " + nameArr[0] + " " + (nameArr[1] || "").slice(0, 1);
          // console.log(getTextWidth(d.cuttedname, 12, 'Roboto'));
          return 0 - (getTextWidth(cuttedname, 12, "Roboto") / 2 + 8);
        }
      })
      .attr("y", 32)
      .attr("width", (d) => {
        if (d && d.name) {
          let name = d.name;
          let nameArr = name.split(/(\s+)/).filter(function (e) {
            return e.trim().length > 0;
          });
          let cuttedname =
            nameArr[0] + " " + (nameArr[1] || "").slice(0, 1) + ".";
          // console.log(getTextWidth(d.cuttedname, 12, 'Roboto'));
          return getTextWidth(cuttedname, 12, "Roboto") + 16;
        } else {
          let name = d.id;
          let nameArr = name.split(/(\s+)/).filter(function (e) {
            return e.trim().length > 0;
          });
          let cuttedname =
            "ID: " + nameArr[0] + " " + (nameArr[1] || "").slice(0, 1);
          // console.log(getTextWidth(d.cuttedname, 12, 'Roboto'));
          return getTextWidth(cuttedname, 12, "Roboto") + 16;
        }
      })
      .attr("height", 18)
      // .attr('fill','red')
      .attr("rx", 3)
      .attr("ry", 3)
      .style("display", (d) => (d.granted ? "block" : "none"));
    //   .attr("filter", "url(#blur)")
    //draw labels for the nodes
    var lablel = node
      .append("text")
      .attr("class", "lablel")
      .text((d) => {
        if (d && d.name) {
          let name = d.name;
          let nameArr = name.split(/(\s+)/).filter(function (e) {
            return e.trim().length > 0;
          });
          let cuttedname =
            nameArr[0] + " " + (nameArr[1] || "").slice(0, 1) + ".";
          // return cuttedname;
          return cuttedname; // change to cuttedname !!!
        } else {
          let name = d.id;
          let nameArr = name.split(/(\s+)/).filter(function (e) {
            return e.trim().length > 0;
          });
          let cuttedname =
            "ID: " + nameArr[0] + " " + (nameArr[1] || "").slice(0, 1);
          // return cuttedname;
          return cuttedname; // change to cuttedname !!!
        }
      })
      .attr("x", 0)
      .attr("y", 45);

    //draw messageCount for the nodes
    var messageRectShadow = node
      .append("rect")
      .attr("class", "messageRect-shadow")
      .attr("x", 17)
      .attr("y", -40)
      .attr("width", (d) => {
        return (
          getTextWidth(
            d.messageAmount[relationSelected], //.toFixed(),
            10,
            "Roboto"
          ) + 6
        );
      }) //d.values[relationSelected]
      .attr("height", 13)
      // .attr('fill','red')
      .attr("rx", 3)
      .attr("ry", 3)
      .style("display", (d) => (d.granted ? "block" : "none"))
      .attr("filter", "url(#blur)")
      .style("visibility", "hidden");

    var dialogueTriangleShadow = node
      .append("g")
      .attr("transform", "translate(20 -28)")
      .append("path")
      .attr("class", "dialogue-triangle-shadow")
      .attr("d", "M1 0 L0 5 L5 0 Z")
      .style("display", (d) => (d.granted ? "block" : "none"))
      .attr("filter", "url(#blur)")
      .style("visibility", "hidden");
    // .attr('fill', 'red')
    // .attr("stroke", "black")
    // .attr("stroke-width", 2)
    var messageRect = node
      .append("rect")
      .attr("class", "messageRect")
      .attr("x", 17)
      .attr("y", -40)
      .style("display", (d) => (d.granted ? "block" : "none"))
      .attr(
        "width",
        (d) =>
          getTextWidth(
            d.messageAmount[relationSelected], //.toFixed(),
            10,
            "Roboto"
          ) + 6
      ) //d.values[relationSelected]
      .attr("height", 13)
      // .attr('fill','red')
      .attr("rx", 3)
      .attr("ry", 3);
    //   .attr("filter", "url(#blur)")
    var dialogueTriangle = node
      .append("g")
      .attr("transform", "translate(20 -28)")
      .append("path")
      .attr("class", "dialogue-triangle")
      .attr("d", "M1 0 L0 5 L5 0 Z")
      .style("display", (d) => (d.granted ? "block" : "none"));
    //   .attr("filter", "url(#blur)")
    // .attr('fill', 'red')
    // .attr("stroke", "black")
    // .attr("stroke-width", 2)
    var messageCount = node
      .append("text")
      .attr("class", "messageCount")
      .text(function (d) {
        return d.messageAmount[relationSelected]; //.toFixed(); // d.values[relationSelected]
      })
      .attr("x", 20)
      .style("display", (d) => (d.granted ? "block" : "none"))
      .attr("y", -30);

    let setInitialZoom = (levelIndex) => {
      // reversedZoomLevels
      let level =
        levelIndex < reversedZoomLevels.length - 1
          ? reversedZoomLevels[levelIndex]
          : 0;
      if (level) {
        zoomLevelIndex = levelIndex;
        // console.log("svg: ",  transform);
        // initTransform = { x: 0, y: 0, k: level / 100 };
        // props.chartScalePositionHolder({ x: 0, y: 0, k: level / 100 });
        //   var transform = d3.zoomTransform(svg.node());
        // props.chartScalePositionHolder({ ...transform, k: level / 100 }); // need try later
        // let svg = d3.select(element).select('.svg');
        zoom_handler.scaleTo(
          svg,
          initTransform.calculatedK ? initTransform.k : level / 100
        ); //.delay(300).f;
        // svg.call(zoom_handler.transform, d3.zoomIdentity.scale(level / 100));
        // let transform = d3.zoomIdentity.scale(level / 100).translate(0, 0);
        // svg.call(zoom_handler.transform, d3.zoomIdentity.scale(level / 100).translate(0, 0));
        setTimeout(() => {
          let reset = true;
          let gMainWidth = svg.node().getBBox();
          // console.log("initial  gMain width: ", gMainWidth, ", reset", reset, ", level", level);
          // if ((gMainWidth.width + (radius / 2)) <= width && (gMainWidth.height + (radius / 2)) <= height) {
          if (gMainWidth.width <= width && gMainWidth.height <= height) {
            // currentSize > windowSize
            reset = false;
          }
          if (reset) {
            setInitialZoom(levelIndex + 1);
          } else {
            var transform = d3.zoomTransform(svg.node());
            initTransform.x = transform.x;
            initTransform.y = transform.y;
            // console.log("initTransform.calculatedK", initTransform.calculatedK);
            initTransform.k = initTransform.calculatedK
              ? initTransform.k
              : level / 100;
            initTransform.calculatedK = true;
            // initTransform = { ...transform, k: level / 100 };
            // props.chartScalePositionHolder(initTransform);
            // let x = Math.abs(width - gMainWidth.width) / 2 - gMainWidth.x;
            // let y = Math.abs(height - gMainWidth.height) / 2 - gMainWidth.y;
            // props.chartScalePositionHolder({ x, y, k: level / 100 });
            // svg.call(zoom_handler.transform, d3.zoomIdentity.scale(level / 100).translate(x, y));
          }
        }, 20); // intervals between innitial zoom iterations
        // console.log("BADABOOOM 2");
      }
    };
    setTimeout(() => {
      // let gMainWidth = gMain.node().getBBox();
      // console.log("initial  gMain width: ", that.firstCalc);
      if (that.firstCalc) {
        setInitialZoom(0); // 100 (0) => 75 (1) => ... => 5 (n-1)
        that.firstCalc = false;
      }
    }, 1000);

    function dragstart() {
      // console.log(props.graphClick, that.graphClick);
      if (!that.graphClick) {
        that.graphClick = true;
        // console.log("1");
        props.handleGraphClick(true);
      }

      // console.log("dragstart");
      // d3.select(that).classed("fixed", true);
      if (!d3.event.active) simulation.alphaTarget(0.1).restart();
      node.each(function (d) {
        d.fx = d.x;
        d.fy = d.y;
      });
    }

    function dragged(d) {
      if (!that.graphClick) {
        that.graphClick = true;
        // console.log("2");
        props.handleGraphClick(true);
      }
      // console.log("dragged", d3.event.clientX, d3.event.x);
      // d.fx = clamp(d3.event.x, 0 + radius, width - radius);
      // d.fy = clamp(d3.event.y, 0 + radius, height - radius);
      d.fx = d3.event.x;
      d.fy = d3.event.y;
      // simulation.alphaTarget(0).restart();
      tooltip
        .style("pointer-event", "none")
        .style("left", d3.event.sourceEvent.clientX + 20 + "px")
        .style("top", d3.event.sourceEvent.clientY - 28 + "px");
    }

    function dragended(d) {
      if (!that.graphClick) {
        that.graphClick = true;
        // console.log("3");
        props.handleGraphClick(true);
      }

      if (!d3.event.active) simulation.alphaTarget(0);
      // d.fx = null;
      // d.fy = null;
      d.fx = d.x;
      d.fy = d.y;
      // simulation.stop();
    }

    function tickActions() {
      // console.log("tickActions")
      //update circle positions each tick of the simulation
      // console.log("isNotVisible",isNotVisible);
      node
        // .attr("cx", function(d) { return d.x; })
        // .attr("cy", function(d) { return d.y; });
        .attr("transform", (d) => {
          if (positions && positions[d.id]) {
            // console.log("if (positions...", gridChartCondition, ", >>> ", positions, !!positions, relationSelected);
            if (!gridChartCondition) {
              //drag
              positions[d.id].x = d.x;
              positions[d.id].y = d.y;
              // positions[d.id].fx = d.fx;
              // positions[d.id].fy = d.fy;
            } else {
              // relationSelected change
              d.x = positions[d.id].x;
              d.y = positions[d.id].y;
              // d.fx = positions[d.id].fx;
              // d.fy = positions[d.id].fy;
            }
            // d.vx = positions[d.id].vx;
            // d.vy = positions[d.id].vy;
          } else {
            if (gridChartCondition) {
              // console.log("tickActions gridChartCondition TRUE", d, d.x, d.y)
              d.x = Math.round(d.x / distance) * distance;
              d.y = Math.round(d.y / distance) * distance;
              // set node without contacts closer...
              // if (d.contacts.length !== 0) {
              //   maxX = d.x > maxX ? d.x : maxX;
              //   maxY = d.y > maxY ? d.y : maxY;
              // }
              // if (nodesWithoutContacts.length !== nodes_data.length && d.contacts.length === 0) {
              //   d.x = maxX + distance; // set the position for node without relations
              //   d.y = maxY;// + distance;
              //   maxX = d.x;
              //   maxY = d.y;
              // }
            }
          }

          // // console.log("tickActions  not gridChartCondition")
          // // d.x = clamp(d.x, radius, width - radius);
          // // d.y = clamp(d.y, radius, height - radius);
          // d.x = d.x;
          // d.y = d.y;
          return "translate(" + d.x + "," + d.y + ")";
        })
        .transition()
        .delay(10)
        .style("opacity", isNotVisible ? 0 : 1);
      //STATIC NODES FIELD
      // .attr("transform", (d) => {
      //   if (that.positions[staticNodesField] && that.positions[staticNodesField][d.id]) {
      //     // console.log("if (that.positions[staticNodesField]...", gridChartCondition, ", >>> ", that.positions, !!that.positions[staticNodesField], relationSelected);
      //     if (!gridChartCondition) {
      //       that.positions[staticNodesField][d.id].x = d.x;
      //       that.positions[staticNodesField][d.id].y = d.y;
      //       // that.positions[staticNodesField][d.id].fx = d.fx;
      //       // that.positions[staticNodesField][d.id].fy = d.fy;
      //     } else {
      //       d.x = that.positions[staticNodesField][d.id].x;
      //       d.y = that.positions[staticNodesField][d.id].y;
      //       // d.fx = that.positions[staticNodesField][d.id].fx;
      //       // d.fy = that.positions[staticNodesField][d.id].fy;
      //     }
      //     // d.vx = that.positions[staticNodesField][d.id].vx;
      //     // d.vy = that.positions[staticNodesField][d.id].vy;
      //   } else {
      //     if (gridChartCondition) {
      //       // console.log("tickActions gridChartCondition TRUE")
      //       let size = radius * 2;
      //       d.x = Math.round(d.x / size) * size;
      //       d.y = Math.round(d.y / size) * size;
      //     }
      //   }

      //   // console.log("tickActions  not gridChartCondition")
      //   d.x = clamp(d.x, radius, width - radius);
      //   d.y = clamp(d.y, radius, height - radius);
      //   return "translate(" + d.x + "," + d.y + ")";
      // })
      // .transition()
      // .delay(10)
      // .style("opacity", isNotVisible ? 0 : 1);
      //
      //update link positions
      //simply tells one end of the line to follow one node around
      //and the other end of the line to follow the other node around
      //         link
      // .attr("x1", function (d) {
      //   return d.source.x;
      // })
      // .attr("y1", function (d) {
      //   return d.source.y;
      // })
      // .attr("x2", function (d) {
      //   return d.target.x;
      // })
      // .attr("y2", function (d) {
      //   return d.target.y;
      // });

      link
        .each((d) => {
          // Total difference in x and y from source to target
          d.target.x = d.target.x || 0;
          d.target.y = d.target.y || 0;
          d.source.x = d.source.x || 0;
          d.source.y = d.source.y || 0;
          d.diffX = d.target.x - d.source.x;
          d.diffY = d.target.y - d.source.y;
          // d.target.radius = (lineWidthScale(d.target.value) + markerSize * 2) || 0;

          // Length of path from center of source node to center of target node
          d.pathLength = Math.sqrt(d.diffX * d.diffX + d.diffY * d.diffY);

          // x and y distances from center to outside edge of target node
          d.offsetX = (d.diffX * radius) / d.pathLength || 0; //d.target.radius
          d.offsetY = (d.diffY * radius) / d.pathLength || 0; //d.target.radius

          // d.offsetX = ((d.diffX * d.target.radius) / d.pathLength) || 0;
          // d.offsetY = ((d.diffY * d.target.radius) / d.pathLength) || 0;
        })
        .attr("x1", (d) => {
          return d.source.x || 0;
        })
        .attr("y1", (d) => {
          return d.source.y || 0;
        })
        .attr("x2", (d) =>
          !!(d.source && relationSelected === "all" && !d.doubleDirectedAll) ||
            !!(
              d.source &&
              relationSelected === "private" &&
              !d.doubleDirectedPrivate
            )
            ? d.target.x - d.offsetX || 0
            : d.target.x || 0
        )
        .attr("y2", (d) =>
          !!(d.source && relationSelected === "all" && !d.doubleDirectedAll) ||
            !!(
              d.source &&
              relationSelected === "private" &&
              !d.doubleDirectedPrivate
            )
            ? d.target.y - d.offsetY || 0
            : d.target.y || 0
        )
        .transition()
        .delay(10)
        .style("opacity", isNotVisible ? 0 : 1);

      // link.attr("d", function (d) {

      //     if (d.source.x === d.target.x || d.source.y === d.target.y) {
      //         return "M" + d.source.x + "," + d.source.y + "L" + d.target.x + "," + d.target.y;
      //     }
      //     var offset = 100;

      //     var midpoint_x = (d.source.x + d.target.x) / 2;
      //     var midpoint_y = (d.source.y + d.target.y) / 2;

      //     var dx = (d.target.x - d.source.x);
      //     var dy = (d.target.y - d.source.y);

      //     var normalise = Math.sqrt((dx * dx) + (dy * dy));

      //     var offSetX = midpoint_x + offset * (dy / normalise);
      //     var offSetY = midpoint_y - offset * (dx / normalise);
      //     return "M" + d.source.x + "," + d.source.y +
      //         "S" + offSetX + "," + offSetY +
      //         " " + d.target.x + "," + d.target.y;
      // });
    }
    // prevents nodes from being dragged outside the script boundaries
    // function clamp(x, lo, hi) {
    //   return x < lo ? lo : x > hi ? hi : x;
    // }

    var linkedById = {};
    sortedLinks.forEach(function (d) {
      linkedById[d.source.id + "," + d.target.id] = 1;
    });

    // function isConnected(a, b) {
    //   return a && b
    //     ? linkedById[a.id + "," + b.id] ||
    //     linkedById[b.id + "," + a.id] ||
    //     a.id === b.id
    //     : false;
    // }

    // var clickToggler = false;
    // var that.wasClicked = false;

    // drag_handler(node)
    function toogleNode(clickedNode, hoveredNode) {
      // var opacity = 0.3;
      let clickedNodeId = clickedNode ? clickedNode.id : undefined;
      let hoveredNodeId = hoveredNode ? hoveredNode.id : undefined;
      var isNodeExists = !!(clickedNode || hoveredNode);
      var selectedNode = clickedNode || hoveredNode || undefined;
      // node.style("stroke-opacity", function (o) {
      //   return isNodeExists &&
      //     (isConnected(hoveredNode, o) || isConnected(clickedNode, o))
      //     ? 1
      //     : opacity;
      // });
      node.style("stroke", function (o) {
        // console.log(clickedNode, hoveredNode,"EVENT NODE",o);
        return isNodeExists && selectedNode && selectedNode.id === o.id
          ? // (isConnected(hoveredNode, o) || isConnected(clickedNode, o))
          darkTheme
            ? "#F7F7F7"
            : "#000000"
          : darkTheme
            ? "#404040"
            : "#3D1D75";
      });

      backGroundCircle
        .style("fill", darkTheme ? "#131313" : "#F7F7F7")
        .style("stroke", "none");

      circle
        .style("stroke", function (o) {
          // console.log(isNodeExists,o);
          return isNodeExists && selectedNode && selectedNode.id === o.id
            ? // (isConnected(hoveredNode, o) || isConnected(clickedNode, o))
            darkTheme
              ? "#F7F7F7"
              : "#000000"
            : darkTheme
              ? "#404040"
              : "#3D1D75";
        })
        .style("stroke-width", function (o) {
          return isNodeExists && selectedNode && selectedNode.id === o.id
            ? // (isConnected(hoveredNode, o) || isConnected(clickedNode, o))
            2
            : 2;
        });

      //draw avatar for the nodes
      avatarClock.attr("xlink:href", function (o) {
        return isNodeExists && selectedNode && selectedNode.id === o.id
          ? // (isConnected(hoveredNode, o) || isConnected(clickedNode, o))
          darkTheme
            ? clockSelectedDark
            : clockSelected
          : darkTheme
            ? clockDark
            : clock;
      }); // props.darkTheme ? clockDark: clock);
      //draw avatar for the nodes
      avatarLetter.attr("xlink:href", function (o) {
        return isNodeExists && selectedNode && selectedNode.id === o.id
          ? // (isConnected(hoveredNode, o) || isConnected(clickedNode, o))
          darkTheme
            ? letterSelectedDark
            : letterSelected
          : darkTheme
            ? letterDark
            : letter;
      }); // props.darkTheme ? clockDark: clock);

      //draw avatar for deleted nodes
      avatarUninvitable.attr("xlink:href", function (o) {
        return isNodeExists && selectedNode && selectedNode.id === o.id
          ? // (isConnected(hoveredNode, o) || isConnected(clickedNode, o))
          darkTheme
            ? archiveSelectedDark
            : archiveSelected
          : darkTheme
            ? archiveDark
            : archive;
      }); // props.darkTheme ? clockDark: clock);

      //

      // link.style("stroke-opacity", function (o) {
      //   return isNodeExists &&
      //     (o.source === clickedNode ||
      //       o.target === clickedNode ||
      //       o.source === hoveredNode ||
      //       o.target === hoveredNode)
      //     ? 1
      //     : opacity;
      // });
      link.sort(function (o, b) {
        let sourceId = (o && o.source && o.source.id) ? o.source.id : null;
        let targetId = (o && o.target && o.target.id) ? o.target.id : null;
        // select the parent and sort the path's

        // console.log("d",d);
        if (
          isNodeExists &&
          (sourceId === clickedNodeId ||
            targetId === clickedNodeId ||
            sourceId === hoveredNodeId ||
            targetId === hoveredNodeId)
        )
          return 1;
        // a is not the hovered element, send "a" to the back
        else return -1; // a is the hovered element, bring "a" to the front
      });
      link
        .style("stroke", function (o) {
          let sourceId = (o && o.source && o.source.id) ? o.source.id : null;
          let targetId = (o && o.target && o.target.id) ? o.target.id : null;
          return isNodeExists &&
            (sourceId === clickedNodeId ||
              targetId === clickedNodeId ||
              sourceId === hoveredNodeId ||
              targetId === hoveredNodeId)
            ? darkTheme
              ? "#F7F7F7"
              : "#000000"
            : darkTheme
              ? "#404040" //was #828282
              : "#D2CBDD"; // was  #E4E1EA #9A8AB6
        })
        .style("marker-end", (o) => {
          let sourceId = (o && o.source && o.source.id) ? o.source.id : null;
          let targetId = (o && o.target && o.target.id) ? o.target.id : null;
          return (
            `url(#${isNodeExists &&
              (sourceId === clickedNodeId ||
                targetId === clickedNodeId ||
                sourceId === hoveredNodeId ||
                targetId === hoveredNodeId)
              ? darkTheme
                ? "F7F7F7"
                : "000000"
              : darkTheme
                ? "404040" //was #828282
                : "D2CBDD"})`

          )
        })
        // .style("marker", (o) => {
        //   return markerEnd(
        //     isNodeExists &&
        //       (o.source === clickedNode ||
        //         o.target === clickedNode ||
        //         o.source === hoveredNode ||
        //         o.target === hoveredNode)
        //       ? darkTheme
        //         ? "#F7F7F7"
        //         : "#000000"
        //       : darkTheme
        //       ? "#404040" //was #828282
        //       : "#D2CBDD"
        //   ); // #E4E1EA
        // })
        // .style("marker-end", (0) =>


        // !!(relationSelected === "all" && !d.doubleDirectedAll) ||
        // !!(relationSelected === "private" && !d.doubleDirectedPrivate)
        //   ? `url(#${props.darkTheme ? "404040" : "D2CBDD"})`//'url(#arrowhead)'//markerEnd(props.darkTheme ? "#404040" : "#D2CBDD") // [ "#F7F7F7","#000000","#404040","#D2CBDD"];
        //   : "none"
        // )
        ;
      messageRect
        .style("fill", function (o) {
          return isNodeExists && selectedNode && selectedNode.id === o.id
            ? // (isConnected(hoveredNode, o) || isConnected(clickedNode, o))
            // 6F6F6F
            darkTheme
              ? "#6F6F6F"
              : "#fff"
            : darkTheme
              ? "#bdbdbd"
              : "#3D1D75";
        })
        .attr("filter", (o) => {
          return isNodeExists && selectedNode && selectedNode.id === o.id
            ? // (isConnected(hoveredNode, o) || isConnected(clickedNode, o))
            //isNodeExists &&
            // (o.source === clickedNode ||
            //   o.target === clickedNode ||
            //   o.source === hoveredNode ||
            //   o.target === hoveredNode)
            "url(#blur)"
            : "";
        });
      dialogueTriangle
        .style("fill", function (o) {
          return isNodeExists && selectedNode && selectedNode.id === o.id
            ? // (isConnected(hoveredNode, o) || isConnected(clickedNode, o))
            darkTheme
              ? "#6F6F6F"
              : "#fff"
            : darkTheme
              ? "#bdbdbd"
              : "#3D1D75";
        })
        .attr("filter", (o) => {
          return isNodeExists && selectedNode && selectedNode.id === o.id
            ? // (isConnected(hoveredNode, o) || isConnected(clickedNode, o))
            //isNodeExists &&
            // (o.source === clickedNode ||
            //   o.target === clickedNode ||
            //   o.source === hoveredNode ||
            //   o.target === hoveredNode)
            "url(#blur)"
            : "";
        });
      messageCount.style("fill", function (o) {
        return isNodeExists && selectedNode && selectedNode.id === o.id
          ? // (isConnected(hoveredNode, o) || isConnected(clickedNode, o))
          darkTheme
            ? "#f2f2f2"
            : "#000000"
          : darkTheme
            ? "#222222"
            : "#fff";
      });

      // shadows
      circleShadow.style("visibility", function (o) {
        return isNodeExists && selectedNode && selectedNode.id === o.id
          ? darkTheme
            ? "hidden"
            : "visible"
          : "hidden";
      });
      messageRectShadow.style("visibility", function (o) {
        return isNodeExists && selectedNode && selectedNode.id === o.id
          ? // (isConnected(hoveredNode, o) || isConnected(clickedNode, o))
          darkTheme
            ? "hidden"
            : "visible"
          : "hidden";
      });
      dialogueTriangleShadow.style("visibility", function (o) {
        return isNodeExists && selectedNode && selectedNode.id === o.id
          ? // (isConnected(hoveredNode, o) || isConnected(clickedNode, o))
          darkTheme
            ? "hidden"
            : "visible"
          : "hidden";
      });
      lablelRectShadow
        .style("visibility", function (o) {
          return isNodeExists && selectedNode && selectedNode.id === o.id
            ? // (isConnected(hoveredNode, o) || isConnected(clickedNode, o))
            darkTheme
              ? "hidden"
              : "visible"
            : "hidden";
        })
        .style("dispay", (d) => (d.granted ? "block" : "none "));

      if (darkTheme) {
        lablelRect
          .style("fill", function (o) {
            return isNodeExists && selectedNode && selectedNode.id === o.id
              ? // (isConnected(hoveredNode, o) || isConnected(clickedNode, o))
              "#6f6f6f"
              : "#131313";
          })
          .style("dispay", (d) => (d.granted ? "block" : "none "));
      }
    }

    function clickNode(d, clickFromGrapth) {
      if (!that.graphClick) {
        that.graphClick = true;
        // console.log("4");
        props.handleGraphClick(true);
      }
      if (clickFromGrapth) {
        that.clickedNodeId = d ? d.id : undefined;
      }
      // that.clickFromGrapth = clickFromGrapth;
      // console.log("setClickedNode 1", clickFromGrapth, that.clickFromGrapth);
      //, _wasClicked
      // console.log("click d",d, "clickInTable", clickInTable);
      // let event = d3.event;
      if (d3.event) {
        d3.event.stopPropagation();
      }
      if (d) {//d && d.granted && d.invitable
        // props.openTableOnNodeClick();
        props.toggleIndividualStatistic(true);
        if (!isMobileDevice) {
          props.toggleTable(true);
        }
      }
      // that.isntFirstRender = true;
      // console.log("CLICK ON NODE", d);
      // if(!d){
      // clickToggler = true;
      // that.wasClicked = !that.wasClicked;
      // }
      // console.log("clickToggler: ", clickToggler, "that.wasClicked: ", that.wasClicked);
      //   that.wasClicked = !that.wasClicked;
      // console.log("d",d);
      // d.clicked = !d.clicked;
      //   if (that.wasClicked) {
      // that.nodeSelected = d;
      // let _clickedNode = d && clickedNode && d.id === clickedNode.id ? undefined : d;
      // let _clickedNode = d && clickedNode && d.id === clickedNode.id ? undefined : d;
      // if (d && clickInTable === undefined) {
      if (d && d.id !== that.clickedNodeId) {
        // console.log("zoom_handler");
        zoom_handler.translateTo(
          svg.transition().duration(250),
          d.x,
          d.y
        ); //[, p]
      } // zoom_handler.scaleTo(svg.transition().duration(250)
      // props.nodeSelectedHolderUpdateTable(d); // rebuild table

      // clickedNodeHolderUpdateTable(clickedNode);
      if (clickFromGrapth) {
        dispatch(setClickedNode(d || undefined));
        // toogleNode(_clickedNode, hoveredNode);
      }
      that.clickedNodeId = d ? d.id : undefined;
      // that.clickFromGrapth = false;
    }

    function mouseOver(d) {
      dispatch(setHoveredNode(d || undefined));
      // toogleNode(clickedNode, d);
    }

    function mouseOut() {
      dispatch(setHoveredNode(undefined));
      // toogleNode(clickedNode, undefined);
    }

    function svgClick() {
      if (!that.graphClick) {
        that.graphClick = true;
        // console.log("5");
        props.handleGraphClick(true);
      }
      // console.log("svgClick");
      if (d3.event) {
        d3.event.stopPropagation();
      }
      // console.log("CLICK ON SVG", d3.event);
      // clickedNode = undefined;
      // hoveredNode = undefined;
      // console.log("setClickedNode 2");
      dispatch(setClickedNode(undefined));
      dispatch(setHoveredNode(undefined));
      // toogleNode(undefined, undefined);
    }

    function plusMinusVisSwither(isZoombuttonVisible) {
      if (isZoombuttonVisible) {
        return (plusMinusContainer.style.display = "flex");
      } else {
        return (plusMinusContainer.style.display = "none");
      }
    }
    function getTextWidth(text, fontSize, fontName) {
      let c = document.createElement("canvas");
      let ctx = c.getContext("2d");
      ctx.font = fontSize + " " + fontName;
      // console.log("WIDTH:", text, ctx.measureText(text).width);
      return ctx.measureText(text).width;
    } // getTextWidth(d, '12px', 'myriad-pro-condensed, robotoCondensed-light, sans-serif');

    // props.nodeSelectedHolder(that.nodeSelected, click);

    // clickedNodeHolderUpdateChart(clickedNode, click, true);
    // hoveredNodeHolderUpdateChart(hoveredNode, mouseOver);

    // console.log("props.nodeSelectedHolder", props.nodeSelectedHolder);
    // console.log("clickedNode", clickedNode, clickedNode, click);
    // if (clickedNode) {
    //   click(clickedNode, true);
    // }
    // toogleNode(undefined, undefined);

    that.toogleNode = toogleNode;
    that.clickNode = clickNode;
    // props.handleGraphClick(false);
  }

  return (
    <>
      <div
        id={"zoomPannel"}
        ref={zoomPannelRef}
      // className={props.tutorialVIsible}
      // onMouseOver={()=> plusMinusContainer.style.display="flex"}
      // onMouseLeave={()=> plusMinusContainer.style.display="none"}
      >
        <div
          id="plusMinusContainer"
          // className={that.isZoombuttonVisible ? "plus-minus-container visible": "plus-minus-container hidden"}
          className="plus-minus-container"
          ref={plusMinusContainerRef}
        >
          <div
            id="zoom_out"
            className="minus-container"
            ref={zoomOutRef}
          ></div>
          <div
            id="zoom_in"
            className="plus-container"
            ref={zoomInRef}
          ></div>
        </div>
        <div className="zoomDimensionContainer">
          <div
            id="zoomDimensionDiv"
            ref={zoomDimensionRef}
          >
            {that.zoomDemention}%
          </div>
          {/* {that.state.zoomDemention}  %  */}
        </div>
      </div>
      <div
        className="d3-chart-wrapper"
        id="forceSvg"
        ref={vizRef}
      ></div>
      {/* <div className="preloadContainer">
        <div id="preloadPlus"></div>
        <div id="preloadPlusSelected"></div>
        <div id="preloadPlusDisabled"></div>
        <div id="preloadMinus"></div>
        <div id="preloadMinusSelected"></div>
        <div id="preloadMinusDisabled"></div>
      </div> */}
      <div className="tooltip" id="tooltip"></div>
    </>
  );
}
  // , ()=>{
  //   // nextProps.nodes === props.nodes &&
  //   // nextProps.links === props.links &&
  //   // nextProps.relationSelected === props.relationSelected &&
  //   // nextProps.darkTheme === props.darkTheme &&
  //   // nextProps.gridChartCondition === props.gridChartCondition
  // }
);

export default D3forceChart;
