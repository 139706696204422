
import React, { memo, useState } from 'react';
import { Table } from 'semantic-ui-react';
import "./NodesTable.sass";
// import userIcon from "../../img/user.svg";
// import userIconDark from "../../img/user-dark.svg";
// import { useDispatch, useSelector } from "react-redux";
// import { setClickedNode, setHoveredNode } from "../../redux/toolsSlice";

const IndividualMessageIntensivity = memo((props) => {
  const [field, setField] = useState('key');
  const [isDesc, setIsDesc] = useState(true);
  
  let nodes = JSON.parse(JSON.stringify(props.nodes));
  // let nodes = props.nodes.map(d => ({...d}));

  let formatedHeaders = [
    { field: 'key', name: "Date", colSpan: 2, width: "24px"  }, // , sort: 'asc', type: 'abc' },
    { field: 'sent', name: "Sent", colSpan: 1, width: "24px" }, // , sort: 'asc', type: 'num' },
    { field: 'received', name: "Received", colSpan: 1, width: "24px" }, // , sort: 'asc', type: 'num' },
  ];
  let headers = [
    { field: 'key' },
    { field: 'sent' },
    { field: 'received' },
  ];

  let onSort = (d) =>{
    // this.setState({ field: d.field, isDesc: field === d.field ? !isDesc : !isDesc });
    setField(d.field);
    setIsDesc(field === d.field ? !isDesc : !isDesc);
  }

  let renderHeaderCells = () => {
    return (formatedHeaders.map((item, i) => {
      return <Table.HeaderCell onClick={() => onSort(item)} style={{width: item.width || "unset"}} key={i + "th"}
       colSpan={item.colSpan}
      >
        <div className="table-header" >
          <div> {item.name} </div>
          <div className={(nodes && nodes.length === 0) ? "arrow-bottom disabled" : isDesc ? "arrow-bottom" : "arrow-top"} style={{opacity: field === item.field ? 1 : 0.3}}></div>
        </div>
      </Table.HeaderCell>
    })
    )
  }

  let renderRowsAndCells = () => {
    return (
      nodes.map((d, j) => {

        return (
          <Table.Row
            tabIndex={j === 0 ? 0 : -1}
            id={"table-node-" + d.key}
            key={j + "tr"}
          >
            {
              headers.map((item, i) => {
                return (
                  <Table.Cell className={""} key={(i + "td" + j)}   colSpan={item.colSpan}>

                    {/* {item.field === "date" && <div></div>} */}
                    {d[item.field]}
                  </Table.Cell>
                )
              })
            }
          </Table.Row>
        )
      }
      ))
  }

    // console.log("INDIVIDUAL MESSAGE INTENSITY",nodes);
    if (field === "key") {
      if (isDesc) {
        // nodes.sort((a, b) => ((a[field] > b[field]) ? 1 : ((a[field] < b[field]) ? -1 : 0)));
        nodes.sort((a, b) => +b.milliseconds - +a.milliseconds);
      } else {
        // nodes.sort((b, a) => ((a[field] > b[field]) ? 1 : ((a[field] < b[field]) ? -1 : 0)));
        nodes.sort((b, a) => +b.milliseconds - +a.milliseconds);
      }
    } else if(field === 'sent' || field === 'received') {
      if (isDesc) {
        nodes.sort((a, b) => +b[field] - +a[field]);
      } else {
        nodes.sort((b, a) => +b[field] - +a[field]);
      }
    }

  return (
    <div className={props.areaChartVisible ? "customTable individual short interactive-scroll" : "customTable individual long interactive-scroll"}
    >
      {!!(nodes && nodes.length) &&
        <>
          <Table unstackable singleLine>
            <Table.Header>
              <Table.Row>
                {renderHeaderCells()}
              </Table.Row>
            </Table.Header>
            <Table.Body className="table-body-wrapper">
              {renderRowsAndCells()}
            </Table.Body>
          </Table>
        </>}
      {!!(nodes && nodes.length === 0) &&
        <div className="no-data-container">
          No data available yet
        </div>
      }
    </div>
  );
});

export default IndividualMessageIntensivity;



