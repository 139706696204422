
import React, { memo } from 'react';
// import { Link } from "react-router-dom";
import "./InactiveList.sass";
import { useDispatch } from "react-redux";
import { setClickedNode } from "../../redux/toolsSlice";

const InactiveList = memo((props) => {
  // console.log("props",props);
  const dispatch = useDispatch();

  let click = (d, e) => {
    // console.log("inactive user click", d, e);
    if (e) {
      e.stopPropagation();
    }
    dispatch(setClickedNode(d || undefined));
  }

  let inactiveUsersRender = () => {
    let usersArr = props.relationSelected === "private" ? props.inactiveUsersDirect :  props.inactiveUsersAll;
    // console.log("usersArr",usersArr);
    return (
      usersArr.map((d, i) => {

        // if (d && d.name) {
        return (
          <div className="borderless-button" //inactive-users-item
            // onMouseOver={e => this.hover(d, e)}
            onClick={e => click(d, e)}
            key={`key_${i}`}
          >
            {d && d.name ? d.name : d.id}
          </div>
        )
        // }
      })
    )
  }


  return (
    <div className="inactive-user-list-wrapper">
      <>
        {inactiveUsersRender()}
      </>
    </div>
  );
});

export default InactiveList;
