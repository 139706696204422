
import React, { memo, useState } from 'react';
import { Table } from 'semantic-ui-react';
import "./NodesTable.sass";
import userIcon from "../../img/user.svg";
import userIconDark from "../../img/user-dark.svg";
// import { useDispatch, useSelector } from "react-redux";
// import { setClickedNode, setHoveredNode } from "../../redux/toolsSlice";

const IndividualConnectionsTable = memo((props) => {
  const [field, setField] = useState('relationNum');
  const [isDesc, setIsDesc] = useState(true);


  let formatedHeaders = [
    { field: 'name', name: "User", colSpan: 2, width: "124px" }, // , sort: 'asc', type: 'abc' },
    { field: 'relationNum', name: "Relation", colSpan: 1 }, // , sort: 'asc', type: 'num'}, // d.invitable
    { field: 'sent', name: "Sent", colSpan: 1 }, // , sort: 'asc', type: 'num' },
    { field: 'received', name: "Received", colSpan: 1 }, // , sort: 'asc', type: 'num' },
  ];
  let headers = [
    { field: 'avatar' },
    { field: 'name' },
    { field: 'relation' },
    { field: 'sent' },
    { field: 'received' },
  ];

  let onSort = (d) => {
    // this.setState({ field: d.field, isDesc: field === d.field ? !isDesc : !isDesc });
    setField(d.field);
    setIsDesc(field === d.field ? !isDesc : !isDesc);
  }

  let renderHeaderCells = () => {
    return (formatedHeaders.map((item, i) => {
      return <Table.HeaderCell onClick={() => onSort(item)} style={{ width: item.width || "unset" }} key={i + "th"} colSpan={item.colSpan}>
        <div className="table-header" >
          <div> {i === 0 ? item.name + " (" + props.nodes.length + ") " : item.name} </div>
          <div className={(props.nodes && props.nodes.length === 0) ? "arrow-bottom disabled" : isDesc ? "arrow-bottom" : "arrow-top"} style={{ opacity: field === item.field ? 1 : 0.3 }}></div>
        </div>
      </Table.HeaderCell>
    })
    )
  }

  let renderRowsAndCells = () => {
    return (
      props.nodes.map((d, j) => {

        return (
          <Table.Row
            tabIndex={j === 0 ? 0 : -1}
            // id={"table-node-" + d.id}
            key={j + "tr"}
          >
            {
              headers.map((item, i) => {
                return (
                  <Table.Cell className={item.field === "avatar" ? "avatar-container" : ""} key={(i + "td" + j)}>
                    {item.field === "avatar" && <img className="user-avatar" src={d && d.avatar ? d.avatar : props.darkTheme ? userIconDark : userIcon} alt="user-icon"></img>}
                    {/* <p> */}
                    {item.field === "relation" && <div>
                      <div className="rounds-container">
                        <div className={d.relation === "c" || d.relation === "b" || d.relation === "a" ? "round active" : "round"}></div>
                        <div className={d.relation === "b" || d.relation === "a" ? "round active" : "round"}></div>
                        <div className={d.relation === "a" ? "round active" : "round"}></div>
                      </div>
                    </div>}
                    {(item.field !== "avatar" && item.field !== "relation") && d[item.field]}
                  </Table.Cell>
                )
              })
            }
          </Table.Row>
        )
      }
      ))
  }

  // console.log("INDIVIDUAL CONNECTIONS TABLE",props.nodes);
  if (field === "name") {
    if (isDesc) {
      props.nodes.sort((a, b) => ((a[field] > b[field]) ? 1 : ((a[field] < b[field]) ? -1 : 0)));
    } else {
      props.nodes.sort((b, a) => ((a[field] > b[field]) ? 1 : ((a[field] < b[field]) ? -1 : 0)));
    }
  } else {
    if (isDesc) {
      props.nodes.sort((a, b) => +b[field] - +a[field]);
    } else {
      props.nodes.sort((b, a) => +b[field] - +a[field]);
    }
  }

  return (
    <div className={props.areaChartVisible ? "customTable short interactive-scroll" : "customTable long interactive-scroll"} /*interactive-scroll*/
    >
      {!!(props.nodes && props.nodes.length) &&
        <>
          <Table unstackable singleLine>
            <Table.Header>
              <Table.Row>
                {renderHeaderCells()}
              </Table.Row>
            </Table.Header>
            <Table.Body className="table-body-wrapper">
              {renderRowsAndCells()}
            </Table.Body>
          </Table>
        </>}
      {!!(props.nodes && props.nodes.length === 0) &&
        <div className="no-data-container">
          No data available yet
        </div>
      }
    </div>
  );
});

export default IndividualConnectionsTable;



