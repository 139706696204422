import React from "react"; //, { Component }
import DatePicker from "react-datepicker";

export default class DatePickerComponent extends React.Component {
  constructor(props) {
    super(props);
    // this.isSearchInputActive = false;
    // this.searchVisible = false;
    this.state = {
      // dateSelectorOpened: false,
      // isSearchInputActive: false,
      refresh: false,
      clickInTable: false,
      leaveTable: false,
      searchQuery: false,
      // sortOrder: 'asc'
    };
    // this.headers = ['name', 'contactCountByRelations', 'messageAmountCounter'];//['name','contactCount','messageAmountCounter']
    // this.sortTypeHeaders = ['abc', 'num', 'num']; // abcAsc/abcDesc, numAsc/numDesc

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.wrapperRef = undefined;
    this.activeNode = undefined;
    this.clickedNode = undefined;
    this.hoveredNode = undefined;
  }

  // expand = () => {
  //   this.setState({ dateSelectorOpened: true });
  // };
  // collapse = () => {
  //   this.setState({ dateSelectorOpened: false });
  //   // }
  // };
  /**
   * Set the wrapper ref
   */
   setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event, graphClick) {
    // console.log("handleClickOutside", event);
    if ((
      event &&
      event.target &&
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      !this.state.searchQuery
    ) || graphClick) {
      this.props.handleGraphClick(false);
      // console.log("You clicked outside of search pannel!");
      this.props.collapse();
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  shouldComponentUpdate(nextProps, nextState){
    return nextProps !== this.props || nextState !== this.state;
    // return nextState !== this.state;
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  // filterProject = (query, project) => {
  //   return query ? project.name.toLowerCase().includes(query.toLowerCase()) : [];
  // }


  render() {
    // console.log(
    //   "this.state.searchQuery",
    //   this.state.searchQuery,
    //   "this.state.isSearchInputActive",
    //   this.state.isSearchInputActive
    // );
    return (
      <div
        ref={this.setWrapperRef}
        // onMouseLeave={this.props.collapse}
        className={
          this.props.dateSelectorOpened
            ? "datePicker-container opened"
            : "datePicker-container closed"
        }
      >
                    {!!(
                      this.props.minDate &&
                      this.props.maxDate &&
                      this.props.lineChartData
                    ) && (
                      <>
                        <DatePicker
                          className={this.props.darkTheme ? "dark" : ""}
                          selected={this.props.startDate}
                          onChange={this.props.setDateRange}
                          startDate={this.props.startDate}
                          endDate={this.props.endDate}
                          selectsRange
                          inline
                          minDate={this.props.minDate}
                          maxDate={this.props.maxDate}
                        />
                      </>
                    )}
      </div>
    );
  }
}
