import React, { Component } from 'react';
import { Link } from "react-router-dom";//withRouter
import { Button} from 'semantic-ui-react';//Checkbox, Popup, Input 
import './RecoverPasswordError.sass';
// import slackIcon from "../../img/slack-icon.svg";//"../../img/slack-icon.svg"

export default class RecoverPasswordError extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount(){
    
    document.title = "Pavooq Slack Reggistration Error";
  }




  render() {

    return (
      <div className={this.props.isAuthenticated ? "RecoverPasswordError" : "RecoverPasswordError unautetificated"}>
          <div className="slack-connection-wrapper">
          <Link id="comeBackToLogin" className="logo-medium-container" to={`/`}>
            <div className="logo-big-container"></div>
          </Link>
            <h2>
            Create new password
            </h2>

              <div className="conent">
                <div className="error-message-container">
                  <div className="avatar-empty-container"></div>
                  <p >
                    Oops! Something went wrong...
                    <br />
                    Please try again.</p>
                </div>
                  <div className="button-container">
                  
              <Button
                className="big-button"
                as={Link}
                to="/recoverpassword"
                // target="_self"
              >
                Proceed
              </Button>
            
                  </div>
              </div>


          </div>
      </div>
    )
  }
}
