import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./RecoverPassword.sass";
import { Form, Button} from 'semantic-ui-react';
import dompurify from 'dompurify';
import helpers from "../../helpers";

export default class RecoverPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isPasswordVisible: false,
      isEmailValid: true,
      email: "",
    };
    // this.validateEmail = helpers.validateEmail();
    this.sanitizer = dompurify.sanitize;
    this.validateEmail = helpers.validateEmail.bind(this);
  }

  validateForm() {
    return !!(this.state.email.length > 0 && this.state.isEmailValid);
  }

  handleChange = event => {
    if (event.target.name === "email") {
      this.setState({
        isEmailValid: this.validateEmail(event.target.value),
      });
    }
    this.setState({
      // isEmailValid: this.validateEmail(event.target.value),
      [event.target.name]: this.sanitizer(event.target.value)
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });

    fetch(`${this.props.apiUrl}/account/reset?email=${this.state.email}`, {
      method: "GET",
      headers: {
        // Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: 'include',//'same-origin',
    })
      .then((res) => {
        if (res.ok) {
          this.setState({ isVerifyCodeIsSent: true, isLoading: false });
        } else {
          if (res.status === 401) {
            this.pavooqAccessRefresh();
            this.setState({ isVerifyCodeIsSent: false, isLoading: false });
          } else {
            console.log("getSlackAutorisationLink ERROR", res);
            this.pavooqAccessRefresh();
            this.onFail(res);
            this.setState({ isVerifyCodeIsSent: false, isLoading: false });
          }
          return false
        }
      })
      .catch((e) => {
        this.pavooqAccessRefresh();
        console.log("getSlackAutorisationLink ERROR", e);
        this.setState({ mainLoader: false });
      });
  };

  renderForm() {
    return (
      <Form onSubmit={this.handleSubmit}
        autoComplete="new-password">
        <Form.Input
          className={
            !this.state.isEmailValid
              ? "item-content error"
              : "item-content"
          }
          autoComplete="new-password"
          name="email"
          id="email"
          placeholder='Enter your email'
          value={this.state.email}
          onPaste={this.handleChange}
          onChange={this.handleChange}
        />
        {!this.state.isEmailValid && (
          <div className="email-error">
            Please enter valid email
          </div>
        )}
        <Button className="big-button" fluid type='submit' disabled={!this.validateForm()}>
          <h4>{!this.state.isLoading ? <span>Recover Password</span> : <span className="Loader-wrapper">Loading...</span>}</h4>
        </Button>
        <div className="login-link-container">
          <span>Have everything you need? </span>
          <Link className="login-link" to={`/login`}> Log In</Link>
        </div>
      </Form>

    );
  }

  renderConfirmationForm() {
    return (
      <div className="main-registration-form center">
        <div className="message-sent"></div>
        <p>We sent the link to recover a password on your email.</p>
        <p>Please, check it.</p>
      </div>
    );
  }

  render() {
    return (
      <div
        className={this.props.darkTheme ? "RecoverPassword dark" : "RecoverPassword"}>
        <div className="main-content">
          <div className="signup-link-container">
            <span>Not a member? </span>
            <Link className="login-link" to={`/`}> Sign up now</Link>
          </div>

          <Link className="sign-in-container" to={`/`}>
            <div className="logo-big-container"></div>
          </Link>

          <div>
            <div className="main-registration-form">
              <div className="pass-recovery-header">
                <p>Password recovery</p>
              </div>
              <div className="registration-form">
                {this.state.isVerifyCodeIsSent
                  ? this.renderConfirmationForm()
                  : this.renderForm()}
              </div>
            </div>
          </div>
        </div>
        <div className="additional-container">
          <div className="mail-to-container">
            <div className="avatar-monochrome-support"></div>
            <p>Any problem? Please, write me at
              <a id="loginSupport" href="mailto:yevhen.bereza@pavooq.com"> yevhen.bereza@pavooq.com</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}