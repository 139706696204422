import React, { Component } from "react";
import { Link } from "react-router-dom"; //withRouter
import { Button } from "semantic-ui-react";
import "./SuccessfullSlackInstalation.sass";
// import slackIcon from "../img/slack-icon.svg";

export default class SuccessfullSlackInstalation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // refresh:
    };
  }
  componentDidMount(){
    document.title = "Pavooq Slack Is Connected";
  }

  render() {
    return (
      <div className={this.props.isAuthenticated ? "SuccessfullSlackInstalation" : "SuccessfullSlackInstalation unautetificated" }>
        <div className="slack-connection-wrapper">
          <Link id="comeBackToLogin" className="logo-medium-container" to={`/`}>
            <div className="logo-big-container"></div>
          </Link>
          <h2>Connect my Slack workspace</h2>
          <div className="conent">
            <div className="celebrate-container"></div>
            <p>Well done!
              <br />
              Now your Slack workspace connected to Pavooq</p>
            {this.props.isAuthenticated ? (
              <Button
                className="big-button"
                as={Link}
                to="/inviteslackmembers"
                target="_self"
              >
                Proceed
              </Button>
            ):(
              <Button
                className="big-button"
                as={"a"}
                href="https://pavooq.com/#rec286082479"
                target="_self"
              >
                Proceed
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
