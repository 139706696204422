import {
  createSlice
} from '@reduxjs/toolkit'

const initialState = {
  clickedNode: undefined,
  hoveredNode: undefined,
  clickedNodeId: undefined,
  hoveredNodeId: undefined,
  nodes: undefined,
  links: undefined,
  individualAccumulativeDataByClickedNode: {}, // input: clickedNode.id, startDate, endDate, relationSelected [`${clickedNode.id}_${relationSelected}`]
  dropdownSelectedItemForClickedUser: undefined, 
  individualAccumulativeDataByClickedAndDropdownNode: {}, // input: clickedNode.id, startDate, endDate, relationSelected [`${clickedNode.id}_${relationSelected}`]
  nestedAccObjectOfSendersAndRecieversArr: [],
  isMobileDevice: false,
}

export const toolsSlice = createSlice({
  name: 'tools',
  initialState,
  reducers: {
    setClickedNode: (state, action) => {
      // console.log("setClickedNode", action.payload);
      state.clickedNodeId = action.payload ? action.payload.id : undefined;
      state.clickedNode = action.payload ? JSON.parse(JSON.stringify(action.payload)) : undefined;
    },
    setHoveredNode: (state, action) => {
      state.hoveredNodeId = action.payload ? action.payload.id : undefined;
      state.hoveredNode = action.payload ? JSON.parse(JSON.stringify(action.payload)) : undefined;
    },

    setIndividualAccumulativeDataByClickedNode: (state, action) => {
      state.individualAccumulativeDataByClickedNode[action.payload.key] = action.payload.values;
    },
    clearIndividualAccumulativeDataByClickedNode: (state) => {
      state.individualAccumulativeDataByClickedNode = {};
    },
    setDropdownSelectedItemForClickedUser: (state, action) => {
      state.dropdownSelectedItemForClickedUser = action.payload ? JSON.parse(JSON.stringify(action.payload)) : undefined;
    },
    setIndividualAccumulativeDataByClickedAndDropdownNode: (state, action) => {
      // console.log("setIndividualAccumulativeDataByClickedAndDropdownNode", action.payload);
      state.individualAccumulativeDataByClickedAndDropdownNode[action.payload.key] = action.payload.values;
    },
    clearIndividualAccumulativeDataByClickedAndDropdownNode: (state) => {
      state.individualAccumulativeDataByClickedAndDropdownNode = {};
    },
    setNestedAccObjectOfSendersAndRecieversArr: (state, action) => {
      state.nestedAccObjectOfSendersAndRecieversArr = action.payload;
    },
    setIsMobileDevice: (state, action) => {
      state.isMobileDevice = action.payload;
    },
  },
})

export const {
  setClickedNode,
  setHoveredNode,
  setIndividualAccumulativeDataByClickedNode,
  clearIndividualAccumulativeDataByClickedNode,
  setDropdownSelectedItemForClickedUser,
  setIndividualAccumulativeDataByClickedAndDropdownNode,
  clearIndividualAccumulativeDataByClickedAndDropdownNode,
  setNestedAccObjectOfSendersAndRecieversArr,
  setIsMobileDevice,
} = toolsSlice.actions

export default toolsSlice.reducer