import React, { Component } from 'react';
import { Link } from "react-router-dom";//withRouter
import { Button, Popup, Input } from 'semantic-ui-react';//Checkbox
import './FailedSlackInstalation.sass';
import slackIcon from "../../img/slack-icon.svg";//"../../img/slack-icon.svg"

export default class FailedSlackInstalation extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount(){
    
    document.title = "Pavooq Slack Reggistration Error";
  }




  render() {
    // console.log("ERROR PAGE this.props.location.pathname", this.props.location.pathname);
    const pathname = this.props.location.pathname;
    // console.log("ERROR PAGE search",search);
    // const reason = new URLSearchParams(search).get("reason");
    // console.log("ERROR PAGE reason",reason);
    let errorURLArray = pathname.split('/');
    let errorLastWord = errorURLArray[errorURLArray.length - 1];
    // console.log("ERROR PAGE errorLastWord", errorLastWord);
    return (
      <div className={this.props.isAuthenticated ? "FailedSlackAuthPage" : "FailedSlackAuthPage unautetificated"}>
        {errorLastWord === "cancel" && (
          <div className="slack-connection-wrapper">
            <Link id="comeBackToLogin" className="logo-medium-container" to={`/`}>
              <div className="logo-big-container"></div>
            </Link>
            <h2>
              Connect my Slack workspace
            </h2>
            {this.props.isAuthenticated && (
              <div className="conent">
                <div className="error-message-container">
                  <div className="avatar-empty-container"></div>
                  <p >Slack autentification was canceled by user</p>
                  <Button
                    as="a"
                    className="field big-button slack-btn"
                    fluid
                    href={this.props.slackUrl || ""}
                  >
                    <>
                      <img src={slackIcon} alt="" srcSet="" />
                      <span>Connect your Slack workspace to Pavooq</span>
                    </>
                  </Button>
                </div>
                {/* <div className="login-opts-divider">
                  <hr /> <span> Or </span> <hr />
                </div>
                <div>
                  <div className="not-admin-container">
                    <p className="accentuated">
                      I don’t have admin rights in Slack workspace
                    </p>
                    <p>
                      You need to send the link to your Slack admin
                    </p>

                    <div>
                      <Input
                        className="copy-input item-content"
                        defaultValue={
                          this.props.slackUrl
                        } //defaultValue
                      />
                      <Popup
                        // onClose={this.handleClosePopup}
                        // onOpen={this.handleOpenPopup}
                        content={`Copied !`}
                        on='click'
                        position="bottom right"
                        trigger={
                          <Button
                            className="copy-button"
                            onClick={(e) => {
                              let link =
                                this.props.slackUrl.replace(
                                  /['"]+/g,
                                  ""
                                );
                              // console.log("copied"," this.props.slackUrl", link);
                              navigator.clipboard.writeText(
                                link // JSON.stringify(link)
                              );
                            }}
                          >
                            Copy
                          </Button>
                        }
                      />
                    </div>

                    <Link
                      className="bottom-link"
                      to="/forcepage"
                    >
                      Close and go back to Demo dashboard
                    </Link>
                  </div>
                </div> */}
              </div>
            )}
            {!this.props.isAuthenticated && (
              <div className="conent">
                <div className="error-message-container">
                  <div className="avatar-empty-container"></div>
                  <p >Slack autentification was canceled by user</p>
                </div>
                  <div className="button-container">
                    <Button
                      className="big-button"
                      as={"a"}
                      href="https://pavooq.com/"
                      target="_self"
                    >
                      Proceed
                    </Button>
                  </div>
              </div>

            )}

          </div>
        )}
        {errorLastWord === "internal" && (
          <div className="slack-connection-wrapper">
            <Link id="comeBackToLogin" className="logo-medium-container" to={`/`}>
              <div className="logo-big-container"></div>
            </Link>
            <h2>
              Connect my Slack workspace
            </h2>
            {this.props.isAuthenticated && (
              <div className="conent">
                <div className="error-message-container">
                  <div className="avatar-empty-container"></div>
                  <p >Internal server error.
                    <br />  Please try later</p>
                  <Button
                    as="a"
                    className="field big-button slack-btn"
                    fluid
                    href={this.props.slackUrl || ""}
                  >
                    <>
                      <img src={slackIcon} alt="" srcSet="" />
                      <span>Try again</span>
                    </>
                  </Button>
                </div>
                <div className="login-opts-divider">
                  <hr /> <span> Or </span> <hr />
                </div>
                <div>
                  <div className="not-admin-container">
                    <p className="accentuated">
                      I don’t have admin rights in Slack workspace
                    </p>
                    <p>
                      You need to send the link to your Slack admin
                    </p>

                    <div>
                      <Input
                        className="copy-input item-content"
                        defaultValue={
                          this.props.slackUrl
                        } //defaultValue
                      />
                      <Popup
                        // onClose={this.handleClosePopup}
                        // onOpen={this.handleOpenPopup}
                        content={`Copied !`}
                        on='click'
                        position="bottom right"
                        trigger={
                          <Button
                            className="copy-button"
                            onClick={(e) => {
                              let link =
                                this.props.slackUrl.replace(
                                  /['"]+/g,
                                  ""
                                );
                              // console.log("copied"," this.props.slackUrl", link);
                              navigator.clipboard.writeText(
                                link // JSON.stringify(link)
                              );
                            }}
                          >
                            Copy
                          </Button>
                        }
                      />
                    </div>

                    <Link
                      className="bottom-link"
                      to="/forcepage"
                    >
                      Close and go back to Demo dashboard
                    </Link>
                  </div>
                </div>
              </div>

            )}
            {!this.props.isAuthenticated && (
              <div className="conent">
                <div className="error-message-container">
                  <div className="avatar-empty-container"></div>
                  <p >Internal server error.
                    <br />  Please try later</p>
                </div>
                  <div className="button-container">
                    <Button
                      className="big-button"
                      as={"a"}
                      href="https://pavooq.com/"
                      target="_self"
                    >
                      Proceed
                    </Button>
                  </div>
              </div>

            )}
          </div>
        )}
        {errorLastWord === "external" && (
          <div className="slack-connection-wrapper">
          <Link id="comeBackToLogin" className="logo-medium-container" to={`/`}>
            <div className="logo-big-container"></div>
          </Link>
            <h2>
              Connect my Slack workspace
            </h2>
            {this.props.isAuthenticated && (
              <div className="conent">
                <div className="error-message-container">
                  <div className="avatar-empty-container"></div>
                  <p >Slack service error. Unable to get token.<br /> Please try later</p>
                  <Button
                    as="a"
                    className="field big-button slack-btn"
                    fluid
                    href={this.props.slackUrl || ""}
                  >
                    <>
                      <img src={slackIcon} alt="" srcSet="" />
                      <span>Try again</span>
                    </>
                  </Button>
                </div>
                <div className="login-opts-divider">
                  <hr /> <span> Or </span> <hr />
                </div>
                <div>
                  <div className="not-admin-container">
                    <p className="accentuated">
                      I don’t have admin rights in Slack workspace
                    </p>
                    <p>
                      You need to send the link to your Slack admin
                    </p>

                    <div>
                      <Input
                        className="copy-input item-content"
                        defaultValue={
                          this.props.slackUrl
                        } //defaultValue
                      />
                      <Popup
                        // onClose={this.handleClosePopup}
                        // onOpen={this.handleOpenPopup}
                        content={`Copied !`}
                        on='click'
                        position="bottom right"
                        trigger={
                          <Button
                            className="copy-button"
                            onClick={(e) => {
                              let link =
                                this.props.slackUrl.replace(
                                  /['"]+/g,
                                  ""
                                );
                              // console.log("copied"," this.props.slackUrl", link);
                              navigator.clipboard.writeText(
                                link // JSON.stringify(link)
                              );
                            }}
                          >
                            Copy
                          </Button>
                        }
                      />
                    </div>

                    <Link
                      className="bottom-link"
                      to="/forcepage"
                    >
                      Close and go back to Demo dashboard
                    </Link>
                  </div>
                </div>
              </div>

            )}
            {!this.props.isAuthenticated && (
              <div className="conent">
                <div className="error-message-container">
                  <div className="avatar-empty-container"></div>
                  <p >Slack service error. Unable to get token.<br /> Please try later</p>
                </div>
                  <div className="button-container">
                    <Button
                      className="big-button"
                      as={"a"}
                      href="https://pavooq.com/"
                      target="_self"
                    >
                      Proceed
                    </Button>
                  </div>
              </div>

            )}

          </div>
        )}
        {errorLastWord === "exists" && (
          <div className="slack-connection-wrapper">
          <Link id="comeBackToLogin" className="logo-medium-container" to={`/`}>
            <div className="logo-big-container"></div>
          </Link>
            <h2>
              Connect my Slack workspace
            </h2>

              <div className="conent">
                <div className="error-message-container">
                  <div className="avatar-empty-container"></div>
                  <p >Sorry, but seems that this Slack workspace is already added to a
                    <br /> different Pavooq account</p>
                </div>
                  <div className="button-container">
                  {this.props.isAuthenticated ? (
              <Button
                className="big-button"
                as={Link}
                to="/inviteslackmembers"//forcepage
                // target="_self"
              >
                Proceed
              </Button>
            ):(
              <Button
                className="big-button"
                as={"a"}
                href="https://pavooq.com/#rec286082479"
                target="_self"
              >
                Proceed
              </Button>
            )}
                  </div>
              </div>


          </div>
        )}
        {errorLastWord === "mismatch" && (
          <div className="slack-connection-wrapper">
          <Link id="comeBackToLogin" className="logo-medium-container" to={`/`}>
            <div className="logo-big-container"></div>
          </Link>
            <h2>
              Wrong workspace
            </h2>

              <div className="conent">
                <div className="error-message-container">
                  <div className="avatar-empty-container"></div>
                  <p >It seems you are trying to Grant access for a wrong workspace.
                    <br />Please check the screenshot below to choose correct one:</p>
                </div>
                <div className="connect-workspace"></div>
                  <div className="button-container">
                  {/* {this.props.isAuthenticated ? (
              <Button
              as="a"
              id="redirectToSlackID"
              className="field big-button slack-btn"
              fluid
              href={this.props.slackUrl || ""}
            >
              <>
                <img src={slackIcon} alt="" srcSet="" />
                <span>Try again</span>
              </>
            </Button>
            ):(
              <Button
                className="big-button"
                as={"a"}
                href="https://pavooq.com/#rec286082479"
                target="_self"
              >
                Proceed
              </Button>
            )} */}
                  </div>
              </div>


          </div>
        )}
      </div>
    )
  }
}
