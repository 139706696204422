import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Auth } from "aws-amplify";
import "./RecoverPasswordSuccess.sass";
import { Button, Input, Modal } from 'semantic-ui-react';
import dompurify from 'dompurify';
import PasswordStrengthBar from "react-password-strength-bar";
import qs from "qs"

export default class RecoverPasswordSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPasswordVisible: false,
      isRepeatPasswordVisible: false,
      isVerifying: false,
      password: "",
      repeatPassword: "",
      passwordWasUpdated: false,
    };
    this.minPassScore = 1;
    this.sanitizer = dompurify.sanitize;
    this.token = undefined;
  }
  componentDidMount =() =>{
    this.token = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).token
    // console.log("this.token",this.token);
  }
  togglePass = (event) => {
    event.preventDefault();
    this.setState(prevState => ({
      isPasswordVisible: !prevState.isPasswordVisible
    }))
  }
  toggleRepeatPass = (event) => {
    event.preventDefault();
    this.setState(prevState => ({
      isRepeatPasswordVisible: !prevState.isRepeatPasswordVisible
    }))
  }

  handleChange = event => {
    // this.setState({
    //   [event.target.name]: event.target.value
    // });
    if (event.target.name === "password") {
      // console.log(" password  !!!!!");
      this.setState({
        isPasswordError: false,
        [event.target.name]: this.sanitizer(event.target.value)
      });
      this.password = this.sanitizer(event.target.value);
    }
    if (event.target.name === "repeatPassword") {
      // console.log(" repeatPassword  !!!!!");
      this.setState({
        isRepeatPasswordError: false,
        [event.target.name]: this.sanitizer(event.target.value)
      });
      this.repeatPassword = this.sanitizer(event.target.value);
    }
  };

  handleChangePWD = (event) => {
    // console.log("handleChangePWD", event);
    if (event >= this.minPassScore) {
      this.setState({
        isPasswordValid: true,
        // password: event.password,
      });
    } else {
      this.setState({
        isPasswordValid: false,
        // password: event.password,
      });
    }
  }
  onFail = (e) => {
    if (e) {
      console.log("ERROR", e);
      this.setState({ isError: true, errorText: e + "" || "Please try reloading the page." }); // errorText: e
      // this.respSlackError = e;
    }
  };
  updatePassword = (event) => {
    let targetName = event.target.name;
    if (targetName === "passwordButton" && this.state.isPasswordValid && this.state.password && this.state.password.length > 0) {
      if (this.state.password === this.oldPassword) {
        return
      }

      let userUpdageBody = {
        password: this.state.password,
      };
      fetch(`${this.props.apiUrl}/account`, { // old /customers/${this.state.loginnedUserData["ID"]}
        method: "PATCH",
        // headers: {
        //   // Accept: "application/json",
        //   "Content-Type": "application/json",
        //   "Bearer" //<token>
        // },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
          //   "Bearer" //<token>
        },
        credentials: 'include',//'same-origin',
        body: JSON.stringify(userUpdageBody),
      })
        .then((res) => {
          // console.log("res", res, res.ok);
          if (res.ok) {
            this.oldPassword = this.state.password;

            this.setState({
              passwordChanged: true,
              needToChangePassword: false,
              passwordWasUpdated: true
            })
          } else if(res.status===401) {
            this.onFail("Seems you didn't logged in");
          } else {
            this.onFail(res && res.status? "Status code: "+res.status+"" : "Please try again") 
          }
        })
        .catch((e) => {
          console.log("ERROR", e);
          this.onFail(e);
        });
      // }
    }
  };

  enterPasswordForm = () => {
    return (<div className="registration-form">

      <div className="password">
        <Input
          className={this.state.isPasswordError ? "item-content error" : "item-content"}
          name="password"
          id="password"
          onPaste={this.handleChange}
          onChange={this.handleChange}
          placeholder={"New password"}
          type={this.state.isPasswordVisible ? "text" : "password"}
          icon={<div className={this.state.isPasswordVisible ? "pwrd-icon" : "pwrd-icon-hidden"} onClick={this.togglePass} alt="" srcSet="" ></div>}
          value={this.state.password}
        />
        <PasswordStrengthBar
          className="PasswordStrengthBar"
          minScore={this.minPassScore}
          minLength={6}
          onChangeScore={this.handleChangePWD}
          password={this.state.password}
          barColors={[
            this.props.darkTheme ? " #686868" : "#9A8AB6",
            "#E01E5A",
            "#DCE01E",
            "#DCE01E",
            "#27C245",
          ]}
        />
      </div>
      <div className="password">
        <Input
          className={this.state.isPasswordError ? "item-content error" : "item-content"}
          name="repeatPassword"
          id="repeatPassword"
          onPaste={this.handleChange}
          onChange={this.handleChange}
          placeholder={"Repeat new password"}
          type={this.state.isRepeatPasswordVisible ? "text" : "password"}
          icon={<div className={this.state.isRepeatPasswordVisible ? "pwrd-icon" : "pwrd-icon-hidden"} onClick={this.toggleRepeatPass} alt="" srcSet="" ></div>}
          value={this.state.repeatPassword}
        />
        <PasswordStrengthBar
          className="PasswordStrengthBar"
          minScore={this.minPassScore}
          minLength={6}
          onChangeScore={this.handleChangePWD}
          password={this.state.repeatPassword}
          barColors={[
            this.props.darkTheme ? " #686868" : "#9A8AB6",
            "#E01E5A",
            "#DCE01E",
            "#DCE01E",
            "#27C245",
          ]}
        />
        {(this.state.password.length > 0 && this.state.repeatPassword.length > 0 && this.state.password !== this.state.repeatPassword) && (
          <div className="password-error">
            Passwords do not correspond
          </div>
        )}
      </div>

      <br />
      <div className="password-buttons-container">

        <Button disabled={!(this.state.password &&
          this.state.repeatPassword &&
          this.state.password === this.state.repeatPassword)} className="big-button"
          onClick={this.updatePassword}
          name="passwordButton">
          Save
        </Button>
      </div>



    </div>
    )
  }

  congradulationForm = () => {
    return (
      <div className="content">
        <div className="celebrate-container"></div>
        <p>Well done!
          <br />
          Your new password was successfully saved
        </p>
        {this.props.isAuthenticated ? (
          <Button
            className="big-button"
            as={Link}
            to="/forcepage"
          // target="_self"
          >
            Proceed
          </Button>
        ) : (
          <Button
            className="big-button"
            as={Link}
            to="/login"
          // target="_self"
          >
            Proceed
          </Button>
        )}
      </div>
    )
  }

  errorContainerRender = () => {
    return (
      <Modal
        size="mini"
        closeIcon={<div className="close-container"></div>}
        className={this.props.darkTheme ? "customModal dark" : "customModal"}
        centered={true} //false
        open={this.state.isError}
        onClose={() => this.setState({ isError: false })}
        onOpen={() => this.setState({ isError: true })}
      // trigger={

      // } //{<Button>Show Modal</Button>}
      >
        <Modal.Header>Opps..</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            An error has occurred.
            <br />
            {/* Please try reloading the page. */}
            {this.state.errorText ? this.state.errorText : ""}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions className="center">
          <Button
            className="wide"
            onClick={() => this.setState({ isError: false })}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
  render() {
    return (

      <div
        // className="Login"
        className={this.props.darkTheme ? "RecoverPasswordSuccess dark" : "RecoverPasswordSuccess"}>
        <div className="main-content">

          <Link className="sign-in-container" to={`/`}>
            <div className="logo-big-container"></div>
          </Link>

            <div className="main-registration-form">
              <div className="pass-recovery-header">
                <p>Create new password</p>
              </div>

              <div className="registration-form">
                {!this.state.passwordWasUpdated ?
                  this.enterPasswordForm() : 
                  this.congradulationForm()
                }
              </div>
            </div>
            
        </div>
        <div className="additional-container">
          <div className="mail-to-container">
            <div className="avatar-monochrome-support"></div>
            <p>Any problem? Please, write me at
              {/* <Link
                // className='borderless-button'
                to='#'
                onClick={(e) => {
                    window.location = 'yevhen@pavooq.com';
                    e.preventDefault();
                }}
              >
                {' yevhen@pavooq.com'}
              </Link> */}
              <a id="loginSupport" href="mailto:yevhen.bereza@pavooq.com"> yevhen.bereza@pavooq.com</a>
            </p>
          </div>
        </div>
        {this.errorContainerRender()}
      </div>

    );
  }
}
