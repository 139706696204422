
import React, { memo, useMemo, useState, useEffect, useRef } from 'react';
import { Input } from "semantic-ui-react";
import userIcon from "../img/user.svg";
import userIconDark from "../img/user-dark.svg";
import { useDispatch } from "react-redux";
import { setClickedNode, setHoveredNode } from "../redux/toolsSlice";

const SearchedUserHolder = memo((props) => {

  const dispatch = useDispatch();
  // const clickedNode = useSelector(state => state.tools.clickedNode);
  // const hoveredNode = useSelector(state => state.tools.hoveredNode);
  let wrapperRef = useRef(null);
  let inputRef = useRef(null);
  // const [isSearchInputActive, setIsDesc] = useState(true);
  const [foundUsers, setFoundUsers] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState(false);

  let that = useMemo(() => {
    let that = {};
    that.isSearchInputActive = false;
    that.searchVisible = false;
    that.activeNode = undefined;
    that.graphClick = false;
    that.higlightedText = false;
    return that;
  }, []);



  // wrapperRef.current

  /**
   * Alert if clicked on outside of element
   */
  let handleClickOutside = (event, graphClick) => {
    // console.log("handleClickOutside", event, graphClick);
    if ((
      event &&
      event.target &&
      wrapperRef &&
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target)
      // && !searchQuery
    ) || graphClick) {
      props.handleGraphClick(false);
      // console.log("You clicked outside of search pannel!");
      that.isSearchInputActive = false;
      that.searchVisible = false;
      that.graphClick = false;
      that.higlightedText = false;
      setRefresh(!refresh);
    } else {
      that.graphClick = true;
    }
  }

  useEffect(() => { // componentDidMount
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, []);

  // componentDidMount() {
  //   props.clickedNodeHolderUpdateTable(undefined, that.clickLocal);
  //   props.hoveredNodeHolderUpdateTable(undefined, that.hoverLocal);
  //   document.addEventListener("mousedown", that.handleClickOutside);
  // }

  // componentWillUnmount() {
  //   document.removeEventListener("mousedown", that.handleClickOutside);
  // }

  // shouldComponentUpdate(nextProps, nextState) {
  //   // console.log("shouldComponentUpdate", nextProps.graphClick , that.graphClick);
  //   if (nextProps.graphClick && that.graphClick) { // && nextProps.graphClick !== props.graphClick){//!== props.graphClick){
  //     // that.handleClickOutside(false, nextProps.graphClick);
  //     // that.setState({ isSearchInputActive: false, searchVisible: false });
  //     that.isSearchInputActive = false;
  //     that.searchVisible = false;
  //   }
  //   return true
  // }


  // let clearInput = () => {
  //   that.setState({
  //     foundUsers: undefined,
  //     inputValue: "",
  //     searchQuery: "",})
  // }

  // filterProject = (query, project) => {
  //   return query ? project.name.toLowerCase().includes(query.toLowerCase()) : [];
  // }

  let handleInputChange = (event) => {
    const query = event.currentTarget.value;
    // const { projects, onFindUsers } = props;
    const projects = props.projects;

    const filteredProjects = query
      ? projects.filter((project) =>
        project.name.toLowerCase().includes(query.toLowerCase())
      )
      : [];
    // props.cnangeSearchQuerry(!!query);
    setFoundUsers(filteredProjects);
    setInputValue(query);
    setSearchQuery(!!query);
    // onFindUsers(filteredProjects);
  };

  let click = (d, e) => {
    if (e) {
      e.stopPropagation();
    }
    // that.clickedNode = d ? d.id : undefined;
    // props.clickedNodeHolderUpdateChart(d, undefined, undefined);
    // props.cnangeSearchQuerry(false);
    // that.setState({
    //   foundUsers: [],
    //   inputValue: "",
    //   searchQuery: false,
    //   searchVisible: false,
    // });
    setFoundUsers([]);
    setInputValue("");
    setSearchQuery(false);
    that.searchVisible = false;
    dispatch(setClickedNode(d || undefined));
    // console.log("table",that.clickedNode);
  };

  let hover = (d, e) => {
    // console.log("table hover",d,e);
    if (e) {
      e.stopPropagation();
    }
    // that.hoveredNode = d ? d.id : undefined;
    // props.hoveredNodeHolderUpdateChart(d);
    // setRefresh(!refresh);
    dispatch(setHoveredNode(d || undefined));
  };


  let slackUserListToInviteRender = (data = []) => {
    // data.sort((a, b) => {
    //   // console.log("a,b",a,b);
    //   return (a.invited > b.invited) ? 1 : ((b.invited > a.invited) ? -1 : 0)
    // });
    return data.map((d, i) => {
      // if (!d.granted) {
      return (
        <div
          className="user-holder" //that.state.selectedUsers
          key={d.id + "container" + i}
          onClick={(e) => click(d, e)}
          onMouseOver={(e) => hover(d, e)}
        // onClick={(e) => !!(d && !d.invited) ? that.handleUserSelect(e, d) : console.log("nothing")}
        >
          <img
            className="user-avatar"
            src={
              d.avatar
                ? d.avatar
                : props.darkTheme
                  ? userIconDark
                  : userIcon
            }
            alt="user"
            srcSet=""
          />
          {/* <Checkbox
              checked={d.checked}
              // onClick={(e, data) => that.handleUserToInviteSelector(e, data)}
              key={d.id + "_" + i}
              name={d.id}
              label={d.name}
            /> */}
          <p>{d && d.name ? d.name : d && d.id ? d.id : "Unknown user"}</p>
          {/* {(d && d.invited) && <p className="date-container">Invited: {that.parseDate(new Date(d.invited * 1000))}</p>} */}
        </div>
      );
      // }
    });
    //
  };


  return (
    <div
      ref={wrapperRef}

      className={`force-btn-group search-pannel ${props.tutorialVIsible
        } ${that.searchVisible ? "" : "short"} ${(foundUsers && foundUsers.length > 0) ? "border-bottom-none" : ""}`} // || searchQuery 
    > 
    {/* || (that.searchVisible && foundUsers.length === 0)  */}
      <div className="search-input-holder"
        onMouseOver={() => {
          // // console.log("onMouseOver", that.searchVisible);
          // // that.setState({ searchVisible: true }
          // that.searchVisible = true;
          // that.graphClick = false;
          // if(ref && ref.inputRef && ref.inputRef.current && !that.higlightedText){ //searchQuery && 
          //   ref.select();
          //   that.higlightedText = true;
          // }
          // that.setState({ refresh: !that.state.refresh });
          // that.setState({ searchVisible: true, isSearchInputActive: true })
          // that.isSearchInputActive = true;
          that.graphClick = false;
          that.searchVisible = true;
          setRefresh(!refresh);
          // if (ref && ref.inputRef && ref.inputRef.current) {
          //   setTimeout(() => {
          //     // console.log(ref, "focus");
          //     // ref.focus();
          //     if(searchQuery && !that.higlightedText){
          //       ref.select();
          //       that.higlightedText = true;
          //     }
          //     // ref.inputRef.current.focus();
          //   }, 100);
          // }
        }}
        onClick={() => {
          // that.setState({ searchVisible: true, isSearchInputActive: true })
          that.isSearchInputActive = true;
          that.searchVisible = true;
          setRefresh(!refresh);
          if (inputRef && inputRef.current) {
            setTimeout(() => {
              // console.log(ref, "focus");
              inputRef.current.focus();
              if (searchQuery && !that.higlightedText) {
                inputRef.current.select();
                that.higlightedText = true;
              }
              inputRef.current.focus();
            }, 100);
          }
        }}
        onMouseOut={() => {
          // console.log("onMouseOut");
          if (!that.isSearchInputActive) { //!searchQuery &&
            if (inputRef && inputRef.current && inputRef.current.blur) {
              inputRef.current.blur();
            }
            // that.setState({ searchVisible: false });
            that.searchVisible = false;
            that.higlightedText = false;
            setRefresh(!refresh);
          }
        }}>
        <Input
          className="dash-search"
          // icon="search"
          placeholder="Search"
          onChange={handleInputChange}
          // onClick={() => that.setState({ isSearchInputActive: true })}
          // onBlur={() => that.setState({ isSearchInputActive: false })}
          ref={inputRef}
          // onClick={()=>props.cnangeSearchQuerry(true)}
          // onBlur={()=>props.cnangeSearchQuerry(false)}
          value={inputValue}
        />
        <div className="search-container">
          {props.darkTheme ?
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="path-1-outside-1_2876:12683" maskUnits="userSpaceOnUse" x="2" y="2" width="19" height="19" fill="black">
                <rect fill="white" x="2" y="2" width="19" height="19" />
                <path d="M4 10C4 13.3137 6.68629 16 10 16C11.6348 16 13.117 15.3462 14.1991 14.2857C15.3105 13.1967 16 11.6789 16 10C16 6.68629 13.3137 4 10 4C6.68629 4 4 6.68629 4 10Z" />
              </mask>
              <path d="M19.4737 20.5343C19.7688 20.825 20.2436 20.8214 20.5343 20.5263C20.825 20.2312 20.8214 19.7564 20.5263 19.4657L19.4737 20.5343ZM13.6728 14.82L19.4737 20.5343L20.5263 19.4657L14.7255 13.7514L13.6728 14.82ZM14.1991 14.2857L15.249 15.3571L15.249 15.3571L14.1991 14.2857ZM10 14.5C7.51472 14.5 5.5 12.4853 5.5 10H2.5C2.5 14.1421 5.85786 17.5 10 17.5V14.5ZM5.5 10C5.5 7.51472 7.51472 5.5 10 5.5V2.5C5.85786 2.5 2.5 5.85786 2.5 10H5.5ZM10 5.5C12.4853 5.5 14.5 7.51472 14.5 10H17.5C17.5 5.85786 14.1421 2.5 10 2.5V5.5ZM14.5 10C14.5 11.2595 13.9846 12.3958 13.1493 13.2144L15.249 15.3571C16.6364 13.9976 17.5 12.0983 17.5 10H14.5ZM13.1493 13.2144C12.3361 14.0112 11.2266 14.5 10 14.5V17.5C12.043 17.5 13.8978 16.6811 15.249 15.3571L13.1493 13.2144Z" fill="#BDBDBD" mask="url(#path-1-outside-1_2876:12683)" />
            </svg>
            :
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="path-1-outside-1_2876:12673" maskUnits="userSpaceOnUse" x="2" y="2" width="19" height="19" fill="black">
                <rect fill="white" x="2" y="2" width="19" height="19" />
                <path d="M4 10C4 13.3137 6.68629 16 10 16C11.6348 16 13.117 15.3462 14.1991 14.2857C15.3105 13.1967 16 11.6789 16 10C16 6.68629 13.3137 4 10 4C6.68629 4 4 6.68629 4 10Z" />
              </mask>
              <path d="M19.4737 20.5343C19.7688 20.825 20.2436 20.8214 20.5343 20.5263C20.825 20.2312 20.8214 19.7564 20.5263 19.4657L19.4737 20.5343ZM13.6728 14.82L19.4737 20.5343L20.5263 19.4657L14.7255 13.7514L13.6728 14.82ZM14.1991 14.2857L15.249 15.3571L15.249 15.3571L14.1991 14.2857ZM10 14.5C7.51472 14.5 5.5 12.4853 5.5 10H2.5C2.5 14.1421 5.85786 17.5 10 17.5V14.5ZM5.5 10C5.5 7.51472 7.51472 5.5 10 5.5V2.5C5.85786 2.5 2.5 5.85786 2.5 10H5.5ZM10 5.5C12.4853 5.5 14.5 7.51472 14.5 10H17.5C17.5 5.85786 14.1421 2.5 10 2.5V5.5ZM14.5 10C14.5 11.2595 13.9846 12.3958 13.1493 13.2144L15.249 15.3571C16.6364 13.9976 17.5 12.0983 17.5 10H14.5ZM13.1493 13.2144C12.3361 14.0112 11.2266 14.5 10 14.5V17.5C12.043 17.5 13.8978 16.6811 15.249 15.3571L13.1493 13.2144Z" fill="#3D1D75" mask="url(#path-1-outside-1_2876:12673)" />
            </svg>

          }

        </div>
      </div>

      <div className={`dashbord-search-container ${that.searchVisible ? "" : "invisible"}`}
      //|| searchQuery 
      >
        {slackUserListToInviteRender(foundUsers)}
        {that.searchVisible &&
          searchQuery &&
          (foundUsers.length === 0 || !foundUsers) && (
            <p className="no-result">No result</p>
          )}
      </div>
    </div>
  );
});

export default SearchedUserHolder;