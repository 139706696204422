
import React, { memo, useMemo, useState, useEffect } from 'react';
// import { Link } from "react-router-dom";
import { Table } from 'semantic-ui-react'; // Divider,
// import { Loader } from 'semantic-ui-react'; //Modal, Button, Icon
// import "./NodesTable.css";
import "./NodesTable.sass";
// import * as d3 from "d3"
import userIcon from "../../img/user.svg";
import userIconDark from "../../img/user-dark.svg";
import { useDispatch, useSelector } from "react-redux";
import { setClickedNode, setHoveredNode } from "../../redux/toolsSlice";



const NodesTable = memo((props) => {

  const dispatch = useDispatch();
  const isMobileDevice = useSelector(state => state.tools.isMobileDevice);
  const clickedNode = useSelector(state => state.tools.clickedNode);
  const hoveredNode = useSelector(state => state.tools.hoveredNode);
  const clickedNodeId = useSelector(state => state.tools.clickedNodeId);
  // const hoveredNodeId = useSelector(state => state.tools.hoveredNodeId);
  // const [refresh, setRefresh] = useState(false);
  // const [clickInTable, setClickInTable] = useState(false);
  // const [leaveTable, setLeaveTable] = useState(false);
  const [field, setField] = useState('messageAmountForTable');
  const [isDesc, setIsDesc] = useState(true);

  let that = useMemo(() => {
    let that = {};
    // that.formatedHeaders = ["User", "Status", "contactsForTable", "Messages"];
    that.formatedHeaders = [
      { field: 'name', name: 'User' },
      { field: 'invitable', name: 'Status' }, //d.invitable
      { field: 'contactsForTable', name: 'Contacts' },
      { field: 'messageAmountForTable', name: 'Messages' },
    ]
    that.headers = [
      { field: 'avatar' }, // sort: 'asc', type: 'abc'
      { field: 'name' }, // , sort: 'asc', type: 'abc' },
      { field: 'invitable' }, // , sort: 'asc', type: 'abc' },
      { field: 'contactsForTable' }, // , sort: 'asc', type: 'num' },
      { field: 'messageAmountForTable' }, // , sort: 'asc', type: 'num' },
    ];
    that.clickedNodeId = clickedNodeId;
    return that;
  }, []);


  // useEffect(()=>{ // componentDidMount
  //   props.clickedNodeHolderUpdateTable(undefined, clickLocal);
  //   props.hoveredNodeHolderUpdateTable(undefined, hoverLocal);
  // },[]);


  let onSort = (d) => {
    setField(d.field);
    setIsDesc(field === d.field ? !isDesc : !isDesc);
  }

  let renderHeaderCells = () => {
    return (that.formatedHeaders.map((item, i) => {
      return <Table.HeaderCell
        // colSpan="3"
        // id="header"
        onClick={e => onSort(item)} key={i + "th"}
      // sorted={column === item ? direction : null}
      >
        <div className="table-header" >
          <div> {i === 0 ? item.name + " (" + props.nodes.length + ") " : item.name} </div>
          <div className={(props.nodes && props.nodes.length === 0) ? "arrow-bottom disabled" : isDesc ? "arrow-bottom" : "arrow-top"} style={{ opacity: field === item.field ? 1 : 0.3 }}></div>
        </div>
      </Table.HeaderCell>
    })
    )
  }

  let click = (d, e) => {
    // console.log("table click",d,e);
    if (e) {
      e.stopPropagation();
    }
    that.clickedNodeId = d ? d.id : undefined;
    // props.clickedNodeHolderUpdateChart(d, undefined, undefined);
    dispatch(setClickedNode(d || undefined));
  }

  useEffect(() => {// update scroll position on chanigh node inside the force chart
    if (that.clickedNodeId !== clickedNodeId && !isMobileDevice) {
      let target = document.getElementById("table-node-" + clickedNodeId);
      if (target) {
        target.scrollIntoView({ block: "center", behavior: "smooth" });
      }
    }
    that.clickedNodeId = clickedNodeId;
    // dispatch(setClickedNode(d ? d.id : undefined));
  }, [clickedNodeId]);


  let hover = (d, e) => {
    if (e) {
      e.stopPropagation();
    }
    // props.hoveredNodeHolderUpdateChart(d);
    // setRefresh(!refresh);
    dispatch(setHoveredNode(d || undefined));
  }

  // let hoverLocal = (d, e) => {
  //   // hoveredNode = d ? d.id : undefined;
  //   // setRefresh(!refresh);
  //   dispatch(setHoveredNode(d ? d.id : undefined));
  // }

  let renderRowsAndCells = () => {
    // let nodes = props.nodes.map(d => {
    //   messageAmountForTable = d.messageAmount[props.relationSelected]
    //   return {

    //   }
    // })
    // console.log(" props.nodes", props.nodes);
    return (
      props.nodes.map((d, j) => {
        d.contactsForTable = props.relationSelected === 'private' ? +d.contactByRelDirectCount : +d.contactCount;
        d.messageAmountForTable = d.messageAmount[props.relationSelected];
        return (
          
          <Table.Row
            tabIndex={j === 0 ? 0 : -1}
            id={"table-node-" + d.id}
            className={(clickedNode && clickedNode.id === d.id) ? "active-node" : (hoveredNode && hoveredNode.id === d.id) ? "active-node" : ""} onMouseOver={e => hover(d, e)} onClick={e => click(d, e)} key={j + "tr"}
          >
            {
              that.headers.map((item, i) => {
                return (
                  <Table.Cell className={item.field === "avatar" ? "avatar-container" : ""} key={(i + "td" + j)}>
                    {item.field === "avatar" && <img className="user-avatar" src={d && d.avatar ? d.avatar : props.darkTheme ? userIconDark : userIcon} alt="user-icon"></img>}
                    {/* <p> */}
                    {item.field === "invitable" && <div className={`user-activity-status ${d.invitable ? "active" : "inactive"
                      }`}></div>}
                    {/* <p title={that.name || that.id}> */}
                    {/* {item.field !== "avatar" && item.field !== "invitable" &&
                      (item.field === 'messageAmountForTable' ?
                      (+d[item.field])
                      :d[item.field]//.toFixed()
                      )} */}
                    {item.field !== "avatar" && item.field !== "invitable" &&
                      (item.field === 'messageAmountForTable' || item.field === 'contactsForTable' ?
                      +d[item.field]
                      : d[item.field]//.toFixed()
                      )}
                    {/* </p> */}
                  </Table.Cell>
                )
              })
            }
          </Table.Row>
        )
      }
      ))
  }

  if (field === "name" || field === "avatar") {
    if (isDesc) {
      props.nodes.sort((a, b) => ((a[field] > b[field]) ? 1 : ((a[field] < b[field]) ? -1 : 0)));
    } else {
      props.nodes.sort((b, a) => ((a[field] > b[field]) ? 1 : ((a[field] < b[field]) ? -1 : 0)));
    }
  } else {
    if (isDesc) {
      props.nodes.sort((a, b) => +b[field] - +a[field]);
    } else {
      props.nodes.sort((b, a) => +b[field] - +a[field]);
    }
  }
  return (
    <div className={props.areaChartVisible ? "customTable short interactive-scroll" : "customTable long interactive-scroll"}
    >
      {!!(props.nodes && props.nodes.length) &&
        <>
          <Table unstackable singleLine>
            <Table.Header>
              <Table.Row>
                {renderHeaderCells()}
              </Table.Row>
            </Table.Header>
            <Table.Body className="table-body-wrapper" onMouseLeave={e => hover()}>
              {renderRowsAndCells()}
            </Table.Body>
          </Table>
        </>}
      {!!(props.nodes && props.nodes.length === 0) &&
        <div className="no-data-container">
          No data available yet
        </div>
      }
    </div>
  );
});


export default NodesTable;