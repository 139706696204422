import React, { Component } from "react";
import { Link } from "react-router-dom";
// import "./NotFound.css";
import "./NotFound.sass";
// import '../../img/'

export default class NotFound extends Component {
  // constructor(props) {
  //   super(props);
  // }


  componentDidMount() {
    document.title = "Pavooq Not Found";
  }

  render(){
    // let autentificatedUser = this.props.isAuthenticated;
    // console.log(" this.props", this.props,autentificatedUser);
    return(
      <div className="not-found">
      <div className="main-content">

        {/* <Link className="sign-in-container" to={`/login`}>
          <div className="logo-big-container"></div>
          </Link> */}

        <div className="NotFound">{/* padding-rem */}
        <div className="img-wrapper">
          <div className="not-found-img"></div>
        </div>
          <div className="text-container">
            <p>
              Try to open other pages:
            </p>
            <Link className="sign-in-container" to={`/forcepage`}>
            Messages
            </Link>
            <br/>
            <Link className="sign-in-container" to={`/profile`}>
            Profile
            </Link>
          </div>
        </div>

      </div>

    </div>


    )
  }
}