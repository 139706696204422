import React from 'react';
import ReactDOM from 'react-dom';
import store from './redux/store';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router
} from 'react-router-dom';
// import './index.css';

import './index.sass';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'semantic-ui-css/semantic.min.css';
import "react-datepicker/dist/react-datepicker.css";
import TagManager from 'react-gtm-module'
// import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
// import Amplify from "aws-amplify";
// import config from "./config";
const tagManagerArgs = {
  gtmId: 'GTM-K4B8WWX'
}

TagManager.initialize(tagManagerArgs)
ReactDOM.render(
  <Router>
    <Provider store={store}>
      <React.Fragment>
        <App />
      </React.Fragment>
    </Provider>
  </Router>,
  document.getElementById("root")
);

serviceWorker.unregister();