import React, { Component } from "react";// import * as d3 from "d3";
import { Link } from "react-router-dom";
import googleIcon from "../../img/google-icon.svg";
// import './SignUp.css';
import "./SignUp.sass";
// import { Link } from "react-router-dom";
// import { Auth } from "aws-amplify";
import { Form, Button, Checkbox, Modal } from "semantic-ui-react"; //Input, Loader,
// import ReactPasswordStrength from "react-password-strength";
import PasswordStrengthBar from "react-password-strength-bar";
import { IframePolicy } from "../../components/IframePolicy";
import dompurify from 'dompurify';
// import { validate } from 'isemail';
// import Login from "../Login/Login";
import helpers from "../../helpers";

export default class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // clientData: {},
      // SignUpData: {},
      user: undefined,
      id: undefined,
      companyId: undefined,
      isEmailValid: true,
      isNameValid: true,
      email: "", //FirstStep
      password: "",
      isPasswordValid: false,
      isPhoneNumberValid: true,
      // name: "", // secondStep
      firstName: "",
      lastName: "",
      company: "",
      phone: "",
      companySize: undefined,
      isPasswordVisible: false,
      openPolicyModal: false,
      openCancelModal: false,
      policyButtonAvailable: true,
      privatPolicyAcepted: false,
      currentStep: "firstRegistrationStepButton",
      firstStepPassed: false,
      secondStepPassed: false,
      thirdStepPassed: false,
      isError: false,
      errorText: "",
      // darkTheme: this.props.darkTheme || "false",
    };
    this.validateEmail = helpers.validateEmail.bind(this);
    this.sanitizer = dompurify.sanitize;
    this.stepChange = this.stepChange.bind(this);
    this.options = [
      { key: "less15", text: "1-15", value: [0, 15] },
      { key: "16-50", text: "16-50", value: [16, 50] },
      { key: "51-100", text: "51-100", value: [51, 100] },
      { key: "100+", text: "more than 100", value: [101, 9999] },
    ];
    // this.companySize = '';
    this.minPassScore = 1;
    // this.apiUrl = window.__RUNTIME_CONFIG__.REACT_APP_API_URL || undefined; //process.env
    this.companies = {
      slask: false,
      googleChat: false,
      telegram: false,
      microsoftTeams: false,
      zoom: false,
      skype: false,
      mattermost: false,
      googleMeet: false,
      whatsApp: false,
      signal: false,
      googleWorkspace: false,
      office365: false,
      ownServer: false,
      otherService: false,
    };
    this.userCompaniesArray = [];
    // this.validateFullName = helpers.validateFullName();
  }

  // validateFullName(fullName) {
  //   const pattern = /^[a-zA-Z]{2,40}( [a-zA-Z]{2,40})+$/;
  //   return pattern.test(String(fullName));
  // }
  componentDidMount() {
    document.title = "Pavooq Sign Up";
  }

  validateFirstStep() {
    let conditionPassed = !!(
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.isPasswordValid &&
      this.state.isEmailValid &&
      this.state.privatPolicyAcepted
    );
    return conditionPassed;
  }

  validateSecondStep() {
    let conditionPassed = !!(
      this.state.firstStepPassed &&
      this.state.isNameValid &&
      this.state.firstName.length > 0 &&
      this.state.lastName.length > 0 &&
      this.state.company.length > 0 &&
      this.state.companySize
    );
    // console.log(
    //   "validateSecondStep:",
    //   this.state.name.length > 0,
    //   this.state.company.length > 0,
    //   "this.companySize : ",
    //   this.state.companySize
    // );
    return conditionPassed;
  }
  stepChange(e) {
    // e.target.id
    this.setState({ currentStep: e.target.id })
  }

  togglePass = (event) => {
    event.preventDefault();
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible,
    });
  };

  handleChange = (event) => {
    // console.log("event", event);
    if (event && event.target && event.target.name) {
      // console.log(
      //   // "event.target.name",
      //   // event.target.name,
      //   "event.target.value",
      //   event.target.value
      // );
      if (event.target.name === "email") {
        this.setState({
          isEmailValid: this.validateEmail(event.target.value),
        });
      }
      if (event.target.name === "phone") {
        let myString = event.target.value.replace(/\D/g,'');
        // console.log(" phone:", myString);
        this.setState({
          isPhoneNumberValid: myString.length > 0 && myString.length < 20 ? true : false,
        });
      }
      // if (event.target.name === "name") {
      //   this.setState({
      //     isNameValid: this.validateFullName(event.target.value),
      //   });
      // }
      this.setState({
        // isEmailValid: this.validateEmail(event.target.value),
        [event.target.name]: this.sanitizer(event.target.value)
      });
    }
  };

  handleSelect = (event, { name, value }) => {
    // console.log("event, { name, value }", event, { name, value });
    // this.state[name] = value;
    this.setState({
      [name]: value, //this.sanitizer(event.target.value)
    });
    // console.log("this[name] = value", this.state[name], value);
    this.validateSecondStep();
    // this.setState({
    //   [name]: value
    // });
    // console.log("current:", value, "in state:", this.state["custom:state"]);
  };

  handleChangePWD = (event) => {
    // console.log("handleChangePWD", event);
    if (event >= this.minPassScore) {
      this.setState({
        isPasswordValid: true,
        // password: event.password,
      });
    } else {
      this.setState({
        isPasswordValid: false,
        // password: event.password,
      });
    }

    // console.log("PASSWORD:",event,"this.state.password",this.state.password);
  };

  hendleCheckBoxChange = (event) => {
    // console.log(
    //   "this.state.privatPolicyAcepted",
    //   this.state.privatPolicyAcepted
    // );
    this.setState({
      privatPolicyAcepted: !this.state.privatPolicyAcepted,
    });
  };

  handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      this.setState({
        policyButtonAvailable: true,
      });
    }
  };

  pavooqAPILogin(email, password, persistent) {
    var details = {
      username: email,
      password: password,
    };
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(`${this.props.apiUrl}/authorization?persistent=${persistent}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      credentials: 'include',//'same-origin',
      body: formBody,
    })
      // .then((res) => res.json())
      .then((res) => {
        // console.log("res ", res, "res ok", res.ok);
        if (res.ok) {
          return res.json();
        }
      })
      .then((response) => {
        // console.log(
        //   "pavooqAPILogin response .access_token",
        //   // response.access_token
        // );
        this.setState({
          // apiToken: response.access_token,
          // refreshToken: response.refresh_token,
          user: response,
        });
        // let user = {
        //   email: this.state.email,
        //   password: this.state.password,
        // };

        // this.props.userHasAuthenticated(true);
        // this.props.getSlackAutorisationLink(); //response.access_token
        this.props.getSlackUserListToInvite(true); //response.access_token,this.props.apiUrl,
      })
      .catch((e) => {
        console.log("ERROR", e);
      });
  }

  firstStepHandleSubmit = async (event) => {
    let details = {
      email: this.state.email,
      password: this.state.password,
    };
    // console.log("firstStepHandleSubmit", details);
    if (this.state.email && this.state.password) {
      fetch(`${this.props.apiUrl}/account`, { // old "customers"
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        // credentials: 'include',//'same-origin',
        body: JSON.stringify(details),
      })
        // .then((resp) => {
        //   if(!resp.ok)
        // })
        .then((res) => {
          // console.log("res", res, res.ok);
          if (res.ok) {
            return res.json();
          } else {
            if (res.status === 409) {
              this.onFail("User has already exist");
              // this.setState({ firstStepPassed: false });
            }
            // this.onFail(res.details || res.statusText || res || "Something wrong..");
            // this.setState({ firstStepPassed: false });
          }
        })
        .then((response) => {
          if (!this.state.isError) {
            // console.log("firstStepHandleSubmit Resp", response);

            this.APIToken = this.pavooqAPILogin(
              this.state.email,
              this.state.password,
              false
            );
            this.setState({ currentStep: "secondRegistrationStepButton", firstStepPassed: true, id: response["ID"] });
          } else {
            if (response && response.statusText) {
              this.onFail(response.statusText);
            }
            // this.setState({ firstStepPassed: false });
          }
        })
        .catch((e) => {
          console.log("firstStepHandleSubmit ERROR", e);
          this.onFail(e);
        });
    }
  };

  secondStepHandleSubmit = async (event) => {
    // let firstName = this.state.name.split(" ").slice(0, -1).join(" ");
    // let lastName = this.state.name.split(" ").slice(-1).join(" ");
    // console.log("secondStepHandleSubmit");
    let secondStepCustomersPatchBody = {};
    // console.log("phone validation", !!(this.state.phone === null || !this.state.phone || !this.state.isPhoneNumberValid), !!(!this.state.phone && !this.state.isPhoneNumberValid), "this.state.phone === null", this.state.phone === null, "!this.state.phone", !this.state.phone, "!this.state.isPhoneNumberValid", !this.state.isPhoneNumberValid);
    if (!!(!this.state.phone || !this.state.isPhoneNumberValid)) {
      secondStepCustomersPatchBody = {
        // email: this.state.email,
        // password: this.state.password,
        // phone: this.state.phone,
        // company: this.state.company,// old /customers/${this.state.id}
        firstname: this.state.firstName,
        lastname: this.state.lastName,
      };
    } else {
      secondStepCustomersPatchBody = {
        // email: this.state.email,
        // password: this.state.password,
        phone: `+${this.state.phone.replace(/\D/g,'')}`,
        // company: this.state.company,// old /customers/${this.state.id}
        firstname: this.state.firstName,
        lastname: this.state.lastName,
      };
    }

    // console.log(
    //   "secondStepCustomersPatchBody",
    //   secondStepCustomersPatchBody,
    //   "this.state.id",
    //   this.state.id,
    //   " this.APIToken",
    //   this.APIToken
    // );
    // if (this.state.email && this.state.password) {
    let secondStepAPICustomersPatch = fetch(
      `${this.props.apiUrl}/account`, // old /customers/${this.state.id}
      {
        method: "PATCH",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        credentials: 'include',//'same-origin',
        body: JSON.stringify(secondStepCustomersPatchBody),
      }
    )
      // .then((res) => res.json())
      .then((res) => {
        // console.log("res", res, res.ok);
        if (res.ok) {
          return res.json();
        } else {
          if (res && res.detail) {
            this.onFail(res.detail);
          }
          this.setState({ secondStepPassed: false });
        }
      })
      .then((response) => {
        console.log(
          "Resp secondStepAPICustomersPatch: ",
          response
        );

      })
      .catch((e) => {
        console.log("ERROR", e);
      });
    // }

    // console.log(
    //   "this.state.companySize",
    //   this.state.companySize,
    //   "this.state.id",
    //   this.state.id,
    //   " this.state.apiToken",
    //   this.state.apiToken
    // );

    let secondStepCompaniesAPIPostBody = {
      name: this.state.company,
      minsize: this.state.companySize[0],
      maxsize: this.state.companySize[1],
    };
    // console.log(
    //   "secondStepCompaniesAPIPostBody",
    //   secondStepCompaniesAPIPostBody
    // );
    // if (this.state.email && this.state.password) {
    let secondStepCompaniesAPIPost = fetch(`${this.props.apiUrl}/company`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: 'include',//'same-origin',
      body: JSON.stringify(secondStepCompaniesAPIPostBody),
    })
      // .then((res) => res.json())
      .then((res) => {
        // console.log("res", res, res.ok);
        if (res.ok) {
          return res.json();
        } else {
          if (res && res.detail) {
            this.onFail(res.detail);
          }
          // this.setState({ secondStepPassed: false });
        }
      })
      .then((response) => {
        // console.log(
        //   "Resp secondStepCompaniesAPIPost: ",
        //   response
        // );
        if (!this.state.isError) {
          // console.log("Resp secondStepCompaniesAPIPost", response);
          this.props.setGlobalCompanyParameters(response);
          this.setState({ secondStepPassed: true, companyId: response["ID"] });
        } else {
          if (response && response.statusText) {
            this.onFail(response.statusText);
          }
          // this.setState({ secondStepPassed: false });
        }

      })
      .catch((e) => {
        console.log("ERROR", e);
      });
    // }

    //////////////////////

    Promise.all([secondStepAPICustomersPatch, secondStepCompaniesAPIPost]).then(() => {

      this.props.profileAPILoader();
      this.setState({ currentStep: "thirdRegistrationStepButton", secondStepPassed: true });
    });

    //////////////////////
  };

  thirdStepHandleSubmit = async (event) => {
    // console.log("thirdStepHandleSubmit");
    // var result = Object.keys(this.companies).map((key) =>{ if(key === true){return key} } );
    let thirdStepCompaniesAPIPostBody = {
      name: this.state.company,
      minsize: this.state.companySize[0],
      maxsize: this.state.companySize[1],
      toolset: this.userCompaniesArray || [],
    };
    // console.log(
    //   "secondStepCompaniesAPIPostBody",
    //   thirdStepCompaniesAPIPostBody
    // );
    // if (this.state.email && this.state.password) {
    fetch(`${this.props.apiUrl}/company`, {///companies/${this.state.companyId}
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: 'include',//'same-origin',
      body: JSON.stringify(thirdStepCompaniesAPIPostBody),
    })
      // .then((res) => res.json())
      .then((res) => {
        // console.log("thirdStepHandleSubmit res", res, res.ok);
        if (res.ok) {
          this.setState({ thirdStepPassed: true });
          return res.json();
        } else {
          if (res && res.detail) {
            this.onFail(res.detail);
          }
        }
      })
      .catch((e) => {
        console.log("ERROR", e);
      });
    // }
    // console.log("this.state.user", this.state.user);
    // console.log(
    //   "this.state.id, this.state.email, this.state.password",
    //   this.state.id,
    //   this.state.email,
    //   this.state.password
    // );

    //this.state.company,
    // alert("Success");
    // console.log("this.state.apiToken", this.state.apiToken);
    // this.props.saveApiTokenToLocal(this.state.apiToken);

    this.props.userHasAuthenticated(true);
    this.props.toggleLoading();

    // if( this.props.isAutentificated && this.state.thirdStepPassed){

    this.props.history.push("/forcepage"); // this.props.history.push("/home");
    this.props.handleItemClick("forcepage");
    // }
  };

  onFail = (e) => {
    if (e) {
      console.log("ERROR", e);
      this.setState({ isError: true, errorText: e + "" || "" }); // errorText: e
      // this.respSlackError = e;
    }
  };

  handleServiceSelector = (event) => {
    this.setState({ [event.target.id]: !this.state[event.target.id] });
    this.companies[event.target.id] = !this.state[event.target.id];

    if (!this.userCompaniesArray.includes(event.target.id)) {
      //checking weather array contain the id
      this.userCompaniesArray.push(event.target.id); //adding to array because value doesnt exists
    } else {
      this.userCompaniesArray.splice(
        this.userCompaniesArray.indexOf(event.target.id),
        1
      ); //deleting
    }
  };
  handleAccoutnDeleteAndRedirect = (e) => {
    // console.log("handleAccoutnDeleteAndRedirect");
    // if (link && this.props.apiToken){
    if (this.props.apiUrl && this.state.id && this.state.apiToken) {
      fetch(`${this.props.apiUrl}/account`, { // old /customers/${this.state.id}
        // mode: "no-cors",
        accept: "*/*",
        method: "DELETE",
        // credentials: 'include',
        // headers: {
        //   // Accept: "application/json",
        //   "Content-Type": "application/json",
        // },
        credentials: 'include',//'same-origin',
      })
        .then((res) => {
          // console.log("res", res, res.ok);
          if (res.ok) {
            // return res.json();
            // console.log("res", res);
            document.location.href = 'https://pavooq.com/';
            // this.props.handleLogout();
          } else {
            console.log(res);
            this.onFail(res);
          }
        })
        .then((data) => {
          // data = JSON.parse(JSON.stringify(data));
          console.log("profile API  ", data);
          document.location.href = 'https://pavooq.com/';
          // this.props.handleLogout();
        })
        .catch((error) => {
          console.log(error);
          this.onFail(error);
        });
    } else {
      document.location.href = 'https://pavooq.com/';
    }
  }

  errorContainerRender = () => {
    return (
      <Modal
        size="mini"
        closeIcon={<div className="close-container"></div>}
        className={this.props.darkTheme ? "customModal dark" : "customModal"}
        centered={true} //false
        open={this.state.isError}
        onClose={() => this.setState({ isError: false })}
        onOpen={() => this.setState({ isError: true })}
      // trigger={

      // } //{<Button>Show Modal</Button>}
      >
        <Modal.Header>Opps..</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            An error has been occurred.
            <br />
            {this.state.errorText}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions className="center">
          <Button
            className="wide"
            onClick={() => this.setState({ isError: false })}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
  canselContainerRender = () => {
    return (
      <>
        <div className="cancel-container">
          <Modal
            // size="mini"
            // dimmer={this.props.darkTheme ? undefined : "inverted"} //'blurring'//
            closeIcon={<div className="close-container"></div>}
            className={
              this.props.darkTheme ? "customModal cancel dark" : "customModal cancel"
            }
            centered={true} //false
            open={this.state.openCancelModal}
            onClose={() => this.setState({ openCancelModal: false })}
            onOpen={() => this.setState({ openCancelModal: true })}
            trigger={<button className="borderless-button">Cancel</button>} //{<Button>Show Modal</Button>}
          >
            <Modal.Header>Cencel Regirtration</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                Are you sure you want to cancel registration and back to the
                landing page?
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions className="center">
              <Button
                className="cancel-button"
                onClick={() => this.setState({ openCancelModal: false })}
              >
                Cancel
              </Button>
              <Button
                // className="wide"
                // as="a"
                // href="https://pavooq.com/"
                // to="https://pavooq.com/"
                onClick={this.handleAccoutnDeleteAndRedirect}
              >
                Sure
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
        <div className="signup-link-container">
          <span>Do you have account? </span>
          <Link className="login-link" to={`/login`}>
            {" "}
            Log In
          </Link>
        </div>
      </>
    );
  };
  render() {
    // console.log("this.state.phone", this.state.phone);
    return (
      <div
        // className="SignUp"
        className={this.props.darkTheme ? "SignUp dark" : "SignUp"}
      >
        <div className="main-content">
          {this.state.currentStep === "firstRegistrationStepButton" && (
            <>
              {this.canselContainerRender()}
              {this.errorContainerRender()}
              <div className="sign-in-container">
                <div className="logo-big-container"></div>
                {/* <img src={logoBigWhite} alt="" srcSet="" /> */}
              </div>

              <div className="main-registration-form">
                {/* <div> */}
                <h5>Create Pavooq account</h5>
                {/* </div> */}
                <div>
                  <div className="registration-form">
                    <Form
                      autoComplete="new-password"
                      onSubmit={this.firstStepHandleSubmit}
                    >
                      <Button
                        className="field big-button google-btn"
                        fluid
                        as="a"
                        href={`${this.props.apiUrl}/oauth/google`}
                        // disabled
                        type="submit"
                      >
                        <img src={googleIcon} alt="" srcSet="" />
                        <span>Sign up with Google</span>
                      </Button>

                      {/* <a target="_blank" rel="noopener noreferrer" className="field big-button slack-btn" href="https://slack.com/oauth/v2/authorize?scope=incoming-webhook&client_id=33336676.569200954261">
                    <img src={slackIcon} alt="" srcSet="" />
                    <span>Sign in with Slack</span>
                  </a> */}

                      <div className="login-opts-divider">
                        <hr /> <span> Or </span> <hr />
                      </div>

                      <Form.Input
                        // disabled
                        // error={}
                        className={
                          !this.state.isEmailValid
                            ? "item-content error"
                            : "item-content"
                        }
                        autoComplete="new-password"
                        name="email"
                        id="email"
                        // label='Email'
                        placeholder="Email"
                        value={this.state.email}
                        onPaste={this.handleChange}
                        onChange={this.handleChange}
                      />
                      {!this.state.isEmailValid && (
                        <div className="email-error">
                          Please enter valid email
                        </div>
                      )}

                      {/* <Form.Field className="item-content pwrd-imput">
                        <div
                          className={
                            this.state.isPasswordVisible
                              ? "icon pwrd-icon"
                              : "icon pwrd-icon-hidden"
                          }
                          onClick={this.togglePass}
                          alt=""
                          srcSet=""
                        ></div>
                         */}

                      <Form.Input //Field
                        // disabled
                        className="item-content"
                        icon={
                          <div
                            className={
                              this.state.isPasswordVisible
                                ? "pwrd-icon"
                                : "pwrd-icon-hidden"
                            }
                            onClick={this.togglePass}
                            alt=""
                            srcSet=""
                          ></div>
                        }
                        // label='Password'
                        type={
                          this.state.isPasswordVisible ? "text" : "password"
                        }
                        autoComplete="new-password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        value={this.state.password}
                        onPaste={this.handleChange}
                        onChange={this.handleChange}
                      />
                      <PasswordStrengthBar
                        className="PasswordStrengthBar"
                        minScore={this.minPassScore}
                        minLength={6}
                        onChangeScore={this.handleChangePWD}
                        password={this.state.password}
                        barColors={[
                          this.props.darkTheme ? " #686868" : "#9A8AB6",
                          "#E01E5A",
                          "#DCE01E",
                          "#DCE01E",
                          "#27C245",
                        ]}
                      />

                      {/* </Form.Field> */}

                      {/* ///////////////////////////////// */}

                      <div className="additional-login-options">
                        {/* <div className="policy-wrapper"> */}
                        <Checkbox
                          className=" checkbox-custom field "
                          label={
                            <label id="policyCheckboxLabel">I have read</label>
                          } // label="I have read"
                          id="policyCheckbox"
                          checked={this.state.privatPolicyAcepted}
                          onClick={this.hendleCheckBoxChange}
                        />
                        <div className="privacy-policy-container">
                          {/* ${ */}
                          <Modal
                            // size="mini"
                            // dimmer={
                            //   this.props.darkTheme ? undefined : "inverted"
                            // }
                            dimmer={
                              this.props.darkTheme ? undefined : "inverted"
                            } //'blurring'//
                            closeIcon={<div className="close-container"></div>}
                            className={
                              this.props.darkTheme
                                ? "customModal dark"
                                : "customModal"
                            }
                            centered={true} //false
                            open={this.state.openPolicyModal}
                            onClose={() =>
                              this.setState({ openPolicyModal: false })
                            }
                            onOpen={() =>
                              this.setState({
                                openPolicyModal: true,
                                policyButtonAvailable: false,
                              })
                            }
                            trigger={
                              <button
                                id="readPolicy"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                }}
                                className="borderless-button"
                              >
                                Privacy Policy
                              </button>
                            }
                          >
                            <Modal.Header>Privacy Policy</Modal.Header>
                            <Modal.Content
                            // scrolling
                            // onScroll={this.handleScroll}
                            >
                              {/* <Modal.Description> */}
                              <IframePolicy
                                src="https://docs.google.com/document/d/e/2PACX-1vQAi0y6ovCZTbkuRRxdOd0bYQAAo22W2NLw9hhlAkh0buKM3k9MRpW2DreUgOyULYsukS16hXOKcSjQ/pub?embedded=true"
                                // scrolling="no"
                                frameBorder="no"
                                height="520px"
                                width="100%"
                              />
                              {/* </Modal.Description> */}
                            </Modal.Content>
                            <Modal.Actions className="center">
                              <Button
                                // disabled={
                                //   this.state.policyButtonAvailable
                                //     ? false
                                //     : true
                                // }
                                // className={this.state.policyButtonAvailable ?'' :  'disabled' }
                                onClick={() =>
                                  this.setState({
                                    openPolicyModal: false,
                                    privatPolicyAcepted: true,
                                  })
                                }
                              >
                                I have read and agree
                              </Button>
                            </Modal.Actions>
                          </Modal>
                          {/* } */}
                        </div>
                        {/* </div> */}
                      </div>

                      <Button
                        className="big-button"
                        fluid
                        type="submit"
                        id="submitFirstRegistrationStep"
                        disabled={!this.validateFirstStep()}
                      >
                        <h4>
                          <span>Next</span>
                        </h4>
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </>
          )}
          {this.state.currentStep === "secondRegistrationStepButton" && (
            <>
              {this.canselContainerRender()}
              {this.errorContainerRender()}
              <div className="sign-in-container">
                <div className="logo-big-container"></div>
                {/* <img src={logoBigWhite} alt="" srcSet="" /> */}
              </div>
              <div className="main-registration-form">
                {/* <div> */}
                <h5>Create Pavooq account</h5>
                {/* </div> */}
                <div>
                  <div className="registration-form">
                    <Form
                      autoComplete="name"
                      onSubmit={this.secondStepHandleSubmit}
                    >
                      {/* <Form.Input //Field
                        // disabled
                        // className="item-content"
                        className={
                          !this.state.isNameValid
                            ? "item-content error"
                            : "item-content"
                        }
                        autoComplete="new-password"
                        name="name"
                        id="name"
                        // label='Email'
                        placeholder="Full Name"
                        value={this.state.name}
                        onChange={this.handleChange}
                      />
                      {!this.state.isNameValid && (
                        <div className="email-error">
                          Please enter valid first & last names
                        </div>
                      )} */}

                      <Form.Input
                        className="item-content"
                        autoComplete="new-password"
                        name="firstName"
                        id="firstName"
                        // label='Email'
                        placeholder="First Name"
                        value={this.state.firstName}
                        onPaste={this.handleChange}
                        onChange={this.handleChange}
                      />

                      <Form.Input
                        className="item-content"
                        autoComplete="new-password"
                        name="lastName"
                        id="lastName"
                        // label='Email'
                        placeholder="Last Name"
                        value={this.state.lastName}
                        onPaste={this.handleChange}
                        onChange={this.handleChange}
                      />

                      <Form.Input
                        className="item-content"
                        autoComplete="new-password"
                        name="phone"
                        id="phone"
                        // label='Email'
                        placeholder="Phone number (optional)"
                        value={this.state.phone}
                        onPaste={this.handleChange}
                        onChange={this.handleChange}
                      />

                      {!this.state.isPhoneNumberValid && (
                        <div className="phone-error">
                          Please enter valid phone number (+XXXXXXXXXXXX)
                        </div>
                      )}

                      <Form.Input //Field
                        // disabled
                        className="item-content"
                        autoComplete="new-password"
                        name="company"
                        id="company"
                        // label='Email'
                        placeholder="Company Name"
                        value={this.state.company}
                        onPaste={this.handleChange}
                        onChange={this.handleChange}
                      />

                      <Form.Select
                        fluid
                        // search
                        className="item-content"
                        // label='companySize'
                        // value={this.companySize}
                        options={this.options}
                        defaultValue={this.state.companySize}
                        placeholder="Company Size"
                        name="companySize"
                        id="companySize"
                        selection
                        onChange={this.handleSelect}
                      />

                      <div className="privacy-policy-container"></div>
                      <Button
                        className="big-button"
                        fluid
                        type="submit"
                        id="submitSecondRegistrationStep"
                        disabled={!this.validateSecondStep()}
                      >
                        <h4>
                          <span>Next</span>
                        </h4>
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </>
          )}
          {this.state.currentStep === "thirdRegistrationStepButton" && (
            <>
              {this.errorContainerRender()}
              <div className="sign-in-container">
                <div className="logo-big-container"></div>
                {/* <img src={logoBigWhite} alt="" srcSet="" /> */}
              </div>

              <div className="main-registration-form questionnaire-container">
                {" "}
                {/* <div> */}
                <h5>Create Pavooq account</h5>
                <div className="confirm-email-container"></div>
                <p>Please confirm your email to proceed...</p>
                {/* <hr /> */}
                <p>Please mark the services you use for work communications</p>
                <Form
                  autoComplete="new-password"
                  className="service-items-container"
                  onSubmit={this.thirdStepHandleSubmit}
                >
                  {/* onSubmit={this.handleSubmit} > */}

                  {/* <div className="service-items-container"> */}
                  <div
                    className={
                      this.state.slask ? "service-item active" : "service-item"
                    }
                    id="slask"
                    onClick={this.handleServiceSelector}
                  >
                    <div className="slask"></div>
                    Slack
                  </div>
                  <div
                    className={
                      this.state.googleChat
                        ? "service-item active"
                        : "service-item"
                    }
                    id="googleChat"
                    onClick={this.handleServiceSelector}
                  >
                    <div className="google-chat"></div>
                    Google Chat
                  </div>
                  <div
                    className={
                      this.state.telegram
                        ? "service-item active"
                        : "service-item"
                    }
                    id="telegram"
                    onClick={this.handleServiceSelector}
                  >
                    <div className="telegram"></div>
                    Telegram
                  </div>
                  <div
                    className={
                      this.state.microsoftTeams
                        ? "service-item active"
                        : "service-item"
                    }
                    id="microsoftTeams"
                    onClick={this.handleServiceSelector}
                  >
                    <div className="microsoft-teams"></div>
                    Microsoft Teams
                  </div>
                  <div
                    className={
                      this.state.zoom ? "service-item active" : "service-item"
                    }
                    id="zoom"
                    onClick={this.handleServiceSelector}
                  >
                    <div className="zoom"></div>
                    Zoom
                  </div>
                  <div
                    className={
                      this.state.skype ? "service-item active" : "service-item"
                    }
                    id="skype"
                    onClick={this.handleServiceSelector}
                  >
                    <div className="skype"></div>
                    Skype
                  </div>
                  <div
                    className={
                      this.state.mattermost
                        ? "service-item active"
                        : "service-item"
                    }
                    id="mattermost"
                    onClick={this.handleServiceSelector}
                  >
                    <div className="mattermost"></div>
                    Mattermost
                  </div>
                  <div
                    className={
                      this.state.googleMeet
                        ? "service-item active"
                        : "service-item"
                    }
                    id="googleMeet"
                    onClick={this.handleServiceSelector}
                  >
                    <div className="google-meet"></div>
                    Google Meet
                  </div>
                  <div
                    className={
                      this.state.whatsApp
                        ? "service-item active"
                        : "service-item"
                    }
                    id="whatsApp"
                    onClick={this.handleServiceSelector}
                  >
                    <div className="whatsapp"></div>
                    WhatsApp
                  </div>
                  <div
                    className={
                      this.state.signal ? "service-item active" : "service-item"
                    }
                    id="signal"
                    onClick={this.handleServiceSelector}
                  >
                    <div className="signal"></div>
                    Signal
                  </div>
                  <div
                    className={
                      this.state.googleWorkspace
                        ? "service-item active"
                        : "service-item"
                    }
                    id="googleWorkspace"
                    onClick={this.handleServiceSelector}
                  >
                    <div className="google-workspace"></div>
                    Google Workspace
                  </div>
                  <div
                    className={
                      this.state.office365
                        ? "service-item active"
                        : "service-item"
                    }
                    id="office365"
                    onClick={this.handleServiceSelector}
                  >
                    <div className="office-365"></div>
                    Office 365
                  </div>
                  <div
                    className={
                      this.state.ownServer
                        ? "service-item active"
                        : "service-item"
                    }
                    id="ownServer"
                    onClick={this.handleServiceSelector}
                  >
                    <div className="own-server"></div>
                    Own Server
                  </div>
                  <div
                    className={
                      this.state.otherService
                        ? "service-item active"
                        : "service-item"
                    }
                    id="otherService"
                    onClick={this.handleServiceSelector}
                  >
                    <div className="other-service"></div>
                    Other
                  </div>
                  {/* </div> */}
                  <div className="button-container">
                    {/* <Form
                      autoComplete="name"
                      onSubmit={this.secondStepHandleSubmit}
                    >
                    </Form> */}
                    <Button
                      className="big-button"
                      fluid
                      id="submitThirdRegistrationStep"
                      type="submit"
                      disabled={
                        !(
                          this.state.firstStepPassed &&
                          this.state.secondStepPassed
                        )
                      }
                      // onClick={() => this.thirdStepHandleSubmit}
                    >
                      <span>Send and go to demo dashboard</span>
                    </Button>
                  </div>
                </Form>
              </div>
            </>
          )}
        </div>
        <div className="additional-container">
          <div className="rounds-container">
            <div
              id="firstRegistrationStepButton"
              onClick={this.stepChange}
              className={
                this.state.currentStep === "firstRegistrationStepButton"
                  ? "round active"
                  : "round"
              }
            ></div>
            <div
              id="secondRegistrationStepButton"
              onClick={this.stepChange}
              className={
                this.state.currentStep === "secondRegistrationStepButton"
                  ? "round active"
                  : "round"
              }
            ></div>
            <div
              id="thirdRegistrationStepButton"
              onClick={this.stepChange}
              className={
                this.state.currentStep === "thirdRegistrationStepButton"
                  ? "round active"
                  : "round"
              }
            ></div>
          </div>

          <div className="mail-to-container">
            <div className="avatar-monochrome-support"></div>
            <p>
              Any problem? Please, write me at
              <a href="mailto:yevhen.bereza@pavooq.com">
                {" "}
                yevhen.bereza@pavooq.com
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
