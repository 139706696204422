import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Checkbox } from "semantic-ui-react";
// import DescriptionModalWindow from "../../components/DescriptionModalWindow";
// import "./Settings.css";
import "./Settings.sass";
// import userIcon from "../../img/user.svg";
// import userIconDark from "../../img/user-dark.svg";
// import UserForInviteSearch from "../UserForInviteSearch/UserForInviteSearch";
// import * as d3 from "d3";
export default class Settings extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    // };
  }

  render() {

    return (
      <div className="SettingsPage">
        <div className="settings-wrapper">
          <h5 className="settings-title">Settings</h5>

          <div className="settings-item">
            <div className="item-text">
              <span>Grid condition</span>
              <p>
                Use grid to set nodes positions
              </p>
            </div>
            <Checkbox
              className="custom-checkbox"
              toggle
              // defaultChecked={this.props.gridChartCondition}
              onChange={this.props.gridToggler}
              checked={this.props.gridChartCondition}
            />
          </div>

          <hr />

          <div className="settings-item">
            <div className="item-text">
              <span>Hide inactive nodes</span>
              <p>
                Turn on to hide nodes who have not written any private messages
              </p>
            </div>
            <Checkbox
              className="custom-checkbox"
              toggle
              // defaultChecked={this.props.hideEmptyUsersCondition}
              onChange={this.props.emptyNodesToggler}
              checked={this.props.hideEmptyUsersCondition}
            />
          </div>

        </div>
      </div>
    );
  }
}
