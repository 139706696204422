import React, { Component } from 'react'
import { createPortal } from 'react-dom'
// import ReactDOM from 'react-dom';

export class IframePolicy extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // mountNode: null,
      iFrameHeight: undefined
    }
    // this.setContentRef = (contentRef) => {
    //   this.setState({
    //     mountNode: contentRef?.contentWindow?.document?.body
    //   })
    // }
  }
//   componentDidMount() {
//     const obj = ReactDOM.findDOMNode(this);
//     this.setState({iFrameHeight:  obj.contentWindow.document.body.scrollHeight + 'px'});
//  }

  render() {
    const { children, title, ...props } = this.props
    const { mountNode } = this.state
    return (
      <iframe
    //   onLoad={() => {
    //     const obj = ReactDOM.findDOMNode(this);
    //     this.setState({
    //         iFrameHeight:  obj.contentWindow.document.body.scrollHeight + 'px'
    //     });
    // }}
      // style={{
      // // maxWidth:640,scrolling="no" frameborder="no" height="100%" width="100%"
      // scroling:"no",
      // frameborder:"no",
      // width:'100%',
      // height:this.state.iFrameHeight,
      // overflow:'auto'}}
        title={title}
        {...props}
        ref={this.setContentRef}
        // width="100%" 
        // height={this.state.iFrameHeight}
      >
        {mountNode && createPortal(children, mountNode)}
      </iframe>
    )
  }
}
