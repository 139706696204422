import React from "react";
import { Route, Redirect } from "react-router-dom";// export default ({ component: C, props: cProps, ...rest }) => (//   <Route {...rest} render={props => <C {...props} {...cProps} />} />
// );

export default ({ component: C, props: cProps, ...rest }) => {
  // const params = new URLSearchParams(cProps.location.search);
  // const page = params.get('page') || '';
  // console.log("AppliedRoute", page);
  return (
    <Route
      {...rest}
      render={(props) => {
        return (cProps.isAuthenticated &&
          (props.location.pathname === `/login` || props.location.pathname === '/oauth/google/success')) ? (
          <Redirect to="/forcepage" />
        ) : (!cProps.isAuthenticated && props.location.pathname === `/login`) || // && !cProps.mainLoader
          (cProps.isAuthenticated && props.location.pathname !== `/login`) ||
          (!cProps.isAuthenticated &&
            props.location.pathname === `/reset/success`) ||
          (cProps.isAuthenticated &&
            props.location.pathname === `/reset/success`) ||
          (!cProps.isAuthenticated &&
            props.location.pathname === `/reset/error`) ||
          (cProps.isAuthenticated &&
            props.location.pathname === `/reset/error`) ||
          (!cProps.isAuthenticated &&
            props.location.pathname === `/recoverpassword`) ||
          (cProps.isAuthenticated &&
            props.location.pathname === `/oauth/success`) ||
          (!cProps.isAuthenticated &&
            props.location.pathname === `/oauth/success`) ||
          // (cProps.isAuthenticated &&
          //   props.location.pathname === `/oauth/google/success`) ||
          // (!cProps.isAuthenticated &&
          //   props.location.pathname === `/oauth/google/success`) ||
          (!cProps.isAuthenticated &&
            props.location.pathname === `/oauth/error/cancel`) ||
          (!cProps.isAuthenticated &&
            props.location.pathname === `/oauth/google/error`) ||
          (!cProps.isAuthenticated &&
            props.location.pathname === `/oauth/error/internal`) ||
          (!cProps.isAuthenticated &&
            props.location.pathname === `/oauth/error/external`) ||
          (!cProps.isAuthenticated &&
            props.location.pathname === `/oauth/error/exists`) ||
          (!cProps.isAuthenticated &&
            props.location.pathname === `/oauth/error/mismatch`) ||
          (!cProps.isAuthenticated &&
            props.location.pathname === `/email/success`) ||
          (!cProps.isAuthenticated &&
            props.location.pathname === `/email/error`) ||
          (cProps.isAuthenticated && props.location.pathname === `/settings`) ||
          (cProps.isAuthenticated && props.location.pathname === `/profile`) ||
          (cProps.isAuthenticated &&
            props.location.pathname === `/inviteslackmembers`) ||
          (!cProps.isAuthenticated &&
            props.location.pathname === `/inviteslackmembers`) ? (
          <C {...props} {...cProps} />
        ) : (
          <Redirect to="/forcepage" />
        );
      }}
    />
  );
};
