import React, { Component } from "react";import { Link } from "react-router-dom"; //withRouterimport { Button } from 'semantic-ui-react';// import DescriptionModalWindow from "../../components/DescriptionModalWindow";// import "./ConnectSlack.css";
import "./ConnectSlack.sass";
import slackIcon from "../../img/slack-icon.svg";
import { Button } from "semantic-ui-react";

export default class ConnectSlack extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.apiUrl = window.__RUNTIME_CONFIG__.REACT_APP_API_URL || undefined; //process.env
    this.slackLink = `${this.apiUrl}/providers/slack/install`;
  }
  componentDidMount() {
    document.title = "Pavooq Connect My Slack";
    // this.setState({slackUrl: this.props.slackUrl})
  }

  // runToSlack = () => {
  //   let apiUrl = window.__RUNTIME_CONFIG__.REACT_APP_API_URL || undefined; //process.env
  //   fetch(`${apiUrl}/providers/slack/install`,
  //     {
  //       method: "GET",
  //       credentials: "include", //'same-origin',
  //     }
  //   )
  //     .then((response) => {
  //       if (response.ok) {
  //         return response.json();
  //       } else {
  //         return Promise.reject({
  //           status: response.status,
  //           statusText: response.statusText
  //         });
  //       }
  //     })
  //     .then((response) => {
  //       window.location.assign(response);
  //     })
  //     .catch((e) => {
  //     console.log("ERROR", e);
  //     this.props.onFail("inviteSlackUsers error");
  //     });
  // }
  // shouldComponentUpdate(nextProps, nextState) {
  //   // console.log("ForcePage, shouldComponentUpdate", !(nextProps.nodes === this.props.nodes && nextProps.links === this.props.links && nextProps.relationSelected === this.props.relationSelected))
  //   return !(nextProps.slackUrl === this.props.slackUrl);
  // }

  render() {
    return (
      <div className="ConnectSlackPage">
        <div className="slack-connection-wrapper">
          <Link id="comeBackToLogin" className="logo-medium-container" to={`/`}>
            <div className="logo-big-container"></div>
          </Link>

          <h2>Connect my Slack workspace</h2>
          <div className="conent">
            <div>
              <p className="accentuated">
                By clicking on the button you will see a Slack security popup.
                You need to allow Pavooq to connect to your workspace.
              </p>
              <Button
                as="a"
                id="redirectToSlackID"
                className="field big-button slack-btn"
                fluid
                href={this.slackLink || ""}
                // href={this.props.slackUrl || ""}
                // onClick={this.runToSlack}
              >
                <>
                  <img src={slackIcon} alt="" srcSet="" />
                  <span>Connect my workspace</span>
                </>
              </Button>
            </div>
            {/* <div className="login-opts-divider">
              <hr /> <span> Or </span> <hr />
            </div>
            <div>
              <div className="not-admin-container">
                <p className="accentuated">
                  I don’t have admin rights in Slack workspace
                </p>
                <p>
                  You need to send the link to your Slack admin
                </p>

                <div className="copy-button-container">
                  <Input
                    className="copy-input item-content"
                    defaultValue={
                      this.props.slackUrl
                    } //defaultValue
                  />
                  <Popup
                    // onClose={this.handleClosePopup}
                    // onOpen={this.handleOpenPopup}
                    content={`Copied !`}
                    on='click'
                    position="bottom right"
                    trigger={
                      <Button
                        className="copy-button"
                        onClick={(e) => {
                          let link =
                            this.props.slackUrl.replace(
                              /['"]+/g,
                              ""
                            );
                          // console.log("copied"," this.props.slackUrl", link);
                          navigator.clipboard.writeText(
                            link // JSON.stringify(link)
                          );
                        }}
                      >
                        Copy
                      </Button>
                    }
                  />
                </div>

                <Link
                  className="bottom-link"
                  to="/forcepage"
                >
                  Close and go back to Demo dashboard
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
